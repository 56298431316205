<template>
  <page title="Šablóny provizií">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa príjemcu</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-2">
            <select-field
              :options="tmRewardTemplateTypes"
              v-model="filter.type"
              :codelist="true"
              field-id="type"
              label="Stav">
            </select-field>
          </div>
          <div class="col-md-2">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.inactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
      </template>
      <template #body>
        <reward-template-modal :show-model="showCreateModal" :model="selectedRewardTemplate" @close="hideModals()"></reward-template-modal>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table no-padding table-hover dataTable" v-if="tmRewardTemplates && tmRewardTemplates.length">
                <thead>
                <tr>
                  <the label="Príjemca" property="recipient.label" :sort-data="sortData" @sort="sort($event)"></the>
                  <th>Typ</th>
                  <th>Odmena za MD</th>
                  <the label="Subjekt" property="sourceSubject.label" :sort-data="sortData" @sort="sort($event)"></the>
                  <th>Projekt</th>
                  <th></th>
                </tr>
                <template v-for="tmRewardTemplate in tmRewardTemplates">
                  <tr :class="{inactive : !tmRewardTemplate.active}">
                    <td>
                      <subject-link v-if="tmRewardTemplate.recipient" :subject="tmRewardTemplate.recipient"></subject-link>
                    </td>
                    <td>{{ tmRewardTemplate.type | codeListValue(tmRewardTemplateTypes) }}</td>
                    <td v-if="tmRewardTemplate.rewardShare">{{ tmRewardTemplate.rewardShare | number }} %</td>
                    <td v-else>{{ tmRewardTemplate.fixedRate | currency(templateCurrency(tmRewardTemplate.sourceProject.id)) }}</td>
                    <td>
                      <subject-link v-if="tmRewardTemplate.sourceSubject" :subject="tmRewardTemplate.sourceSubject"></subject-link>
                    </td>
                    <td>
                      <project-link v-if="tmRewardTemplate.sourceProject" :project="tmRewardTemplate.sourceProject"></project-link>
                    </td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editRewardTemplate(tmRewardTemplate)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="toggleRewardTemplateActive(tmRewardTemplate)"><i :class="{'fa-toggle-on': tmRewardTemplate.active, 'fa-toggle-off text-success': !tmRewardTemplate.active}" class="fa fa-2x" :title="tmRewardTemplate.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteRewardTemplate(tmRewardTemplate)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                </template>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="tmRewardTemplates"></loading>
            <no-records :data="tmRewardTemplates" loading-id="tmRewardTemplates"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapGetters, mapState } from 'vuex';
  import tmRewardTemplateTypes from 'codelist/tmRewardTemplateTypes';
  import rewardTemplateModal from 'components/tmRewardTemplateCreateModal';
  import notificationService from 'services/notificationService';
  import tmRewardTemplateService from 'services/tmRewardTemplateService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import the from 'components/the';
  import currencies from 'codelist/currencies';
  import projectLink from 'modules/project/components/projectLink';
  import subjectLink from 'components/subjectLink';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { rewardTemplateModal, loading, noRecords, selectField, the, projectLink, subjectLink, page, box },
    data: () => ({
      showCreateModal: false,
      showChangePasswordModal: false,
      selectedRewardTemplate: null,
      tmRewardTemplateTypes: tmRewardTemplateTypes,
      filter: {
        query: null,
        inactive: false,
        type: null
      },
      sortData: {
        by: 'recipient.label',
        asc: true
      },
      currencies: currencies
    }),
    computed: {
      ...mapState({
        tmRewardTemplates (state) {
          let allRewardTemplates = state.tmRewardTemplate.items;
          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            allRewardTemplates = allRewardTemplates.filter(tmRewardTemplate => !!normalize(tmRewardTemplate.recipient.format()).match(regexp));
          }
          const sorted = _.sortBy(allRewardTemplates, this.sortData.by);
          return this.sortData.asc ? sorted : sorted.reverse();
        }
      }),
      ...mapGetters({
        projects: 'tmProject/openWithCurrency'
      })
    },
    methods: {
      // we need find out currency from state's projects because reward templates are fetched without project's orders
      templateCurrency (projectId) {
        const tmProject = _.find(this.projects, { id: projectId });
        return !!tmProject && !!tmProject.currency ? tmProject.currency : null;
      },
      createShow () {
        this.selectedRewardTemplate = tmRewardTemplateService.newTmRewardTemplate();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
        this.showChangePasswordModal = false;
      },
      deleteRewardTemplate (tmRewardTemplate) {
        this.confirm('tmRewardTemplate.delete.confirmation').then(() => {
          this.$store.dispatch('tmRewardTemplate/delete', tmRewardTemplate.id).then((result) => {
            if (result) {
              notificationService.success('tmRewardTemplate.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      toggleRewardTemplateActive (tmRewardTemplate) {
        const action = tmRewardTemplate.active ? 'deactivate' : 'activate';
        this.$store.dispatch('tmRewardTemplate/' + action, tmRewardTemplate.id).then((result) => {
          if (result) {
            notificationService.success('tmRewardTemplate.' + action + '.success');
          } else {
            notificationService.error('error.tmRewardTemplate.' + action);
          }
        });
      },
      editRewardTemplate (tmRewardTemplate) {
        this.selectedRewardTemplate = tmRewardTemplate;
        this.showCreateModal = true;
      },
      createFilter () {
        const filter = this.filter;
        return _.pickBy({
          inactive: filter.inactive ? filter.inactive : null,
          type: filter.type ? filter.type : null
        }, _.identity);
      },
      sort (sortData) {
        this.sortData.by = sortData.by;
        this.sortData.asc = sortData.asc;
      }
    },
    watch: {
      filter: {
        handler () {
          this.$store.dispatch('tmRewardTemplate/getAllByFilter', this.createFilter());
        },
        deep: true
      }
    },
    created () {
      this.$store.dispatch('tmRewardTemplate/getAllByFilter', this.createFilter());
    }
  };
</script>
