import moneyBoxResource from './moneyBoxResource';
import _ from 'lodash';
import MoneyBox from '../domain/moneyBox';
import MoneyBoxBalance from "../domain/moneyBoxBalance";

export default {
  newMoneyBox() {
    return new MoneyBox({
      type: 'CASH'
    });
  },
  async findAll() {
    const response = await moneyBoxResource.query();
    if (response.ok) {
      return response.data.map((moneyBoxData) => new MoneyBox(moneyBoxData));
    } else {
      return null;
    }
  },
  async balances(filter) {
    const response = await moneyBoxResource.balances( _.pickBy(filter));
    if (response.ok) {
      return response.data.map((moneyBoxBalanceData) => new MoneyBoxBalance(moneyBoxBalanceData));
    } else {
      return null;
    }
  },
  async create(moneyBox) {
    const response = await moneyBoxResource.save({}, _.pickBy(moneyBox));
    if (response.ok) {
      return new MoneyBox(response.data);
    } else {
      return null;
    }
  },
  async update(moneyBox) {
    const response = await moneyBoxResource.update({}, _.pickBy(moneyBox));
    if (response.ok) {
      return new MoneyBox(response.data);
    } else {
      return null;
    }
  },
  async updatePosition(id, position) {
    const response = await moneyBoxResource.updatePosition({id, position}, {});
    return response.ok;
  },
  async delete(id) {
    return moneyBoxResource.delete({id: id});
  }
};
