'use strict';
import _ from 'lodash';

export default {
  active: (state) => {
    return _.filter(state.items || [], 'active');
  },
  activeWithToken: (state) => {
    return _.filter(state.items || [], item => item.active && !!item.apiToken);
  }
};
