import invoiceResource from './invoiceResource';
import deliveryService from 'services/tmDeliveryService';
import _ from 'lodash';
import {vsprintf} from 'sprintf-js';
import Invoice from '../domain/invoice';
import moment from 'moment';
import {
  DEFAULT_CONSTANT_SYMBOL,
  DEFAULT_CURRENCY,
  DEFAULT_INOVOICE_ITEM_UNIT,
  DEFAULT_PAYMENT_METHOD,
  INVOICE_ITEM_NAME_TEMPLATE_FOR_DELIVERY
} from 'config';
import InvoiceItem from '../domain/invoiceItem';
import {DOWNLOAD_BLOB, DOWNLOAD_BLOB_TEXT_FILE, FORMAT_DATE_PERIOD_SLASH} from '../../../utils';
import companyService from '../../company/service/companyService';
import BankAccount from '../../bank/domain/bankAccount';

export default {
  newOutcomeInvoice (invoiceSeries, configuration) {
    return new Invoice({
      invoiceSeries: _(invoiceSeries).filter('defaultFlag').first(),
      type: 'OUTCOME',
      dueDate: moment().add(configuration.invoice.defaultDueDateOffset, 'd'),
      dateOfIssue: moment(),
      taxableDate: moment(),
      items: [this.newInvoiceItem()],
      currency: DEFAULT_CURRENCY,
      paymentMethod: DEFAULT_PAYMENT_METHOD,
      constantSymbol: DEFAULT_CONSTANT_SYMBOL
    });
  },
  newIncomeInvoice (configuration) {
    return new Invoice({
      type: 'INCOME',
      dueDate: moment().add(configuration.invoice.defaultDueDateOffset, 'd'),
      dateOfIssue: moment(),
      taxableDate: moment(),
      currency: DEFAULT_CURRENCY,
      paymentMethod: DEFAULT_PAYMENT_METHOD,
      constantSymbol: DEFAULT_CONSTANT_SYMBOL
    });
  },
  async outcomeCopy (invoiceId, invoiceSeries, configuration) {
    const origInvoice = await this.find(invoiceId);
    const newInvoice = this.newOutcomeInvoice(invoiceSeries, configuration);
    this.copyInvoice(origInvoice, newInvoice);
    return newInvoice;
  },
  async incomeCopy (invoiceId, configuration) {
    const origInvoice = await this.find(invoiceId);
    const newInvoice = this.newIncomeInvoice(configuration);
    this.copyInvoice(origInvoice, newInvoice);
    return newInvoice;
  },
  async outcomeForDelivery (deliveryIds, invoiceSeries, configuration) {
    const firstDeliveryId = deliveryIds[0];
    const firstDelivery = await deliveryService.find(firstDeliveryId);
    const supplier = await companyService.find(firstDelivery.order.mainOrder.supplier.id);
    const subscriber = await companyService.find(firstDelivery.order.mainOrder.subscriber.id);
    const invoice = this.newOutcomeInvoice(invoiceSeries, configuration);

    const mainOrder = firstDelivery.order.mainOrder;
    const dueDateOffset = _.isNil(mainOrder.invoiceDueDateOffset) ? configuration.invoice.defaultDueDateOffset : mainOrder.invoiceDueDateOffset;
    invoice.dateOfIssue = moment(firstDelivery.period).endOf('month');
    invoice.taxableDate = moment(firstDelivery.period).endOf('month');
    invoice.dueDate = moment(firstDelivery.period).endOf('month').add(dueDateOffset, 'd');
    invoice.supplier = supplier;
    invoice.subscriber = subscriber;
    invoice.reference = mainOrder.number;
    invoice.currency = mainOrder.currency;

    const bankAccounts = _.filter(invoice.supplier.bankAccounts, {currency: invoice.currency});
    if (bankAccounts.length === 1) {
      invoice.bankAccount = new BankAccount(bankAccounts[0]); // encapsulate to BankAccount because is Entity -> cee Subject constructor
    }

    invoice.items = [];

    for (let i = 0; i < deliveryIds.length; i++) {
      const delivery = i === 0 ? firstDelivery : await deliveryService.find(deliveryIds[i]);
      const mainOrder = delivery.order.mainOrder;

      const item = this.newInvoiceItem();

      item.name = !!mainOrder.invoiceItemText ? mainOrder.invoiceItemText : vsprintf(INVOICE_ITEM_NAME_TEMPLATE_FOR_DELIVERY, [delivery.order.supplier.name, FORMAT_DATE_PERIOD_SLASH(delivery.period)]);
      item.unit = 'MAN_DAY';
      item.number = delivery.mdNumber;
      item.price = mainOrder.mdRate;
      item.vatRate = mainOrder.vatRate;

      delivery.optionalItems.forEach(deliveryItem => {
        let item = this.newInvoiceItem();
        item.name = deliveryItem.orderItem.name;
        item.price = deliveryItem.orderItem.price;
        item.vatRate = mainOrder.vatRate;
        item.number = deliveryItem.itemNumber;
        item.unit = 'PIECE';
        invoice.items.push(item);
      });

      invoice.deliveries.push(delivery);
      invoice.items.push(item);
    }

    return invoice;
  },
  async incomeForDelivery (deliveryIds, configuration) {
    const firstDeliveryId = deliveryIds[0];
    const firstDelivery = await deliveryService.find(firstDeliveryId);

    const order = firstDelivery.order;
    const supplier = await companyService.find(order.supplier.id);
    const subscriber = await companyService.find(order.subscriber.id);
    const invoice = this.newIncomeInvoice(configuration);

    invoice.supplier = supplier;
    invoice.subscriber = subscriber;
    invoice.reference = order.number;
    invoice.currency = order.currency;

    invoice.dateOfIssue = moment(firstDelivery.period);
    invoice.taxableDate = moment(firstDelivery.period);
    invoice.dueDate = moment(firstDelivery.period).add(configuration.invoice.defaultDueDateOffset, 'd');

    invoice.totalVat = 0;
    invoice.totalAmount = 0;
    for (let i = 0; i < deliveryIds.length; i++) {
      const delivery = i === 0 ? firstDelivery : await deliveryService.find(deliveryIds[i]);
      const order = delivery.order;
      if (order.vatRate && order.vatRate > 0) {
        const vat = delivery.priceTotal * order.vatRate / 100;
        invoice.totalAmount = invoice.totalAmount + _.round(delivery.priceTotal + vat, 2);
        invoice.totalVat = invoice.totalVat + _.round(vat, 2);
      } else {
        invoice.totalAmount = invoice.totalAmount + delivery.priceTotal;
      }
      invoice.deliveries.push(delivery);
    }

    const bankAccounts = _.filter(invoice.supplier.bankAccounts, {currency: invoice.currency});
    if (bankAccounts.length === 1) {
      invoice.bankAccount = new BankAccount(bankAccounts[0]);
    }

    return invoice;
  },
  newInvoiceItem () {
    return new InvoiceItem({
      unit: DEFAULT_INOVOICE_ITEM_UNIT
    });
  },
  async find (id) {
    const response = await invoiceResource.get({id: id});
    if (response.ok) {
      return new Invoice(response.data);
    } else {
      return null;
    }
  },
  async findByFilter (filter) {
    const response = await invoiceResource.query(filter);
    if (response.ok) {
      return response.data.map((invoice) => new Invoice(invoice));
    } else {
      return null;
    }
  },
  async create (invoice) {
    const response = await invoiceResource.save({}, _.omitBy(invoice, _.isNil));
    if (response.ok) {
      return new Invoice(response.data);
    } else {
      return null;
    }
  },
  async update (invoice) {
    const response = await invoiceResource.update({}, _.omitBy(invoice, _.isNil));
    if (response.ok) {
      return new Invoice(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return invoiceResource.delete({id: id});
  },
  async generateDocument (id, templateId, fileName) {
    await invoiceResource.generateDocument({id, templateId})
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  },
  async generatePdfDocument (id, templateId, fileName) {
    await invoiceResource.generatePdfDocument({id, templateId})
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  },
  async exportTransactions (invoiceIds, fileName) {
    await invoiceResource.exportTransactions({}, invoiceIds)
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB_TEXT_FILE(data, fileName));
  },
  copyInvoice(origInvoice, newInvoice) {
    newInvoice.supplier = origInvoice.supplier;
    newInvoice.subscriber = origInvoice.subscriber;
    newInvoice.constantSymbol = origInvoice.constantSymbol;
    newInvoice.variableSymbol = origInvoice.variableSymbol;
    newInvoice.specificSymbol = origInvoice.specificSymbol;
    newInvoice.reference = origInvoice.reference;
    newInvoice.currency = origInvoice.currency;
    newInvoice.bankAccount = origInvoice.bankAccount;
    newInvoice.totalAmount = origInvoice.totalAmount;
    newInvoice.totalVat = origInvoice.totalVat;
    newInvoice.items = [];
    origInvoice.items.forEach(item => {
      newInvoice.items.push(
        new InvoiceItem({
            name: item.name,
            price: item.price,
            unit: item.unit,
            number: item.number,
            vatRate: item.vatRate
          }
        ));
    });
    return newInvoice;
  }
};
