import leadsView from 'modules/lead/components/leads';
import leadView from 'modules/lead/components/lead';
import leadDocumentsView from 'modules/lead/components/leadDocuments';
import leadContactsView from 'modules/lead/components/leadContacts';
import leadRecordsView from 'modules/lead/components/leadRecords';
import leadActivitiesView from 'modules/lead/components/leadActivities';
import leadBankAccounts from 'modules/lead/components/leadBankAccounts';

export default [
  {
    path: '/leads',
    component: leadsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/lead/:id',
    name: 'lead',
    component: leadView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    },
    redirect: { name: 'leadRecords' },
    children: [
      {
        path: 'documents',
        name: 'leadDocuments',
        component: leadDocumentsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'contacts',
        name: 'leadContacts',
        component: leadContactsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'records',
        name: 'leadRecords',
        component: leadRecordsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'activities',
        name: 'leadActivities',
        component: leadActivitiesView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'bank-accounts',
        name: 'leadBankAccounts',
        component: leadBankAccounts,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      }
    ]
  }
];
