<template>
  <div v-if="lead">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia subjektu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <select-field
              :options="companies"
              v-model="searchedCompany"
              field-id="searchedCompanyId"
              label="Vyhladaj v ARESe (aspoň 4 písmenká názvu, nebo IČO)"
              :on-search="onSearch"></select-field>
          </div>
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Názov spoločnosti"
                        placeholder="Zadaj názov firmy"
                        v-model="lead.name"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <text-field field-id="leadId"
                        label="IČO"
                        placeholder="Zadaj IČ firmy"
                        v-model="lead.companyId"
                        :maxlength="20"
                        :validation="convert ? 'required|max:20' : 'max:20'"></text-field>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>
                <input type="checkbox" v-model="vatPayer"/> Plátca DPH
              </label>
              <input class="form-control" id="vatId" placeholder="DIČ" v-model="lead.vatId" name="vatId" maxlength="12" v-show="vatPayer">
            </div>
          </div>
          <div class="col-md-6">
            <select-field field-id="roles"
                          label="Roly"
                          :multiple="true"
                          :codelist="true"
                          :options="subjectRoles"
                          v-model="lead.roles"></select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field field-id="locations"
                          label="Lokality"
                          :multiple="true"
                          :options="locations"
                          v-model="lead.locations"></select-field>
          </div>
          <div class="col-md-6">
            <select-field field-id="technologies"
                          label="Technológie"
                          :multiple="true"
                          :options="technologies"
                          v-model="lead.technologies"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-6">
            <text-field field-id="streetName"
                        label="Ulica"
                        placeholder="Zadaj ulicu"
                        v-model="lead.address.streetName"
                        :maxlength="50"
                        :validation="convert ? 'required|max:50' : 'max:50'"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="houseNumber"
                        label="Číslo domu"
                        placeholder="Zadaj číslo domu"
                        v-model="lead.address.houseNumber"
                        :maxlength="10"
                        :validation="convert ? 'required|max:10' : 'max:10'"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="city"
                        label="Zadaj obec"
                        placeholder="Zadaj názov obce"
                        v-model="lead.address.city"
                        :maxlength="50"
                        :validation="convert ? 'required|max:50' : 'max:50'"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="zipCode"
                        label="PSČ"
                        placeholder="Zadaj smerovacie číslo"
                        v-model="lead.address.zipCode"
                        :maxlength="10"
                        :validation="convert ? 'required|max:10' : 'max:10'"></text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="countries"
              v-model="lead.address.country"
              :codelist="true"
              field-id="country"
              label="Štát"
              :validation="convert ? 'required' : ''"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-6">
            <text-field field-id="leadWeb"
                        label="Web"
                        placeholder="Zadaj web firmy"
                        v-model="lead.web"
                        :maxlength="100"></text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <text-area-field field-id="note"
                             v-model="lead.note"
                             label="Poznámka"
                             :maxlength="300"
                             validation="max:300"
                             css-class="note"></text-area-field>
          </div>
        </div>
        <div class="row" v-if="isEdit()">
          <div class="col-md-12">
            <label>
              <button class="btn btn-xs btn-link" v-model="convert" @click.prevent="convert = !convert">
                <i :class="{'fa-toggle-on': convert, 'fa-toggle-off text-success': !convert}" class="fa fa-2x" :title="convert ? 'Deaktivovať' : 'Aktivovať'"></i>
              </button>
              Evidovať ako firmu
            </label>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { countries, subjectRoles } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import { mapState, mapGetters } from 'vuex';
  import leadService from '../service/leadService';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';
  import { ARES_SEARCH_MIN_LENGTH, ARES_SEARCH_DELAY } from '../../../config';

  export default {
    components: { modal, vForm, textField, selectField, textAreaField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      vatPayer: false,
      lead: null,
      v: false,
      convert: false,
      convertedCompanyId: null,
      subjectRoles: subjectRoles,
      countries: countries,
      searchedCompany: null
    }),
    computed: {
      ...mapState({
        companies: (state) => state.company.aresItems
      }),
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      })
    },
    methods: {
      onSearch (search, loading) {
        if (search && search.length >= ARES_SEARCH_MIN_LENGTH) {
          this.deferredFetch(search, loading);
        }
      },
      deferredFetch: _.debounce(function (name, loading) {
        loading(true);
        this.$store.dispatch('company/fetchFromAres', name).finally(() => loading(false));
      }, ARES_SEARCH_DELAY),
      isEdit () {
        return this.lead && this.lead.id;
      },
      onSubmit () {
        let operation;
        if (this.convert) {
          operation = 'lead/convert';
        } else if (!this.convert && this.lead.id) {
          operation = 'lead/update';
        } else {
          operation = 'lead/create';
        }
        const lead = _.cloneDeep(this.lead);
        this.$store.dispatch(operation, lead).then((result) => {
          if (result) {
            if (this.convert) {
              this.convertedCompanyId = result.id;
              notificationService.success('lead.convert.success');
            } else {
              notificationService.success('lead.edit.success');
            }
            this.$emit('close');
            if (operation === 'lead/create') {
              this.$router.push({ name: 'lead', params: { id: result.id }});
            } else if (operation === 'lead/convert') {
              this.$router.push({ name: 'company', params: { id: this.convertedCompanyId }});
            }
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.vatPayer = false;
        this.lead = null;
        this.convert = null;
        this.searchedCompany = null;
      }
    },
    watch: {
      searchedCompany (company) {
        this.lead = this.isEdit() ? leadService.newLead(company, this.lead) : leadService.newLead(company);
        this.vatPayer = this.lead && !!this.lead.vatId;
      },
      model: function (model) {
        this.lead = _.cloneDeep(model);
        if (model) {
          this.vatPayer = this.lead && !!this.lead.vatId;
        }
      }
    },
    mounted () {
      this.$store.dispatch('technology/getAll', true);
      this.$store.dispatch('location/getAll', true);
    }
  };
</script>
