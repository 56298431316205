import bankTransactionResource from './bankTransactionResource';
import BankTransaction from '../domain/bankTransaction';

export default {
  async findByFilter (filter) {
    const response = await bankTransactionResource.query(filter);
    if (response.ok) {
      return response.data.map((bankTransactionData) => new BankTransaction(bankTransactionData));
    } else {
      return null;
    }
  },
  async syncAll () {
    const response = await bankTransactionResource.syncAll();
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  }
};
