<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <span class="static-field">
      <slot></slot>
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      }
    }
  };
</script>
