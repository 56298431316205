import tmRewardResource from '../resources/tmRewardResource';
import _ from 'lodash';
import TmReward from '../domain/tmReward';
import { DOWNLOAD_BLOB_TEXT_FILE } from '../utils';

export default {
  newTmReward (project) {
    return new TmReward({
      project: project,
      status: 'NEW',
      currency: project.currency,
      amount: null
    });
  },
  async findAll (filter) {
    const response = await tmRewardResource.query(filter);
    if (response.ok) {
      return response.data.map((tmRewardData) => new TmReward(tmRewardData));
    } else {
      return null;
    }
  },
  async create (tmReward) {
    const response = await tmRewardResource.save({}, _.pickBy(tmReward));
    if (response.ok) {
      return new TmReward(response.data);
    } else {
      return null;
    }
  },
  async update (tmReward) {
    const response = await tmRewardResource.update({}, _.pickBy(tmReward));
    if (response.ok) {
      return new TmReward(response.data);
    } else {
      return null;
    }
  },
  async pay (tmRewardIds) {
    const response = await tmRewardResource.pay({}, tmRewardIds);
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  },
  async delete (id) {
    return tmRewardResource.delete({ id: id });
  },
  async exportRewards (tmRewardIds, fileName) {
    await tmRewardResource.exportRewards({}, tmRewardIds)
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB_TEXT_FILE(data, fileName));
  }
};
