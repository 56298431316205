import {
  MONEY_BOX_ACTIVATE,
  MONEY_BOX_BALANCE_LIST, MONEY_BOX_BALANCE_LIST_CLEAR,
  MONEY_BOX_DEACTIVATE,
  MONEY_BOX_LIST,
  MONEY_BOX_LIST_CLEAR
} from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [MONEY_BOX_LIST] (state, action) {
    state.items = action.items;
  },
  [MONEY_BOX_LIST_CLEAR] (state) {
    state.items = [];
  },
  [MONEY_BOX_BALANCE_LIST] (state, action) {
    state.balances = action.items;
  },
  [MONEY_BOX_BALANCE_LIST_CLEAR] (state) {
    state.balances = [];
  },
  [MONEY_BOX_ACTIVATE] (state, id) {
    const moneyBox = _.find(state.items, { id: id });
    if (moneyBox) {
      moneyBox.active = true;
    }
  },
  [MONEY_BOX_DEACTIVATE] (state, id) {
    const moneyBox = _.find(state.items, { id: id });
    if (moneyBox) {
      moneyBox.active = false;
    }
  }
};

export default mutations;
