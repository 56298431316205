import Entity from 'domain/entity';
import Document from './document';

class DocumentTemplate extends Entity {
  constructor (data) {
    super(data);

    this.name = this.name || null;
    this.type = this.type || null;
    this.note = this.note || null;
    this.document = this.document ? new Document(this.document) : null;
  }
}

export default DocumentTemplate;
