import cashMoneyMovementService from '../service/cashMoneyMovementService';

const actions = {
  async update({state, dispatch, commit}, moneyMovement) {
    try {
      const updatedBankAccount = await cashMoneyMovementService.update(moneyMovement);
      if (updatedBankAccount) {
        await dispatch('moneyMovement/refresh', null, {root: true});
        dispatch('moneyBox/refresh', null, { root: true });
        dispatch('invoice/refresh', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async create({state, dispatch, commit}, moneyMovement) {
    try {
      const updatedBankAccount = await cashMoneyMovementService.create(moneyMovement);
      if (updatedBankAccount) {
        await dispatch('moneyMovement/refresh', null, {root: true});
        dispatch('invoice/refresh', null, {root: true});
        dispatch('moneyBox/refresh', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await cashMoneyMovementService.delete(id);
    if (response.ok) {
      await dispatch('moneyMovement/refresh', null, {root: true});
      dispatch('invoice/refresh', null, {root: true});
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
