import configurationResource from 'resources/configurationResource';
import Configuration from '../domain/configuration';

export default {
  async getConfiguration () {
    const response = await configurationResource.query();
    if (response.ok) {
      return new Configuration(response.data);
    } else {
      return null;
    }
  }
};
