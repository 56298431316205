<template>
  <div class="form-group">
    <label v-show="label">{{ label }}</label>
    <date-range-field :range="true"
                      :locale="locale"
                      :class="cssClass"
                      :custom-shortcuts="customShortcuts"
                      :input-size="inputSize"
                      :no-label="true"
                      :max-date="maxDateData"
                      @input="updateValue($event)"
                      color="#00a65a"
                      format="YYYY-MM-DDTHH:mm:ss.sssZ"
                      :value="rangeInternal"
                      :label="placeholder"></date-range-field>
  </div>
</template>
<script>
import moment from 'moment';
import Range from 'components/range';
import dateRangeField from 'vue-ctk-date-time-picker';
import {LOCALE} from 'config';

export default {
    components: {dateRangeField},
    data: () => ({
      locale: LOCALE,
      customShortcuts: [
        {label: 'Dnes', value: 'day', key: 'today'},
        {label: 'Včera', value: '-day', key: 'yesterday'},
        {label: 'Tento týždeň', value: 'week', key: 'thisWeek'},
        {label: 'Minulý týždeň', value: '-week', key: 'lastWeek'},
        {label: 'Tento mesiac', value: 'month', key: 'thisMonth'},
        {label: 'Minulý mesiac', value: '-month', key: 'lastMonth'},
        {label: 'Tento rok', value: 'year', key: 'thisYear'},
        {label: 'Minulý rok', value: '-year', key: 'lastYear'}
      ],
      rangeInternal: null,
      maxDateData: null
    }),
    props: {
      value: {
        type: Range
      },
      maxDate: {
        type: Object,
        required: false
      },
      label: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        default: 'Obdobie'
      },
      cssClass: {
        type: String,
        required: false
      },
      inputSize: {
        type: String,
        default: 'sm'
      }
    },
    methods: {
      updateValue(value) {
        this.rangeInternal = value;
        this.$emit('input', value ? new Range(value.start ? moment(value.start) : null, value.end ? moment(value.end) : null) : null);
      }
    },
    watch: {
      value (value) {
        if (value) {
          this.rangeInternal = {};
          if (value.from) {
            this.rangeInternal.start = value.from.toISOString();
          }
          if (value.to) {
            this.rangeInternal.end = value.to.toISOString();
          }
        }
      },
      maxDate(maxDate) {
        this.maxDateData = maxDate ? maxDate.toISOString() : null;
      }
    },
    mounted() {
      if (this.value) {
        this.rangeInternal = {};
        if (this.value.from) {
          this.rangeInternal.start = this.value.from.toISOString();
        }
        if (this.value.to) {
          this.rangeInternal.end = this.value.to.toISOString();
        }
        this.rangeInternal.shortcut = 'month';
      }
      if (this.maxDate) {
        this.maxDateData = this.maxDate.toISOString();
      }
    }
  };
</script>
