import tmProjectView from 'modules/project/components/tmProject';
import tmProjectIncomingOrdersView from 'modules/project/components/tmProjectIncomingOrders';
import tmProjectDeliveryOrdersView from 'modules/project/components/tmProjectDeliveryOrders';
import tmProjectDeliveriesView from 'modules/project/components/tmProjectDeliveries';
import tmProjectRewardsView from 'modules/project/components/tmProjectRewards';
import tmProjectRecordsView from 'modules/project/components/tmProjectRecords';
import tmProjectActivitiesView from 'modules/project/components/tmProjectActivities';
import tmProjectsView from 'modules/project/components/tmProjects';

export default [
  {
    path: '/tm-project/:id',
    name: 'tmProjectDetail',
    component: tmProjectView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    },
    redirect: { name: 'projectIncomingOrders' },
    children: [
      {
        path: 'incoming-orders',
        name: 'projectIncomingOrders',
        component: tmProjectIncomingOrdersView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      },
      {
        path: 'delivery-orders',
        name: 'projectDeliveryOrders',
        component: tmProjectDeliveryOrdersView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      },
      {
        path: 'deliveries',
        name: 'projectDeliveries',
        component: tmProjectDeliveriesView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      },
      {
        path: 'rewards',
        name: 'projectRewards',
        component: tmProjectRewardsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      },
      {
        path: 'records',
        name: 'projectRecords',
        component: tmProjectRecordsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      },
      {
        path: 'activities',
        name: 'projectActivities',
        component: tmProjectActivitiesView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'DEALER'
        }
      }
    ]
  },
  {
    name: 'tmProjects',
    path: '/tm-projects',
    component: tmProjectsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    }
  }
];
