<template>
  <div v-if="documentTemplate">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia šablóny dokumentu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <text-field
              field-id="name"
              label="Názov"
              placeholder="Zadaj meno"
              v-model="documentTemplate.name"
              :maxlength="100"
              validation="required|max:100" />
          </div>
          <div class="col-md-6">
            <select-field
              :options="types"
              v-model="documentTemplate.type"
              field-id="type"
              label="Typ"
              :codelist="true"
              validation="required"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Dokument</label>
              <div>
                <document-field :document="documentTemplate.document" :editable="false"/>
                <document-upload @documentUploaded="documentUploaded($event, documentTemplate)" allowed-types="docx"></document-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field
              v-model="documentTemplate.note"
              label="Poznámka"
              field-id="note"
              :maxlength="300"
              validation="max:300"
              css-class="note"/>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>
<script>
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import documentTemplateTypes from 'codelist/documentTemplateTypes';
  import notificationService from 'services/notificationService';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import textField from 'components/form/textField';
  import validationMixin from 'components/validationMixin';
  import DocumentTemplate from 'modules/document/domain/documentTemplate';
  import documentField from 'components/form/documentField';
  import documentUpload from 'modules/document/components/documentUpload';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, textAreaField, textField, documentField, documentUpload },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      documentTemplate: null,
      types: documentTemplateTypes
    }),
    methods: {
      onSubmit () {
        if (!this.documentTemplate.document) {
          this.unprotect();
          return notificationService.error('error.documentTemplate.document.empty');
        }
        let operation;
        if (this.documentTemplate.id) {
          operation = 'documentTemplate/update';
        } else {
          operation = 'documentTemplate/create';
        }
        const documentTemplate = _.cloneDeep(this.documentTemplate);
        this.$store.dispatch(operation, documentTemplate).then((result) => {
          if (result) {
            notificationService.success('documentTemplate.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      documentUploaded (document) {
        this.documentTemplate.document = document;
      },
      clearData () {
        this.documentTemplate = null;
      }
    },
    watch: {
      model (model) {
        if (model) {
          this.documentTemplate = new DocumentTemplate(model);
        }
      }
    }
  };
</script>
