<template>
  <div>
    <modal v-model="show" @cancel="cancel()" @ok="submit()" @closed="onClose()" title="Upozornenie" ok-text="Potvrdiť" cancel-text="Zrušiť">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            {{ text }}
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import modal from 'vue-strap/src/Modal';
  import modalMixin from './modalMixin';
  import validationMixin from 'components/validationMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm },
    mixins: [validationMixin, modalMixin],
    props: {
      text: {
        type: String
      }
    },
    methods: {
      async onSubmit () {
        await this.protect();
        this.$emit('confirm');
      },
      cancel () {
        this.$emit('cancel');
      }
    }
  };
</script>
