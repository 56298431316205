<template>
  <box>
    <template #header>
      <h3 class="box-title">Stav pokladní</h3>
    </template>
    <template #body>
      <div class="table-responsive">
        <table class="table no-padding table-hover no-padding">
          <thead>
          <tr>
            <th>Pokladňa</th>
            <th class="text-right">Stav</th>
            <th colspan="2">Poslendý pohyb</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="moneyBox in moneyBoxes">
              <td class="text-nowrap">{{ moneyBox.name }}</td>
              <td class="text-right text-nowrap">{{ moneyBox.amount | currency(moneyBox.currency) }}</td>
              <td>
                <movement-description :movement="lastMovement(moneyBox.id)"></movement-description>
              </td>
              <td>
                <a role="button" @click.stop="detail(moneyBox.id)"><i class="fa fa-2x fa-search" title="Detail"></i></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>CASHFLOW</th>
              <th class="text-right text-nowrap">{{ cashFlow | currency(currency) }}</th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </box>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import {mapState} from 'vuex';
  import box from 'components/box';
  import movementDescription from './moneyMovementDescrtiption';
  import {FORMAT_SYSTEM_DATE} from 'utils';

  export default {
    components: {box, movementDescription},
    props: {
      currency: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState({
        latestMovements: (state) => state.moneyMovement.latestItems
      }),
      moneyBoxes () {
        return this.$store.getters['moneyBox/activeByCurrency'](this.currency);
      },
      cashFlow() {
        return _(this.moneyBoxes).map('amount').sum();
      }
    },
    methods: {
      lastMovement (moneyBoxId) {
        return _.find(this.latestMovements, movement => movement.moneyBox.id === moneyBoxId);
      },
      detail(moneyBoxId) {
        this.$router.push({name: 'moneyBox',
          query: {
            fn_moneyBoxId: moneyBoxId,
            frf_range: FORMAT_SYSTEM_DATE(moment().subtract(30, 'day')),
            frt_range: FORMAT_SYSTEM_DATE(moment())
          }
        });
      }
    }
  };
</script>
