<template>
  <div v-if="user">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                 title="Editácia užívateľa" :cancel-text="'Zrušiť'" :ok-text="'Uložiť'">
      <v-form ref="form">
        <text-field field-id="username"
                    label="Prihlasovacie meno"
                    placeholder="Zadaj prihlasovacie meno"
                    v-model="user.username"
                    :maxlength="100"
                    validation="required|min:5|max:100"></text-field>

        <text-field v-if="!editMode"
                    field-id="password"
                    label="Heslo"
                    type="password"
                    placeholder="Zadaj heslo"
                    v-model="user.password"
                    :maxlength="20"
                    validation="required|min:5|max:20"></text-field>

        <text-field field-id="firstname"
                    label="Meno"
                    placeholder="Zadaj meno"
                    v-model="user.firstname"
                    :maxlength="100"
                    validation="required|min:3|max:100"></text-field>

        <text-field field-id="surname"
                    label="Priezvisko"
                    placeholder="Zadaj priezvisko"
                    v-model="user.surname"
                    :maxlength="100"
                    validation="required|min:3|max:100"></text-field>

        <text-field field-id="email"
                    label="E-mail"
                    placeholder="Zadaj e-mail"
                    v-model="user.email"
                    :maxlength="30"
                    validation="min:6|max:30|email"></text-field>


        <text-field field-id="phoneNumber"
                    label="Telefónne číslo"
                    placeholder="Zadaj telefónne číslo"
                    v-model="user.phoneNumber"
                    :maxlength="30"
                    validation="min:9|max:30"></text-field>

        <select-field field-id="roles"
                      label="Užívateľské role"
                      :multiple="true"
                      :options="roles"
                      :codelist="true"
                      v-model="user.roles"></select-field>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import { roles } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import _ from 'lodash';
  import validationMixin from 'components/validationMixin';
  import textField from 'components/form/textField';
  import selectField from 'components/form/selectField';
  import notificationService from 'services/notificationService';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, textField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      roles: roles,
      user: null
    }),
    computed: {
      editMode () {
        return this.user && this.user.id;
      }
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.user.id) {
          operation = 'update';
        } else {
          operation = 'create';
        }
        const user = _.cloneDeep(this.user);
        this.$store.dispatch('user/' + operation, user).then((result) => {
          if (result) {
            notificationService.success('user.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.user = null;
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.user = _.cloneDeep(model);
        }
      }
    }
  };
</script>
