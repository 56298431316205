import currencyRateResource from './currencyRateResource';
import CurrencyRate from '../domain/currencyRate';

export default {
  async findByFilter (filter) {
    const response = await currencyRateResource.query(filter);
    if (response.ok) {
      return response.data.map((currencyRateData) => new CurrencyRate(currencyRateData));
    } else {
      return null;
    }
  },
  async download (filter) {
    const response = await currencyRateResource.download(filter, {});
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  }
};
