import actions from './invoiceActions';
import getters from './invoiceGetters';
import mutations from './invoiceMutations';

const state = {
  items: [],
  selected: null
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
