import { TM_PROJECT_LIST, TM_PROJECT_LIST_FILTERED, TM_PROJECT_SELECT } from './mutationTypes';

const mutations = {
  [TM_PROJECT_LIST] (state, action) {
    state.items = action.items;
  },
  [TM_PROJECT_LIST_FILTERED] (state, action) {
    state.itemsByFilter = action.itemsByFilter;
    state.filter = action.filter;
  },
  [TM_PROJECT_SELECT] (state, action) {
    state.selected = action.item;
  }
};

export default mutations;
