import tmRewardTemplateResource from '../resources/tmRewardTemplateResource';
import _ from 'lodash';
import TmRewardTemplate from '../domain/tmRewardTemplate';

export default {
  newTmRewardTemplate () {
    return new TmRewardTemplate({});
  },
  async find (id) {
    const response = await tmRewardTemplateResource.get({ id: id });
    if (response.ok) {
      return new TmRewardTemplate(response.data);
    } else {
      return null;
    }
  },
  async findAllByFilter (filter) {
    const response = await tmRewardTemplateResource.query(filter);
    if (response.ok) {
      return response.data.map((tmRewardTemplateData) => new TmRewardTemplate(tmRewardTemplateData));
    } else {
      return null;
    }
  },
  async findAll () {
    const response = await tmRewardTemplateResource.query();
    if (response.ok) {
      return response.data.map((tmRewardTemplateData) => new TmRewardTemplate(tmRewardTemplateData));
    } else {
      return null;
    }
  },
  async create (tmRewardTemplate) {
    const response = await tmRewardTemplateResource.save({}, _.pickBy(tmRewardTemplate));
    if (response.ok) {
      return new TmRewardTemplate(response.data);
    } else {
      return null;
    }
  },
  async update (tmRewardTemplate) {
    const response = await tmRewardTemplateResource.update({}, _.pickBy(tmRewardTemplate));
    if (response.ok) {
      return new TmRewardTemplate(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return tmRewardTemplateResource.delete({ id: id });
  }
};
