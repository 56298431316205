<template>
  <page title="Firmy">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Technológie"
                          :multiple="true"
                          :codelist="true"
                          :options="technologies"
                          v-model="filter.technologies"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Lokality"
                          :multiple="true"
                          :codelist="true"
                          :options="locations"
                          v-model="filter.locations"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="roles"
                          label="Roly"
                          :multiple="true"
                          :codelist="true"
                          :options="roles"
                          v-model="filter.roles"></select-field>
          </div>
          <div class="col-sm-2">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <div class="row">
          <div class="col-sm-2">
            <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <company-modal :show-model="showCreateModal" :model="selectedCompany" @close="hideModals()"></company-modal>
              <table class="table table-hover no-padding" v-if="companies && companies.length">
                <tbody>
                <tr>
                  <th>Názov</th>
                  <th class="hidden-sm hidden-md">IČ</th>
                  <th class="visible-lg">DIČ</th>
                  <th>Lokality</th>
                  <th>Roly</th>
                  <th>Technológie</th>
                  <th></th>
                </tr>
                <template v-for="company in companies">
                  <tr :class="{inactive : !company.active}" @click.prevent="detail(company)" role="button">
                    <td>{{ company.name }}</td>
                    <td>{{ company.companyId }}</td>
                    <td>{{ company.vatId }}</td>
                    <td>
                      <span class="icon-item badge" v-for="location in company.locations">{{ location.label }}</span>
                    </td>
                    <td>
                      <span class="role-item" v-for="role in company.roles" :title="role | codeListValue(roles)">
                        <i class="fa fa-user" :class="role | codeListProp(roles, 'iconColor')" aria-hidden="true"></i>
                      </span>
                    </td>
                    <td>
                      <span class="icon-item badge" v-for="technology in company.technologies">{{ technology.label }}</span>
                    </td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editCompany(company)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="toggleCompanyActive(company)"><i :class="{'fa-toggle-on': company.active, 'fa-toggle-off text-success': !company.active}" class="fa fa-2x" :title="company.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteCompany(company)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                      <router-link tag="button" class="btn btn-xs btn-link" :to="{ name: 'company', params: { id: company.id }}"><i class="fa fa-2x fa-search" title="Detail"></i></router-link>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="companies"></loading>
            <no-records :data="companies" loading-id="companies"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>

<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapGetters, mapState } from 'vuex';
  import companyModal from 'modules/company/components/companyCreateModal';
  import notificationService from 'services/notificationService';
  import companyService from 'modules/company/service/companyService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import roles from 'codelist/subjectRoles';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { companyModal, loading, noRecords, selectField, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      showCreateModal: false,
      selectedCompany: null,
      roles: roles,
      filter: {
        showInactive: null,
        query: null,
        technologies: [],
        locations: [],
        roles: []
      }
    }),
    computed: {
      ...mapState({
        companies (state) {
          let companies;
          if (!this.filter.showInactive) {
            companies = this.$store.getters['company/active'];
          } else {
            companies = state.company.items;
          }
          if (_.some(this.filter.technologies)) {
            companies = _.filter(companies, company => {
              return !_(this.filter.technologies).difference(_.map(company.technologies, 'id')).some();
            });
          }
          if (_.some(this.filter.locations)) {
            companies = _.filter(companies, company => {
              return !_(this.filter.locations).difference(_.map(company.locations, 'id')).some();
            });
          }
          if (_.some(this.filter.roles)) {
            companies = _.filter(companies, company => {
              return !_(this.filter.roles).difference(company.roles).some();
            });
          }
          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            return companies.filter(company => {
              return !!normalize(company.name).match(regexp);
            });
          } else {
            return companies;
          }
        }
      }),
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      })
    },
    methods: {
      createShow() {
        this.selectedCompany = companyService.newCompany();
        this.showCreateModal = true;
      },
      hideModals() {
        this.showCreateModal = false;
      },
      deleteCompany(company) {
        this.confirm('company.delete.confirmation', [company.name]).then(() => {
          this.$store.dispatch('company/delete', company.id).then((result) => {
            if (result) {
              notificationService.success('company.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      toggleCompanyActive(company) {
        const action = company.active ? 'deactivate' : 'activate';
        this.$store.dispatch('company/' + action, company.id).then((result) => {
          if (result) {
            notificationService.success('company.' + action + '.success');
          } else {
            notificationService.error('error.company.' + action);
          }
        });
      },
      editCompany(company) {
        this.selectedCompany = _.cloneDeep(company);
        this.showCreateModal = true;
      },
      detail(company) {
        this.$router.push({name: 'company', params: {id: company.id}});
      },
      defaultFilter() {
        this.filter.showInactive = false;
      },
    },
    mounted () {
      this.$store.dispatch('company/getAll', true);
      this.$store.dispatch('technology/getAll');
      this.$store.dispatch('location/getAll');
    }
  };
</script>
