<template>
  <page title="Šablóny dokumentov">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa typu</label>
              <input v-focus type="text" class="form-control" v-model="query">
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <div class="row">
          <div class="col-md-3">
            <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <document-template-modal :show-model="showCreateModal" :model="selectedDocumentTemplate" @close="hideModals()"></document-template-modal>
            <div class="table-responsive">
              <table class="table no-padding table-hover" v-if="documentTemplates && documentTemplates.length">
                <tbody>
                <tr>
                  <th>Typ</th>
                  <th>Názov</th>
                  <th>Dokument</th>
                  <th>Poznámka</th>
                  <th></th>
                </tr>
                <template v-for="documentTemplate in documentTemplates">
                  <tr>
                    <td>{{ documentTemplate.type | codeListValue(documentTemplateTypes) }}</td>
                    <td>{{ documentTemplate.name }}</td>
                    <td><document-field :document="documentTemplate.document" :editable="false"/></td>
                    <td>{{ documentTemplate.note }}</td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editDocumentTemplate(documentTemplate)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteDocumentTemplate(documentTemplate)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="documentTemplates"></loading>
            <no-records :data="documentTemplates" loading-id="documentTemplates"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapState } from 'vuex';
  import documentTemplateTypes from 'codelist/documentTemplateTypes';
  import documentTemplateModal from 'modules/document/components/documentTemplateCreateModal';
  import notificationService from 'services/notificationService';
  import documentTemplateService from 'services/documentTemplateService';
  import documentField from 'components/form/documentField';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { documentTemplateModal, documentField, loading, noRecords, page, box },
    data: () => ({
      showCreateModal: false,
      showChangePasswordModal: false,
      selectedDocumentTemplate: null,
      documentTemplateTypes: documentTemplateTypes,
      query: null
    }),
    computed: mapState({
      documentTemplates (state) {
        const documentTemplates = state.documentTemplate.items;
        if (this.query) {
          const regexp = new RegExp(normalize(this.query), 'g');
          return _.filter(documentTemplates, template => {
            return normalize(_.find(this.documentTemplateTypes, { value: template.type }).label).match(regexp);
          });
        } else {
          return documentTemplates;
        }
      }
    }),
    methods: {
      createShow () {
        this.selectedDocumentTemplate = documentTemplateService.newDocumentTemplate();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
        this.showChangePasswordModal = false;
      },
      deleteDocumentTemplate (documentTemplate) {
        this.confirm('documentTemplate.delete.confirmation').then(() => {
          this.$store.dispatch('documentTemplate/delete', documentTemplate.id).then((result) => {
            if (result) {
              notificationService.success('documentTemplate.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      editDocumentTemplate (documentTemplate) {
        this.selectedDocumentTemplate = _.cloneDeep(documentTemplate);
        this.showCreateModal = true;
      }
    },
    created () {
      this.$store.dispatch('documentTemplate/getAll');
    }
  };
</script>
