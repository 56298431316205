import {INVOICE_LIST_FILTERED, INVOICE_SELECT, INVOICE_LIST_CLEAR} from './mutationTypes';

const mutations = {
  [INVOICE_LIST_FILTERED](state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [INVOICE_LIST_CLEAR] (state) {
    state.items = [];
    state.filter = null;
  },
  [INVOICE_SELECT] (state, action) {
    state.selected = action.item;
  }
};

export default mutations;
