import tmDeliveryService from '../../../services/tmDeliveryService';
import * as types from '../../../store/mutationTypes';

const actions = {
  async getAll ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'tmDeliveries', { root: true });
    await commit(types.TM_DELIVERY_LIST, {
      filter: null,
      items: []
    });
    const tmDeliveries = await tmDeliveryService.findAll(filter);
    await commit(types.TM_DELIVERY_LIST, {
      filter: filter,
      items: tmDeliveries
    });
    dispatch('app/loadingDataDisable', 'tmDeliveries', { root: true });
  },
  async refreshAll ({ state, dispatch }) {
    dispatch('getAll', state.filter);
  },
  async clear ({ commit}) {
    await commit(types.TM_DELIVERY_LIST_CLEAR);
  },
  async create ({ dispatch }, tmDelivery) {
    try {
      const newDelivery = await tmDeliveryService.create(tmDelivery);
      if (newDelivery) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, tmDelivery) {
    try {
      const updatedDelivery = await tmDeliveryService.update(tmDelivery);
      if (updatedDelivery) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async updateDocuments ({ dispatch }, tmDelivery) {
    try {
      const updatedOrder = await tmDeliveryService.updateDocuments(tmDelivery);
      if (updatedOrder) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async pay ({ dispatch }, rewardIds) {
    try {
      const result = await tmDeliveryService.pay(rewardIds);
      if (result) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch, commit }, id) {
    const response = await tmDeliveryService.delete(id);
    if (response.ok) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
