import modal from 'vue-strap/src/Modal';
import _ from 'lodash';
import notificationService from 'services/notificationService';
import validationMixin from './validationMixin';

export default {
  mixins: [validationMixin],
  components: { modalWindow: modal },
  data: () => ({
    item: null
  }),
  computed: {
    editMode () {
      return this.item && this.item.id;
    }
  },
  props: {
    moduleName: {
      type: String,
      required: true
    }
  },
  methods: {
    onSubmit () {
      let operation = this.moduleName;
      if (this.item.id) {
        operation += '/update';
      } else {
        operation += '/create';
      }
      this.$store.dispatch(operation, this.item).then(() => {
        notificationService.success('codeList.save.success');
        this.$emit('close');
      }).catch(this.unprotect);
    }
  },
  watch: {
    model: function (model) {
      this.item = _.cloneDeep(model);
      this.clear();
    }
  }
};
