<template>
  <div>
    <records :entity="lead" record-entity="subject" :show-project-link="true"></records>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import records from 'modules/record/components/records';

  export default {
    components: { records },
    computed: mapState({
      lead: (state) => state.lead.selected
    })
  };
</script>
