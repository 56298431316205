import actions from './tmOrderActions';
import mutations from './tmOrderMutations';

const state = {
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
