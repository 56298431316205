import actions from './bankTransactionActions';
import mutations from './bankTransactionMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
