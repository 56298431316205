'use strict';
import _ from 'lodash';

export default {
  customers: state => {
    return _.filter(state.items || [], item => _.includes(item.roles, 'SUBSCRIBER'));
  },
  suppliers: state => {
    return _.filter(state.items || [], item => _.includes(item.roles, 'SUPPLIER'));
  },
  ownershipped: (state) => {
    return _.filter(state.items || [], item => _.includes(item.roles, 'SMARTBRAINS'));
  },
  active: (state) => {
    return _.filter(state.items || [], 'active');
  }
};
