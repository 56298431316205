<template>
  <page title="Zamestnanci">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-3">
            <select-field field-id="technologies"
                          label="Technológie"
                          :multiple="true"
                          :codelist="true"
                          :options="technologies"
                          v-model="filter.technologies"></select-field>
          </div>
          <div class="col-md-3">
            <select-field field-id="locations"
                          label="Lokality"
                          :multiple="true"
                          :codelist="true"
                          :options="locations"
                          v-model="filter.locations"></select-field>
          </div>
          <div class="col-md-3">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
      </template>
      <template #body>
        <employee-modal :show-model="showCreateModal" :model="selectedEmployee" @close="hideModals()"></employee-modal>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-responsive no-padding table-hover" v-if="employees && employees.length">
                <tbody>
                <tr>
                  <th>Meno</th>
                  <th>Priezvisko</th>
                  <th>Lokality</th>
                  <th>Technológie</th>
                  <th></th>
                </tr>
                <template v-for="employee in employees">
                  <tr :class="{inactive : !employee.active}" @click.prevent="detail(employee)" role="button">
                    <td>{{ employee.firstName }}</td>
                    <td>{{ employee.surname }}</td>
                    <td>
                      <span class="icon-item badge" v-for="location in employee.locations">{{ location.label }}</span>
                    </td>
                    <td>
                      <span class="icon-item badge" v-for="technology in employee.technologies">{{ technology.label }}</span>
                    </td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editEmployee(employee)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="toggleEmployeeActive(employee)"><i :class="{'fa-toggle-on': employee.active, 'fa-toggle-off text-success': !employee.active}" class="fa fa-2x" :title="employee.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteEmployee(employee)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                      <router-link tag="button" class="btn btn-xs btn-link" :to="{ name: 'employee', params: { id: employee.id }}"><i class="fa fa-2x fa-search" title="Detail"></i></router-link>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <loading id="employees"></loading>
            <no-records :data="employees" loading-id="employees"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapState, mapGetters } from 'vuex';
  import employeeModal from './employeeCreateModal';
  import notificationService from 'services/notificationService';
  import employeeService from '../service/employeeService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { employeeModal, loading, noRecords, selectField, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      showCreateModal: false,
      showChangePasswordModal: false,
      selectedEmployee: null,
      filter: {
        query: null,
        showInactive: false,
        technologies: [],
        locations: []
      }
    }),
    computed: {
      ...mapState({
        loading: (state) => state.app.loadingData,
        employees (state) {
          let employees;
          if (!this.filter.showInactive) {
            employees = this.$store.getters['employee/active'];
          } else {
            employees = state.employee.items;
          }
          if (_.some(this.filter.technologies)) {
            employees = _.filter(employees, employee => {
              return !_(this.filter.technologies).difference(_.map(employee.technologies, 'id')).some();
            });
          }
          if (_.some(this.filter.locations)) {
            employees = _.filter(employees, employee => {
              return !_(this.filter.locations).difference(_.map(employee.locations, 'id')).some();
            });
          }
          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            return employees.filter(employee => !!normalize(employee.format()).match(regexp));
          } else {
            return employees;
          }
        }
      }),
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      })
    },
    methods: {
      createShow () {
        this.selectedEmployee = employeeService.newEmployee();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
        this.showChangePasswordModal = false;
      },
      deleteEmployee (employee) {
        this.confirm('employee.delete.confirmation', [employee.fullName]).then(() => {
          this.$store.dispatch('employee/delete', employee.id).then((result) => {
            if (result) {
              notificationService.success('employee.delete.confirmation');
            }
          }).catch(this.unprotect);
        });
      },
      toggleEmployeeActive (employee) {
        const action = employee.active ? 'deactivate' : 'activate';
        this.$store.dispatch('employee/' + action, employee.id).then((result) => {
          if (result) {
            notificationService.success('employee.' + action + '.success');
          } else {
            notificationService.error('error.employee' + action);
          }
        });
      },
      editEmployee (employee) {
        this.selectedEmployee = _.cloneDeep(employee);
        this.showCreateModal = true;
      },
      detail (employee) {
        this.$router.push({ name: 'employee', params: { id: employee.id }});
      }
    },
    created () {
      this.$store.dispatch('employee/getAll');
      this.$store.dispatch('technology/getAll');
      this.$store.dispatch('location/getAll');
    }
  };
</script>
