import Entity from './entity';
import Technology from 'modules/technology/domain/technology';
import Location from 'modules/location/domain/location';

class Subject extends Entity {
  constructor (data) {
    super(data);

    this.active = data.active || null;
    this.contacts = data.contacts; // can't be mapped to Contact because of circular dependency
    this.technologies = data.technologies ? data.technologies.map(technology => new Technology(technology)) : [];
    this.locations = data.locations ? data.locations.map(location => new Location(location)) : [];
    this.bankAccounts = data.bankAccounts ? data.bankAccounts.map(bankAccount => new Entity(bankAccount, 'id', 'name')) : [];  // can't be mapped to BankAccount because of circular dependency
    this.roles = data.roles || [];
  }

  get isSupplier() {
    return this.roles.indexOf('SUPPLIER') >= 0;
  }

  get isSubscriber() {
    return this.roles.indexOf('SUBSCRIBER') >= 0;
  }
}

export default Subject;
