import * as types from './mutationTypes';
import transactionService from '../service/transactionService';

const actions = {
  async findByFilter ({ dispatch, commit }, filter) {
    await commit(types.TRANSACTION_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'transactions', { root: true });
    const transactions = await transactionService.findByFilter(filter);
    await commit(types.TRANSACTION_LIST, {
      filter: filter,
      items: transactions
    });
    dispatch('app/loadingDataDisable', 'transactions', { root: true });
  },
  async refresh ({ dispatch, state }) {
    dispatch('findByFilter', state.filter);
    dispatch('account/refreshSelected', null, { root: true });
  },
  async create ({ dispatch }, transaction) {
    try {
      const newTransaction = await transactionService.create(transaction);
      if (newTransaction) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, transaction) {
    try {
      const updatedTransaction = await transactionService.update(transaction);
      if (updatedTransaction) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await transactionService.delete(id);
    if (response.ok) {
      await dispatch('refresh');
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
