import _ from 'lodash';
import tmRewardTemplateService from '../../../services/tmRewardTemplateService';
import * as types from '../../mutationTypes';

const actions = {
  async getAllByFilter ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'tmRewardTemplates', { root: true });
    const templates = await tmRewardTemplateService.findAllByFilter(filter);
    await commit(types.TM_REWARD_TEMPLATE_LIST_FILTERED, {
      items: templates,
      filter: filter
    });
    dispatch('app/loadingDataDisable', 'tmRewardTemplates', { root: true });
  },
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'tmRewardTemplates', { root: true });
      const templates = await tmRewardTemplateService.findAll();
      await commit(types.TM_REWARD_TEMPLATE_LIST, {
        items: templates
      });
      dispatch('app/loadingDataDisable', 'tmRewardTemplates', { root: true });
    }
  },
  async create ({ dispatch }, tmRewardTemplate) {
    try {
      const newRewardTemplate = await tmRewardTemplateService.create(tmRewardTemplate);
      if (newRewardTemplate) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ commit, dispatch }, rewardTemplate) {
    try {
      const updatedTmRewardTemplate = await tmRewardTemplateService.update(rewardTemplate);
      if (updatedTmRewardTemplate) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ state, commit }, id) {
    try {
      await commit(types.TM_REWARD_TEMPLATE_ACTIVATE, id);
      const tmRewardTemplate = _.find(state.items, { id: id });
      const updatedTmRewardTemplate = await tmRewardTemplateService.update(tmRewardTemplate);
      if (updatedTmRewardTemplate) {
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ state, commit }, id) {
    try {
      await commit(types.TM_REWARD_TEMPLATE_DEACTIVATE, id);
      const tmRewardTemplate = _.find(state.items, { id: id });
      const updatedTmRewardTemplate = await tmRewardTemplateService.update(tmRewardTemplate);
      if (updatedTmRewardTemplate) {
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await tmRewardTemplateService.delete(id);
    if (response.ok) {
      await dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
