<template>
  <div>
    <tm-delivery-modal :show-model="showCreateTmDeliveryModal" :model="selectedDelivery" @close="hideModals()"></tm-delivery-modal>
    <agreement-modal :show-model="showContractAmountModal" :model="selectedDelivery" @close="hideModals()"></agreement-modal>
    <div class="row">
      <div class="col-md-12">
        <p>
          <button v-show="project.editable" class="btn btn-success" type="button" @click="createDeliveryShow()"><i class="fa fa-plus" aria-hidden="true"></i> Pridať dodávku</button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <accordion :one-at-atime="true" type="info">
          <panel v-for="delivery in deliveries" :key="deliveries.id">
            <strong slot="header">
              <u>Objednávka č. {{ delivery.order.number }}, obdobie: {{ delivery.period | dateMonth }} </u>
              <div class="clearfix"></div>
            </strong>

            <div class="row">
              <div class="col-md-10">
                <div class="summary">
                  <div class="pull-left labels">
                    <p>Počet MD</p>
                    <p>Suma</p>
                    <p>Predaj</p>
                    <p>Zisk</p>
                  </div>
                  <div class="pull-left values">
                    <p>{{ delivery.mdNumber | number(5) }}</p>
                    <p>{{ delivery.price | currency(delivery.order.currency) }}</p>
                    <p>{{ delivery.sell | currency(delivery.order.currency) }}</p>
                    <p>{{ delivery.profit | currency(delivery.order.currency) }}</p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-2" v-show="project.editable" >
                <div class="pull-right">
                  <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <i class="fa fa-gear"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a role="button" @click.prevent="editDelivery(delivery)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                      <li><a role="button" @click.prevent="deleteDelivery(delivery)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>
                      <li><a role="button" @click.prevent="createInvoice(delivery)" v-show="!delivery.invoiceOutcome" ><i class="fa fa-file-text-o text-success"></i> Fakturovať</a></li>
                      <li><a role="button" @click.prevent="invoiceDetail(delivery)" v-if="delivery.invoiceOutcome" ><i class="fa fa-file-text text-primary"></i> Faktura {{ delivery.invoiceOutcome.number }}</a></li>
                      <li v-if="delivery.order.supplier.supportsContractAgreement()" ><a role="button" @click.prevent="createContractAgreement(delivery)"><i class="fa fa-recycle text-primary"></i> Generovať DOPP</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <delivery-items v-if="delivery.optionalItems.length" v-model="delivery.optionalItems"></delivery-items>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <h4>Dokumenty</h4>
              </div>
              <div class="col-md-12">
                <documents :documents="delivery.documents" :editable="project.editable" @deleteDocument="deleteDocumentDelivery($event, delivery)"></documents>
                <document-upload v-show="project.editable" @documentUploaded="documentUploadedDelivery($event, delivery)"></document-upload>
              </div>
            </div>
          </panel>
        </accordion>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import { accordion, panel } from 'vue-strap';
  import notificationService from 'services/notificationService';
  import tmDeliveryService from 'services/tmDeliveryService';
  import tmDeliveryModal from 'components/tmDeliveryCreateModal';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';
  import agreementModal from 'components/agreementCreateModal';
  import deliveryItems from 'modules/delivery/components/tmDeliveryItems';

  export default {
    components: {
      agreementModal,
      accordion, panel, documents, documentUpload, tmDeliveryModal, deliveryItems },
    data: () => ({
      selectedDelivery: null,
      showCreateTmDeliveryModal: false,
      showContractAmountModal: false
    }),
    computed: mapState({
      project: (state) => state.tmProject.selected,
      deliveries: (state) => state.tmProject.selected ? _.flatMap(state.tmProject.selected.deliveryOrders, 'deliveries') : []
    }),
    methods: {
      createDeliveryShow (order) {
        this.selectedDelivery = tmDeliveryService.newTmDelivery({
          order: order
        });
        this.showCreateTmDeliveryModal = true;
      },
      createContractAgreement (delivery) {
        this.selectedDelivery = delivery;
        this.showContractAmountModal = true;
      },
      editDelivery (delivery) {
        this.selectedDelivery = _.cloneDeep(delivery);
        this.showCreateTmDeliveryModal = true;
      },
      deleteDelivery (delivery) {
        this.confirm('delivery.delete.confirmation').then(() => {
          this.$store.dispatch('tmDelivery/delete', delivery.id).then((result) => {
            if (result) {
              notificationService.success('delivery.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      createInvoice (delivery) {
        this.$router.push({ name: 'invoiceOutcomeCreate', params: { deliveryIds: delivery.id + '' }});
      },
      invoiceDetail (delivery) {
        this.$router.push({ name: 'invoiceDetail', params: { id: delivery.invoiceOutcome.id }});
      },
      documentUploadedDelivery (document, delivery) {
        const deliveryCopy = _.cloneDeep(delivery);
        deliveryCopy.documents.push(document);
        this.$store.dispatch('tmDelivery/updateDocuments', deliveryCopy).then((result) => {
          if (result) {
            notificationService.success('delivery.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocumentDelivery (document, delivery) {
        const deliveryCopy = _.cloneDeep(delivery);
        _.remove(deliveryCopy.documents, { id: document.id });
        this.$store.dispatch('tmDelivery/updateDocuments', deliveryCopy).then((result) => {
          if (result) {
            notificationService.success('delivery.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      hideModals () {
        this.selectedDelivery = null;
        this.showCreateTmDeliveryModal = false;
        this.showContractAmountModal = false;
      }
    }
  };
</script>
