import { LIST } from 'store/mutationTypes';
import recordService from '../../../services/recordService';

const actions = {
  async getAll ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'records', { root: true });
    await commit(LIST, {
      filter: null,
      items: []
    });
    const records = await recordService.findAll(filter);
    await commit(LIST, {
      filter: filter,
      items: records
    });
    dispatch('app/loadingDataDisable', 'records', { root: true });
  },
  async findByEntity ({ dispatch, commit }, data) {
    await commit(LIST, {
      entityId: null,
      entity: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'records', { root: true });
    const records = await recordService.findByEntity(data);
    await commit(LIST, {
      entityId: data.entityId,
      entity: data.entity,
      items: records
    });
    dispatch('app/loadingDataDisable', 'records', { root: true });
  },
  async refresh ({ dispatch, state }) {
    dispatch('findByEntity', { entityId: state.entityId, entity: state.entity });
  },
  async create ({ dispatch }, data) {
    try {
      const newRecord = await recordService.create(data);
      if (newRecord) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, data) {
    try {
      const updatedRecord = await recordService.update(data);
      if (updatedRecord) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, data) {
    const response = await recordService.delete(data);
    if (response.ok) {
      await dispatch('refresh');
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
