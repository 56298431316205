import Entity from '../../../domain/entity';
import Company from 'modules/company/domain/company';
import { vsprintf } from 'sprintf-js';


class InvoiceSeries extends Entity {
  constructor (data) {
    super(data);
    this.company = data.company == null ? null : new Company(data.company);
    this.prefix = data.prefix || null;
    this.year = data.year || null;
    this.index = data.index || null;
    this.active = data.active;
    this.defaultFlag = data.defaultFlag;

    this.value = this.id;
    this.label = this.formatName();
  }

  formatName () {
    return `${this.prefix}XXXX${this.year}`;
  }

  nextVariableSymbol () {
    let prefixNumbers = '';
    if (this.prefix) {
      let result = this.prefix.match(/\d+/g);
      if (result && result.length) {
        prefixNumbers = result[0].slice(-2);
      }
    }
    const indexNumbers = vsprintf('%04d', this.index).slice(-4);
    const yearNumbers = vsprintf('%04d', this.year).slice(-4);
    return prefixNumbers + indexNumbers + yearNumbers;
  }
}

export default InvoiceSeries;
