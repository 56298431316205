<template>
  <div>
    <div v-if="loading[id]" class="text-center">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';

  /**
   * "id" has to be a property defined in initial state of app.loadingData object.
   */
  export default {
    computed: mapState({
      loading: (state) => state.app.loadingData
    }),
    props: {
      id: {
        type: String,
        required: true
      }
    }
  };

</script>
