<template>
  <div>
    <modal v-model="showModel" @ok="submit()" @cancel="cancel()" @closed="onClose()" title="Zmena hesla"
           :cancel-text="'Zrušiť'" :ok-text="'Uložiť'" :backdrop="false" :small="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="newPassword"
                        label="Nové heslo"
                        placeholder="Zadaj nové heslo"
                        v-model="credentials.newPassword"
                        :maxlength="20"
                        ref="newPassword"
                        type="password"
                        validation="required|min:5|max:20"
            ></text-field>
            <text-field field-id="confirmPassword"
                        label="Nové heslo pre kontrolu"
                        placeholder="Zadaj nové heslo znova"
                        v-model="credentials.confirmPassword"
                        :maxlength="20"
                        type="password"
                        validation="required|confirmed:newPassword"></text-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      credentials: {
        newPassword: null,
        confirmPassword: null
      }
    }),
    computed: {
      editMode () {
        return this.user && this.user.id;
      }
    },
    methods: {
      onSubmit () {
        this.$store.dispatch('user/passwordChange', { credentials: this.credentials, userId: this.user.id }).then((result) => {
          if (result) {
            notificationService.success('Heslo bolo zmenené.');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.credentials.newPassword = null;
        this.credentials.confirmPassword = null;
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.user = _.cloneDeep(model);
        }
      }
    }
  };
</script>
