<template>
  <div v-if="orderItem">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia položky objednávky" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
            <div class="col-md-8">
              <text-field field-id="item.name"
                          label="Popis položky"
                          placeholder="Zadaj popis"
                          v-model="orderItem.name"
                          :maxlength="200"
                          validation="required|min:1|max:200"></text-field>
            </div>
            <div class="col-md-4">
              <number-field
                v-model="orderItem.price"
                placeholder="Cena"
                label="Cena"
                field-id="item.price"
                :min="1"
                :step="100"
                :suffix="currency | codeListValue(currencies)"
                validation="required|numeric|min_value:1">
              </number-field>
            </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { currencies } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import textField from 'components/form/textField';
  import numberField from 'components/form/numberField';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, numberField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      orderItem: null,
      currencies
    }),
    props: {
      currency: {
        required: true,
        type: String
      }
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.orderItem.id) {
          operation = 'tmOrderItem/update';
        } else {
          operation = 'tmOrderItem/create';
        }
        const orderItem = _.cloneDeep(this.orderItem);
        this.$store.dispatch(operation, orderItem).then((result) => {
          if (result) {
            notificationService.success('tmOrderItem.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.orderItem = null;
      }
    },
    watch: {
      model: function (model) {
        this.orderItem = _.cloneDeep(model);
      }
    }
  };
</script>
