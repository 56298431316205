<template>
  <page title="Prehľad záznamov">
    <record-modal :show-model="showCreateModal" :model="selectedRecord" :entity="selectedRecord ? selectedRecord.subject : null" @close="hideModals()" record-entity="subject"></record-modal>

    <!--filter box-->
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <date-field v-model="filter.periodFrom" field-id="date" label="Dátum od"></date-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <date-field v-model="filter.periodTo" field-id="date" label="Dátum do"></date-field>
          </div>
        </div>
      </template>
    </box>
    <!--end of filter box-->

    <!--rewards box-->
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover dataTable records-overview">
                <thead>
                <tr>
                  <th>Typ</th>
                  <the label="Dátum a čas" property="date" :sort-data="sortRecordsData" @sort="sortRecords($event)"></the>
                  <th>Názov subjektu</th>
                  <th>Kontaktná osoba</th>
                  <th>Projekt</th>
                  <th>Poznámka</th>
                  <th>Príloha</th>
                  <th>Autor</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="record in records">
                  <tr @click.prevent="detail(record)" role="button">
                    <td><i :title="record.type | codeListValue(recordTypes)" :class="recordIcon(record.type)" class="record-type"></i></td>
                    <td class="text-nowrap" :title="record.date | dateTime">{{ elapsedTime(record.date) }}</td>
                    <td class="text-nowrap">
                      <subject-link v-if="record.subject" :subject="record.subject"></subject-link>
                    </td>
                    <td class="text-nowrap">{{ record.contact ? record.contact.name : null }}</td>
                    <td class="text-nowrap">
                      <project-link v-if="record.project" :project="record.project"></project-link>
                    </td>
                    <td class="format-text">
                      <truncate :content="record.note"></truncate>
                    </td>
                    <td><documents :documents="record.documents" :editable="false" :compact="true"></documents></td>
                    <td class="text-nowrap">{{ record.createdBy.fullName }}</td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editRecord(record)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteRecord(record)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="records"></loading>
            <no-records :data="records" loading-id="records"></no-records>
          </div>
        </div>
      </template>
    </box>
    <!--end of rewards box-->
  </page>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import dateField from 'components/form/dateField';
  import the from 'components/the';
  import { mapState } from 'vuex';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import documents from 'modules/document/components/documents';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import recordTypes from 'codelist/recordTypes';
  import recordMixin from 'modules/record/components/recordMixin';
  import recordModal from 'modules/record/components/recordCreateModal';
  import notificationService from 'services/notificationService';
  import projectLink from 'modules/project/components/projectLink';
  import subjectLink from 'components/subjectLink';
  import truncate from 'components/truncate';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    mixins: [recordMixin],
    components: { dateField, the, documents, loading, noRecords, recordModal, projectLink, subjectLink, truncate, page, box },
    data: () => {
      return {
        filter: {
          periodFrom: moment().subtract(1, 'month'),
          periodTo: null
        },
        sortRecordsData: {
          by: 'date',
          asc: true
        },
        recordTypes,
        selectedRecord: null,
        showCreateModal: false
      };
    },
    computed: {
      ...mapState({
        records (state) {
          const records = _(state.record.items)
            .sortBy(this.sortRecordsData.by)
            .value();
          return this.sortRecordsData.asc ? records.reverse() : records;
        }
      })
    },
    methods: {
      elapsedTime (date) {
        const diff = date.diff(moment());
        return moment.duration(diff).humanize(true);
      },
      sortRecords (sortData) {
        this.sortRecordsData.by = sortData.by;
        this.sortRecordsData.asc = sortData.asc;
      },
      detail (record) {
        if (record.subject) {
          this.$router.push({ name: `${record.subject.constructor.route}Records`, params: { id: record.subject.id }});
        } else {
          this.$router.push({ name: `projectRecords`, params: { id: record.project.id }});
        }
      },
      createFilter () {
        const periodFrom = this.filter.periodFrom ? this.filter.periodFrom : null;
        const periodTo = this.filter.periodTo ? this.filter.periodTo : null;
        return _.pickBy({
          periodFrom: periodFrom ? FORMAT_SYSTEM_DATE(periodFrom) : null,
          periodTo: periodTo ? FORMAT_SYSTEM_DATE(periodTo) : null,
          currency: this.currencyCode
        }, _.identity);
      },
      editRecord (record) {
        this.selectedRecord = record;
        this.showCreateModal = true;
      },
      deleteRecord (record) {
        this.confirm('record.delete.confirmation').then(() => {
          this.$store.dispatch('record/delete', { id: record.id }).then((result) => {
            if (result) {
              notificationService.success('record.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      hideModals () {
        this.showCreateModal = false;
        this.selectedRecord = null;
      }
    },
    watch: {
      filter: {
        handler () {
          this.$store.dispatch('record/getAll', this.createFilter());
        },
        deep: true
      }
    },
    created () {
      this.$store.dispatch('record/getAll', this.createFilter());
    }
  };
</script>
