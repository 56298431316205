<template>

  <div v-if="record && entity">

    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia záznamu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-8">
            <date-time-field
              v-model="record.date"
              field-id="date"
              label="Dátum"
              validation="required">
            </date-time-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <select-field
              :options="recordTypes"
              v-model="record.type"
              :codelist="true"
              field-id="type"
              label="Typ"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-4" v-if="record.type !== 'COMMENT'">
            <select-field
              :options="contacts"
              v-model="record.contact"
              field-id="contact"
              label="Kontakt"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-4" v-if="record.type !== 'COMMENT' && recordEntity !== 'project'">
            <select-field
              :options="projects"
              v-model="record.project"
              field-id="project"
              label="Projekt">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Dokumenty</label>
            </div>
            <documents :documents="record.documents" @deleteDocument="deleteDocument($event)" :delete-confirm="false"></documents>
            <document-upload @documentUploaded="documentUploaded($event)"></document-upload>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field class="note-big" v-model="record.note" label="Poznámka" field-id="note" :maxlength="1000000" validation="max:1000000" css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import dateTimeField from 'components/form/dateTimeField';
  import modal from 'vue-strap/src/Modal';
  import recordTypes from 'codelist/recordTypes';
  import notificationService from 'services/notificationService';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';
  import validationMixin from 'components/validationMixin';
  import Entity from 'domain/entity';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, textAreaField, dateTimeField, documents, documentUpload },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      recordTypes,
      record: null
    }),
    computed: mapState({
      contacts: (state) => state.contact.items,
      projects (state) {
        if (this.entity) {
          return _.filter(state.tmProject.items, p => _(p.orders).flatMap(order => [order.supplier.id, order.subscriber.id]).includes(this.entity.id));
        } else {
          return [];
        }
      }
    }),
    props: {
      entity: {
        type: Entity
      },
      recordEntity: {
        type: String,
        required: true
      }
    },
    methods: {
      onSubmit () {
        let operation = 'record/';
        const record = _.cloneDeep(this.record);
        if (this.record.id) {
          operation += 'update';
        } else {
          operation += 'create';
        }
        // when creating new record, resolving a resource via context part has to be done
        record[this.recordEntity] = this.entity;
        this.$store.dispatch(operation, record).then((result) => {
          if (result) {
            notificationService.success('record.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      documentUploaded (document) {
        this.record.documents.push(document);
      },
      deleteDocument (document) {
        this.record.documents = _.reject(this.record.documents, { id: document.id });
      },
      clearData () {
        this.record = null;
      },
      fetchContacts () {
        if (this.entity) {
          this.$store.dispatch('contact/findBy' + _.capitalize(this.recordEntity), this.entity.id);
        }
      }
    },
    watch: {
      model (model) {
        if (model) {
          this.record = _.cloneDeep(model);
        }
      },
      entity () {
        this.fetchContacts();
      },
      record: {
        handler () {
          // delete contact if comment type is selected
          if (this.record && this.record.type === 'COMMENT') {
            this.record.contact = null;
            if (this.recordEntity === 'project') {
              this.record.subject = null;
            }
            if (this.recordEntity === 'subject') {
              this.record.project = null;
            }
          }
        },
        deep: true
      }
    },
    mounted () {
      this.$store.dispatch('tmProject/getAll');
      this.fetchContacts();
    }
  };
</script>
