import Vue from 'vue';
import { API_ROOT } from 'config';

const context = 'invoice';
const customActions = {
  generateDocument: { method: 'GET', url: API_ROOT + context + '/generate-document{/id}/template{/templateId}', responseType: 'blob' },
  generatePdfDocument: { method: 'GET', url: API_ROOT + context + '/generate-document{/id}/template{/templateId}/pdf', responseType: 'blob' },
  exportTransactions: { method: 'PUT', url: API_ROOT + context + '/export-transactions', responseType: 'blob' }
};

export default Vue.resource(API_ROOT + context + '/{id}', {}, customActions);

