import Entity from 'domain/entity';
import BankAccount from "../../bank/domain/bankAccount";
import Company from "../../company/domain/company";

class MoneyBox extends Entity {
  constructor (data) {
    super(data);

    this.bankAccount = data.bankAccount == null ? null : new BankAccount(data.bankAccount);
    this.company = data.company == null ? null : new Company(data.company);

    this.value = this.id;
    this.label = this.name;
  }

  get amountWithoutVat() {
    return this.amount - (this.vatAmount || 0);
  }
}

export default MoneyBox;
