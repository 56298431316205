import Entity from '../../../domain/entity';

class Transaction extends Entity {
  constructor (data) {
    super(data);

    this.account = this.account || null;
    this.date = this.convertDate(data.date);
    this.type = this.type || null;
    this.amount = this.amount || null;
    this.note = this.note || null;
    this.currency = this.currency || 'CZK';
    this.generated = this.generated || false;
    this.deliveries = this.deliveries || [];
    this.rewards = this.rewards || [];
  }
}

export default Transaction;
