<template>
  <span class="document-upload">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Pusti súbor tu</h3>
    </div>
    <button v-if="!uploadVisible" class="btn btn-sm btn-success" type="button" @click.prevent="showUpload()"><i class="fa fa-plus" aria-hidden="true"></i> Pridať</button>
    <span v-if="uploadVisible">
            <file-upload
              ref="upload"
              v-model="files"
              dropzone="dropZone"
              :headers="headers"
              :drop="true"
              :drop-directory="true"
              @input-file="inputFile"
              :post-action="apiRoot + 'document'"
              :extensions="allowedTypes || fileExtensions"
              :size="maxSize">
              <button @click="$refs.fileLabel.click()" class="btn btn-primary btn-sm">Vyber alebo pretiahni súbor</button>
              <label for="file" ref="fileLabel"></label>
            </file-upload>
            <button class="btn btn-success btn-sm" v-show="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true" type="button">Nahrať</button>
            <button class="btn btn-danger btn-sm" v-show="$refs.upload && $refs.upload.active" @click.prevent="$refs.upload.active = false" type="button">Zastaviť</button>
            <button class="btn btn-default btn-sm" @click.prevent="cancel()">Zrušiť</button>
            <span v-if="files && files.length">{{ files[0].name }} ({{ files[0].size | fileSize }})</span>
    </span>
  </span>

</template>
<script>
  import { FILE_MAX_SIZE, FILE_SUPPORTED_EXTENSIONS, API_ROOT } from '../../../config';
  import { mapState } from 'vuex';
  import eventBus from 'services/eventBus';
  import fileUpload from 'vue-upload-component/src';
  import notificationService from 'services/notificationService';
  import Document from '../domain/document';

  export default {
    components: { fileUpload: fileUpload },
    data: () => ({
      uploadVisible: false,
      files: [],
      apiRoot: API_ROOT,
      maxSize: FILE_MAX_SIZE,
      fileExtensions: FILE_SUPPORTED_EXTENSIONS
    }),
    computed: mapState({
      headers: (state) => ({
        'Authorization': 'Bearer ' + state.auth.accessToken
      })
    }),
    props: {
      showFiles: {
        type: Boolean,
        default: true
      },
      allowedTypes: {
        type: String,
        default: null
      }
    },
    methods: {
      showUpload () {
        eventBus.$emit('fileUploadShow');
        this.uploadVisible = true;
      },
      cancel () {
        this.files.length = 0;
        this.hideUpload();
      },
      hideUpload () {
        this.uploadVisible = false;
      },
      inputFile: function (newFile, oldFile) {
        if (newFile && oldFile && !newFile.active && oldFile.active) {
          // Get response data
          if (newFile && newFile.error) {
            let arg = null;
            switch (newFile.error) {
              case 'extension': arg = this.allowedTypes || FILE_SUPPORTED_EXTENSIONS; break;
              case 'size': arg = this.$options.filters.fileSize(FILE_MAX_SIZE); break;
            }
            notificationService.error('error.upload.' + newFile.error, [arg]);

            this.files.length = 0;
          } else if (oldFile.xhr) {
            //  Get the response status code
            this.$emit('documentUploaded', new Document(newFile.response));
            this.cancel();
          }
        }
      }
    },
    created () {
      // show
      eventBus.$on('fileUploadShow', () => {
        this.hideUpload();
      });
    }
  };
</script>
