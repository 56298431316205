import accountResource from './accountResource';
import Account from '../domain/account';

export default {
  async find (id) {
    const response = await accountResource.get({ id: id });
    if (response.ok) {
      return new Account(response.data);
    } else {
      return null;
    }
  }
};
