import * as types from './mutationTypes';
import locationService from '../service/locationService';

const actions = {
  async getAll ({ commit, state, dispatch }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'locations', { root: true });
      const locations = await locationService.findAll();
      await commit(types.LOCATION_LIST, {
        items: locations || []
      });
      dispatch('app/loadingDataDisable', 'locations', { root: true });
    }
  },
  async create ({ dispatch, commit }, location) {
    const newLocation = await locationService.create(location);
    if (newLocation) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async update ({ dispatch, commit }, location) {
    const updatedLocation = await locationService.update(location);
    if (updatedLocation) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async delete ({ dispatch, commit }, id) {
    const response = await locationService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async deactivate ({ dispatch }, id) {
    const response = await locationService.deactivate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async activate ({ dispatch }, id) {
    const response = await locationService.activate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
