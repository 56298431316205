import CodeList from 'domain/codeList';

class MoneyMovementCategory extends CodeList {
  constructor (data) {
    super(data);
    this.type = data.type || null;
    this.order = data.order || 0;
  }
}

export default MoneyMovementCategory;
