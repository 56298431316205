'use strict';
import Vue from 'vue';
import autInterceptor from './authInterceptor';
import unauthorizedInterceptor from './unauthorizedInterceptor';
import errorInterceptor from './errorInterceptor';
import dataOptimalizationInterceptor from './dataOptimalizationInterceptor';

export default (store, router) => {
  Vue.http.interceptors.push(autInterceptor(store));
  Vue.http.interceptors.push(unauthorizedInterceptor(store, router));
  Vue.http.interceptors.push(errorInterceptor(store, router));
  Vue.http.interceptors.push(dataOptimalizationInterceptor());
};
