import bankTransactionsView from 'modules/bank/components/bankTransactions';

export default [
  {
    path: '/bank-transactions',
    component: bankTransactionsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  }
];
