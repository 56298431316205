'use strict';

import _ from 'lodash';
import {FORMAT_CURRENCY} from "utils";

export default (value, currency) => {
  if (_.isUndefined(value) || value === null) {
    return null;
  } else {
    return FORMAT_CURRENCY(value, currency);
  }
};
