<template>
  <div v-if="moneyMovement">
    <modal :backdrop="false" v-model="show" @ok="submit()" @cancel="cancel($event)" @closed="onClose()"
           title="Upraviť pohyb" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <static-field label="Dátum">
              {{ moneyMovement.date | dateTime }}
            </static-field>
          </div>
          <div class="col-md-6">
            <date-field v-model="moneyMovement.taxableDate" field-id="taxableDate" label="Dátum zdan. plnenia"></date-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <select-field
              v-if="editableSubject"
              label="Subjekt"
              :options="subjects"
              field-id="subject"
              v-model="moneyMovement.subject">
            </select-field>
            <static-field v-else label="Subjekt">
              {{ moneyMovement.subject.format() }}
            </static-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              label="Kategória"
              :options="categories"
              field-id="category"
              v-model="moneyMovement.category">
            </select-field>
          </div>
          <div class="col-md-6">
            <select-field
              label="Doklad"
              :options="invoices"
              field-id="invoice"
              v-model="moneyMovement.invoice">
            </select-field>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-4">
            <static-field label="Celkom">
              <span :class="{'text-danger' : moneyMovement.type === 'OUTCOME', 'text-success' : moneyMovement.type === 'INCOME'}">
                {{ moneyMovement.totalAmount | currency(moneyMovement.moneyBox.currency) }}
              </span>
            </static-field>
          </div>
          <div class="col-md-3">
            <radio-field
              field-id="fiscal"
              :inline="false"
              class="amount-type-radio"
              v-model="selectedAmountType"
              :options="movementAmountTypes"
              validation="required">
            </radio-field>
          </div>
          <div class="col-md-1">
            <dropdown type="default" class="btn-input-line" :disabled="vatDisabled() || !moneyMovement.date">
              <li v-for="vatRate in vatRates"><a role="button" @click.prevent="moneyMovement.calculateVat(vatRate.rate)">{{ vatRate.label }}</a></li>
            </dropdown>
          </div>
          <div class="col-md-4">
            <number-field v-model="moneyMovement.totalVat"
                          label="DPH"
                          placeholder="Zadaj DPH"
                          field-id="totalVat"
                          :readonly="vatDisabled()"
                          :step="100"
                          :suffix="moneyMovement.moneyBox.currency | codeListValue(currencies)"
                          :validation-obj="{required: selectedAmountType === 'FISCAL', decimal: 2, max_value:99999999.99, min_value: 0}" ></number-field>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <text-field v-model="moneyMovement.note" label="Poznámka" field-id="note" :maxlength="300" validation="max:300"></text-field>
          </div>
        </div>
        <div class="row" v-if="isEdit()">
          <div class="col-md-12">
            <checkbox-field
              field-id="active"
              label="Ignorovať pohyb"
              v-model="moneyMovement.active"
              :middle="false"
              :reverse="true">
            </checkbox-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import modal from 'vue-strap/src/Modal';
  import dropdown from 'vue-strap/src/Dropdown';
  import validationMixin from 'components/validationMixin';
  import moneyMovementCreateModalMixin from './moneyMovementCreateModalMixin';
  import staticField from 'components/form/staticField';
  import checkboxField from 'components/form/checkboxField';
  import textField from 'components/form/textField';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import radioField from 'components/form/radioField';
  import dateField from 'components/form/dateField';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, dropdown, staticField, numberField, textField, selectField, radioField, checkboxField, dateField },
    mixins: [validationMixin, moneyMovementCreateModalMixin],
    data: () => ({
      editableSubject: true
    }),
    watch: {
      model: function (model) {
        this.editableSubject = !model.subject;
      },
      'moneyMovement.invoice' (invoice) {
        if (invoice && this.modelInitialized) {
          this.selectedAmountType = 'FISCAL';
          this.moneyMovement.totalVat = invoice.totalVat;
          this.moneyMovement.taxableDate = invoice.taxableDate;
        }
      }
    }
  };
</script>
<style lang="less">
  .amount-type-radio {
    label {
      font-weight: normal;
      margin-bottom: 15px;
    }
  }
</style>
