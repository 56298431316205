<template>
  <th :class="{'sorting_asc': active && sortData.asc, 'sorting_desc': active && !sortData.asc, 'sorting': !active}" @click.prevent="sort()" role="button">{{ label }}</th>
</template>
<script>
  export default {
    computed: {
      active () {
        return this.sortData.by === this.property;
      }
    },
    props: {
      label: {
        type: String,
        required: true
      },
      property: {
        type: String,
        required: true
      },
      sortData: {
        type: Object,
        required: true
      }
    },
    methods: {
      sort () {
        const asc = this.sortData.by === this.property ? !this.sortData.asc : this.sortData.asc;
        this.$emit('sort', { by: this.property, asc: asc });
      }
    }
  };
</script>
