<template>
  <div v-if="activities">
    <activity-modal :show-model="showActivityModal" :model="selectedActivity" @close="hideModals()"></activity-modal>
    <record-modal v-if="entity && entityType" :show-model="showRecordModal" :model="newRecord" :entity="entity" :record-entity="entityType" @close="hideModals()"></record-modal>

    <box>
      <template #header v-if="title">
        <h3 class="box-title">{{ title }}</h3>
      </template>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover dataTable">
                <thead>
                <tr>
                  <th class="text-center">Stav</th>
                  <th class="text-center">Typ</th>
                  <th>Dátum</th>
                  <th>Subjekt</th>
                  <th>Kontakt</th>
                  <th>Projekt</th>
                  <th>Názov</th>
                  <th class="text-center">Poznámka</th>
                  <th>Autor</th>
                  <th v-if="displayAssignee">Riešiteľ</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="activity in activities">
                  <tr role="button" :class="{'danger': isInPast(activity.date), 'warning': isDateToday(activity.date) }">
                    <td class="text-center">
                      <span :title="activity.status | codeListValue(activityStatuses)">
                        <i :class="statusIcon(activity.status)"></i>
                      </span>
                    </td>
                    <td class="text-center">
                      <span :title="activity.type | codeListValue(activityTypes)">
                        <i :class="typeIcon(activity.type)" class="text-primary"></i>
                      </span>
                    </td>
                    <td>{{ activity.date | date }}</td>
                    <td>
                      <subject-link v-if="activity.subject" :subject="activity.subject"></subject-link>
                    </td>
                    <td>{{ activity.contact ? activity.contact.name : null }}</td>
                    <td>
                      <project-link v-if="activity.project" :project="activity.project"></project-link>
                    </td>
                    <td>{{ activity.name }}</td>
                    <td class="text-center text-primary">
                      <i v-if="activity.note" :title="activity.note" class="fa fa-info-circle fa-2x"></i>
                    </td>
                    <td>{{ activity.createdBy ? activity.createdBy.fullName : null }}</td>
                    <td v-if="displayAssignee">{{ activity.assignee ? activity.assignee.fullName : null }}</td>
                    <td>
                      <div class="pull-right text-nowrap">
                        <button class="btn btn-xs btn-link" @click.prevent="editActivity(activity)" :title="'Editovať'"><i class="fa fa-pencil-square-o fa-2x text-primary"></i></button>
                        <button class="btn btn-xs btn-link" :disabled="!activity.isOpen()" @click.prevent="finishActivity(activity)" :title="'Ukončiť'"><i class="fa fa-check fa-2x text-success"></i></button>
                        <button class="btn btn-xs btn-link" :disabled="!activity.isOpen()" @click.prevent="cancelActivity(activity)" :title="'Zrušiť'"><i class="fa fa-times fa-2x text-danger"></i></button>
                        <button class="btn btn-xs btn-link" @click.prevent="sendActivity(activity)" :title="'Odoslať'"><i class="fa fa-envelope-o fa-2x text-primary"></i></button>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row box-data">
          <div class="col-sm-12">
            <loading id="activities"></loading>
            <no-records :data="activities" loading-id="activities"></no-records>
          </div>
        </div>
      </template>
    </box>
  </div>
</template>

<script>
  import moment from 'moment';
  import activityStatuses from '../../../codelist/activityStatuses';
  import activityTypes from '../../../codelist/activityTypes';
  import noRecords from '../../../components/noRecords';
  import loading from '../../../components/loading';
  import activityModal from 'modules/activity/components/activityCreateModal';
  import notificationService from 'services/notificationService';
  import _ from 'lodash';
  import emailService from 'services/emailService';
  import recordService from 'services/recordService';
  import recordModal from 'modules/record/components/recordCreateModal';
  import projectLink from 'modules/project/components/projectLink';
  import subjectLink from 'components/subjectLink';
  import box from 'components/box';

  export default {
    components: { noRecords, loading, activityModal, recordModal, projectLink, subjectLink, box },
    data: () => {
      return {
        activityStatuses: activityStatuses,
        activityTypes: activityTypes,
        showActivityModal: false,
        selectedActivity: null,
        showRecordModal: false,
        newRecord: null
      };
    },
    props: {
      displayAssignee: {
        type: Boolean,
        default () {
          return false;
        }
      },
      title: {
        type: String,
        default () {
          return null;
        }
      },
      activities: {
        type: Array,
        default () {
          return [];
        }
      }
    },
    computed: {
      entity () {
        if (this.selectedActivity && this.selectedActivity.project) {
          return this.selectedActivity.project;
        } else if (this.selectedActivity && this.selectedActivity.subject) {
          return this.selectedActivity.subject;
        } else {
          return null;
        }
      },
      entityType () {
        if (this.selectedActivity && this.selectedActivity.project) {
          return 'project';
        } else if (this.selectedActivity && this.selectedActivity.subject) {
          return 'subject';
        } else {
          return null;
        }
      }
    },
    methods: {
      createRecord (activity) {
        this.newRecord = recordService.newFromActivity(activity);
        if (activity.subject || activity.project) {
          this.showRecordModal = true;
        }
      },
      editActivity (activity) {
        this.selectedActivity = _.cloneDeep(activity);
        this.showActivityModal = true;
      },
      finishActivity (activity) {
        this.$store.dispatch('activity/finish', activity.id).then((result) => {
          if (result) {
            notificationService.success('activity.finish.success');
            this.selectedActivity = _.cloneDeep(activity);
            this.createRecord(activity);
          } else {
            notificationService.error('error.activity.finish');
          }
        });
      },
      cancelActivity (activity) {
        this.$store.dispatch('activity/cancel', activity.id).then((result) => {
          if (result) {
            notificationService.success('activity.close.success');
          } else {
            notificationService.error('error.activity.close');
          }
        });
      },
      sendActivity (activity) {
        const contacts = activity.contact ? [activity.contact] : null;
        emailService.prepareEmailForSubject({ contacts }, activity.name, activity.note);
      },
      isDateToday (date) {
        return date.isSame(moment(), 'd');
      },
      isInPast (date) {
        return moment().startOf('day').isAfter(date);
      },
      statusIcon (status) {
        switch (status) {
          case 'PLANNED':
            return 'fa fa-clock-o fa-2x text-warning';
          case 'DONE':
            return 'fa fa-check-circle-o fa-2x text-success';
          case 'CANCELED':
            return 'fa fa-ban fa-2x text-danger';
        }
      },
      typeIcon (status) {
        switch (status) {
          case 'PERSONAL_MEETING':
            return 'fa fa-users fa-2x';
          case 'EMAIL':
            return 'fa fa-envelope-o fa-2x';
          case 'PHONE_CALL':
            return 'fa fa-phone fa-2x';
          case 'TO_DO':
            return 'fa fa-list-ol fa-2x';
        }
      },
      hideModals () {
        this.showActivityModal = false;
        this.showRecordModal = false;
        this.selectedActivity = null;
      }
    }
  };
</script>
