import {
  EMPLOYEE_ACTIVATE,
  EMPLOYEE_CLEAR,
  EMPLOYEE_DEACTIVATE,
  EMPLOYEE_LIST,
  EMPLOYEE_SELECT,
  EMPLOYEE_UPDATE
} from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [EMPLOYEE_LIST] (state, action) {
    state.items = action.items;
  },
  [EMPLOYEE_SELECT] (state, action) {
    state.selected = action.item;
  },
  [EMPLOYEE_CLEAR] (state) {
    state.selected = null;
  },
  [EMPLOYEE_UPDATE] (state, action) {
    // let toBeUpdated = _.find(state.items, {id: action.item.id});
    // //when array, replace it at all - vue problem
    // _.mergeWith(toBeUpdated, action.item, MERGE);
    state.selected = action.item;
  },
  [EMPLOYEE_ACTIVATE] (state, id) {
    const employee = _.find(state.items, { id: id });
    if (employee) {
      employee.active = true;
    }
  },
  [EMPLOYEE_DEACTIVATE] (state, id) {
    const employee = _.find(state.items, { id: id });
    if (employee) {
      employee.active = false;
    }
  }
};

export default mutations;
