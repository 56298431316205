import tmOrderItemService from '../service/tmOrderItemService';

const actions = {
  async create ({ dispatch }, orderItem) {
    const newOrderItem = await tmOrderItemService.create(orderItem);
    if (newOrderItem) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  },
  async update ({ dispatch }, orderItem) {
    const updateOrderItem = await tmOrderItemService.update(orderItem);
    if (updateOrderItem) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  },
  async delete ({ dispatch }, id) {
    const response = await tmOrderItemService.delete(id);
    if (response.ok) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
