import usersView from '../components/users';

export default [{
  path: '/users',
  component: usersView,
  meta: {
    requiresLoggedIn: true,
    requiresRole: 'ADMIN'
  }
}];
