export const API_ROOT = 'api/';
export const COLOR = '#00a65a';
export const YEARS_MIN = 2015;
export const AUTH_STORAGE_KEY = 'auth_data';
export const LOCALE = 'sk';
export const OAUTH_CLIENT_ID = 'brainis-client';
export const OAUTH_SECRET = 'password';
export const FILE_SUPPORTED_EXTENSIONS = 'xls,xlsx,doc,docx,pdf,png,jpg,jpeg';
export const FILE_MAX_SIZE = 2097152; // 2MB
export const DEFAULT_CURRENCY = 'CZK';
export const ORDER_GENEATED_DOCUMENT_NAME = 'objednavka';
export const INVOICE_GENERATED_DOCUMENT_NAME = 'Faktura';
export const COMPANY_AGREEMENT_DOCUMENT_NAME = 'ramcova-smlouva';
export const INVOICE_ITEM_NAME_TEMPLATE_FOR_DELIVERY = 'Subdodávka vývojových a konzultačních prací - %s - %s';
export const GENERATED_DOCUMENT_SUFFIX = '.docx';
export const GENERATED_DOCUMENT_PDF_SUFFIX = '.pdf';
export const GENERATED_DOCUMENT_CSV_SUFFIX = '.csv';
export const GENERATED_DOCUMENT_XML_SUFFIX = '.xml';
export const EMPLOYMENT_AGREEMENT_DOCUMENT_NAME = 'dopp';
export const TM_REWARDS_DOCUMENT_NAME = 'provizie';
export const TM_REWARDS_DUE_DATE_DAY_OFFSET = 45;
export const TRANSACTIONS_DOCUMENT_NAME = 'vypis-brainis';
export const DEFAULT_EMPLOYMENT_AGREEMENT_AMOUNT = 10000;
export const ARES_SEARCH_MIN_LENGTH = 3;
export const ARES_SEARCH_DELAY = 2000;

export const DEFAULT_CONSTANT_SYMBOL = '0308';
export const DEFAULT_PAYMENT_METHOD = 'BANK_TRANSFER';
export const DEFAULT_INOVOICE_ITEM_UNIT = 'MAN_DAY';
export const DEFAULT_APP_COLOR = 'green';

export const CC_EMAILS = 'vmartis@smartbrains.cz,mhostak@smartbrains.cz';

export const CHART_COLORS = {
  green: '#00A65A',
  red: '#DD4B39',
  blue: '#0073B7',
  turquoise: '#48D1CC'
};
