import * as types from './mutationTypes';
import vatRateService from '../service/vatRateService';

const actions = {
  async getAll ({ commit, state, dispatch }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'vatRates', { root: true });
      const vatRates = await vatRateService.findAll();
      await commit(types.VAT_RATE_LIST, {
        items: vatRates || []
      });
      dispatch('app/loadingDataDisable', 'vatRates', { root: true });
    }
  }
};

export default actions;
