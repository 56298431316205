<template>
  <div>
    <hr>
    <div class="row">
      <div class="col-md-12">
        <h4>Voliteľné položky</h4>
      </div>

      <div v-show="value.length" class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-hover table-condensed">
              <thead>
                <tr>
                  <th>Popis</th>
                  <th>Počet</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="item in value">
                <td class="col-md-8">{{ item.orderItem.name }}</td>
                <td class="col-md-2">{{ item.itemNumber }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    props: {
      value: {
        required: true,
        type: Array
      }
    }
  };
</script>
