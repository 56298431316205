import * as types from './mutationTypes';
import employeeService from '../service/employeeService';
import _ from 'lodash';

const actions = {
  async select ({ dispatch, commit }, id) {
    await commit(types.EMPLOYEE_SELECT, {
      item: null
    });
    dispatch('app/loadingDataEnable', 'employee', { root: true });
    const employee = await employeeService.find(id);
    await commit(types.EMPLOYEE_SELECT, {
      item: employee
    });
    dispatch('app/loadingDataDisable', 'employee', { root: true });
  },
  async clear({ commit }) {
    await commit(types.EMPLOYEE_CLEAR);
  },
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'employees', { root: true });
      const employees = await employeeService.findAll();
      await commit(types.EMPLOYEE_LIST, {
        items: employees
      });
      dispatch('app/loadingDataDisable', 'employees', { root: true });
    }
  },
  async create ({ dispatch }, employee) {
    try {
      const newEmployee = await employeeService.create(employee);
      if (newEmployee) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        return newEmployee;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch, commit }, employee) {
    try {
      const updatedEmployee = await employeeService.update(employee);
      if (updatedEmployee) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.EMPLOYEE_UPDATE, {
          item: employee
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.EMPLOYEE_ACTIVATE, id);
      const employee = _.find(state.items, { id: id });
      const updatedEmployee = await employeeService.update(employee);
      if (updatedEmployee) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.EMPLOYEE_UPDATE, {
          item: employee
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.EMPLOYEE_DEACTIVATE, id);
      const employee = _.find(state.items, { id: id });
      const updatedEmployee = await employeeService.update(employee);
      if (updatedEmployee) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.EMPLOYEE_UPDATE, {
          item: employee
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await employeeService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      dispatch('subject/getAll', true, { root: true });
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
