import Entity from 'domain/entity';

class AmountSummary extends Entity {
  constructor (data) {
    super(data);

    this.dateFrom = this.convertDate(this.dateFrom);
    this.dateTo = this.convertDate(this.dateTo);
  }
}

export default AmountSummary;
