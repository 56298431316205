import tmRewardService from '../../../services/tmRewardService';
import * as types from '../../mutationTypes';

const actions = {
  async getAll ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'tmRewards', { root: true });
    const tmRewards = await tmRewardService.findAll(filter);

    await commit(types.TM_REWARD_LIST, {
      filter: filter,
      items: tmRewards
    });
    dispatch('app/loadingDataDisable', 'tmRewards', { root: true });
  },
  async refreshAll ({ state, dispatch }) {
    dispatch('getAll', state.filter);
  },
  async create ({ dispatch }, tmReward) {
    try {
      const newReward = await tmRewardService.create(tmReward);
      if (newReward) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, reward) {
    try {
      const updatedTmReward = await tmRewardService.update(reward);
      if (updatedTmReward) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async pay ({ dispatch }, rewardIds) {
    try {
      const result = await tmRewardService.pay(rewardIds);
      if (result) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await tmRewardService.delete(id);
    if (response.ok) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
