<template>
  <div>
    <contacts :subject="lead"></contacts>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import contacts from 'modules/contact/components/contacts';

  export default {
    components: { contacts },
    computed: mapState({
      lead: (state) => state.lead.selected
    })
  };
</script>
