<template>
    <box>
      <template #header v-if="title">
        <h3 class="box-title">{{ title }}</h3>
      </template>
      <template #body>
        <code-list-modal :show-model="showCreateModal" :model="selectedItem" :module-name="moduleName" @close="createHide()"></code-list-modal>
        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <button class="btn btn-success" type="button" @click="createShow()"><i class="fa fa-plus"></i></button>
            </div>
          </div>
          <form class="form-inline">
            <div class="col-sm-10">
             <div class="pull-right">
               <div class="form-group">
                 <div class="checkbox">
                   <label>
                     <input type="checkbox" id="inactive" v-model="showInactive"> Zobraziť neaktívne
                   </label>
                 </div>
               </div>
               <div class="form-group" v-if="showQueryFilter" >
                 <label>
                   <input v-focus type="text" placeholder="Vyhľadaj podľa názvu" class="form-control" v-model="query">
                 </label>
               </div>
             </div>
            </div>
          </form>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-responsive no-padding table-hover" v-if="items.length">
                <thead>
                <tr class="bg-gray">
                  <th>#</th>
                  <th>Názov</th>
                  <th>Popis</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, index) in items">
                  <tr :class="{inactive : !item.valid}">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.label }}</td>
                    <td>{{ item.shortDescription }}</td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.prevent="editItem(item)"><i class="fa fa-pencil text-muted" title="Editovať"></i></button>
                      <button class="btn btn-link" @click.prevent="positionUp(item)" v-if="item.valid && orderable"><i class="fa fa-long-arrow-up text-muted"></i></button>
                      <button class="btn btn-link" @click.prevent="positionDown(item)" v-if="item.valid && orderable"><i class="fa fa-long-arrow-down text-muted"></i></button>
                    </td>
                  </tr>
                </template>
                </tbody>
                <tfoot>
                  <tr class="bg-gray">
                    <td colspan="5">Počet položiek: {{ items.length }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading :id="`${loadingId}`"></loading>
            <no-records :data="items" :loading-id="`${loadingId}`"></no-records>
          </div>
        </div>
      </template>
    </box>
</template>

<script>
  import noRecords from 'components/noRecords';
  import loading from 'components/loading';
  import codeListMixin from './codeListMixin';
  import box from 'components/box';

  export default {
    components: { loading, noRecords, box },
    mixins: [codeListMixin]
  };
</script>
