<template>
  <div>
    <bank-accounts :filter="{subjectId: company.id, showInactive: true}" :subjects="[company]" :api-token-supported="false" :subject-select-supported="false"></bank-accounts>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import bankAccounts from 'modules/bank/components/bankAccounts';

  export default {
    components: { bankAccounts },
    computed: mapState({
      company: (state) => state.company.selected
    })
  };
</script>
