<template>
  <div v-if="delivery">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Nová DOPP" cancel-text="Zrušiť" ok-text="Generovať" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <number-field
              v-model="amount"
              label="Fakturovaná čiastka" placeholder="Zadaj fakturovanú čiastku"
              field-id="amount"
              :min="1"
              :max="500000"
              :step="1000"
              validation="required|numeric|min_value:1|max_value:500000"
              :suffix="!!delivery.order ? delivery.order.currency : currency | codeListValue(currencies)">
            </number-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import modal from 'vue-strap/src/Modal';
  import numberField from 'components/form/numberField';
  import validationMixin from 'components/validationMixin';
  import TmDelivery from '../domain/tmDelivery';
  import {
    DEFAULT_CURRENCY,
    DEFAULT_EMPLOYMENT_AGREEMENT_AMOUNT,
    EMPLOYMENT_AGREEMENT_DOCUMENT_NAME,
    GENERATED_DOCUMENT_SUFFIX
  } from '../config';
  import { FORMAT_DATE_PERIOD, normalizeFilename } from 'utils';
  import currencies from 'codelist/currencies';
  import tmDeliveryService from '../services/tmDeliveryService';
  import modalMixin from './modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, numberField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      currencies: currencies,
      show: false,
      delivery: null,
      amount: DEFAULT_EMPLOYMENT_AGREEMENT_AMOUNT,
      currency: DEFAULT_CURRENCY
    }),
    methods: {
      onSubmit () {
        const deliveryPeriod = FORMAT_DATE_PERIOD(this.delivery.period);
        const supplierName = this.delivery.order.supplier.name;
        const fileName = normalizeFilename(`${deliveryPeriod}-${supplierName}-${EMPLOYMENT_AGREEMENT_DOCUMENT_NAME}`);
        tmDeliveryService.generateAgreementDocument(this.delivery.id, this.amount, `${fileName}${GENERATED_DOCUMENT_SUFFIX}`);
        this.$emit('close');
      },
      clearData () {
        this.delivery = null;
        this.amount = DEFAULT_EMPLOYMENT_AGREEMENT_AMOUNT;
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.delivery = new TmDelivery(model);
        }
      }
    }
  };
</script>
