<template>
  <page title="Prehľad financií">
    <div class="row">
      <div class="col-md-4">
        <cash-flow-box :currency="currency"></cash-flow-box>
      </div>
      <div class="col-md-4">
        <vat-summary-box :currency="currency"></vat-summary-box>
      </div>
      <div class="col-md-4">
        <tax-base-summary-box :currency="currency"></tax-base-summary-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <money-boxes-summary :currency="currency"></money-boxes-summary>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <cash-flow-summary :currency="currency"></cash-flow-summary>
      </div>
    </div>
  </page>
</template>
<script>
  import page from 'components/page';
  import cashFlowBox from './cashFlowBox';
  import vatSummaryBox from './vatSummaryBox';
  import taxBaseSummaryBox from './taxBaseSummaryBox';
  import moneyBoxesSummary from './moneyBoxesSummary';
  import cashFlowSummary from './cashFlowSummary';

  export default {
    components: {moneyBoxesSummary, vatSummaryBox, taxBaseSummaryBox, cashFlowBox, page, cashFlowSummary},
    props: {
      currency: {
        type: String,
        required: true
      }
    },
    watch: {
      //need to clear movements based on currency change, because different currency
      // pages are same route only with different value of currency parameter and destroy method
      // is not called
      async currency(newCurrency, oldCurrency) {
        if (newCurrency && oldCurrency && newCurrency !== oldCurrency) {
          await this.$store.dispatch('moneyMovement/clear');
        }
      }
    },
    created() {
      this.$store.dispatch('moneyBox/getAll', true);
      this.$store.dispatch('moneyMovement/findLatest');
    },
    destroyed() {
      this.$store.dispatch('moneyBox/clear', true);
      this.$store.dispatch('moneyMovement/clearLatest');
      this.$store.dispatch('moneyMovement/clear');
    }
  };
</script>
