import subjectsView from 'modules/subject/components/subjects';

export default [
  {
    name: 'subjects',
    path: '/subjects',
    component: subjectsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    }
  }
];
