<template>
  <a @click="copy" role="button" class="m" v-if="content"><i class="fa fa-clone" aria-hidden="true" /></a>
</template>

<script>
  import notificationService from 'services/notificationService';

  export default {
    data: () => ({
      show: false
    }),
    props: {
      content: {
        type: String,
        required: false
      }
    },
    methods: {
      copy () {
        let container = this.$refs.container;
        this.$copyText(this.content, container);
        notificationService.success('text.copied.clipboard');
      }
    },
  };
</script>
