import tmOrderService from '../service/tmOrderService';

const actions = {
  async create ({ dispatch }, tmProject) {
    try {
      const newOrder = await tmOrderService.create(tmProject);
      if (newOrder) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, tmOrder) {
    try {
      const updatedOrder = await tmOrderService.update(tmOrder);
      if (updatedOrder) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async updateDocuments ({ dispatch }, tmOrder) {
    try {
      const updatedOrder = await tmOrderService.updateDocuments(tmOrder);
      if (updatedOrder) {
        await dispatch('tmProject/refreshSelected', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await tmOrderService.delete(id);
    if (response.ok) {
      await dispatch('tmProject/refreshSelected', null, { root: true });
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
