import Vue from 'vue';
import currencyFilter from './currencyFilter';
import numberFilter from './numberFilter';
import dateFilter from './dateFilter';
import dateTimeFilter from './dateTimeFilter';
import timeFilter from './timeFilter';
import truncateFilter from './truncateFilter';
import dateMonthFilter from './dateMonthFilter';
import codeListValueFilter from './codeListValueFilter';
import codeListPropertyFilter from './codeListPropertyFilter';
import fileSize from './fileSizeFilter';
import translate from './translateFilter';

export default () => {
  Vue.filter('currency', currencyFilter);
  Vue.filter('number', numberFilter);
  Vue.filter('date', dateFilter);
  Vue.filter('dateTime', dateTimeFilter);
  Vue.filter('time', timeFilter);
  Vue.filter('dateMonth', dateMonthFilter);
  Vue.filter('truncate', truncateFilter);
  Vue.filter('codeListValue', codeListValueFilter);
  Vue.filter('codeListProp', codeListPropertyFilter);
  Vue.filter('fileSize', fileSize);
  Vue.filter('translate', translate);
};
