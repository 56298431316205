import * as types from './mutationTypes';
import moneyMovementCategoryService from '../service/momentMovementCategoryService';

const actions = {
  async getAll ({ commit, state, dispatch }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'moneyMovementCategories', { root: true });
      const categories = await moneyMovementCategoryService.findAll();
      await commit(types.MONEY_MOVEMENT_CATEGORY_LIST, {
        items: categories || []
      });
      dispatch('app/loadingDataDisable', 'moneyMovementCategories', { root: true });
    }
  },
  async create ({ dispatch, commit }, category) {
    const newCategory = await moneyMovementCategoryService.create(category);
    if (newCategory) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async update ({ dispatch, commit }, category) {
    const updatedCategory = await moneyMovementCategoryService.update(category);
    if (updatedCategory) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async delete ({ dispatch, commit }, id) {
    const response = await moneyMovementCategoryService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async deactivate ({ dispatch }, id) {
    const response = await moneyMovementCategoryService.deactivate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async activate ({ dispatch }, id) {
    const response = await moneyMovementCategoryService.activate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async updatePosition ({ dispatch, state, commit }, data) {
    try {
      const updated = await moneyMovementCategoryService.updatePosition(data.id, data.position );
      if (updated) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  }
};

export default actions;
