<template>
  <div class="row">
    <div class="col-md-12">
      <p v-if="document">
        <a role="button" @click.prevent="download(document)" download><i :class="[fileIcon(document.fileName)]" class="fa" :title="document.fileName"></i> {{ document.fileName }}</a> <a role="button" v-show="editable" @click.prevent="deleteDocument(document)"> &nbsp;&nbsp;<i class="fa fa-times text-danger"></i></a>
      </p>
      <alert v-show="!document" type="warning">{{ 'document.empty' | translate }}</alert>
    </div>
  </div>
</template>
<script>

  import { alert } from 'vue-strap';
  import documentMixin from 'modules/document/components/documentMixin';

  export default {
    components: { alert },
    props: {
      document: {
        type: Object
      },
      editable: {
        type: Boolean,
        default: true
      }
    },
    mixins: [documentMixin]
  };
</script>
