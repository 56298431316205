import actions from './transactionActions';
import mutations from './transactionMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
