import invoicesView from 'modules/invoice/components/invoices';
import invoiceOutcomeCreate from 'modules/invoice/components/invoiceOutcomeCreate';
import invoiceIncomeCreate from 'modules/invoice/components/invoiceIncomeCreate';
import invoiceDetialView from 'modules/invoice/components/invoiceDetial';

export default [
  {
    path: '/invoices/:type',
    name: 'invoices',
    component: invoicesView,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice/:id',
    name: 'invoiceDetail',
    component: invoiceDetialView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-new/OUTCOME/:deliveryIds?',
    name: 'invoiceOutcomeCreate',
    component: invoiceOutcomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-copy/OUTCOME/:invoiceId?',
    name: 'invoiceOutcomeCopy',
    component: invoiceOutcomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-new/INCOME/:deliveryIds?',
    name: 'invoiceIncomeCreate',
    component: invoiceIncomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-copy/INCOME/:invoiceId?',
    name: 'invoiceIncomeCopy',
    component: invoiceIncomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-edit/OUTCOME/:id',
    name: 'invoiceOutcomeEdit',
    component: invoiceOutcomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/invoice-edit/INCOME/:id',
    name: 'invoiceIncomeEdit',
    component: invoiceIncomeCreate,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  }
];
