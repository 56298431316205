import Entity from '../../../domain/entity';
import Contact from '../../contact/domain/contact';
import Document from 'modules/document/domain/document';
import User from 'modules/user/domain/user';
import TmProject from 'modules/project/domain/tmProject';
import subjectService from 'modules/subject/service/subjectService';

class Record extends Entity {
  constructor (data) {
    super(data);
    this.date = this.convertDate(this.date);
    this.type = this.type || null;
    this.note = this.note || null;
    this.contact = data.contact ? new Contact(data.contact) : null;
    this.documents = data.documents ? data.documents.map(document => new Document(document)) : [];
    this.subject = data.subject ? subjectService.getSubject(data.subject) : null;
    this.createdBy = data.createdBy ? new User(data.createdBy) : null;
    this.project = data.project ? new TmProject(data.project) : null;
  }
}

export default Record;
