<template>
  <div>
    <info-box title="Základ dane z príjmu" icon-class="fa fa-upload">
      <template #content>
        <div v-for="summary in taxBaseSummaries">
          <span>{{ summary.dateFrom.get('year') }}</span>
          <strong class="pull-right">{{ summary.amount | currency(currency) }}</strong>
        </div>
      </template>
    </info-box>
  </div>

</template>
<script>
  import infoBox from 'components/infoBox';
  import {mapState} from 'vuex';

  export default {
    components: {infoBox},
    props: {
      currency: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState({
        taxBaseSummaries(state) {
          return state.financeSummary.taxBaseSummaryItems;
        }
      })
    },
    methods: {
      fetch() {
        this.$store.dispatch('financeSummary/getTaxBaseSummaries', this.currency);
      }
    },
    watch: {
      currency() {
        this.fetch();
      }
    },
    mounted() {
      this.fetch();
    }
  };
</script>
