import locationsView from 'modules/location/components/locations';

export default [{
  path: '/locations',
  component: locationsView,
  meta: {
    requiresLoggedIn: true,
    requiresRole: 'ADMIN'
  }
}];
