'use strict';
/**
 * Interceptor for optimization of CREATE/UPDATE entity. It ensures not sending whole associated entity properties to
 * BE, just id and @class attribute.
 */

import _ from 'lodash';
import Entity, {RELATIONS} from '../domain/entity';

const IGNORING_PREFIX = '_'; // property with prefix '_' will be ignored

export default () => {
  function optimizeArray (arr) {
    const result = [];
    arr.forEach(item => {
      if (_.isArray(item)) {
        result.push(optimizeArray(item));
      } else if (_.isObject(item)) {
        result.push(optimizeProp(item));
      } else {
        result.push(item);
      }
    });
    return result;
  }

  function optimizeObject (obj) {
    const result = {};
    if (obj) {
      const relations = obj[RELATIONS]; // store relations to local prop, will be eliminated in optimization
      _.mapKeys(obj, (value, key) => {
        // do not optimize, if property is marked as relation
        if (relations && relations.indexOf(key) >= 0) {
          // only remove props staring with '_'
          result[key] = withoutIgnoring(value);
        } else if (key.startsWith(IGNORING_PREFIX)) {
          // do not map keys starting with '_'
        } else {
          result[key] = optimizeProp(value);
        }
      });
    }
    return result;
  }

  function optimizeProp (prop) {
    if (prop instanceof Entity) {
      return _.pick(prop, ['id', '@class']);
    } else if (_.isArray(prop)) {
      return optimizeArray(prop);
    } else {
      return prop;
    }
  }

  function withoutIgnoring (prop) {
    if (prop instanceof Entity) {
      return withoutIgnoringObject(prop);
    } else if (_.isArray(prop)) {
      return prop.map(withoutIgnoringObject);
    } else {
      return prop;
    }
  }

  function withoutIgnoringObject (prop) {
    return _.omitBy(prop, (value, key) => key.startsWith(IGNORING_PREFIX));
  }

  return (request) => {
    if (request.method === 'POST' || request.method === 'PUT') {
      if (_.isArray(request.body)) {
        request.body = optimizeArray(request.body);
      } else {
        request.body = optimizeObject(request.body);
      }
    }
  };
};
