<template>
  <div v-if="lead">
    <documents :documents="lead.documents" @deleteDocument="deleteDocument($event, lead)"></documents>
    <document-upload @documentUploaded="documentUploaded($event, lead)"></document-upload>
  </div>
</template>
<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import notificationService from 'services/notificationService';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';

  export default {
    components: { documents, documentUpload },
    computed: mapState({
      lead: (state) => state.lead.selected
    }),
    methods: {
      documentUploaded (document, lead) {
        const leadCopy = _.cloneDeep(lead);
        leadCopy.documents.push(document);
        this.$store.dispatch('lead/update', leadCopy).then((result) => {
          if (result) {
            notificationService.success('lead.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocument (document, lead) {
        const leadCopy = _.cloneDeep(lead);
        _.remove(leadCopy.documents, { id: document.id });
        this.$store.dispatch('lead/update', leadCopy).then((result) => {
          if (result) {
            notificationService.success('lead.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      }
    }
  };
</script>
