<template>
  <page title="Číslelné rady faktúr">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <select-field
                :options="years"
                v-model="filter.year"
                field-id="year"
                label="Rok">
              </select-field>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <div class="row">
          <div class="col-sm-2">
            <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
          </div>
        </div>
      </template>

      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <invoice-series-modal :show-model="showCreateModal" :model="selectedInvoiceSeries" @close="hideModals()"></invoice-series-modal>
              <table class="table table-hover no-padding" v-if="invoiceSeries && invoiceSeries.length">
                <tbody>
                <tr>
                  <th>Spoločnosť</th>
                  <th>Prefix</th>
                  <th>Rok</th>
                  <th>Index</th>
                  <th class="text-center">Predvolená</th>
                  <th></th>
                </tr>
                <template v-for="series in invoiceSeries">
                  <tr :class="{ inactive : !series.active }">
                    <td>{{ series.company ? series.company.name : '' }}</td>
                    <td>{{ series.prefix }}</td>
                    <td>{{ series.year }}</td>
                    <td>{{ series.index }}</td>
                    <td class="text-center"><i class="fa" :class="{'fa-check text-success': series.defaultFlag, 'fa-times text-danger': !series.defaultFlag}"></i></td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" @click.stop="editInvoiceSeries(series)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="toggleInvoiceSeriesActive(series)"><i :class="{ 'fa-toggle-on': series.active, 'fa-toggle-off text-success': !series.active }" class="fa fa-2x" :title="series.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                      <button class="btn btn-xs btn-link" @click.stop="deleteInvoiceSeries(series)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="invoiceSeries"></loading>
            <no-records :data="invoiceSeries" loading-id="invoiceSeries"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>

</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import invoiceSeriesModal from 'modules/invoice/components/invoiceSeriesCreateModal';
  import notificationService from 'services/notificationService';
  import invoiceSeriesService from 'modules/invoice/service/invoiceSeriesService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import years from 'codelist/years';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { invoiceSeriesModal, loading, noRecords, selectField, page, box },
    data: () => ({
      showCreateModal: false,
      selectedInvoiceSeries: null,
      years,
      filter: {
        year: null,
        showInactive: false
      }
    }),
    computed: {
      ...mapState({
        invoiceSeries (state) {
          let invoiceSeries;
          if (!this.filter.showInactive) {
            invoiceSeries = this.$store.getters['invoiceSeries/active'];
          } else {
            invoiceSeries = state.invoiceSeries.items;
          }

          if (this.filter.year) {
            return state.invoiceSeries.items.filter(item => item.year === this.filter.year.value);
          } else {
            return invoiceSeries;
          }
        }
      })
    },
    methods: {
      createShow () {
        this.selectedInvoiceSeries = invoiceSeriesService.newInvoiceSeries();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      deleteInvoiceSeries (invoiceSeries) {
        this.confirm('invoiceSeries.delete.confirmation', [invoiceSeries.formatName()]).then(() => {
          this.$store.dispatch('invoiceSeries/delete', invoiceSeries.id).then((result) => {
            if (result) {
              notificationService.success('invoiceSeries.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      toggleInvoiceSeriesActive (invoiceSeries) {
        const action = invoiceSeries.active ? 'deactivate' : 'activate';
        this.$store.dispatch('invoiceSeries/' + action, invoiceSeries.id).then((result) => {
          if (result) {
            notificationService.success('invoiceSeries.' + action + '.success');
          } else {
            notificationService.error('error.invoiceSeries.' + action);
          }
        });
      },
      editInvoiceSeries (invoiceSeries) {
        this.selectedInvoiceSeries = _.cloneDeep(invoiceSeries);
        this.showCreateModal = true;
      }
    },
    created () {
      this.$store.dispatch('invoiceSeries/getAll', true);
    }
  };
</script>
