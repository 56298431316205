<template>
  <page title="Užívatelia">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
      </template>
      <template #body>
        <password-change-modal :show-model="showChangePasswordModal" :model="selectedUser" @close="hideModals()"></password-change-modal>
        <user-modal :show-model="showCreateModal" :model="selectedUser" @close="hideModals()"></user-modal>
        <div class="row">
          <div class="col-sm-12">
            <div class="box-body table-responsive">
              <table class="table table-hover no-padding" v-if="users && users.length">
                <thead>
                <tr>
                  <th>Meno</th>
                  <th>Priezvisko</th>
                  <th>Prihlasovacie meno</th>
                  <th>E-mail</th>
                  <th>Telefónne číslo</th>
                  <th>Role</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="user in users">
                  <tr :class="{inactive : !user.active}">
                    <td>{{ user.firstname }}</td>
                    <td>{{ user.surname }}</td>
                    <td>{{ user.username }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phoneNumber }}</td>
                    <td><span v-for="(role, index) in user.roles"> {{ role | codeListValue(roles) }}<span v-if="user.roles.length -1 !== index">, </span></span></td>
                    <td class="text-right">
                      <button class="btn btn-xs btn-link" :disabled="!editable(user.id)" @click.prevent="editUser(user)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" :disabled="!editable(user.id)" @click.prevent="changePassword(user)"><i class="fa fa-2x fa-key text-primary" title="Zmena hesla"></i></button>
                      <button class="btn btn-xs btn-link" :disabled="!activatable(user.id)" @click.prevent="toggleUserActive(user)"><i :class="{'fa-toggle-on': user.active, 'fa-toggle-off text-success': !user.active}" class="fa fa-2x" :title="user.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                      <button class="btn btn-xs btn-link" :disabled="!deletable(user.id)" @click.prevent="deleteUser(user)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="users"></loading>
            <no-records :data="users" loading-id="users"></no-records>
          </div>
        </div>
      </template>

    </box>
  </page>

</template>

<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapState } from 'vuex';
  import { roles } from 'codelist/';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import userModal from 'modules/user/components/userCreateModal';
  import passwordChangeModal from 'modules/user/components/passwordChangeModal';
  import notificationService from 'services/notificationService';
  import userService from 'modules/user/service/userService';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { userModal, passwordChangeModal, loading, noRecords, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      roles: roles,
      showCreateModal: false,
      showChangePasswordModal: false,
      selectedUser: null,
      filter: {
        query: null,
        showInactive: false
      }
    }),
    computed: mapState({
      loading: (state) => state.app.loadingData,
      currentUser: (state) => state.auth.user,
      users (state) {
        let users = null;
        if (!this.filter.showInactive) {
          users = this.$store.getters['user/active'];
        } else {
          users = state.user.items;
        }
        if (this.filter.query) {
          const regexp = new RegExp(normalize(this.filter.query), 'g');
          return users.filter(item => !!normalize(item.fullName).match(regexp));
        } else {
          return users;
        }
      }
    }),
    methods: {
      createShow () {
        this.selectedUser = userService.newUser();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
        this.showChangePasswordModal = false;
      },
      deletable (userId) {
        return this.currentUser.id !== userId;
      },
      activatable (userId) {
        return this.currentUser.id !== userId;
      },
      editable (userId) {
        return this.currentUser.id !== userId;
      },
      deleteUser (user) {
        this.confirm('user.delete.confirmation', [user.username]).then(() => {
          this.$store.dispatch('user/delete', user.id).then((result) => {
            if (result) {
              notificationService.success('user.delete.success');
            }
          }).finally(this.unprotect);
        });
      },
      toggleUserActive (user) {
        const action = user.active ? 'deactivate' : 'activate';
        this.$store.dispatch('user/' + action, user.id).then((result) => {
          if (result) {
            notificationService.success('user.' + action + '.success');
          } else {
            notificationService.error('error.user.' + action);
          }
        });
      },
      editUser (user) {
        this.selectedUser = _.cloneDeep(user);
        this.showCreateModal = true;
      },
      changePassword (user) {
        this.selectedUser = _.cloneDeep(user);
        this.showChangePasswordModal = true;
      },
      defaultFilter () {
        this.filter.query = null;
        this.filter.showInactive = false;
      }
    },
    created () {
      this.$store.dispatch('user/getAll');
    }
  };
</script>
