import Vue from 'vue';
import eventBus from 'services/eventBus';

export default () => {
  // inject a handler for `myOption` custom option
  Vue.mixin({
    methods: {
      confirm: (confirmationTextKey, args) => {
        const promise = new Promise((resolve, reject) => {
          eventBus.$on('confirmed', () => {
            resolve();
          });
          eventBus.$on('cancelled', () => {
            reject();
          });
        });

        eventBus.$emit('confirmAction', confirmationTextKey, args);
        return promise;
      }
    }
  });
};
