<script>
  import { Bar, mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      responsive: {
        type: Boolean,
        default: true
      },
      maintainAspectRatio: {
        type: Boolean,
        default: false
      },
      beforeLabelCallback: {
        type: Function,
        default: (tooltipItem, data) => null
      },
      afterLabelCallback: {
        type: Function,
        default: (tooltipItem, data) => null
      },
      labelCallback: {
        type: Function,
        default: null
      },
      tooltipMode: {
        type: String,
        default: 'nearest'
      }
    },
    methods: {
      createChartOptions () {
        return {
          responsive: this.responsive,
          maintainAspectRatio: this.maintainAspectRatio,
          tooltips: {
              mode: this.tooltipMode,
            callbacks: {
              label: this.labelCallback,
              beforeLabel: this.beforeLabelCallback,
              afterLabel: this.afterLabelCallback
            }
          },
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true
              }
            }]
          }
        };
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.createChartOptions());
    }
  };
</script>
