import _ from 'lodash';
import i18n from 'i18n/';

export default {
  prepareEmailForSubject (targetSubject, subjectKey, bodyKey, subjectsArgs, bodyArgs) {
    const emailRecipients = !_.isNil(targetSubject) ? _(targetSubject.contacts).filter(c => !_.isNil(c.email)).map('email').join() : '';
    this.prepareEmail(emailRecipients, subjectKey, bodyKey, subjectsArgs, bodyArgs);
  },
  prepareEmail (emailRecipients, subjectKey, bodyKey, subjectsArgs, bodyArgs) {
    const emailSubject = _.isString(subjectKey) ? i18n.message(subjectKey, subjectsArgs) : '';
    const emailBody = _.isString(bodyKey) ? i18n.message(bodyKey, bodyArgs) : '';

    let email = `subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    if (_.isString(emailRecipients)) {
      email = `mailto:${emailRecipients}?${email}`;
    } else if (_.isObject(emailRecipients)) {
      email = `mailto:${emailRecipients.mailto}?cc=${emailRecipients.cc}&${email}`;
    }

    window.location = email;
  }
};
