<template>
  <div>
    <h4>Aktuálny zostatok na účte</h4>
    <table class="table table-responsive no-padding table-hover dataTable">
      <tbody>
        <tr>
          <td><strong>Celkom</strong></td>
          <td class="text-right"><strong>{{ moneyBox.amount | currency(moneyBox.currency) }}</strong></td>
        </tr>
        <tr>
          <td>Z toho DPH</td>
          <td class="text-right">{{ moneyBox.vatAmount || 0 | currency(moneyBox.currency) }}</td>
        </tr>
        <tr>
          <td>Bez DPH</td>
          <td class="text-right">{{ moneyBox.amountWithoutVat | currency(moneyBox.currency) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import MoneyBox from "../domain/moneyBox";

  export default {
    props: {
      moneyBox: {
        type: MoneyBox,
        required: true
      }
    }
  };
</script>
