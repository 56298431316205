<template>
  <validation-provider :rules="validation || validationObj" v-slot="{ errors }">
    <div class="form-group" :class="{ 'has-error': errors.length }">
      <label v-show="showLabel">
        <slot name="label"></slot>
        {{ label }}
      </label>
      <textarea :name="fieldId"
                :id="fieldId"
                v-model="innerValue"
                :maxlength="maxlength"
                :readonly="readonly"
                class="form-control"
                :class="cssClass"></textarea>
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
    </div>
  </validation-provider>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {ValidationProvider},
    data: () => ({
      innerValue: null
    }),
    props: {
      value: {
        type: String
      },
      label: {
        type: String,
        required: false,
        default () {
          return '';
        }
      },
      showLabel: {
        type: Boolean,
        required: false,
        default () {
          return true;
        }
      },
      cssClass: {
        type: String,
        required: false
      },
      fieldId: {
        type: String,
        required: true
      },
      maxlength: {
        type: Number,
        required: false,
        default () {
          return Number.MAX_VALUE;
        }
      },
      readonly: {
        type: Boolean,
        default: false
      },
      validation: {
        type: String
      },
      validationObj: {
        type: Object,
        default () {
          return {};
        }
      }
    },
    watch: {
      innerValue (value) {
        this.$emit('input', value);
      },
      value: {
        handler(value) {
          this.innerValue = value;
        }
      }
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    }
  };
</script>
