import bankAccountResource from './bankAccountResource';
import _ from 'lodash';
import BankAccount from '../domain/bankAccount';

export default {
  newBankAccount () {
    return new BankAccount({});
  },
  async findByFilter(filter) {
    const response = await bankAccountResource.query(filter);
    if (response.ok) {
      return response.data.map((bankAccount) => new BankAccount(bankAccount));
    } else {
      return null;
    }
  },
  async create (bankAccount) {
    const response = await bankAccountResource.save({}, _.pickBy(bankAccount));
    if (response.ok) {
      return new BankAccount(response.data);
    } else {
      return null;
    }
  },
  async update (bankAccount) {
    const response = await bankAccountResource.update({}, _.pickBy(bankAccount));
    if (response.ok) {
      return new BankAccount(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return bankAccountResource.delete({ id: id });
  }
};
