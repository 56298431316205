import actions from './currencyRateActions';
import mutations from './currencyRateMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
