<template>
  <div v-if="delivery">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia dodávky" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <select-field
              :options="orderOptions"
              v-model="delivery.order"
              field-id="order"
              label="Objednávka"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <date-field v-model="delivery.period" field-id="period" label="Obdobie" validation="required" :minimum-view="'month'"></date-field>
          </div>
          <div class="col-md-6">
            <number-field
              v-model="delivery.mdNumber"
              label="Počet MD" placeholder="Zadaj počet MD"
              field-id="mdNumber"
              :min="0.00001"
              :step="1"
              validation="required|decimal:5|max_value:99999.99999|min_value:0.00001">
            </number-field>
          </div>
        </div>
        <div class="row" v-if="delivery.optionalItems.length">
          <div class="col-md-12">
            <hr>
            <h4>Voliteľné položky</h4>
          </div>
          <div class="col-md-12">
            <div class="row" v-for="(deliveryItem, index) in delivery.optionalItems">
              <div class="col-md-6">
                <number-field
                  v-model="deliveryItem.itemNumber"
                  :label="deliveryItem.orderItem.name"
                  :field-id="'deliveryItem' + index"
                  :min="0"
                  :step="1"
                  validation="decimal:5|max_value:99999.99999|min_value:0">
                </number-field>
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import notificationService from 'services/notificationService';
  import tmDeliveryService from 'services/tmDeliveryService';
  import dateField from 'components/form/dateField';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import validationMixin from 'components/validationMixin';
  import TmDelivery from '../domain/tmDelivery';
  import modalMixin from './modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, dateField, numberField, selectField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      delivery: null
    }),
    computed: mapState({
      orderOptions: (state) => state.tmProject.selected.deliveryOrders
    }),
    methods: {
      onSubmit () {
        let operation;
        if (this.delivery.id) {
          operation = 'tmDelivery/update';
        } else {
          operation = 'tmDelivery/create';
        }
        const delivery = _.cloneDeep(this.delivery);
        this.$store.dispatch(operation, delivery).then((result) => {
          if (result) {
            notificationService.success('delivery.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.delivery = null;
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.delivery = new TmDelivery(model);
        }
      },
      'delivery.order': function (order) {
        if (this.delivery) {
          tmDeliveryService.generateItems(this.delivery, order);
        }
      }
    }
  };
</script>
