import _ from 'lodash';
import Document from 'modules/document/domain/document';
import Entity from './entity';
import Invoice from '../modules/invoice/domain/invoice';
import { normalize } from '../utils';
import TmDeliveryItem from './tmDeliveryItem';
import TmOrder from '../modules/order/domain/tmOrder';
import TmReward from './tmReward';

class TmDelivery extends Entity {
  constructor (data) {
    super(data);
    this.period = this.convertDate(data.period);
    this.mdNumber = this.mdNumber || null;
    this.order = data.order ? new TmOrder(data.order) : null;
    this.documents = data.documents ? data.documents.map(order => new Document(order)) : [];
    this.rewards = data.rewards ? data.rewards.map(reward => new TmReward(reward)) : [];
    this.optionalItems = data.optionalItems ? data.optionalItems.map(deliveryItem => new TmDeliveryItem(deliveryItem)) : [];
    this.invoiceOutcome = data.invoiceOutcome ? new Invoice(data.invoiceOutcome) : null;
    this.invoiceIncome = data.invoiceIncome ? new Invoice(data.invoiceIncome) : null;
    this.relations('optionalItems');

    this.buildSearchString();
  }

  get price () {
    return this.mdNumber * this.order.mdRate;
  }

  get priceTotal () {
    return this.price + this.optionalItemSum;
  }

  get sell () {
    return this.order.mainOrder.mdRate ? this.mdNumber * this.order.mainOrder.mdRate : 0;
  }

  get sellTotal () {
    return this.sell + this.optionalItemSum;
  }

  get optionalItemSum () {
    return _(this.optionalItems).flatMap(deliveryItem => deliveryItem.itemNumber * deliveryItem.orderItem.price).sum();
  }

  get profit () {
    return this.order.mainOrder ? this.sell - this.price : null;
  }

  get netProfit () {
    return this.order.mainOrder ? this.profit - this.rewardsSum : null;
  }

  get rewardsSum () {
    return this.rewards ? _(this.rewards).map('amount').sum() : 0;
  }

  get rewardsRecipients () {
    return this.rewards ? _(this.rewards).map(r => r.recipient).value() : [];
  }

  buildSearchString () {
    this._searchString = '';

    if (this.order) {
      if (this.order.supplier) {
        this._searchString += this.order.supplier.format() + ' ';
      }
      if (this.order.subscriber) {
        this._searchString += this.order.subscriber.format() + ' ';
      }
      if (this.order.project) {
        this._searchString += this.order.project.name + ' ';
      }
    }

    // concat string without whitespaces
    this._searchString += this._searchString.replace(/\s/g, '');

    this._searchString += ' ' + normalize(this._searchString);
  }
}

export default TmDelivery;
