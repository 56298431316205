import { CONTACT_LIST } from 'modules/contact/store/contactMutationTypes';
import contactService from 'modules/contact/service/contactService';

const actions = {
  async findBySubject ({ dispatch, commit }, subjectId) {
    await commit(CONTACT_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'contacts', { root: true });
    const contacts = await contactService.findBySubject(subjectId);
    await commit(CONTACT_LIST, {
      filter: {
        subjectId
      },
      items: contacts
    });
    dispatch('app/loadingDataDisable', 'contacts', { root: true });
  },
  async findByProject ({ dispatch, commit }, projectId) {
    await commit(CONTACT_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'contacts', { root: true });
    const contacts = await contactService.findByProject(projectId);
    await commit(CONTACT_LIST, {
      filter: {
        projectId
      },
      items: contacts
    });
    dispatch('app/loadingDataDisable', 'contacts', { root: true });
  },
  async refresh ({ dispatch, state }) {
    if (state.filter.hasOwnProperty('subjectId')) {
      dispatch('findBySubject', state.filter.subjectId);
    } else {
      dispatch('findByProject', state.filter.projectId);
    }
  },
  async create ({ dispatch }, contact) {
    try {
      const newReward = await contactService.create(contact);
      if (newReward) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, contact) {
    try {
      const updatedContact = await contactService.update(contact);
      if (updatedContact) {
        await dispatch('refresh');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await contactService.delete(id);
    if (response.ok) {
      await dispatch('refresh');
      return true;
    } else {
      return false;
    }
  },
  clearState ({ commit }) {
    commit(CONTACT_LIST, {
      filter: null,
      items: []
    });
  }
};

export default actions;
