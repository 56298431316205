'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import middlewares from './middlewares';
import * as modules from './modules/';
import plugins from './plugins/';

const debug = process.env.NODE_ENV !== 'production';
Vue.use(Vuex);
Vue.config.debug = debug;
Vue.config.warnExpressionErrors = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  // modules: modules,
  strict: debug,
  middlewares,
  modules: modules,
  plugins: plugins
});

export default store;
