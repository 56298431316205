import actions from './recordActions';
import mutations from './recordMutations';

const state = {
  filter: null,
  entityId: null,
  entity: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
