import eventBus from '../services/eventBus';

/**
 * Hook for fixing of closing modal windows, if redirect to other route occurs during "submit" function.
 * @returns {Function}
 */
export default () => {
  return (to, from, next) => {
    eventBus.$emit('close-force');
    next();
  };
};
