'use strict';

import _ from 'lodash';

export default (value, length) => {
  if (_.isUndefined(value) || value === null || !length) {
    return null;
  } else {
    return _.truncate(value, { length: length });
  }
};
