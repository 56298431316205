<template>
  <div>
    <bank-accounts :filter="{subjectId: employee.id, showInactive: true}" :subjects="[employee]" :api-token-supported="false" :subject-select-supported="false"></bank-accounts>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import bankAccounts from 'modules/bank/components/bankAccounts';

  export default {
    components: { bankAccounts },
    computed: mapState({
      employee: (state) => state.employee.selected
    })
  };
</script>
