import actions from './vatRateActions';
import mutations from './vatRateMutations';
import getters from './vatRateGetters';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  getters: getters,
  actions: actions
};
