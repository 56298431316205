import financeSummaryResource from './financeSummaryResource';
import AmountSummary from "../domain/amountSummary";

export default {
  async getVatSummaries(currency) {
    const response = await financeSummaryResource.vat({currency});
    if (response.ok) {
      return response.data.map((vatSummaryData) => new AmountSummary(vatSummaryData));
    } else {
      return null;
    }
  },
  async getTaxBaseSummaries(currency) {
    const response = await financeSummaryResource.taxBase({currency});
    if (response.ok) {
      return response.data.map((taxBaseSummaryData) => new AmountSummary(taxBaseSummaryData));
    } else {
      return null;
    }
  }
};
