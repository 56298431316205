<template>
  <div>
    <tm-order-modal :show-model="showCreateTmOrderModal" :model="selectedOrder" @close="hideModals()"></tm-order-modal>
    <div class="row">
      <div class="col-md-12">
        <p>
          <button v-show="project.editable" class="btn btn-success" type="button" @click="createIncomingOrderShow()"><i class="fa fa-plus" aria-hidden="true"></i> Pridať objednávku</button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <accordion :one-at-atime="true" type="info">
          <panel v-for="order in project.incomingOrders" :key="order.id">
            <strong slot="header">
              <u>Objednávka č. {{ order.number }}, obdobie: {{ order.validFrom | date }} - {{ order.validTo | date }}</u>
              <div class="clearfix"></div>
            </strong>

            <div class="row">
              <div class="col-md-12">
                <div class="pull-right" v-show="project.editable">
                  <div class="dropdown pull-right">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <i class="fa fa-gear"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a role="button" @click.prevent="editOrder(order)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                      <li><a role="button" @click.prevent="deleteOrder(order)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <dl>
                  <dt>Dodávateľ</dt>
                  <dd><subject-link v-if="order.supplier" :subject="order.supplier"></subject-link></dd>
                </dl>
                <dl>
                  <dt>Číslo</dt>
                  <dd>{{ order.number }}</dd>
                </dl>
                <dl>
                  <dt>DPH</dt>
                  <dd>{{ order.vatRate }} % </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Oberateľ</dt>
                  <dd><subject-link v-if="order.subscriber" :subject="order.subscriber"></subject-link></dd>
                </dl>
                <dl>
                  <dt>Suma</dt>
                  <dd>{{ order.sum | currency(order.currency) }}</dd>
                </dl>
                <dl>
                  <dt>Splatnosť faktury v dňoch</dt>
                  <dd>{{ order.invoiceDueDateOffset }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Platná od</dt>
                  <dd>{{ order.validFrom | date }}</dd>
                </dl>
                <dl>
                  <dt>Počet MD</dt>
                  <dd>{{ order.mdNumber }}</dd>
                </dl>
                <dl>
                  <dt>Text na faktúru</dt>
                  <dd :title="order.invoiceItemText">{{ order.invoiceItemText | truncate(20) }} <copy-text :content="order.invoiceItemText"></copy-text></dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Platná do</dt>
                  <dd>{{ order.validTo | date }}</dd>
                </dl>
                <dl>
                  <dt>Cena za MD</dt>
                  <dd>{{ order.mdRate | currency(order.currency) }}</dd>
                </dl>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <h4>Dokumenty</h4>
              </div>
              <div class="col-md-12">
                <documents :editable="project.editable" :documents="order.documents" @deleteDocument="deleteDocumentOrder($event, order)"></documents>
                <document-upload @documentUploaded="documentUploadedOrder($event, order)" v-show="project.editable"></document-upload>
              </div>
            </div>

            <hr>

            <div class="row" v-show="order.subOrders && order.subOrders.length">
              <hr>
              <div class="col-md-12">
                <h4>Dodávkové objednávky</h4>
              </div>
              <div class="col-md-12">
                <table class="table table-condensed">
                  <thead>
                    <tr>
                      <th>Číslo</th>
                      <th>Dodávateľ</th>
                      <th>Oberateľ</th>
                      <th>Platná od</th>
                      <th>Platná do</th>
                      <th>Počet MD</th>
                      <th>Cena za MD</th>
                      <th v-show="project.editable"></th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="order in order.subOrders">
                    <td>{{ order.number }}</td>
                    <td>
                      <subject-link v-if="order.supplier" :subject="order.supplier"></subject-link>
                    </td>
                    <td>
                      <subject-link v-if="order.subscriber" :subject="order.subscriber"></subject-link>
                    </td>
                    <td>{{ order.validFrom | date }}</td>
                    <td>{{ order.validTo | date }}</td>
                    <td>{{ order.mdNumber }}</td>
                    <td>{{ order.mdRate | currency(order.currency) }}</td>
                    <td class="text-right" v-show="project.editable">
                      <button class="btn btn-xs btn-link" @click.prevent="editOrder(order)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" @click.prevent="deleteOrder(order)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </panel>
        </accordion>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import copyText from "../../common/components/copyText.vue";
  import { mapState } from 'vuex';
  import { projectStatuses } from 'codelist/';
  import { accordion, panel } from 'vue-strap';
  import notificationService from 'services/notificationService';
  import tmOrderService from 'modules/order/service/tmOrderService';
  import tmOrderModal from 'components/tmOrderCreateModal';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';
  import subjectLink from 'components/subjectLink';

  export default {
    components: { copyText, accordion, panel, documents, documentUpload, tmOrderModal, subjectLink },
    data: () => ({
      projectStatuses: projectStatuses,
      selectedOrder: null,
      showCreateTmOrderModal: false
    }),
    computed: mapState({
      project: (state) => state.tmProject.selected,
      configuration: (state) => state.app.configuration
    }),
    methods: {
      createIncomingOrderShow () {
        this.selectedOrder = tmOrderService.newTmOrder({
          type: 'INCOMING',
          project: this.project,
          currency: this.project.currency,
          subscriber: this.project.customer,
          invoiceDueDateOffset: this.configuration.invoice.defaultDueDateOffset
        });
        this.showCreateTmOrderModal = true;
      },
      editOrder (order) {
        this.selectedOrder = _.cloneDeep(order);
        this.showCreateTmOrderModal = true;
      },
      deleteOrder (order) {
        this.confirm('order.delete.confirmation', [order.number]).then(() => {
          this.$store.dispatch('tmOrder/delete', order.id).then((result) => {
            if (result) {
              notificationService.success('order.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      documentUploadedOrder (document, order) {
        const orderCopy = _.cloneDeep(order);
        orderCopy.documents.push(document);
        this.$store.dispatch('tmOrder/updateDocuments', orderCopy).then((result) => {
          if (result) {
            notificationService.success('order.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocumentOrder (document, order) {
        const orderCopy = _.cloneDeep(order);
        _.remove(orderCopy.documents, { id: document.id });
        this.$store.dispatch('tmOrder/updateDocuments', orderCopy).then((result) => {
          if (result) {
            notificationService.success('order.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      hideModals () {
        this.showCreateTmOrderModal = false;
      }
    }
  };
</script>
