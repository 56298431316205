<template>
  <page :title="'Štatistika (' + currencyCode + ')'">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <date-range-field v-model="filter.range" :no-label="true" label="Obdobie"></date-range-field>
          </div>
          <div class="col-sm-6 col-md-4">
            <button class="btn btn-success btn-input-line" type="button" @click="fetch" :disabled="isProtected()">Načítať</button>
          </div>
        </div>
      </template>
    </box>
    <!--end of filter-->
    <loading id="tmDeliveries"></loading>
    <no-records loading-id="tmDeliveries" :data="deliveries"></no-records>

    <div class="row" v-if="hasData()">
      <!--base widgets column-->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <info-box :title="'Počet kontraktov'" :value="totalProjectsCount + ''" :icon-class="'fa fa-files-o'" :bg-class="'bg-aqua'" :fill="false"></info-box>
          </div>

          <div class="col-md-12">
            <info-box :title="'Počet MD'" :value="totalMD | number" :icon-class="'fa fa-calendar-check-o'" :bg-class="'bg-aqua'" :fill="false"></info-box>
          </div>

          <div class="col-md-12">
            <info-box :title="'Obrat kontraktov'" :value="totalSell | currency(currencyCode)" :icon-class="'fa fa-handshake-o'" :bg-class="'bg-aqua'" :fill="false"></info-box>
          </div>
        </div>
        <!--end of base widgets column-->
      </div>

      <!--overview chart widgets column-->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <info-box :title="'Nákup'" :value="totalPrice | currency(currencyCode)" :icon-class="'fa fa-shopping-cart'" :bg-class="'bg-green'" :fill="true"></info-box>
          </div>

          <div class="col-md-12">
            <info-box :title="'Čistý zisk'" :value="totalNetProfit | currency(currencyCode)" :icon-class="'fa fa-usd'" :bg-class="'bg-blue'" :fill="true"></info-box>
          </div>

          <div class="col-md-12">
            <info-box :title="'Provízie'" :value="totalRewards | currency(currencyCode)" :icon-class="'fa fa-gift'" :bg-class="'bg-red'" :fill="true"></info-box>
          </div>
        </div>
        <!--end of overview chart widgets column-->
      </div>

      <!--overview chart column-->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <div class="box box-default">
              <div class="box-body">
                <div class="row">
                  <div class="col-md-12">
                    <doughnut-chart :chart-data="overviewChartData" :label-callback="chartLabelCallback" :height="277"></doughnut-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end of overview chart column-->
      </div>
    </div>

    <!--profit chart row-->
    <div class="row" v-if="hasData()">
      <div class="col-md-12">
        <div class="box box-default">
          <div class="box-body">
            <bar-chart :chart-data="profitChartData" :label-callback="chartLabelCallback" ></bar-chart>
          </div>
        </div>
      </div>
      <!--end of profit chart row-->
    </div>

    <!--customers-->
    <div class="row">
      <div class="col-md-12" v-if="hasData()">
        <box>
          <template #header>
            <h3 class="box-title">Zákazníci</h3>
          </template>
          <template #body>
            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive">
                  <table class="table table-hover dataTable">
                    <thead>
                    <tr>
                      <the label="Zákazník" property="label" :sort-data="sortCustomersData" @sort="sortCustomers($event)"></the>
                      <th>Počet kontraktov</th>
                      <th>Počet MD</th>
                      <th>Predaj</th>
                      <th>Nákup</th>
                      <th>Zisk</th>
                      <th>Čistý zisk</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="customer in customers">
                      <tr role="button">
                        <td>
                          <subject-link v-if="customer" :subject="customer"></subject-link>
                        </td>
                        <td>{{ countProjectsByCustomer(customer.id) }}</td>
                        <td>{{ countMdByCustomer(customer.id) }}</td>
                        <td>{{ totalSellByCustomer(customer.id) | currency(currencyCode) }}</td>
                        <td>{{ totalPriceByCustomer(customer.id) | currency(currencyCode) }}</td>
                        <td>{{ totalProfitByCustomer(customer.id) | currency(currencyCode) }}</td>
                        <td>{{ totalNetProfitByCustomer(customer.id) | currency(currencyCode) }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <loading id="customers"></loading>
                <no-records :data="customers" loading-id="customers"></no-records>
              </div>
            </div>
          </template>
        </box>
      </div>
      <!--end of customers -->
    </div>

    <!--suppliers-->
    <div class="row" v-if="hasData()">
      <div class="col-md-12">
        <box>
          <template #header>
            <h3 class="box-title">Dodávatelia</h3>
          </template>
          <template #body>
            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive">
                  <table class="table table-hover dataTable">
                    <thead>
                    <tr>
                      <the label="Dodávateľ" property="label" :sort-data="sortSuppliersData" @sort="sortSuppliers($event)"></the>
                      <th>Počet kontraktov</th>
                      <th>Počet MD</th>
                      <th>Predaj</th>
                      <th>Nákup</th>
                      <th>Zisk</th>
                      <th>Čistý zisk</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="supplier in suppliers">
                      <tr role="button">
                        <td>
                          <subject-link v-if="supplier" :subject="supplier"></subject-link>
                        </td>
                        <td>{{ countProjectsBySupplier(supplier.id) }}</td>
                        <td>{{ countMdBySupplier(supplier.id) }}</td>
                        <td>{{ totalSellBySupplier(supplier.id) | currency(currencyCode) }}</td>
                        <td>{{ totalPriceBySupplier(supplier.id) | currency(currencyCode) }}</td>
                        <td>{{ totalProfitBySupplier(supplier.id) | currency(currencyCode) }}</td>
                        <td>{{ totalNetProfitBySupplier(supplier.id) | currency(currencyCode) }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <loading id="suppliers"></loading>
                <no-records :data="suppliers" loading-id="suppliers"></no-records>
              </div>
            </div>
          </template>
        </box>
      </div>
    </div>
    <!--end of suppliers-->

    <!--rewards-->
    <div class="row" v-if="hasData()">
      <div class="col-md-12">
        <box>
          <template #header>
            <h3 class="box-title">Provízie</h3>
          </template>
          <template #body>
            <div class="row">
              <div class="col-sm-12">
                <div class="table-responsive">
                  <table class="table table-hover dataTable">
                    <thead>
                    <tr>
                      <the :sort-data="sortRecipientsData" label="Príjemca" @sort="sortRecipients($event)" property="label" />
                      <th>Počet kontraktov</th>
                      <th>Suma</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="recipient in recipients">
                      <tr role="button">
                        <td>
                          <subject-link v-if="recipient" :subject="recipient" />
                        </td>
                        <td>{{ countProjectsByRecipient(recipient.id) }}</td>
                        <td>{{ sumRewardsByRecipient(recipient.id) | currency(currencyCode) }}</td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <loading id="recipients" />
                <no-records :data="recipients" loading-id="recipients" />
              </div>
            </div>
          </template>
        </box>
      </div>
      <!--end of rewards-->
    </div>
  </page>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { months } from 'codelist/';
  import infoBox from 'components/infoBox';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import the from 'components/the';
  import doughnutChart from 'components/chart/doughnutChart';
  import barChart from 'components/chart/barChart';
  import currencyFilter from 'filters/currencyFilter';
  import { FORMAT_DATE_PERIOD, FORMAT_SYSTEM_DATE, FORMAT_TABLE_DATE_LABEL } from 'utils';
  import { CHART_COLORS } from 'config';
  import i18n from 'i18n/';
  import subjectLink from 'components/subjectLink';
  import page from 'components/page';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import box from 'components/box';
  import  dateRangeField from "../components/form/dateRangeField";
  import Range from "../components/range";

  export default {
    components: { dateRangeField, loading, noRecords, the, doughnutChart, barChart, infoBox, subjectLink, page, box },
    mixins: [bookmarkableFilter],
    props: {
      currencyCode: {
        type: String,
        default: null
      }
    },
    data: () => {
      return {
        sortCustomersData: {
          by: 'label',
          asc: true
        },
        sortSuppliersData: {
          by: 'label',
          asc: true
        },
        sortRecipientsData: {
          by: 'label',
          asc: true
        },
        filter: {
          range: null,
          currency: null
        },
        months
      };
    },
    computed: {
      chartLabelCallback () {
        return (tooltipItem, data) => currencyFilter(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], this.currencyCode) || '';
      },
      ...mapState({
        deliveries: (state) => state.tmDelivery.items,
        totalProjectsCount: (state) => _(state.tmDelivery.items).groupBy('order.project.id').size(),
        totalMD: (state) => _.round(_(state.tmDelivery.items).map('mdNumber').sum()),
        totalSell: (state) => _(state.tmDelivery.items).map('sell').sum(),
        totalPrice: (state) => _(state.tmDelivery.items).map('price').sum(),
        totalNetProfit: (state) => _(state.tmDelivery.items).map('netProfit').sum(),
        totalRewards: (state) => _(state.tmDelivery.items).map('rewardsSum').sum(),
        customers (state) {
          const customers = _(state.tmDelivery.items)
            .map('order.mainOrder.subscriber')
            .uniqBy('id')
            .sortBy(this.sortCustomersData.by)
            .value();
          return this.sortCustomersData.asc ? customers : customers.reverse();
        },
        suppliers (state) {
          const suppliers = _(state.tmDelivery.items)
            .map('order.supplier')
            .uniqBy('id')
            .sortBy(this.sortSuppliersData.by)
            .value();
          return this.sortSuppliersData.asc ? suppliers : suppliers.reverse();
        },
        recipients (state) {
          const recipients = _(state.tmDelivery.items)
            .flatMap('rewardsRecipients')
            .uniqBy('id')
            .sortBy(this.sortRecipientsData.by)
            .value();
          return this.sortRecipientsData.asc ? recipients : recipients.reverse();
        },
        profitChartData (state) {
          const data = _(state.tmDelivery.items)
            .groupBy(d => FORMAT_DATE_PERIOD(d.period))
            .map((array, period) => ({ // Map each group to object { period: "YYYY-DD", profit: NNNN, netProfit: MMMM }
              period,
              periodMoment: moment(period),
              profit: _.sumBy(array, 'profit'),
              netProfit: _.sumBy(array, 'netProfit')
            }))
            .orderBy('period') // Order elements from the oldest to recent to most
            .takeRight(12) // Pick last 12
            .value();

          return {
            labels: _.map(data, i => FORMAT_TABLE_DATE_LABEL(i.periodMoment)),
            datasets: [
              {
                backgroundColor: CHART_COLORS.turquoise,
                data: _.map(data, 'profit'),
                label: i18n.message('label.profit')
              },
              {
                backgroundColor: CHART_COLORS.blue,
                data: _.map(data, 'netProfit'),
                label: i18n.message('label.netProfit')
              }
            ]
          };
        },
        overviewChartData (state) {
          return {
            labels: [i18n.message('label.purchase'), i18n.message('label.rewards'), i18n.message('label.netProfit')],
            datasets: [
              {
                backgroundColor: [CHART_COLORS.green, CHART_COLORS.red, CHART_COLORS.blue],
                data: [
                  _(state.tmDelivery.items).map('price').sum(),
                  _(state.tmDelivery.items).map('rewardsSum').sum(),
                  _(state.tmDelivery.items).map('netProfit').sum()]
              }
            ]
          };
        }
      })
    },
    watch: {
      currencyCode: function () {
        this.$store.dispatch('tmDelivery/clear');
        this.filter.periodFrom = moment().startOf('year');
        this.filter.periodTo = moment().endOf('year');
      }
    },
    methods: {
      hasData () {
        return this.deliveries.length > 0;
      },
      async fetch () {
        await this.protect();
        try {
          await this.$store.dispatch('tmDelivery/getAll', this.createFilter());
        } finally {
          await this.unprotect();
        }
      },
      defaultFilter () {
        this.filter.range = new Range(moment().startOf('year'));
      },
      // Customers table
      countProjectsByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .groupBy('order.project.id')
          .size();
      },
      countMdByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .map('mdNumber')
          .sum();
      },
      totalSellByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .map('sell')
          .sum();
      },
      totalPriceByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .map('price')
          .sum();
      },
      totalProfitByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .map('profit')
          .sum();
      },
      totalNetProfitByCustomer (id) {
        return _(this.deliveries)
          .filter(d => d.order.mainOrder.subscriber.id === id)
          .map('netProfit')
          .sum();
      },
      // Suppliers table
      countProjectsBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .groupBy('order.project.id')
          .size();
      },
      countMdBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .map('mdNumber')
          .sum();
      },
      totalSellBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .map('sell')
          .sum();
      },
      totalPriceBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .map('price')
          .sum();
      },
      totalProfitBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .map('profit')
          .sum();
      },
      totalNetProfitBySupplier (id) {
        return _(this.deliveries)
          .filter(d => d.order.supplier.id === id)
          .map('netProfit').sum();
      },
      // Rewards table
      countProjectsByRecipient (id) {
        return _(this.deliveries)
          .flatMap('rewards')
          .filter(r => r.recipient.id === id)
          .groupBy('project.id')
          .size();
      },
      sumRewardsByRecipient (id) {
        return _(this.deliveries)
          .flatMap('rewards')
          .filter(r => r.recipient.id === id)
          .map('amount')
          .sum();
      },
      // others
      createFilter () {
        return _.pickBy({
          periodFrom: this.filter.range && this.filter.range.from ? FORMAT_SYSTEM_DATE(this.filter.range.from) : null,
          periodTo: this.filter.range && this.filter.range.to ? FORMAT_SYSTEM_DATE(this.filter.range.to) : null,
          currency: this.currencyCode
        }, _.identity);
      },
      sortCustomers (sortData) {
        this.sortCustomersData.by = sortData.by;
        this.sortCustomersData.asc = sortData.asc;
      },
      sortSuppliers (sortData) {
        this.sortSuppliersData.by = sortData.by;
        this.sortSuppliersData.asc = sortData.asc;
      },
      sortRecipients (sortData) {
        this.sortRecipientsData.by = sortData.by;
        this.sortRecipientsData.asc = sortData.asc;
      }
    }
  };
</script>
