import Vue from 'vue';
import { API_ROOT } from '../../../config';

const contextPath = 'money-movement/bank';


const customActions = {
  split: { method: 'POST', url: API_ROOT + contextPath + '/split' }
};
export default Vue.resource(API_ROOT + contextPath, {}, customActions);
