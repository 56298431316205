<template>
  <validation-provider :rules="validation" v-slot="{ validate, errors }">
    <div class="form-group" :class="{ 'has-error': errors.length }">
      <label v-if="label && showLabel" class="col-form-label" :class="{ required: requiredVal }">{{ label | translate }}</label>
      <span class="static-field" v-if="readonly && selectedValue">{{ printValue(selectedValue.label) }}</span>
      <v-select v-if="!readonly"
                :value="selectedValue"
                @input="updateValue($event) && validate($event)"
                :placeholder="placeholder | translate"
                :multiple="multiple"
                :searchable="searchable"
                :filter-by="filterBy"
                :options="options"
                :clearable="clearable"
                :disabled="disabled"
                :label="labelProp"
                :taggable="taggable"
                :reduce="reduce"
                @search="onSearch">
        <div slot="no-options">{{ 'common.options.empty' | translate }}</div>
        <template slot="option" slot-scope="option">
          <i v-if="option._icon" :class="option._icon" class="pr-2"></i>
          <span>{{ printValue(findOption(option)[labelProp]) }}</span>
          <span v-if="option.labelDescription" class="text-description"><br/>({{ printValue(option.labelDescription) }})</span>
        </template>
        <template slot="selected-option" slot-scope="option">
          <i v-if="option._icon" :class="option._icon" class="pr-2"></i>
          <span> {{ printValue(findOption(option)[labelProp]) }}</span>
        </template>
      </v-select>
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
    </div>
  </validation-provider>
</template>
<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import vSelect from 'vue-select';
  import { ValidationProvider } from 'vee-validate';
  import i18n from 'i18n/';

  export default {
    components: { ValidationProvider, vSelect },
    computed: {
      requiredVal () {
        return ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required;
      }
    },
    data: () => ({
      selectedValue: null
    }),
    props: {
      value: {
        default: null
      },
      label: {
        type: String,
        required: false
      },
      labelProp: {
        type: String,
        required: false,
        default: () => 'label'
      },
      fieldId: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        required: false
      },
      scope: {
        type: String,
        required: false
      },
      options: {
        type: Array,
        required: true,
        default () {
          return [];
        }
      },
      validation: {
        type: String,
        default () {
          return '';
        }
      },
      validationObj: {
        type: Object,
        default () {
          return {};
        }
      },
      codelist: {
        type: Boolean,
        default: false
      },
      taggable: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      searchable: {
        type: Boolean,
        default: true
      },
      clearable: {
        type: Boolean,
        default: true
      },
      filterBy: {
        type: Function,
        default (option, label, search) {
          return normalize(label).indexOf(normalize(search)) > -1;
        }
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      onSearch: {
        type: Function,
        default: () => {}
      },
      showLabel: {
        type: Boolean,
        default: true
      },
      translate: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      updateValue (value) {
        if (this.multiple && this.taggable) {
          this.selectedValue = _.uniq(value);
        } else {
          this.selectedValue = value;
        }
        this.$emit('input', this.selectedValue);
      },
      valueUpdated (newValue) {
        this.selectedValue = newValue;
      },
      // find regular option -> referenced value can be only object with id/value
      findOption (option) {
        const optionFromList = _.find(this.options, { value: _.isNil(option.value) ? option[this.labelProp] : option.value });
        // if found in list of options, just retunn it
        if (optionFromList) {
          return optionFromList;
        } else if (this.codelist && this.taggable) {
          // not possible to show label of codelist value
          return { label: option.label };
        } else if (this.codelist) {
          // not possible to show label of codelist value
          return { label: this.translate ? 'common.not-valid' : i18n.message('common.not-valid') };
        } else {
          // option is not in list of options -> probably deleted option, show it as selected
          return option;
        }
      },
      printValue (value) {
        if (_.isNil(value)) {
          return null;
        } else {
          return this.translate ? i18n.message(value) : value;
        }
      },
      reduce (value) {
        if (this.codelist) {
          if (_.isString(value)) {
            return value;
          } else {
            return _.isNil(value.value) ? value[this.labelProp] : value.value;
          }
        }
        return value;
      }
    },
    watch: {
      value (newValue) {
        this.valueUpdated(newValue);
      },
      // for situations, where value is set earlier then options -> e.g. filters with dynamic values from server
      // we need to simulate setting of value again
      options (newValues) {
        if (newValues && newValues.length && this.value != null) {
          this.valueUpdated(this.value);
        }
      }
    },
    // for initial fill -> watch doesn't work here
    mounted () {
      this.selectedValue = this.value;
    }
  };
</script>
