import _ from 'lodash';
import moment from 'moment';
import TmOrder from 'modules/order/domain/tmOrder';
import TmReward from 'domain/tmReward';
import Entity from 'domain/entity';
import Company from 'modules/company/domain/company';

class TmProject extends Entity {
  constructor (data) {
    super(data);

    this.name = data.name || null;
    this.note = data.note || null;
    this.customer = data.customer ? new Company(data.customer) : null;

    this.orders = (this.orders || []).map(order => new TmOrder(order));
    this.rewards = (this.rewards || []).map(reward => new TmReward(reward));

    this.value = this.id;
    this.label = this.name;
  }

  get editable () {
    return this.status === 'OPEN';
  }

  get currency () {
    return _.isEmpty(this.orders) ? null : this.orders[0].currency;
  }

  get incomingOrders () {
    return this.ordersByType('INCOMING');
  }

  get deliveryOrders () {
    return this.ordersByType('DELIVERY');
  }

  get incomingOrdersSum () {
    return this.ordersSum('INCOMING');
  }

  get deliveryOrdersSum () {
    return this.ordersSum('DELIVERY');
  }

  get deliveriesSum () {
    return _(this.deliveryOrders)
      .flatMap('deliveries')
      .map(delivery => delivery.price)
      .sum();
  }

  get orderFrom () {
    return this.incomingOrders.length
      ? moment.min(
        _(this.incomingOrders)
          .map(order => order.validFrom)
          .value())
      : null;
  }

  get orderTo () {
    return this.incomingOrders.length
      ? moment.max(
        _(this.incomingOrders)
          .map(order => order.validTo)
          .value())
      : null;
  }

  get rewardsSum () {
    return _(this.rewards)
      .map('amount')
      .sum();
  }

  get profit () {
    return _(this.deliveryOrders)
      .flatMap('deliveries')
      .map(delivery => delivery.profit)
      .sum();
  }

  get profitClear () {
    return this.profit - this.rewardsSum;
  }

  ordersByType (type) {
    return _.filter(this.orders, { type: type });
  }

  ordersSum (type) {
    return _(this.orders)
      .filter({ type: type })
      .map(order => order.mdRate * order.mdNumber)
      .sum() || 0;
  }

  get lastDelivery () {
    return _(this.orders)
      .flatMap('deliveries')
      .map('period')
      .max();
  }

  get missingDelivery () {
    return moment().diff(this.lastDelivery, 'months', true) > 1;
  }

  get lastIncomingOrder () {
    return _.maxBy(this.incomingOrders, 'created');
  }

  get lastDeliveryOrder () {
    return _.maxBy(this.deliveryOrders, 'created');
  }
}

export default TmProject;
