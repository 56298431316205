import Entity from '../../../domain/entity';
import Contact from '../../contact/domain/contact';
import TmProject from 'modules/project/domain/tmProject';
import User from 'modules/user/domain/user';
import subjectService from 'modules/subject/service/subjectService';

class Activity extends Entity {
  constructor (data) {
    super(data);
    this.name = data.name || null;
    this.note = data.note || null;
    this.status = data.status || null;
    this.date = data.date ? this.convertDate(data.date) : null;
    this.type = data.type ? data.type : null;
    this.contact = data.contact ? new Contact(data.contact) : null;
    this.project = data.project ? new TmProject(data.project) : null;
    this.subject = data.subject ? subjectService.getSubject(data.subject) : null;
    this.createdBy = data.createdBy ? new User(data.createdBy) : null;
    this.assignee = data.assignee ? new User(data.assignee) : null;

    this.value = this.id;
    this.label = this.name;
  }

  isOpen () {
    return this.status !== 'DONE' && this.status !== 'CANCELED';
  }
}

export default Activity;
