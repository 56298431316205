<template>
  <div>
    <h4>Zmena zostatku účtu za obdobie</h4>
    <table class="table table-responsive no-padding table-hover dataTable">
      <tbody>
        <tr class="text-">
          <td><i class="fa fa-arrow-up text-success"></i> Príjmy</td>
          <td class="text-right text-success">{{ sumIncome | currency(currency) }}</td>
        </tr>
        <tr>
          <td><i class="fa fa-arrow-down text-danger"></i> Výdaje</td>
          <td class="text-right text-danger">{{ sumOutcome | currency(currency) }}</td>
        </tr>
        <tr>
          <td><strong>CELKOM</strong></td>
          <td class="text-right" :class="{'text-danger': sum < 0, 'text-success': sum >= 0}">{{ sum | currency(currency) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    computed: {
      sumIncome() { return _(this.movements).filter({type: 'INCOME'}).map('totalAmount').sum() || 0; },
      sumOutcome() { return _(this.movements).filter({type: 'OUTCOME'}).map('totalAmount').sum() || 0; },
      sum() { return this.sumIncome + this.sumOutcome; }
    },
    props: {
      movements: {
        type: Array,
        required: true
      },
      currency: {
        type: String,
        required: true
      }
    }
  };
</script>
