import Address from 'domain/address';
import Document from 'modules/document/domain/document';
import Subject from 'domain/subject';
import Account from 'modules/account/domain/account';

class Lead extends Subject {
  constructor (data) {
    super(data);
    this.name = data.name || null;
    this.companyId = data.companyId || null;
    this.vatId = data.vatId || null;
    this.account = data.account ? new Account(data.account) : null;
    this.web = data.web ? data.web : null;

    this.address = data.address ? new Address(data.address) : new Address({});
    this.documents = data.documents ? data.documents.map(document => new Document(document)) : [];

    this.value = this.id;
    this.label = this.name;

    this['@class'] = '.LeadDto';
  }

  static get route () {
    return 'lead';
  }

  format () {
    return this.name;
  }

  supportsContractAgreement () {
    return false;
  }
}

export default Lead;
