<template>
  <validation-provider :rules="validation" v-slot="{ errors }">
    <div class="form-group" :class="{ 'has-error': errors.length }">
      <label :class="{'checkbox-middle': middle}">
        <input type="checkbox"
               :class="cssClass"
               :name="fieldId"
               v-model="innerValue"/>
               {{ label }}
        <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
      </label>
    </div>
  </validation-provider>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {ValidationProvider},
    data: () => ({
      innerValue: null
    }),
    props: {
      value: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      cssClass: {
        type: String,
        required: false
      },
      middle: {
        type: Boolean,
        default: false
      },
      reverse: {
        type: Boolean,
        required: false
      },
      fieldId: {
        type: String,
        required: true
      },
      validation: {
        type: String,
        default: ''
      }
    },
    watch: {
      innerValue (value) {
        this.$emit('input', this.reverse ? !value : value);
      },
      value: {
        handler(value) {
          this.innerValue =  this.reverse ? !value : value;
        }
      }
    },
    created() {
      this.innerValue = this.reverse ? !this.value: this.value;
    }
  };
</script>
