<template>
  <div class="info-box" :class="{ [bgClass] : fill }">
    <span class="info-box-icon" :class="{ [bgClass] : !fill }"><i :class="iconClass"></i></span>
    <div class="info-box-content">
      <span v-if="title" class="info-box-text">{{ title }}</span>
      <span v-if="value" class="info-box-number" :class="[valueClass]">{{ value }}</span>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: null
      },
      value: {
        type: String,
        default: null
      },
      iconClass: {
        type: String,
        default: null
      },
      bgClass: {
        type: String,
        default: 'bg-aqua'
      },
      valueClass: {
        type: String,
        default: null
      },
      fill: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
