import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { currencies } from 'codelist/';

export const FORMAT_TABLE_DATE_LABEL = (value) => {
  return value ? moment(value).format('MMMM (YYYY)') : null;
};

export const FORMAT_DATE_PERIOD = (value) => {
  return value ? moment(value).format('YYYY-MM') : null;
};

export const FORMAT_DATE_WEEK_OF_YEAR = (value) => {
  return value ? moment(value).format('W') : null;
};

export const FORMAT_DATE_PERIOD_SLASH = (value) => {
  return value ? moment(value).format('MM/YYYY') : null;
};

export const FORMAT_DATE = (value) => {
  return value ? moment(value).format('DD.MM.YYYY') : null;
};

export const FORMAT_DATE_TIME = (value) => {
  return value ? moment(value).format('DD.MM.YYYY HH:mm') : null;
};

export const FORMAT_SYSTEM_DATE_TIME = (value) => {
  return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;
};

export const FORMAT_TIME = (value) => {
  return value ? moment(value).format('HH:mm') : null;
};

export const FORMAT_SYSTEM_DATE = (value) => {
  return value ? moment(value).format('YYYY-MM-DD') : null;
};

export const FORMAT_CURRENCY = (value, currency) => {
  return numeral(value).format('0,0.00') + (currency ? ' ' + _.find(currencies, { value: currency }).label : '');
};

export const MERGE = function (a, b) {
  if (_.isArray(a)) {
    return b;
  }
};

export const normalizeFilename = (value) => {
  let normalizedValue = (value || '').toLowerCase().normalize('NFD').replace(/[^\w\s-.,]/g, '').replace(/[,.\s]+/g, '-');
  // remove double dash
  while (normalizedValue.match(/.*[-][-].*/g)) {
    normalizedValue = normalizedValue.replace(/[-][-]/g, '-');
  }
  return normalizedValue;
};

export const normalize = (value) => {
  return (value || '').toLowerCase().normalize('NFD').replace(/[^\w]/g, '');
};

/**
 * Compares two string involving ascendant characters.
 * @param sortBy property to be compared
 * @returns function to be used in lodash sortBy
 */
export const localeCompare = (sortBy) => {
  return (a,b) => a[sortBy].localeCompare(b[sortBy], 0, {numeric: false});
};

export const DOWNLOAD_BLOB_TEXT_FILE = (blob, fileName) => {
  DOWNLOAD_BLOB(new Blob(["\ufeff", blob]), fileName);
};

export const DOWNLOAD_BLOB = (blob, fileName) => {
  const filename = fileName;
  const result = window.document.createElement('a');
  result.href = window.URL.createObjectURL(blob);
  result.download = filename;
  result.click();
};
