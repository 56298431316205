/* eslint-disable no-useless-constructor */
import Entity from 'domain/entity';

class Document extends Entity {
  constructor (data) {
    super(data);
  }
}

export default Document;
