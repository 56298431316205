import activitiesOverviewView from 'modules/activity/components/activitiesOverview';

export default [
  {
    path: '/activities',
    component: activitiesOverviewView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    }
  }
];
