import activityResource from './activityResource';
import Activity from '../domain/activity';
import _ from 'lodash';

export default {
  newActivity () {
    return new Activity({ status: 'PLANNED' });
  },
  async create (activity) {
    const response = await activityResource.save({}, _.pickBy(activity));
    if (response.ok) {
      return new Activity(response.data);
    } else {
      return null;
    }
  },
  async getAll () {
    const response = await activityResource.get();
    if (response.ok) {
      return response.data.map((activity) => new Activity(activity));
    } else {
      return null;
    }
  },
  async getAllByFilter (filter) {
    const response = await activityResource.query(filter);
    if (response.ok) {
      return response.data.map((activity) => new Activity(activity));
    } else {
      return null;
    }
  },
  async update (activity) {
    const response = await activityResource.update({}, _.pickBy(activity));
    if (response.ok) {
      return new Activity(response.data);
    } else {
      return null;
    }
  }
};
