<script>
  import { Doughnut, mixins } from 'vue-chartjs';

  const { reactiveProp } = mixins;

  export default {
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {
      responsive: {
        type: Boolean,
        default: true
      },
      maintainAspectRatio: {
        type: Boolean,
        default: false
      },
      labelCallback: {
        type: Function,
        default: null
      }
    },
    methods: {
      createChartOptions () {
        return {
          responsive: this.responsive,
          maintainAspectRatio: this.maintainAspectRatio,
          tooltips: {
            callbacks: {
              label: this.labelCallback
            }
          }
        };
      }
    },
    mounted () {
      this.renderChart(this.chartData, this.createChartOptions());
    }
  };
</script>
