'use strict';
import _ from 'lodash';
import { vsprintf } from 'sprintf-js';

const messages = {
  'error.validation': 'Neplatné dáta.',
  'error.upload.extension': 'Nepodporovaný typ súboru. Podporované typy sú %s.',
  'error.upload.size': 'Príliš velký súbor. Maximálna veľkosť súboru je %s.',
  'error.upload.server': 'Súbor sa nepodarilo nahrať na server.',
  'error.upload.network': 'Súbor sa nepodarilo nahrať na server.',
  'error.upload.denied': 'Súbor sa nepodarilo nahrať na server.',
  'error.originalPassword.invalid': 'Nesprávne aktuálne heslo.',
  'error.confirmPassword.invalid': 'Heslá sa nezhodujú.',
  'error.auth.invalid': 'Neoprávnený prístup.',
  'error.auth.login': 'Neplatné prihlasovacie údaje.',
  'error.record.notFound': 'Záznam neexistuje.',

  'error.user.delete.myself': 'Nie je možné zmazať prihláseného užívateľa.',
  'error.user.edit.myself': 'Nie je možné editovať prihláseného užívateľa.',
  'error.user.activate': 'Nie je možné aktovať užívateľa.',
  'error.user.deactivate': 'Nie je možné deaktivovať užívateľa.',

  'error.company.activate': 'Nie je možné aktovať firmu.',
  'error.company.deactivate': 'Nie je možné deaktovať firmu.',
  'error.company.agreement.invalid.role': 'Zmluvu nie je možné vygenerovať. Firma nie je registrovaná ako dodávateľ ani ako odberateľ.',

  'error.lead.activate': 'Nie je možné aktovať leada.',
  'error.lead.deactivate': 'Nie je možné deaktovať leada.',

  'error.employee.activate': 'Nie je možné aktovať zamestnanca.',
  'error.employee.deactivate': 'Nie je možné deaktivovať zamestnanca.',

  'error.tmOrder.invalidDates': 'Dátum platnosti objednávky "od" nesmie byť pred dátumom platnosti "do".',

  'error.invoice.export.transaction.currency.multiple': 'Nie je možné vytvoriť import platobných príkazov pre faktúry rôznej meny.',
  'error.invoice.export.transaction.empty': 'Nebola vybraná žiadna faktúra.',

  'error.delivery.update.hasPaidRewards': 'Dodávku nie je možné upraviť. Má na seba naviazané už zaplatené provízie.',
  'error.delivery.delete.hasPaidRewards': 'Dodávku nie je možné zmazať. Má na seba naviazané už zaplatené provízie.',
  'error.tmDeliveries.pay.alreadyPaid': 'Nie je možné zaplatiť už zaplatené dodávky.',
  'error.tmDeliveries.createInvoice.alreadyCreated': 'Nie je možné vytvoriť fakturu k už vyfakturovanej dodávke.',

  'error.tmReward.update.paid': 'Nie je možné upraviť už zaplatenú províziu.',
  'error.tmReward.delete.paid': 'Nie je možné zmazať už zaplatenú províziu.',
  'error.tmReward.create.emptyProject': 'Nie je možné vytvoriť províziu na prázdnom projekte bez objednávok.',
  'error.tmRewards.pay.alreadyPaid': 'Nie je možné zaplatiť už zaplatené provízie.',
  'error.tmRewards.email.multipleRecipients': 'E-mail nie je možné vytvorit. Vybrané provízie obsahujú viacero príjemcov.',

  'error.tmRewardTemplate.create.rewards': 'Nie je možné uložiť šablónu s dvoma typmi odmeny.',

  'error.tmProject.activate': 'Nie je možné aktovať zamestnanca.',
  'error.tmProject.deactivate': 'Nie je možné deaktivovať zamestnanca.',

  'error.activate.edit.myself': 'Nie je možné aktivovať prihláseného užívateľa.',
  'error.deactivate.edit.myself': 'Nie je možné deaktivovať prihláseného užívateľa.',

  'error.codeList.deactivate.invalid': 'Zneplatnenie položky nebolo možné vykonať.',

  'error.transaction.amount.zero': 'Hodnota nesmie byť 0.',
  'error.transaction.update.generated': 'Generovanú transakciu nie je možné upraviť.',
  'error.transaction.delete.generated': 'Generovanú transakciu nie je možné zmazať.',

  'error.data.invalid': 'Dáta sa nepodarilo spracovať.',
  'error.data.notFound': 'Záznam se nenašiel.',
  'error.data.payloadTooLarge': 'Obsah požiadavku je príliš veľký.',
  'error.data.integrity.violation': 'Akciu nie je možné vykonať, pretože by bola porušená integrita dát.',
  'error.data.integrity.violation.referenced': 'Položku nie je možné odstrániť, pretože je používaná v iných záznamoch.',
  'error.data.integrity.violation.duplicate': 'Akciu nie je možné vykonať. V aplikácii už existuje záznam s rovnakou hodnotou.',

  'error.order.empty': 'Objednávku nie je možné vytvoriť. Musí obsahovať aspoň jendu položku na objednanie.',
  'error.order.update.hasDelivery': 'Objednávku nie je možné upraviť. Existuje aspoň jedna dodávka.',
  'error.order.delete.hasDelivery': 'Objednávku nie je možné zmazať. Existuje aspoň jedna dodávka.',
  'error.order.document.generate': 'Dokument sa nepodarilo vygenerovať.',

  'error.tmProject.closed': 'Projekt je uzaretý. Zmeny neboli uložené.',

  'error.activity.close': 'Aktivitu sa nepodarilo zrušiť.',
  'error.activity.finish': 'Aktivitu sa nepodarilo ukončiť.',

  'error.documentType.illegal': 'Neplatný typ dokumentu.',

  'error.documentTemplate.document.empty': 'Súbor nebol vybraný.',
  'error.documentTemplate.notDefined': 'Dokument sa nepodarilo vygenerovať, šablóna nie je definovaná.',

  'error.record.create.noEntity': 'Musí byť zadaná aspoň jedna entita.',

  'error.ares.request.invalid': 'Komunikácia so službou ARES zlyhala.',
  'error.ares.request.data.exceeded': 'Priveľké množstvo výsledkov. Upresni názov firmy.',

  'error.bankAccount.already.exists': 'Bankový úšet už existuje.',
  'error.bankAccount.delete.used.invoice': 'Bankový účet nie je možné zmazať, pretože existujú faktúry naviazané na tento účet.',

  'error.bankTransaction.syncAll': 'Bankové transakcie sa nepodarilo synchronizovať.',

  'error.bankMoneyMovement.moneyBox.not.exist': 'Pre bankový účet s názvom "%s" neexistuje pokladňa.',
  'error.bankMoneyMovement.remainingAmount.notZero': 'Čiastka pohybu musí byť rozdelená celá.',

  'error.currency.rate.download': 'Menové kurzy sa nepodarilo stiahnuť.',
  'error.currency.rate.download.invalid.content': 'Neplatný obsah výsledku volania služby pre menové kurzy.',
  'error.currency.rate.download.invalid.date': 'Neplatný datum. Pre zadaný datum nie je možné stiahnuť menové kurzy.',
  'error.currency.rate.download.invalid.firstLine': 'Neplatný obsah prvého riadku výsledku volania služby pre menové kurzy.',
  'error.currency.rate.download.invalid.firstLine.date': 'Neplatný obsah prvého riadku (očakávaný dátum) výsledku volania služby pre menové kurzy.',
  'error.currency.rate.download.invalid.currencyLine': 'Neplatný obsah riadku s kurzom vo výsledku volania služby pre menové kurzy.',

  'error.moneyBox.updatePosition': 'Zmenu poradia sa nepodarilo vykonať.',

  'error.moneyMovement.subject.change.notAllowed': 'Zmena subjektu nie je možná.',

  'common.data.notRecords': 'Žiadny záznam neexistuje.',
  'common.options.empty': 'Nebola nájdená žiadna položka.',
  'common.not-valid': '!!! Neplatná položka',

  'text.copied.clipboard': 'Text bol zkopírovaný do schránky',

  'codeList.delete.confirmation': 'Naozaj chcete odstrániť položku "%s"?',

  'user.delete.confirmation': 'Naozaj chcete odstrániť užívateľa "%s"?',
  'user.delete.success': 'Uživateľ bol úspešne zmazaný?',
  'user.activate.success': 'Užívateľ bol úspešne aktivovaný.',
  'user.deactivate.success': 'Užívateľ bol úspešne deaktivovaný.',
  'user.edit.success': 'Užívateľ bol úspešne uložený.',

  'company.delete.confirmation': 'Naozaj chcete odstrániť firmu "%s"?',
  'company.delete.success': 'Firma bola úspešne zmazaná.',
  'company.activate.success': 'Firma bola úspešne aktivovaná.',
  'company.deactivate.success': 'Firma bola úspešne deaktivovaná.',
  'company.edit.success': 'Firma bola úspešne uložená.',

  'lead.delete.confirmation': 'Naozaj chcete odstrániť leada "%s"?',
  'lead.delete.success': 'Lead bol úspešne zmazaný.',
  'lead.activate.success': 'Lead bol úspešne aktivovaný.',
  'lead.deactivate.success': 'Lead bol úspešne deaktivovaný.',
  'lead.edit.success': 'Lead bol úspešne uložený.',
  'lead.convert.success': 'Lead bol úspešne transformovaný na firmu.',

  'employee.delete.confirmation': 'Naozaj chcete odstrániť zamestnanca "%s"?',
  'employee.delete.success': 'Zamestnanec bol úspešne zmazaný.',
  'employee.activate.success': 'Zamestnanec bola úspešne aktivovaný.',
  'employee.deactivate.success': 'Zamestnanec bol úspešne deaktivovaný.',
  'employee.edit.success': 'Zamestnanec bol úspešne uložený.',

  'tmProject.delete.confirmation': 'Naozaj chcete odstrániť kontrakt "%s"?',
  'tmProject.delete.success': 'Kontrakt bol úspešne zmazaný.',
  'tmProject.close.confirmation': 'Naozaj chcete uzavrieť kontrakt "%s"?',
  'tmProject.close.success': 'Kontrakt bol úspešne uzavrený',
  'tmProject.open.confirmation': 'Naozaj chcete znovu otvoriť kontrakt "%s"?',
  'tmProject.open.success': 'Kontrakt bol úspešne znovu otvorený.',
  'tmProject.activate.success': 'Kontrakt bola úspešne aktivovaný.',
  'tmProject.deactivate.success': 'Kontrakt bol úspešne deaktivovaný.',
  'tmProject.edit.success': 'Kontrakt bol úspešne uložený.',

  'tmRewardTemplate.delete.confirmation': 'Naozaj chcete odstrániť šablónu provízie?',
  'tmRewardTemplate.delete.success': 'Šablóna provízie bola úspešne zmazaná.',
  'tmRewardTemplate.edit.success': 'Šablóna provízie bola úspešne uložená.',
  'tmRewardTemplate.deactivate.success': 'Šablóna provízie  bola úspešne deaktivovaná.',
  'tmRewardTemplate.activate.success': 'Šablóna provízie  bola úspešne aktivovaná.',

  'order.delete.confirmation': 'Naozaj chcete odstrániť objednávku "%s"?',
  'order.delete.success': 'Objednávka bola úspešne zmazaná.',
  'order.edit.success': 'Objednávka bola úspešne uložená.',

  'tmOrderItem.delete.confirmation': 'Naozaj chcete odstrániť položku "%s"?',
  'tmOrderItem.delete.success': 'Položka bola úspešne zmazaná.',
  'tmOrderItem.edit.success': 'Položka bol úspešne uložená.',

  'delivery.delete.confirmation': 'Naozaj chcete odstrániť dodávku?',
  'delivery.delete.success': 'Dodávka bola úspešne zmazaná.',
  'delivery.edit.success': 'Dodávka bola úspešne uložená.',
  'delivery.notExist': 'Dodávka sa nenašla.',

  'tmReward.delete.confirmation': 'Naozaj chcete odstrániť províziu?',
  'tmReward.delete.success': 'Provízia bola úspešne zmazaná.',
  'tmReward.edit.success': 'Provízia bola úspešne uložená.',

  'documentTemplate.delete.confirmation': 'Naozaj chcete odstrániť šablónu dokumentu?',
  'documentTemplate.delete.success': 'Šablónu dokumentu bola úspešne zmazaná.',
  'documentTemplate.edit.success': 'Šablóna dokumentu bola úspešne uložená.',

  'tmRewards.pay.confirmation': 'Naozaj chcete zaplatit označené províze?',
  'tmRewards.pay.success': 'Provízie boli úspešne zaplatené.',

  'tmDeliveries.pay.confirmation': 'Naozaj chcete zaplatit označené dodávky?',
  'tmDeliveries.pay.success': 'Dodávky boli úspešne zaplatené.',

  'document.delete.confirmation': 'Naozaj chcete odstrániť dokument "%s"?',
  'document.delete.success': 'Dokument bol úspešne zmazaný.',
  'document.empty': 'Dokument nebol vybraný.',

  'contact.delete.confirmation': 'Naozaj chcete odstrániť kontakt "%s"?',
  'contact.delete.success': 'Kontakt bol úspešne zmazaný.',
  'contact.edit.success': 'Kontakt bol úspešne uložený.',

  'transaction.delete.confirmation': 'Naozaj chcete odstrániť transakciu?',
  'transaction.delete.success': 'Transakcia bola úspešne zmazaná.',
  'transaction.edit.success': 'Transakcia bola úspešne uložená.',

  'record.delete.confirmation': 'Naozaj chcete odstrániť záznam?',
  'record.delete.success': 'Záznam bol úspešne zmazaný.',
  'record.edit.success': 'Záznam bol úspešne uložený.',

  'email.order.subject': 'Objednávka - %s',
  'email.order.body': 'Ahoj,\n\nv příloze posílám objednávku (dílčí smlouvu o spolupráci). Je nastavena na %d pracovních dnů za %d %s za MD. Pro její přijetí stačí odpovědět na tento e-mail textem \"Přijímám\".\n\nMěj se fajn',

  'email.tmRewards.subject': 'Provize %s - %s.',
  'email.tmRewards.body': 'Ahoj,\n\nv příloze posílám rozpis provizí za období %s - %s. Celkem ti náleží %s. Vystav prosím fakturu na tuto částku dle naší domluvy (poslední den v měsíci, splatnost %s dnů) a zašli na e-mail faktury@smartbrains.cz.\n\nMěj se fajn',

  'email.delivery.subject': 'Fakturace - %s',
  'email.delivery.body': 'Dobrý den,\n\nposíláme faktury za %s.\n\nS pozdravem,',

  'invoice.delete.confirmation': 'Naozaj chcete odstrániť faktúru "%s"?',
  'invoice.delete.success': 'Faktúra bola úspešne zmazaná.',
  'invoice.edit.success': 'Faktúra bola úspešne uložená.',

  'invoice.export.transaction.file.name': 'import-transakcie',

  'invoiceSeries.delete.confirmation': 'Naozaj chcete odstrániť číselnú radu faktúr "%s"?',
  'invoiceSeries.delete.success': 'Číselná rada faktúr bola úspešne zmazaná.',
  'invoiceSeries.edit.success': 'Číselná rada bola úspešne uložená.',
  'invoiceSeries.deactivate.success': 'Číselná rada bola úspešne deaktivovaná.',
  'invoiceSeries.activate.success': 'Číselná rada bola úspešne aktivovaná.',

  'codeList.save.success': 'Položka bola uložená.',
  'codeList.delete.success': 'Položka bola zmazaná.',
  'codeList.update.activated': 'Položka bola aktivovaná.',
  'codeList.update.deactivated': 'Položka bola deaktivovaná.',

  'codeList.edit.success': 'Položka bola zmenená.',
  'codeList.edit.error': 'Položku sa nepodarilo zmeniť.',

  'label.profit': 'Zisk',
  'label.netProfit': 'Čistý zisk',
  'label.purchase': 'Nákup',
  'label.rewards': 'Provízie',
  'label.cashFlow': 'CashFlow',
  'label.totalAmount': 'Celkom',
  'label.totalAmountIncome': 'Príjmy',
  'label.totalAmountOutcome': 'Výdaje',
  'label.notDefined': 'Nezaradené',

  'activity.close.success': 'Aktivita bola úspešne zrušená.',
  'activity.finish.success': 'Aktivita bola úspešne ukončená.',
  'activity.edit.success': 'Aktivita bola úspešne uložená',

  'bankAccount.delete.confirmation': 'Naozaj chcete odstrániť bankový účet "%s"?',
  'bankAccount.delete.success': 'Bankový účet bol úspešne zmazaný.',
  'bankAccount.edit.success': 'Bankový účet bol úspešne uložený.',
  'bankAccount.deactivate.success': 'Bankový účet bol úspešne deaktivovaný.',
  'bankAccount.activate.success': 'Bankový účet bol úspešne aktivovaný.',

  'bankTransaction.syncAll.success': 'Bankové transakcie boli úspešne synchronizované.',

  'currencyRate.download.success': 'Menové kurzy boli úspešne stiahnuté.',

  'moneyBox.edit.success': 'Pokladňa bola úspešne uložená.',
  'moneyMovement.edit.success': 'Pohyb bol úspešne uložený.',
  'moneyMovement.split.success': 'Pohyb bol úspešne rozdelený.',

  'profile.edit.success': 'Profil bol úspešne uložený.'
};

export default {
  message (key, defaultMessageOrObjectArgs, defaultMessageParam) {
    const defaultMessage = _.isString(defaultMessageOrObjectArgs) ? defaultMessageOrObjectArgs : defaultMessageParam;
    let message = messages[key] || (defaultMessage ? (messages[defaultMessage] || defaultMessage) : key);
    if (_.isArray(defaultMessageOrObjectArgs)) {
      message = vsprintf(message, defaultMessageOrObjectArgs);
    }
    return message;
  }
};
