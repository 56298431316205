<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <p>
          <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <bank-account-modal :show-model="showCreateModal"
                              :model="selectedBankAccount"
                              :subjects="subjects"
                              :api-token-editable="apiTokenSupported"
                              :subject-editable="subjectSelectSupported"
                              @close="hideModals()"></bank-account-modal>
          <table class="table table-hover no-padding" v-if="bankAccounts && bankAccounts.length">
            <tbody>
            <tr>
              <th>Názov</th>
              <th v-if="subjectSelectSupported">Firma</th>
              <th>Číslo účtu</th>
              <th>Mena</th>
              <th>IBAN</th>
              <th>BIC</th>
              <th class="text-center" v-if="apiTokenSupported">API token</th>
              <th></th>
            </tr>
            <template v-for="bankAccount in bankAccounts">
              <tr :class="{ inactive : !bankAccount.active }">
                <td>{{ bankAccount.name }}</td>
                <td v-if="subjectSelectSupported">{{ bankAccount.subject.format() }}</td>
                <td>{{ bankAccount.formatId }}</td>
                <td>{{ currency(bankAccount.currency) }}</td>
                <td>{{ bankAccount.iban }}</td>
                <td>{{ bankAccount.bic }}</td>
                <td class="text-center" v-if="apiTokenSupported"><i class="fa" :class="{'fa-check text-success': bankAccount.apiToken, 'fa-times text-danger': !bankAccount.apiToken}"></i></td>
                <td class="text-right text-nowrap">
                  <button class="btn btn-xs btn-link" @click.stop="editBankAccount(bankAccount)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                  <button class="btn btn-xs btn-link" @click.stop="toggleBankAccountActive(bankAccount)"><i :class="{ 'fa-toggle-on': bankAccount.active, 'fa-toggle-off text-success': !bankAccount.active }" class="fa fa-2x" :title="bankAccount.active ? 'Deaktivovať' : 'Aktivovať'"></i></button>
                  <button class="btn btn-xs btn-link" @click.stop="deleteBankAccount(bankAccount)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <loading id="bankAccounts"></loading>
        <no-records :data="bankAccounts" loading-id="bankAccounts"></no-records>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapState } from 'vuex';
  import bankAccountModal from 'modules/bank/components/bankAccountCreateModal';
  import notificationService from 'services/notificationService';
  import bankAccountService from 'modules/bank/service/bankAccountService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import currencies from 'codelist/currencies';

  export default {
    components: { bankAccountModal, loading, noRecords },
    data: () => ({
      showCreateModal: false,
      selectedBankAccount: null
    }),
    props: {
      filter: {
        required: true,
        type: Object
      },
      subjects: {
        required: true,
        type: Array
      },
      subjectSelectSupported: {
        required: false,
        type: Boolean,
        default: true
      },
      apiTokenSupported: {
        required: false,
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState({
        bankAccounts (state) {
          let bankAccounts;
          if (!this.filter.showInactive) {
            bankAccounts = this.$store.getters['bankAccount/active'];
          } else {
            bankAccounts = state.bankAccount.items;
          }

          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            return bankAccounts.filter(bankAccount => {
              return !!normalize(bankAccount.name).match(regexp);
            });
          } else {
            return bankAccounts;
          }
        }
      })
    },
    methods: {
      createShow () {
        this.selectedBankAccount = bankAccountService.newBankAccount();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      deleteBankAccount (bankAccount) {
        this.confirm('bankAccount.delete.confirmation', [bankAccount.name]).then(() => {
          this.$store.dispatch('bankAccount/delete', bankAccount.id).then((result) => {
            if (result) {
              notificationService.success('bankAccount.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      toggleBankAccountActive (bankAccount) {
        const action = bankAccount.active ? 'deactivate' : 'activate';
        this.$store.dispatch('bankAccount/' + action, bankAccount.id).then((result) => {
          if (result) {
            notificationService.success('bankAccount.' + action + '.success');
          } else {
            notificationService.error('error.bankAccount.' + action);
          }
        });
      },
      editBankAccount (bankAccount) {
        this.selectedBankAccount = _.cloneDeep(bankAccount);
        this.showCreateModal = true;
      },
      currency (currency) {
        return currency ? _.find(currencies, { value: currency }).description : null;
      }
    },
    created () {
      this.$store.dispatch('bankAccount/findByFilter', _.pick(this.filter, ['ownershipped', 'subjectId']));
    }
  };
</script>
