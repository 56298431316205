<template>
  <div>
    <div class="content-header">
      <h1>Môj profil</h1>
    </div>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <dl class="dl-horizontal">
                <dt>Prihlasovacie meno:</dt>
                <dd>{{ user.username }}</dd>
                <dt>Meno:</dt>
                <dd>{{ user.firstname }}</dd>
                <dt>Priezvisko:</dt>
                <dd>{{ user.surname }}</dd>
                <dt>E-mail:</dt>
                <dd>{{ user.email }}</dd>
                <dt>Telefónne číslo:</dt>
                <dd>{{ user.phoneNumber }}</dd>
                <dt>Užívateľské role:</dt>
                <dd>{{ user.roles.join(', ') }}</dd>
              </dl>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr>
              <div class="text-center">
                <router-link tag="button" to="/profile-edit" class="btn btn-primary">Upraviť</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: mapState({
      user: (state) => state.auth.user
    })
  };
</script>
