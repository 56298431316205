import moneyMovementResource from './moneyMovementResource';
import BankMoneyMovement from "../domain/bankMoneyMovement";
import CashMoneyMovement from "../domain/cashMoneyMovement";

export default {
  newCashMoneyMovement(data) {
    return new CashMoneyMovement(data);
  },
  getMoneyMovement (data) {
    switch (data['@class']) {
      case '.BankMoneyMovementDto': return new BankMoneyMovement(data);
      case '.CashMoneyMovementDto': return new CashMoneyMovement(data);
      default: {
        throw new Error('Unresolved subject type.');
      }
    }
  },
  async findByFilter (filter) {
    const response = await moneyMovementResource.query(filter);
    if (response.ok) {
      return response.data.map((moneyMovementData) => this.getMoneyMovement(moneyMovementData));
    } else {
      return null;
    }
  },
  async findLatest () {
    const response = await moneyMovementResource.latest();
    if (response.ok) {
      return response.data.map((moneyMovementData) => this.getMoneyMovement(moneyMovementData));
    } else {
      return null;
    }
  }
};
