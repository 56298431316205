import actions from './financeSummaryActions';
import mutations from './financeSummaryMutations';

const state = {
  vatSummaryItems: [],
  taxBaseSummaryItems: []
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
