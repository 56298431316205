import _ from 'lodash';
import moment from 'moment';
import Entity from '../../../domain/entity';
import Company from 'modules/company/domain/company';
import InvoiceItem from "./invoiceItem";
import BankAccount from "../../bank/domain/bankAccount";
import {FORMAT_CURRENCY, FORMAT_DATE} from "utils";

class Invoice extends Entity {
  constructor (data) {
    super(data);
    this.number = data.number || null;
    this.constantSymbol = data.constantSymbol || null;
    this.variableSymbol = data.variableSymbol || null;
    this.specificSymbol = data.specificSymbol;
    this.subscriber = data.subscriber == null ? null : new Company(data.subscriber);
    this.supplier = data.supplier == null ? null : new Company(data.supplier);
    this.totalAmount = data.totalAmount;
    this.totalVat = data.totalVat;
    this.unpaidAmount = data.unpaidAmount;
    this.currency = data.currency;
    this.paymentMethod = data.paymentMethod;
    this.dateOfIssue = this.convertDate(data.dateOfIssue);
    this.dueDate = this.convertDate(data.dueDate);
    this.taxableDate = this.convertDate(data.taxableDate);
    this.reference = data.reference;
    this.bankAccount = data.bankAccount ? new BankAccount(data.bankAccount) : null;
    this.items = data.items ? data.items.map(orderItem => new InvoiceItem(orderItem)) : [];
    this.deliveries = data.deliveries || [];

    this.relations('items');

    this.value = this.id;
    this.label = this.number;
    this.labelDescription = `Suma: ${FORMAT_CURRENCY(this.totalAmount, this.currency)}, splatnosť: ${FORMAT_DATE(this.dueDate)}`;
  }

  get summaries () {
    return _(this.items)
      .filter(item => item.vatRate != null)
      .groupBy('vatRate')
      .values()
      .map(items => ({
        vatRate: items[0].vatRate,
        totalPrice: _.sumBy(items, 'totalPrice'),
        totalVat: _.sumBy(items, 'totalVat')
      }))
      .value();
  }

  get summary () {
    return ({
      totalPrice: _.sumBy(this.summaries, 'totalPrice'),
      totalVat: _.sumBy(this.summaries, 'totalVat'),
      totalPriceWithVat: _.sumBy(this.summaries, 'totalPrice') + _.sumBy(this.summaries, 'totalVat')
    });
  }

  get totalWithoutVat () {
    return (this.totalAmount || 0) - (this.totalVat || 0);
  }

  get overdue () {
    return this.unpaidAmount > 0 && this.dueDate.endOf('day').isBefore(moment());
  }

  get overdueDays () {
    return this.overdue ? moment().endOf('day').diff(this.dueDate.startOf('day'), 'days') : 0;
  }

  get paid() {
    return this.unpaidAmount === 0;
  }

  get partiallyPaid() {
    return this.unpaidAmount > 0 && this.unpaidAmount < this.totalAmount;
  }

  get notPaid() {
    return this.unpaidAmount === this.totalAmount;
  }
}

export default Invoice;
