import Vue from 'vue';
import { API_ROOT } from '../../../config';

const contextPath = 'finance-summary';

const customActions = {
  vat: { method: 'GET', url: API_ROOT + contextPath + '/vat/{currency}' },
  taxBase: { method: 'GET', url: API_ROOT + contextPath + '/tax-base/{currency}' }
};
export default Vue.resource(API_ROOT + contextPath, {}, customActions);
