import actions from './appActions';
import mutations from './appMutations';

const initialState = {
  submitProtection: false,
  configuration: null,
  loadingData: {
    account: false,
    activities: false,
    bankAccounts: false,
    bankTransactions: false,
    companies: false,
    company: false,
    contacts: false,
    currencyRates: false,
    documentTemplates: false,
    employee: false,
    employees: false,
    financeTaxBaseSummaries: false,
    financeVatSummaries: false,
    invoiceSeries: false,
    invoice: false,
    invoices: false,
    lead: false,
    leads: false,
    locations: false,
    moneyBoxes: false,
    moneyMovements: false,
    tmProject: false,
    tmProjects: false,
    records: false,
    subjects: false,
    technologies: false,
    tmDelivery: false,
    tmDeliveries: false,
    tmRewards: false,
    tmRewardTemplates: false,
    transactions: false,
    users: false,
    vatRates: false
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations: mutations,
  actions: actions
};
