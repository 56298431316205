import * as types from './mutationTypes';
import moneyMovementService from '../service/moneyMovementService';

const actions = {
  async findByFilter ({ dispatch, commit }, filter) {
    await commit(types.MONEY_MOVEMENT_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'moneyMovements', { root: true });
    const moneyMovements = await moneyMovementService.findByFilter(filter);
    await commit(types.MONEY_MOVEMENT_LIST, {
      filter: filter,
      items: moneyMovements
    });
    dispatch('app/loadingDataDisable', 'moneyMovements', { root: true });
  },
  async clear ({ commit}) {
    await commit(types.MONEY_MOVEMENT_LIST_CLEAR);
  },
  async findLatest ({ commit }) {
    await commit(types.MONEY_MOVEMENT_LATEST_LIST, {
      latestItems: []
    });
    const moneyMovements = await moneyMovementService.findLatest();
    await commit(types.MONEY_MOVEMENT_LATEST_LIST, {
      items: moneyMovements
    });
  },
  async clearLatest ({ commit}) {
    await commit(types.MONEY_MOVEMENT_LATEST_LIST_CLEAR);
  },
  async refresh ({ state, dispatch}) {
    await dispatch('findByFilter', state.filter);
  }
};

export default actions;
