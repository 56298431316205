import invoiceSeriesView from 'modules/invoice/components/invoiceSeries';

export default [
  {
    path: '/invoice-series',
    component: invoiceSeriesView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  }
];
