'use strict';

import notificationService from 'services/notificationService';

export default (store, router) => {
  return () => {
    // do logout action if 401 is returned
    return function (response) {
      if (response.status !== 200) {
        // authentication error
        if (response.data && response.data.error === 'invalid_grant') {
          notificationService.error('error.auth.login');
        // validation failure
        } else if (response.status === 400) {
          if (response.data.errors && response.data.errors.length && response.data.errors[0].code) {
            notificationService.error('error.' + response.data.errors[0].code, 'error.data.invalid');
          } else if (response.data.message && response.data.args) {
            notificationService.error('error.' + response.data.message, response.data.args, 'error.data.invalid');
          } else {
            notificationService.error('error.' + response.data.message, 'error.data.invalid');
          }
        } else if (response.status === 401) { // unauthorized error
          notificationService.error('error.auth.invalid');
        } else if (response.status === 404) { // not found
          notificationService.error('error.data.notFound');
          router.push('/');
        } else if (response.status === 413) { // unauthorized error
          notificationService.error('error.data.payloadTooLarge');
        } else if (response.data && response.data.message) { // general error
          // console.error(VueNotifications.error);
          notificationService.error(response.data.message);
          if (response.data.stackTrace) {
            console.error('error: ', response.data.stackTrace);
          }
        }
      }
    };
  };
};
