import actions from './locationActions';
import mutations from './locationMutations';
import getters from './locationGetters';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  getters: getters,
  actions: actions
};
