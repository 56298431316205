import {
  APP_CONFIGURATION_LOAD,
  APP_SUBMIT_PROTECTION_ENABLE,
  APP_SUBMIT_PROTECTION_DISABLE,
  APP_LOADING_DATA_ENABLE,
  APP_LOADING_DATA_DISABLE
} from '../../mutationTypes';

const mutations = {
  async [APP_CONFIGURATION_LOAD] (state, action) {
    state.configuration = action.configuration;
  },
  async [APP_SUBMIT_PROTECTION_ENABLE] (state) {
    state.submitProtection = true;
  },
  async [APP_SUBMIT_PROTECTION_DISABLE] (state) {
    state.submitProtection = false;
  },
  async [APP_LOADING_DATA_ENABLE] (state, id) {
    state.loadingData[id] = true;
  },
  async [APP_LOADING_DATA_DISABLE] (state, id) {
    state.loadingData[id] = false;
  }
};

export default mutations;
