<template>
  <div>
    <div class="content-header">
      <h1>Zmena hesla</h1>
    </div>
    <section class="content">
      <div class="box">
        <div class="box-body">
          <v-form ref="form">
            <div class="row">
              <div class="col-md-4 col-md-offset-4">
                <text-field field-id="originalPassword"
                            label="Aktuálne heslo"
                            placeholder="Zadaj aktuálne heslo"
                            v-model="credentials.originalPassword"
                            :maxlength="20"
                            type="password"
                            validation="required|min:5|max:20"></text-field>
                <text-field field-id="newPassword"
                            label="Nové heslo"
                            placeholder="Zadaj nové heslo"
                            v-model="credentials.newPassword"
                            :maxlength="20"
                            type="password"
                            validation="required|min:5|max:20"></text-field>
                <text-field field-id="confirmPassword"
                            label="Nové heslo pre kontrolu"
                            placeholder="Zadaj nové heslo znova"
                            v-model="credentials.confirmPassword"
                            :maxlength="20"
                            type="password"
                            validation="required|confirmed:newPassword"></text-field>
              </div>
            </div>
            <hr>
            <div class="text-center">
              <span>
                <router-link tag="button" to="/profile" class="btn btn-default">Zrušiť</router-link>
                <button class="btn btn-primary" type="submit" @click.prevent="submit($refs.form)">Uložiť</button>
              </span>
            </div>
          </v-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import _ from 'lodash';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import vForm from 'components/form/vForm';

  const credentials = {
    originalPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  export default {
    components: { vForm, textField },
    mixins: [validationMixin],
    data () {
      return {
        credentials: _.clone(credentials)
      };
    },
    methods: {
      onSubmit () {
        this.$store.dispatch('user/myPasswordChange', this.credentials).then((result) => {
          if (result) {
            notificationService.success('Heslo bolo zmenené.');
            this.credentials = _.clone(credentials);
            this.$router.push('/profile');
          }
        }).finally(this.unprotect);
      }
    }

  };
</script>
