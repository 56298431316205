<template>
  <page title="Firmy">
    <!--filter box-->
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Technológie"
                          :codelist="true"
                          :multiple="true"
                          :options="technologies"
                          v-model="filter.technologies"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Lokality"
                          :codelist="true"
                          :multiple="true"
                          :options="locations"
                          v-model="filter.locations"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="roles"
                          label="Roly"
                          :multiple="true"
                          :codelist="true"
                          :options="roles"
                          v-model="filter.roles"></select-field>
          </div>
          <div class="col-sm-2">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>
    <!--end of filter box-->

    <!--subjects box-->
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover dataTable no-padding" v-if="subjects && subjects.length">
                <thead>
                <tr>
                  <the label="Názov" property="name" :sort-data="sortData" @sort="sort($event)"></the>
                  <th class="hidden-sm hidden-md">IČ/RČ</th>
                  <th class="visible-lg">DIČ</th>
                  <th>Lokality</th>
                  <th>Roly</th>
                  <th>Technológie</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="subject in subjects">
                  <tr :class="{inactive : !subject.active}" @click.prevent="detail(subject)" role="button">
                    <td>{{ subject.name }}</td>
                    <td>{{ subject.companyId || subject.personalId }}</td>
                    <td>{{ subject.vatId }}</td>
                    <td>
                      <span class="icon-item badge" v-for="location in subject.locations">{{ location.label }}</span>
                    </td>
                    <td>
                <span class="role-item" v-for="role in subject.roles" :title="role | codeListValue(roles)">
                  <i class="fa fa-user" :class="role | codeListProp(roles, 'iconColor')" aria-hidden="true"></i>
                </span>
                    </td>
                    <td>
                      <span class="icon-item badge" v-for="technology in subject.technologies">{{ technology.label }}</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="subjects"></loading>
            <no-records :data="subjects" loading-id="subjects"></no-records>
          </div>
        </div>
      </template>
    </box>
    <!--end of subjects box-->
  </page>
</template>

<script>
  import _ from 'lodash';
  import { normalize, localeCompare } from 'utils';
  import { mapGetters, mapState } from 'vuex';
  import notificationService from 'services/notificationService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import the from 'components/the';
  import roles from 'codelist/subjectRoles';
  import Lead from '../../lead/domain/lead';
  import Company from '../../company/domain/company';
  import Employee from '../../employee/domain/employee';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { loading, noRecords, selectField, the, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      showCreateModal: false,
      selectedCompany: null,
      roles: roles,
      filter: {
        showInactive: false,
        query: null,
        technologies: [],
        locations: [],
        roles: []
      },
      sortData: {
        by: null,
        asc: true
      }
    }),
    computed: {
      ...mapState({
        subjects (state) {
          let subjects;
          if (!this.filter.showInactive) {
            subjects = this.$store.getters['subject/active'];
          } else {
            subjects = state.subject.items;
          }
          if (_.some(this.filter.technologies)) {
            subjects = _.filter(subjects, subject => {
              return !_(this.filter.technologies).difference(_.map(subject.technologies, 'id')).some();
            });
          }
          if (_.some(this.filter.locations)) {
            subjects = _.filter(subjects, subject => {
              return !_(this.filter.locations).difference(_.map(subject.locations, 'id')).some();
            });
          }
          if (_.some(this.filter.roles)) {
            subjects = _.filter(subjects, subject => {
              return !_(this.filter.roles).difference(subject.roles).some();
            });
          }

          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            subjects = subjects.filter(subject => {
              return !!normalize(subject.name).match(regexp);
            });
          }

          const sorted = (subjects || []).sort(localeCompare(this.sortData.by));
          return this.sortData.asc ? sorted : sorted.reverse();
        }
      }),
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      })
    },
    methods: {
      detail (subject) {
        if (subject instanceof Company) {
          this.$router.push({ name: 'company', params: { id: subject.id }});
        } else if (subject instanceof Lead) {
          this.$router.push({ name: 'lead', params: { id: subject.id }});
        } else if (subject instanceof Employee) {
          this.$router.push({ name: 'employee', params: { id: subject.id }});
        } else {
          notificationService.error('subject.invalid.type');
        }
      },
      sort (sortData) {
        this.sortData.by = sortData.by;
        this.sortData.asc = sortData.asc;
      },
      defaultFilter () {
        this.sortData.by = 'name';
        this.sortData.asc = true;
      }
    },
    mounted () {
      this.$store.dispatch('subject/getAll', true);
      this.$store.dispatch('technology/getAll');
      this.$store.dispatch('location/getAll');
    }
  };
</script>
