import userResource from './userResource';
import _ from 'lodash';
import User from '../domain/user';
import UserInfo from '../domain/userInfo';

export default {
  newUser () {
    return new User();
  },
  async me () {
    const response = await userResource.me();
    if (response.ok) {
      return new User(response.data);
    } else {
      return null;
    }
  },
  async info () {
    const response = await userResource.info();
    if (response.ok) {
      return new UserInfo(response.data);
    } else {
      return null;
    }
  },
  async updateMe (me) {
    return userResource.updateMe({}, _.pickBy(me));
  },
  async myPasswordChange (credentials) {
    return userResource.myPasswordChange({}, _.pickBy(credentials));
  },
  async passwordChange (userId, credentials) {
    const params = userId ? { userId: userId } : null;
    return userResource.passwordChange(params, credentials);
  },
  async findAll () {
    const response = await userResource.query();
    if (response.ok) {
      return response.data.map((userData) => new User(userData));
    } else {
      return null;
    }
  },
  async create (user) {
    return userResource.save({}, _.pickBy(user));
  },
  async update (user) {
    return userResource.update({}, _.pickBy(user));
  },
  async activate (id) {
    return userResource.activate({ id: id }, {});
  },
  async deactivate (id) {
    return userResource.deactivate({ id: id }, {});
  },
  async delete (id) {
    return userResource.delete({ id: id });
  }
};
