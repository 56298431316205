<template>
  <page title="Technológie">
    <code-list loading-id="technologies" module-name="technology"></code-list>
  </page>
</template>

<script>
  import codeList from 'components/codeListGeneric';
  import page from 'components/page';

  export default {
    components: { page, codeList }
  };
</script>
