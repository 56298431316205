import { COMPANY_ACTIVATE, COMPANY_DEACTIVATE, COMPANY_LIST, COMPANY_SELECT, COMPANY_CLEAR, COMPANY_UPDATE, COMPANY_ARES_LIST } from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [COMPANY_LIST] (state, action) {
    state.items = action.items;
  },
  [COMPANY_SELECT] (state, action) {
    state.selected = action.item;
  },
  [COMPANY_CLEAR] (state) {
    state.selected = null;
  },
  [COMPANY_UPDATE] (state, action) {
    // let toBeUpdated = _.find(state.items, {id: action.item.id});
    // when array, replace it at all - vue problem
    // _.mergeWith(toBeUpdated, action.item, MERGE);
    // state.selected = toBeUpdated;
    state.selected = _.find(state.items, { id: action.item.id });
  },
  [COMPANY_ACTIVATE] (state, id) {
    const company = _.find(state.items, { id: id });
    if (company) {
      company.active = true;
    }
  },
  [COMPANY_DEACTIVATE] (state, id) {
    const company = _.find(state.items, { id: id });
    if (company) {
      company.active = false;
    }
  },
  [COMPANY_ARES_LIST] (state, action) {
    state.aresItems = action.aresItems;
  }
};

export default mutations;
