import * as types from './mutationTypes';
import technologyService from '../service/technologyService';

const actions = {
  async getAll ({ commit, state, dispatch }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'technologies', { root: true });
      const technologies = await technologyService.findAll();
      await commit(types.TECHNOLOGY_LIST, {
        items: technologies || []
      });
      dispatch('app/loadingDataDisable', 'technologies', { root: true });
    }
  },
  async create ({ dispatch, commit }, technology) {
    const newTechnology = await technologyService.create(technology);
    if (newTechnology) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async update ({ dispatch, commit }, technology) {
    const updatedTechnology = await technologyService.update(technology);
    if (updatedTechnology) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async delete ({ dispatch, commit }, id) {
    const response = await technologyService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async deactivate ({ dispatch }, id) {
    const response = await technologyService.deactivate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async activate ({ dispatch }, id) {
    const response = await technologyService.activate(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
