import * as types from './mutationTypes';
import activityService from '../service/activityService';
import _ from 'lodash';

const actions = {
  async create ({ dispatch, commit }, activity) {
    try {
      const response = await activityService.create(activity);
      if (response) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async getAll ({ state, dispatch, commit }) {
    dispatch('app/loadingDataEnable', 'activities', { root: true });
    const activities = await activityService.getAll();
    await commit(types.ACTIVITY_LIST, {
      items: activities
    });
    dispatch('app/loadingDataDisable', 'activities', { root: true });
  },
  async getAllByFilter ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'activities', { root: true });
    await commit(types.ACTIVITY_LIST_FILTERED, {
      filter: null,
      items: []
    });
    const activities = await activityService.getAllByFilter(filter);
    await commit(types.ACTIVITY_LIST_FILTERED, {
      filter: filter,
      items: activities
    });
    dispatch('app/loadingDataDisable', 'activities', { root: true });
  },
  async refreshAll ({ state, dispatch }) {
    if (state.filter) {
      await dispatch('getAllByFilter', state.filter);
    } else {
      await dispatch('getAll', true);
    }
    dispatch('user/fetchInfo', null, { root: true });
  },
  async update ({ dispatch, commit }, activity) {
    try {
      const updatedActivity = await activityService.update(activity);
      if (updatedActivity) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async finish ({ dispatch, state, commit }, id) {
    try {
      await commit(types.ACTIVITY_FINISH, id);
      const activity = _.find(state.items, { id });
      const updatedActivity = await activityService.update(activity);
      if (updatedActivity) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async cancel ({ dispatch, state, commit }, id) {
    try {
      await commit(types.ACTIVITY_CANCEL, id);
      const activity = _.find(state.items, { id });
      const updatedActivity = await activityService.update(activity);
      if (updatedActivity) {
        await dispatch('refreshAll');
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  }
};

export default actions;
