import Address from 'domain/address';
import Document from 'modules/document/domain/document';
import Subject from 'domain/subject';
import Account from 'modules/account/domain/account';

class Employee extends Subject {
  constructor (data) {
    super(data);

    this.firstName = data.firstName || null;
    this.surname = data.surname || null;
    this.personalId = data.personalId || null;
    this.address = data.address ? new Address(data.address) : null;
    this.documents = data.documents ? data.documents.map(document => new Document(document)) : [];
    this.account = data.account ? new Account(data.account) : null;

    this.value = this.id;
    this.label = this.format();

    if (!this['@class']) {
      this['@class'] = 'cz.smartbrains.brainis.dto.EmployeeDto';
    }
  }

  static get route () {
    return 'employee';
  }

  format () {
    return this.firstName + ' ' + this.surname;
  }

  supportsContractAgreement () {
    return true;
  }
}

export default Employee;
