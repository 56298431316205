import actions from './leadActions';
import getters from './leadGetters';
import mutations from './leadMutations';

const state = {
  items: [],
  selected: null
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
