import actions from './tmProjectActions';
import mutations from './tmProjectMutations';
import getters from './tmProjectGetters';

const state = {
  filter: null,
  items: [],
  itemsByFilter: [],
  selected: null
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
