<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div class="date-field">
      <validation-provider :rules="validation" v-slot="{ errors }" tag="div" class="validation-provider">
        <div :class="{ 'has-error': errors.length }">
          <div class="input-wrapper" :class="{'with-arrows': showArrows}">
            <button v-if="showArrows" class="btn btn-default left" type="button" @click.prevent="prev()"><i class="fa fa-chevron-left"></i></button>
            <datepicker
              :class="{rtl}"
              :language="sk"
              :monday-first="true"
              v-model="date"
              calendar-class="date-field-calendar"
              :minimum-view="minimumView"
              :maximum-view="maximumView"
              format="dd.MM.yyyy - D"
              input-class="form-control">
            </datepicker>
            <button v-if="showArrows" class="btn btn-default right" type="button" @click.prevent="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
          <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
        </div>
      </validation-provider>
      <vue-timepicker v-if="time" :class="{rtl: rtl}" v-model="timeModel" format="HH:mm" @change="timeChanged"></vue-timepicker>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import datepicker from 'vuejs-datepicker';
  import { sk } from 'vuejs-datepicker/dist/locale';
  import vueTimepicker from 'vue2-timepicker';
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: { datepicker, vueTimepicker, ValidationProvider },
    data: () => ({
      date: null,
      timeModel: {},
      sk
    }),
    props: {
      value: {
        type: Object
      },
      label: {
        type: String,
        required: false
      },
      rtl: {
        type: Boolean,
        required: false,
        default: false
      },
      fieldId: {
        type: String,
        required: true
      },
      scope: {
        type: String,
        required: false
      },
      validation: {
        type: String,
        default: ''
      },
      showArrows: {
        type: Boolean,
        default: true
      },
      time: {
        type: Boolean,
        default: false
      },
      minimumView: {
        type: String,
        default: 'day'
      },
      maximumView: {
        type: String,
        default: 'year'
      }
    },
    methods: {
      updateValue (date) {
        if (!date) return;

        const mDate = moment.isMoment(date) ? date : moment(date);
        // reflect time
        mDate.set({ h: Number(this.timeModel.HH), m: Number(this.timeModel.mm), s: 0, ms: 0 });

        if ((!date && this.value)
          || (date && !this.value)
          || (date && this.value && !this.value.isSame(moment(date)))) {
          this.$emit('input', mDate);
        }
      },
      prev () {
        if (!this.date) this.date = new Date();
        this.updateValue(moment(this.date).subtract(1, 'd'));
      },
      next () {
        if (!this.date) this.date = new Date();
        this.updateValue(moment(this.date).add(1, 'd'));
      },
      timeChanged (event) {
        this.updateValue(this.date);
      }
    },
    watch: {
      value (value) {
        if (value) {
          //check if new value is not same date
          if (!value.isSame(moment(this.date))) {
            this.date = value.toDate();
          }
        } else {
          this.date = null;
          this.timeModel.HH = '00';
          this.timeModel.mm = '00';
        }
      },
      date (date) {
        this.updateValue(date);
      }
    },
    created () {
      this.date = this.value && moment.isMoment(this.value) ? this.value.toDate() : null;
      if (this.date) {
        const mDate = moment(this.value);
        this.timeModel.HH = _.padStart(mDate.get('h') + '', 2, '0');
        this.timeModel.mm = _.padStart(mDate.get('m') + '', 2, '0');
      }
    }
  };
</script>
