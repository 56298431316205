<template>
  <div>
    <record-modal v-if="entity" :show-model="showCreateModal" :model="selectedRecord" :entity="entity" @close="hideModals()" :record-entity="recordEntity"></record-modal>
    <div class="row">
      <div class="col-md-12">
        <p>
          <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať záznam</button>
        </p>
      </div>
    </div>
    <div v-if="recordGroups && recordGroups.length">
      <div class="row">
        <div class="col-md-12">
          <ul class="timeline">
            <template v-for="recordGroup in recordGroups">
              <li class="time-label"><span class="bg-orange">{{ recordGroup.date | date }}</span></li>
              <li v-for="record in recordGroup.records">
                <i :title="record.type | codeListValue(recordTypes)" :class="recordIcon(record.type)"></i>
                <div class="timeline-item">
                  <span class="time">{{ record.createdBy.fullName }} | <i class="fa fa-clock-o"></i> {{ record.date | time }}</span>
                  <div class="timeline-header">
                    <span class="contact-field" v-if="record.contact"><i class="fa fa-address-book-o" aria-hidden="true"></i> {{ record.contact.label }}</span>
                    <span class="linked-entity" v-if="record.subject || record.project">
                      <i class="fa fa-link" aria-hidden="true"></i>
                      <router-link v-if="showSubjectLink && record.subject"
                                   tag="span"
                                   :to="{ name: `${record.subject.constructor.route}Records`, params: { id: record.subject.id }}"
                                   active-class="active">
                        <a>{{ record.subject.name }}</a>
                      </router-link>
                      <router-link v-else-if="showProjectLink && record.project"
                                   tag="span"
                                   :to="{ name: 'projectRecords', params: { id: record.project.id }}"
                                   active-class="active">
                        <a>{{ record.project.name }}</a>
                      </router-link>
                    </span>
                  </div>
                  <div class="timeline-body">
                    <div class="formatted-text">{{ record.note }}</div>
                    <documents :documents="record.documents" :editable="false" :compact="false" :show-alert="false"></documents>
                  </div>
                  <div class="timeline-footer">
                    <div class="text-right">
                      <button class="btn btn-xs btn-link" :disabled="record.generated" @click.stop="editRecord(record)">
                        <i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                      <button class="btn btn-xs btn-link" :disabled="record.generated" @click.stop="deleteRecord(record)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
                    </div>
                  </div>
                </div>
              </li>
            </template>
            <li><i class="fa fa-clock-o bg-gray"></i></li>
          </ul>
        </div>
      </div>
    </div>
    <loading class="content-header" id="records"></loading>
    <no-records :data="recordGroups" loading-id="records"></no-records>
  </div>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import recordTypes from 'codelist/recordTypes';
  import loading from 'components/loading';
  import recordModal from './recordCreateModal';
  import noRecords from 'components/noRecords';
  import documents from 'modules/document/components/documents';
  import notificationService from 'services/notificationService';
  import Record from 'modules/record/domain/record';
  import Entity from 'domain/entity';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import recordMixin from './recordMixin';

  export default {
    mixins: [recordMixin],
    components: { loading, recordModal, documents, noRecords },
    data: () => ({
      recordTypes,
      selectedRecord: null,
      showCreateModal: false
    }),
    computed: mapState({
      contacts: (state) => state.contact.items,
      recordGroups: (state) => _(state.record.items)
        .groupBy(record => FORMAT_SYSTEM_DATE(record.date))
        .map(records => {
          return { date: records[0].date, records };
        }).orderBy('date', 'desc').value()
    }),
    props: {
      entity: {
        type: Entity,
        required: true
      },
      recordEntity: {
        type: String,
        required: true
      },
      showProjectLink: {
        type: Boolean,
        default: false
      },
      showSubjectLink: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      async createShow () {
        this.selectedRecord = new Record({ date: moment() });
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      editRecord (record) {
        this.selectedRecord = _.cloneDeep(record);
        this.showCreateModal = true;
      },
      deleteRecord (record) {
        this.confirm('record.delete.confirmation').then(() => {
          this.$store.dispatch('record/delete', { id: record.id }).then((result) => {
            if (result) {
              notificationService.success('record.delete.success');
            }
          }).catch(this.unprotect);
        });
      }
    },
    mounted () {
      this.$store.dispatch('record/findByEntity', { entityId: this.entity.id, entity: this.recordEntity });
    }
  };
</script>
