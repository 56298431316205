<template>
  <page title="Dodávky">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-4 col-md-2">
            <div class="form-group">
              <label>Vyhľadaj</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-4">
            <date-range-field v-model="filter.range" :no-label="true" label="Obdobie"></date-range-field>
          </div>
          <div class="col-sm-4 col-md-2">
            <select-field
              :options="projects"
              v-model="filter.project"
              :codelist="true"
              field-id="project"
              label="Projekt">
            </select-field>
          </div>
          <div class="col-sm-4 col-md-2">
            <select-field
              :options="tmDeliveryStatuses"
              v-model="filter.status"
              :codelist="true"
              field-id="status"
              label="Stav">
            </select-field>
          </div>
          <div class="col-sm-4 col-md-2">
              <button class="btn btn-success btn-input-line btn-block" type="button" @click="fetch()">Načítať</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">

            <div class="btn-group">
              <button :disabled="invoiceOutcomeDisabled" @click="createOutcomeInvoice(selected)" type="button" class="btn btn-default"><i class="fa fa-plus text-success"></i> Vystaviť FA</button>
              <button type="button" :disabled="invoiceIncomeDisabled" @click="createIncomeInvoice(selected)" class="btn btn-default"><i class="fa fa-plus text-warning"></i> Prijatá FA</button>
              <button :disabled="payDisabled" type="button" class="btn btn-success" @click="pay">Zaplatiť</button>
            </div>
          </div>
        </div>
        <div class="row box-data-info">
          <div class="col-sm-12">
            <loading id="tmDeliveries"></loading>
            <no-records :data="deliveryGroups" loading-id="tmDeliveries"></no-records>
          </div>
        </div>
      </template>
    </box>

    <template v-if="deliveryGroups && deliveryGroups.length">
      <box v-for="deliveryGroup in deliveryGroups" :key="deliveryGroup[0].order.currency">
        <template #header>
          <h4>Dodávky v mene: {{ currency(deliveryGroup[0].order.currency) }}.</h4>
        </template>
        <template #body>
          <div class="table-responsive">
            <table class="table no-padding table-hover no-padding">
              <tbody>
              <tr>
                <th></th>
                <th>Kontraktor</th>
                <th>Projekt</th>
                <th>Obdobie</th>
                <th class="text-right">Počet MD</th>
                <th class="text-right" title="Suma i s voliteľnými položkami.">Nákup celkom</th>
                <th class="text-right" title="Predaj i s voliteľnými položkami.">Predaj celkom</th>
                <th class="text-center">Stav</th>
                <th class="text-center"></th>
              </tr>
              <template v-for="delivery in deliveryGroup">
                <tr :class="{'text-muted': delivery.status === 'PAID'}">
                  <td><input type="checkbox" :id="delivery.id" :value="delivery" v-model="selected"></td>
                  <td>
                    <subject-link v-if="delivery.order.supplier" :subject="delivery.order.supplier"></subject-link>
                  </td>
                  <td>
                    <project-link v-if="delivery.order.project" :project="delivery.order.project"></project-link>
                  </td>
                  <td>{{ delivery.period | dateMonth }}</td>

                  <td class="text-right">{{ delivery.mdNumber | number(5) }}</td>
                  <td class="text-right">{{ delivery.priceTotal | currency(delivery.order.currency) }}</td>
                  <td class="text-right">{{ delivery.sellTotal | currency(delivery.order.currency) }}</td>
                  <td class="text-center">{{ delivery.status | codeListValue(tmDeliveryStatuses) }}</td>
                  <td class="text-right text-nowrap">
                    <button class="btn btn-link" v-show="!delivery.invoiceOutcome" @click="createOutcomeInvoice([delivery])"><i title="Fakturovať" class="fa fa-file-text-o text-success"></i></button>
                    <router-link tag="button" v-if="delivery.invoiceOutcome" class="btn btn-link" :to="{ name: 'invoiceDetail', params: { id: delivery.invoiceOutcome.id } }">
                      <i :title="'Faktúra ' + delivery.invoiceOutcome.number" class="fa fa-file-text text-success"></i>
                    </router-link>
                    <button class="btn btn-link" v-show="!delivery.invoiceIncome" @click="createIncomeInvoice([delivery])"><i title="Prijatá faktúra" class="fa fa-file-text-o text-info"></i></button>
                    <router-link tag="button" v-if="delivery.invoiceIncome" class="btn btn-link" :to="{ name: 'invoiceDetail', params: { id: delivery.invoiceIncome.id } }">
                      <i :title="'Faktúra ' + delivery.invoiceIncome.number" class="fa fa-file-text text-info"></i>
                    </router-link>
                    <button class="btn btn-link" :disabled="!delivery.invoiceOutcome" @click="prepareEmail(delivery)"><i title="Odoslať faktúru e-mailom" class="fa fa-envelope text-success"></i></button>
                  </td>
                </tr>
              </template>
              </tbody>
              <tfoot>
              <tr>
                <th>Celkom</th>
                <th colspan="4"></th>
                <th class="text-right text-nowrap">{{ sumAmount(deliveryGroup, 'priceTotal') | currency(deliveryGroup[0].order.currency) }}</th>
                <th class="text-right text-nowrap">{{ sumAmount(deliveryGroup, 'sellTotal') | currency(deliveryGroup[0].order.currency) }}</th>
              </tr>
              </tfoot>
            </table>
          </div>
        </template>
      </box>
    </template>
  </page>
</template>

<script>
  import _ from 'lodash';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import box from 'components/box';
  import dateRangeField from 'components/form/dateRangeField';
  import { CC_EMAILS } from 'config';
  import emailService from 'services/emailService';
  import { FORMAT_DATE_PERIOD_SLASH, FORMAT_SYSTEM_DATE } from 'utils';
  import loading from 'components/loading';
  import moment from 'moment';
  import { mapState, mapGetters } from 'vuex';
  import { YEARS_MIN } from 'config';
  import { months, tmDeliveryStatuses, currencies } from 'codelist/';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import notificationService from 'services/notificationService';
  import projectLink from 'modules/project/components/projectLink';
  import subjectLink from 'components/subjectLink';
  import page from 'components/page';
  import Range from 'components/range';

  const now = moment().subtract(1, 'month');

  export default {
    components: { loading, noRecords, selectField, projectLink, subjectLink, page, box, dateRangeField },
    mixins: [bookmarkableFilter],
    data: () => ({
      years: _.range(YEARS_MIN, now.year() + 2).reverse().map(year => ({ value: year, label: year.toString() })),
      months: months,
      tmDeliveryStatuses: tmDeliveryStatuses,
      currencies: currencies,
      filter: {
        query: null,
        range: null,
        status: null,
        project: null
      },
      selected: []
    }),
    computed: {
      ...mapState({
        deliveryGroups(state) {
          return _(state.tmDelivery.items)
            .filter(delivery => !this.filter || !this.filter.query || delivery.matches(this.filter.query))
            .groupBy(delivery => delivery.order.currency)
            .values()
            .value();
        }
      }),
      ...mapGetters({
        projects: 'tmProject/open',
      }),
      payDisabled() {
        return this.selected.length === 0 || _.some(this.selected, { status: 'PAID' });
      },
      invoiceOutcomeDisabled() {
        return this.selected.length === 0 || _.some(this.selected, (delivery) => !!delivery.invoiceOutcome);
      },
      invoiceIncomeDisabled() {
        return this.selected.length === 0 || _.some(this.selected, (delivery) => !!delivery.invoiceIncome);
      }
    },
    methods: {
      createFilter () {
        const filter = this.filter;
        const periodFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const periodTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        return _.pickBy({
          periodFrom,
          periodTo,
          project: filter.project,
          status: filter.status
        }, _.identity);
      },
      sumAmount (deliveries, attribute) {
        return _(deliveries).map(attribute).sum();
      },
      pay () {
        if (_.some(this.selected, { status: 'PAID' })) {
          return notificationService.error('error.tmDeliveries.pay.alreadyPaid');
        }
        if (_(this.selected).map('currency').uniq().value().length > 1) {
          return notificationService.error('error.tmDeliveries.pay.multipleCurrency');
        }

        this.confirm('tmDeliveries.pay.confirmation').then(() => {
          this.$store.dispatch('tmDelivery/pay', _.map(this.selected, 'id')).then((result) => {
            if (result) {
              notificationService.success('tmDeliveries.pay.success');
            }
            this.selected = [];
          }).catch(this.unprotect);
        });
      },
      createIncomeInvoice (deliveries) {
        this.$router.push({ name: 'invoiceIncomeCreate', params: {deliveryIds: _(deliveries).map('id').join('-') }});
      },
      createOutcomeInvoice(deliveries) {
        this.$router.push({ name: 'invoiceOutcomeCreate', params: {deliveryIds: _(deliveries).map('id').join('-') }});
      },
      prepareEmail (delivery) {
        const period = FORMAT_DATE_PERIOD_SLASH(delivery.period);
        const emailRecipients = !_.isNil(delivery.order.mainOrder.subscriber) ? _(delivery.order.mainOrder.subscriber.contacts).filter(c => !_.isNil(c.email) && c.forInvoicing).map('email').join() : '';
        emailService.prepareEmail(
          {
            mailto: emailRecipients,
            cc: CC_EMAILS
          },
          'email.delivery.subject',
          'email.delivery.body',
          [period],
          [period]);
      },
      currency (currency) {
        return currency ? _.find(currencies, { value: currency }).description : null;
      },
      defaultFilter () {
        this.filter.range = new Range(moment().subtract(1, 'month'), moment());
        this.filter.status = null;
        this.filter.project = null;
      },
      fetch () {
        this.$store.dispatch('tmDelivery/getAll', this.createFilter());
      }
    },
    watch: {
      filter: {
        handler () {
          this.selected = [];
        },
        deep: true
      }
    },
    created () {
      this.fetch();
      this.$store.dispatch('tmProject/getAll');
    },
    destroyed() {
      this.$store.dispatch('tmDelivery/clear');
    }
  };
</script>
