import { render, staticRenderFns } from "./moneyMovements.vue?vue&type=template&id=f6adfe98&"
import script from "./moneyMovements.vue?vue&type=script&lang=js&"
export * from "./moneyMovements.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports