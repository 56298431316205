import actions from './tmRewardTemplateActions';
import mutations from './tmRewardTemplateMutations';

const state = {
  items: [],
  filter: null
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
