import _ from 'lodash';
import * as types from './mutationTypes';
import userService from '../service/userService';

const actions = {
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'users', { root: true });
      const users = await userService.findAll();
      await commit(types.USER_LIST, {
        items: users
      });
      dispatch('app/loadingDataDisable', 'users', { root: true });
    }
  },
  async create ({ dispatch, commit }, user) {
    try {
      const newUser = await userService.create(user);
      if (newUser) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch, commit }, user) {
    try {
      const updatedUser = await userService.update(user);
      if (updatedUser) {
        await commit(types.USER_UPDATE, {
          item: user
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ state, commit }, id) {
    try {
      await commit(types.USER_ACTIVATE, id);
      const user = _.find(state.items, { id: id });
      const updatedUser = await userService.update(user);
      if (updatedUser) {
        await commit(types.USER_UPDATE, {
          item: user
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ state, commit }, id) {
    try {
      await commit(types.USER_DEACTIVATE, id);
      const user = _.find(state.items, { id: id });
      const updatedUser = await userService.update(user);
      if (updatedUser) {
        await commit(types.USER_UPDATE, {
          item: user
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch, commit }, id) {
    const response = await userService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  },
  async getAllFailed ({ commit }) {
    await commit(types.USER_LIST, {
      items: []
    });
  },
  async updateProfile ({ dispatch, commit }, user) {
    try {
      const response = await userService.updateMe(user);
      if (response.ok) {
        return await dispatch('auth/fetchMe', null, { root: true });
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async myPasswordChange ({}, credentials) {
    try {
      const response = await userService.myPasswordChange(credentials);
      return response.ok;
    } catch (ex) {
      console.error(ex);
    }
  },
  async passwordChange ({}, data) {
    try {
      const response = await userService.passwordChange(data.userId, data.credentials);
      return response.ok;
    } catch (ex) {
      console.error(ex);
    }
  },
  async fetchInfo ({ commit }) {
    const userInfo = await userService.info();
    await commit(types.USER_INFO_UPDATE, {
      info: userInfo
    });
    return userInfo;
  }
};

export default actions;
