import actions from './activityActions';
import mutations from './activityMutations';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
