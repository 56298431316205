import * as types from './mutationTypes';
import bankTransactionService from '../service/bankTransactionService';

const actions = {
  async findByFilter ({ dispatch, commit }, filter) {
    await commit(types.BANK_TRANSACTION_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'bankTransactions', { root: true });
    const bankTransactions = await bankTransactionService.findByFilter(filter);
    await commit(types.BANK_TRANSACTION_LIST, {
      filter: filter,
      items: bankTransactions
    });
    dispatch('app/loadingDataDisable', 'bankTransactions', { root: true });
  },
  async syncAll ({ dispatch, state }) {
    try {
      const result = await bankTransactionService.syncAll();
      if (result && state.filter) {
        await dispatch('findByFilter', state.filter);
      }
      return result;
    } catch (ex) {
      console.error(ex);
    }
  },
  async clear ({ commit }) {
    await commit(types.BANK_TRANSACTION_LIST_CLEAR);
  }
};

export default actions;
