<template>
  <div>
    <records :entity="company" record-entity="subject" :show-project-link=true></records>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import records from 'modules/record/components/records';

  export default {
    components: { records },
    computed: mapState({
      company: (state) => state.company.selected
    })
  };
</script>
