import { ACTIVITY_LIST, ACTIVITY_LIST_FILTERED, ACTIVITY_UPDATE, ACTIVITY_FINISH, ACTIVITY_CANCEL } from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [ACTIVITY_LIST] (state, action) {
    state.items = action.items;
  },
  [ACTIVITY_LIST_FILTERED] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [ACTIVITY_UPDATE] (state, action) {
    state.items = action.items;
  },
  [ACTIVITY_FINISH] (state, id) {
    const activity = _.find(state.items, { id: id });
    if (activity) {
      activity.status = 'DONE';
    }
  },
  [ACTIVITY_CANCEL] (state, id) {
    const activity = _.find(state.items, { id: id });
    if (activity) {
      activity.status = 'CANCELED';
    }
  }
};

export default mutations;
