import actions from './tmOrderItemActions';
import mutations from './tmOrderItemMutations';

const state = {
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
