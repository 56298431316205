import tmOrderItemResource from './tmOrderItemResource';
import _ from 'lodash';
import TmOrderItem from '../domain/tmOrderItem';

export default {
  newTmOrderItem (data) {
    return new TmOrderItem(data);
  },
  async create (orderItem) {
    const response = await tmOrderItemResource.save({}, _.pickBy(orderItem));
    if (response.ok) {
      return new TmOrderItem(response.data);
    } else {
      return null;
    }
  },
  async update (tmOrderItem) {
    const response = await tmOrderItemResource.update({}, _.pickBy(tmOrderItem));
    if (response.ok) {
      return new TmOrderItem(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return tmOrderItemResource.delete({ id: id });
  }
};
