import Entity from 'domain/entity';
import MoneyBox from "./moneyBox";
import MoneyMovementCategory from "./moneyMovementCategory";
import Invoice from "../../invoice/domain/invoice";
import subjectService from 'modules/subject/service/subjectService';

class MoneyMovement extends Entity {
  constructor(data) {
    super(data);

    this.moneyBox = data.moneyBox == null ? null : new MoneyBox(data.moneyBox);
    this.category = data.category == null ? null : new MoneyMovementCategory(data.category);
    this.invoice = data.invoice == null ? null : new Invoice(data.invoice);
    this.subject = this.subject ? subjectService.getSubject(this.subject) : null;
    this.date = data.date == null ? null : this.convertDate(data.date);
    this.taxableDate = data.taxableDate == null ? null : this.convertDate(data.taxableDate);
  }

  calculateVat(vatRate) {
    this.totalVat = this.totalAmount && vatRate ? _.round((this.totalAmount * (vatRate / (100 + vatRate))), 2) : 0;
  }
}

export default MoneyMovement;
