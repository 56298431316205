import actions from './moneyMovementCategoryActions';
import mutations from './moneyMovementCategoryMutations';
import getters from './moneyMovementCategoryGetters';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
