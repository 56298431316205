import { BANK_TRANSACTION_LIST, BANK_TRANSACTION_LIST_CLEAR } from './mutationTypes';

const mutations = {
  [BANK_TRANSACTION_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [BANK_TRANSACTION_LIST_CLEAR] (state) {
    state.items = [];
  }
};

export default mutations;
