import technologiesView from 'modules/technology/components/technologies';

export default [{
  path: '/technologies',
  component: technologiesView,
  meta: {
    requiresLoggedIn: true,
    requiresRole: 'ADMIN'
  }
}];
