import _ from 'lodash';
import Entity from '../../../domain/entity';

class InvoiceItem extends Entity {
  constructor (data) {
    super(data);
    this.name = this.name || null;
    this.price = this.price || null;
    this.unit = this.unit || null;
    this.number = this.number || null;
    this.vatRate = _.isUndefined(this.vatRate) ? null : this.vatRate;
  }

  get totalPrice () {
    return  _.round(this.price != null && this.number != null ? this.price * this.number : 0, 2);
  }

  get totalVat () {
    return _.round(this.totalPrice * (this.vatRate / 100), 2);
  }
}

export default InvoiceItem;
