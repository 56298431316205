import actions from './employeeActions';
import getters from './employeeGetters';
import mutations from './employeeMutations';

const state = {
  items: [],
  selected: null
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
