import Entity from '../../../domain/entity';

class BankTransaction extends Entity {
  constructor (data) {
    super(data);
    this.transactionId = data.transactionId || null;
    this.date = this.convertDate(data.date);
    this.amount = data.amount || null;
    this.currency = data.currency || null;
    this.type = data.type || null;
    this.correspondingAccountNumber = data.correspondingAccountNumber || null;
    this.correspondingAccountName = data.correspondingAccountName;
    this.correspondingBankId = data.correspondingBankId;
    this.correspondingBankName = data.correspondingBankName;
    this.constantSymbol = data.constantSymbol;
    this.variableSymbol = data.variableSymbol;
    this.specificSymbol = data.specificSymbol;
    this.userIdentification = data.userIdentification;
    this.message = data.message;
    this.submittedBy = data.submittedBy;
    this.detail = data.detail;
    this.detail2 = data.detail2;
  }

  formatCorrespondingAccount () {
    if (!this.correspondingAccountNumber) {
      return null;
    } else if (!this.correspondingBankId) {
      return this.correspondingAccountNumber;
    } else {
      return `${this.correspondingAccountNumber} / ${this.correspondingBankId}`;
    }
  }
}

export default BankTransaction;
