import _ from 'lodash';
import employeeResource from './employeeResource';
import Employee from '../domain/employee';

export default {
  newEmployee () {
    return new Employee({ address: {}});
  },
  async find (id) {
    const response = await employeeResource.get({ id: id });
    if (response.ok) {
      return new Employee(response.data);
    } else {
      return null;
    }
  },
  async findAll () {
    const response = await employeeResource.query();
    if (response.ok) {
      return response.data.map((employeeData) => new Employee(employeeData));
    } else {
      return null;
    }
  },
  async create (employee) {
    const response = await employeeResource.save({}, _.pickBy(employee));
    if (response.ok) {
      return new Employee(response.data);
    } else {
      return null;
    }
  },
  async update (employee) {
    const response = await employeeResource.update({}, _.pickBy(employee));
    if (response.ok) {
      return new Employee(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return employeeResource.delete({ id: id });
  }
};
