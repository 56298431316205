<template>
  <div>
    <div class="content-header">
      <h1>Môj profil - editácia</h1>
    </div>
    <section class="content">
      <v-form ref="form">
        <div class="box">
          <div class="box-body">
            <form v-if="user">
              <div class="row">
                <div class="col-md-3 col-md-offset-3">
                  <text-field field-id="username"
                              label="Prihlasovacie meno"
                              placeholder="Zadaj prihlasovacie meno"
                              v-model="user.username"
                              :maxlength="100"
                              validation="required|min:5|max:100"></text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-md-offset-3">
                  <text-field field-id="firstname"
                              label="Meno"
                              placeholder="Zadaj meno"
                              v-model="user.firstname"
                              :maxlength="100"
                              validation="required|min:3|max:100"></text-field>
                </div>
                <div class="col-md-3">
                  <text-field field-id="surname"
                              label="Priezvisko"
                              placeholder="Zadaj priezvisko"
                              v-model="user.surname"
                              :maxlength="100"
                              validation="required|min:3|max:100"></text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-md-offset-3">
                  <text-field field-id="email"
                              label="E-mail"
                              placeholder="Zadaj e-mail"
                              v-model="user.email"
                              :maxlength="30"
                              validation="min:6|max:30|email"></text-field>
                </div>
                <div class="col-md-3">
                  <text-field field-id="phoneNumber"
                              label="Telefónne číslo"
                              placeholder="Zadaj telefónne číslo"
                              v-model="user.phoneNumber"
                              :maxlength="30"
                              validation="min:9|max:30"></text-field>
                </div>
              </div>
              <hr>
              <div class="text-center">
                <router-link tag="button" to="/profile" class="btn btn-default">Zrušiť</router-link>
                <button class="btn btn-primary" type="submit" @click.prevent="submit($refs.form)">Uložiť</button>
              </div>
            </form>
          </div>
        </div>
      </v-form>
    </section>
  </div>
</template>

<script>
  import _ from 'lodash';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import vForm from 'components/form/vForm';

  export default {
    components: { textField, vForm },
    mixins: [validationMixin],
    data: () => ({
      user: null
    }),
    methods: {
      onSubmit () {
        this.$store.dispatch('user/updateProfile', this.user).then((updatedUser) => {
          if (updatedUser) {
            notificationService.success('profile.edit.success');
            this.$router.push('/profile');
          }
        }).finally(this.unprotect);;
      }
    },
    mounted () {
      this.user = _.cloneDeep(this.$store.state.auth.user);
    }
  };
</script>
