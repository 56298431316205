import { APP_CONFIGURATION_LOAD, APP_SUBMIT_PROTECTION_ENABLE, APP_SUBMIT_PROTECTION_DISABLE, APP_LOADING_DATA_ENABLE, APP_LOADING_DATA_DISABLE } from 'store/mutationTypes';
import configurationService from 'modules/configuration/service/configurationService';

export default {
  async protect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_ENABLE);
  },
  async unprotect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_DISABLE);
  },
  async loadingDataEnable ({ commit }, id) {
    await commit(APP_LOADING_DATA_ENABLE, id);
  },
  async loadingDataDisable ({ commit }, id) {
    await commit(APP_LOADING_DATA_DISABLE, id);
  },
  async loadConfiguration ({ commit, state }) {
    if (!state.configuration) {
      const configuration = await configurationService.getConfiguration();
      await commit(APP_CONFIGURATION_LOAD, {
        configuration: configuration
      });
    }
    return state.configuration;
  }
};
