import {FINANCE_SUMMARY_TAX_BASE_LIST, FINANCE_SUMMARY_VAT_LIST} from './mutationTypes';

const mutations = {
  [FINANCE_SUMMARY_VAT_LIST] (state, action) {
    state.vatSummaryItems = action.items;
  },
  [FINANCE_SUMMARY_TAX_BASE_LIST] (state, action) {
    state.taxBaseSummaryItems = action.items;
  }
};

export default mutations;
