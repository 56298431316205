import moment from 'moment';
import actions from './authActions';
import mutations from './authMutations';
import getters from './authGetters';
import { AUTH_STORAGE_KEY } from 'config';
import User from '../../../modules/user/domain/user';

// Local storage sync state
const authData = localStorage.getItem(AUTH_STORAGE_KEY);
let initialState, storedData;

if (authData) {
  storedData = JSON.parse(authData);
  initialState = {
    accessToken: storedData.accessToken,
    refreshToken: storedData.refreshToken,
    validTo: moment(storedData.validTo),
    user: new User(storedData.user)
  };
} else {
  initialState = {
    accessToken: null,
    refreshToken: null,
    user: null
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  mutations: mutations,
  actions: actions
};
