import {LEAD_ACTIVATE, LEAD_CONVERT, LEAD_DEACTIVATE, LEAD_LIST, LEAD_SELECT, LEAD_CLEAR, LEAD_UPDATE} from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [LEAD_LIST] (state, action) {
    state.items = action.items;
  },
  [LEAD_SELECT] (state, action) {
    state.selected = action.item;
  },
  [LEAD_CLEAR] (state) {
    state.selected = null;
  },
  [LEAD_UPDATE] (state, action) {
    state.selected = _.find(state.items, { id: action.item.id });
  },
  [LEAD_CONVERT] (state, action) {
    state.selected = _.find(state.items, { id: action.item.id });
  },
  [LEAD_ACTIVATE] (state, id) {
    const lead = _.find(state.items, { id: id });
    if (lead) {
      lead.active = true;
    }
  },
  [LEAD_DEACTIVATE] (state, id) {
    const lead = _.find(state.items, { id: id });
    if (lead) {
      lead.active = false;
    }
  }
};

export default mutations;
