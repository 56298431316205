import authService from 'services/authService';
import userService from 'modules/user/service/userService';
import { AUTH_LOG_IN, AUTH_USER_UPDATE, AUTH_LOG_OUT } from 'store/mutationTypes';

export default {
  async logIn ({ dispatch, commit }, credentials) {
    try {
      const token = await authService.login(credentials);
      await commit(AUTH_LOG_IN, token);
      await dispatch('fetchMe');
      dispatch('user/fetchInfo', null, { root: true });
      return true;
    } catch (ex) {
      // console.log(ex);
      // await dispatch("loginFailed");
      return false;
    }
  },
  async logOut ({ commit }) {
    await commit(AUTH_LOG_OUT);
  },
  async fetchMe ({ commit }) {
    let user;
    try {
      user = await userService.me();
      if (user) {
        await commit(AUTH_USER_UPDATE, {
          user: user
        });
        return user;
      }
    } catch (ex) {
      // await dispatch("getAllFailed");
    }
  }
};
