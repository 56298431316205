'use strict';

import modalWindowCloseHandler from './modalWindowCloseHandler';
import modalWindowKeyHandler from './modalWindowKeyHandler';
import adminLteFixHook from './adminLteFixHook';
import bodyClassHook from './bodyClassHook';
import authHook from './routerAuthHook';

export default (store, router) => {
  router.beforeEach(authHook(store));
  router.beforeEach(bodyClassHook());
  router.beforeEach(adminLteFixHook());
  router.beforeEach(modalWindowCloseHandler());
  modalWindowKeyHandler();
};
