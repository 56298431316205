import VatRate from '../domain/vatRate';
import vatRateResource from '../service/vatRateResource';

export default {
  async findAll () {
    const response = await vatRateResource.query();
    if (response.ok) {
      return response.data.map((vatRateData) => new VatRate(vatRateData));
    } else {
      return null;
    }
  }
};
