<template>
  <div>
    <records :entity="project" record-entity="project" :show-subject-link="true"></records>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import records from 'modules/record/components/records';

  export default {
    components: { records },
    computed: mapState({
      project: (state) => state.tmProject.selected
    })
  };
</script>
