<template>
<div class="row">
  <div class="col-md-12">
    <h4>Voliteľné položky</h4>
    <order-item-create-modal :show-model="showCreateModal" :model="selectedOrderItem" :currency="currency" @close="hideModals()"></order-item-create-modal>
  </div>

  <div class="col-md-12" v-show="value.length">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-hover table-condensed">
          <thead>
          <tr>
            <th>Popis</th>
            <th>Cena</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in value">
            <td class="col-md-8">{{ item.name }} <copy-text :content="item.name"></copy-text></td>
            <td class="col-md-2">{{ item.price | currency(currency) }}</td>
            <td class="col-md-2 text-right">
              <button class="btn btn-xs btn-link" @click.stop="editItem(item)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
              <button class="btn btn-xs btn-link" @click.prevent="deleteItem(item)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <alert v-show="!value.length" type="warning">{{ 'common.data.notRecords' | translate }}</alert>
    <button class="btn btn-sm btn-success" type="button" @click.prevent="createOrderItemShow()"><i class="fa fa-plus" aria-hidden="true"></i> Pridať</button>
  </div>
</div>

</template>

<script>

  import _ from 'lodash';
  import { alert } from 'vue-strap';
  import copyText from 'modules/common/components/copyText.vue';
  import notificationService from 'services/notificationService';
  import orderItemCreateModal from 'modules/order/components/tmOrderItemCreateModal';
  import orderItemService from 'modules/order/service/tmOrderItemService';

  export default {
    components: { copyText, orderItemCreateModal, alert },
    data: () => ({
      selectedOrderItem: null,
      showCreateModal: false
    }),
    props: {
      value: {
        required: true,
        type: Array
      },
      orderId: {
        required: true,
        type: Number
      },
      currency: {
        required: true,
        type: String
      }
    },
    methods: {
      createOrderItemShow () {
        this.selectedOrderItem = orderItemService.newTmOrderItem({
          order: { id: this.orderId }
        });
        this.showCreateModal = true;
      },
      editItem (item) {
        this.selectedOrderItem = _.cloneDeep(item);
        this.showCreateModal = true;
      },
      deleteItem (tmOrderItem) {
        this.confirm('tmOrderItem.delete.confirmation', [tmOrderItem.name]).then(() => {
          this.$store.dispatch('tmOrderItem/delete', tmOrderItem.id).then((result) => {
            if (result) {
              notificationService.success('tmOrderItem.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      hideModals () {
        this.showCreateModal = false;
      }
    }

  };
</script>
