import { MONEY_MOVEMENT_CATEGORY_LIST, MONEY_MOVEMENT_CATEGORY_ACTIVATE, MONEY_MOVEMENT_CATEGORY_DEACTIVATE } from './mutationTypes';

const mutations = {
  [MONEY_MOVEMENT_CATEGORY_LIST] (state, action) {
    state.items = action.items;
  },
  [MONEY_MOVEMENT_CATEGORY_ACTIVATE] (state, id) {
    const moneyBox = _.find(state.items, { id: id });
    if (moneyBox) {
      moneyBox.active = true;
    }
  },
  [MONEY_MOVEMENT_CATEGORY_DEACTIVATE] (state, id) {
    const moneyBox = _.find(state.items, { id: id });
    if (moneyBox) {
      moneyBox.active = false;
    }
  }
};

export default mutations;
