import companyResource from './companyResource';
import _ from 'lodash';
import Company from '../domain/company';
import aresResource from '../../../resources/aresResource';
import {DOWNLOAD_BLOB} from "../../../utils";

export default {
  newCompany () {
    return new Company({ address: {}});
  },
  async find (id) {
    const response = await companyResource.get({ id: id });
    if (response.ok) {
      return new Company(response.data);
    } else {
      return null;
    }
  },
  async findAll () {
    const response = await companyResource.query();
    if (response.ok) {
      return response.data.map((companyData) => new Company(companyData));
    } else {
      return null;
    }
  },
  async create (company) {
    const response = await companyResource.save({}, _.pickBy(company));
    if (response.ok) {
      return new Company(response.data);
    } else {
      return null;
    }
  },
  async update (company) {
    const response = await companyResource.update({}, _.pickBy(company));
    if (response.ok) {
      return new Company(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return companyResource.delete({ id: id });
  },
  async fetchFromAres (name) {
    const response = await aresResource.query({ name });
    if (response.ok) {
      return response.data.map((companyData) => new Company(companyData));
    } else {
      return null;
    }
  },
  async generateAgreement (id, role, fileName) {
    await companyResource.generateAgreement({ id, role })
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  }
};
