<template>
  <div>
    <bank-accounts :filter="{subjectId: lead.id, showInactive: true}" :subjects="[lead]" :api-token-supported="false" :subject-select-supported="false"></bank-accounts>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import bankAccounts from 'modules/bank/components/bankAccounts';

  export default {
    components: { bankAccounts },
    computed: mapState({
      lead: (state) => state.lead.selected
    })
  };
</script>
