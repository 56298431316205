import * as types from './mutationTypes';
import companyService from '../service/companyService';
import _ from 'lodash';

const actions = {
  async select ({ dispatch, commit }, id) {
    await commit(types.COMPANY_SELECT, {
      item: null
    });
    dispatch('app/loadingDataEnable', 'company', { root: true });
    const company = await companyService.find(id);
    await commit(types.COMPANY_SELECT, {
      item: company
    });
    dispatch('app/loadingDataDisable', 'company', { root: true });
  },
  async clear({ commit }) {
    await commit(types.COMPANY_CLEAR);
  },
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'companies', { root: true });
      const companies = await companyService.findAll();
      await commit(types.COMPANY_LIST, {
        items: companies
      });
      dispatch('app/loadingDataDisable', 'companies', { root: true });
    }
  },
  async create ({ dispatch }, company) {
    try {
      const newCompany = await companyService.create(company);
      if (newCompany) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        return newCompany;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch, commit }, company) {
    try {
      const updatedCompany = await companyService.update(company);
      if (updatedCompany) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.COMPANY_UPDATE, {
          item: company
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.COMPANY_ACTIVATE, id);
      const company = _.find(state.items, { id: id });
      const updatedCompany = await companyService.update(company);
      if (updatedCompany) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.COMPANY_UPDATE, {
          item: company
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.COMPANY_DEACTIVATE, id);
      const company = _.find(state.items, { id: id });
      const updatedCompany = await companyService.update(company);
      if (updatedCompany) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.COMPANY_UPDATE, {
          item: company
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await companyService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      dispatch('subject/getAll', true, { root: true });
      return true;
    } else {
      return false;
    }
  },
  async fetchFromAres ({ state, dispatch, commit }, name) {
    const companies = await companyService.fetchFromAres(name);
    await commit(types.COMPANY_ARES_LIST, {
      aresItems: companies
    });
  }
};

export default actions;
