/* eslint-disable camelcase */
'use strict';

const messages = {
  alpha: (field) => `Pole môže obsahovať len písmená.`,
  alpha_dash: (field) => `Pole môže obsahovať len písmená, číslice, bodky a podčiarknutie.`,
  alpha_num: (field) => `Pole môže obsahovať len písmená a číslice.`,
  alpha_spaces: (field) => `Pole môže obsahovať len písmená, číslice a medzery.`,
  between: (field, {min, max}) => `Hodnota pola musí byť medzi ${min} a ${max}.`,
  confirmed: (field) => `Hodnota položky nie je rovnaká.`,
  decimal: (field, {decimals = '*'} = []) => `Hodnota pola musí byť číselná a smie obsahovať${decimals === '*' ? '' : ' ' + decimals} desatinné miesta.`,
  digits: (field, {length}) => `Pole musí obsahovať ${length} ${length < 5 ? 'čísla' : 'čísiel'}.`,
  dimensions: (field, [width, height]) => `Obrázok musí mať ${width} x ${height} pixlov.`,
  email: (field) => `Pole musí obsahovať správnu emailovú adresu.`,
  excluded: (field) => `Nesprávna hodnota.`,
  ext: (field) => `Nie je platný súbor.`,
  image: (field) => `Nie je obrázok.`,
  oneOf: (field) => `Pole má nesprávnu hodnotu.`,
  length: (field, {length}) => `Požadovaný počet znakov: ${length}.`,
  max: (field, {length}) => `Pole môže obsahovať najviac ${length} znakov.`,
  max_value: (field, {max}) => `Hodnota pola musí byť maximálne ${max}.`,
  mimes: (field) => `Nesprávny typ súboru.`,
  min: (field, {length}) => `Pole musí obsahovať minimálne ${length} ${length < 4 ? 'znaky' : 'znakov'}.`,
  min_value: (field, {min}) => `Hodnota pola musí byť minimálne ${min}.`,
  numeric: (field) => `Pole môže obsahovať len číslice.`,
  regex: (field) => `Formát položky je nesprávny.`,
  required: (field) => `Pole je povinné.`,
  required_if: (field) => `Pole je povinné.`,
  size: (field, {size}) => `Hodnota pola musí byť menej ako ${formatFileSize(size)}.`
};


/**
 * Formats file size. !!!! Taken from vee-validate library !!!!
 *
 * @param {Number|String} size
 */
const formatFileSize = (size) => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const threshold = 1024;
  size = Number(size) * threshold;
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`;
};


export default {"code": "sk", messages};

