import _ from 'lodash';
import moment from 'moment';
import Range from 'components/range';
import { FORMAT_SYSTEM_DATE } from 'utils';

export default {
  data: () => ({
    sortData: {
      by: null,
      asc: null
    },
    filter: { }
  }),
  methods: {
    updateQuery () {
      const newQuery = {};
      // remove all nulls and undefined and add prefix 'o_';
      _(this.sortData).pickBy((value) => !_.isNil(value)).forEach((value, key) => {
        newQuery['o_' + key] = encodeURI(value);
      });
      // remove all nulls and undefined and add prefix 'f_';
      _(this.filter).pickBy((value) => !_.isNil(value)).forEach((value, key) => {
        if (_.isString(value)) {
          newQuery['fs_' + key] = encodeURI(value);
        } else if (_.isNumber(value)) {
          newQuery['fn_' + key] = encodeURI(value);
        } else if (_.isBoolean(value)) {
          newQuery['fb_' + key] = encodeURI(value);
        } else if (value instanceof moment) {
          newQuery['fd_' + key] = FORMAT_SYSTEM_DATE(value);
        } else if (value instanceof Range) {
          if (value.from && value.from.isValid()) {
            newQuery['frf_' + key] = FORMAT_SYSTEM_DATE(value.from);
          }
          if (value.to && value.to.isValid()) {
            newQuery['frt_' + key] = FORMAT_SYSTEM_DATE(value.to);
          }
        } else if (_.isArray(value) && value.length > 0) {
          newQuery['fa_' + key] = encodeURI(value);
        }
      });
      this.$router.push({ query: newQuery });
    },
    restoreFilter () {
      const query = this.$route.query;
      _(query).forEach((value, key) => {
        if (key === 'o_by') {
          this.sortData.by = decodeURI(value);
        } else if (key === 'o_asc') {
          this.sortData.asc = JSON.parse(decodeURI(value));
        } else if (key.startsWith('fs_')) {
          this.filter[key.substring(3)] = decodeURI(value);
        } else if (key.startsWith('fn_')) {
          this.filter[key.substring(3)] = Number(decodeURI(value));
        } else if (key.startsWith('fb_')) {
          this.filter[key.substring(3)] = JSON.parse(decodeURI(value));
        } else if (key.startsWith('fd_')) {
          this.filter[key.substring(3)] = moment(decodeURI(value));
        } else if (key.startsWith('fa_')) {
          this.filter[key.substring(3)] = _.map(value.split(','), item => _.isNaN(Number(item)) ? item : Number(item));
        } else if (key.startsWith('frf_')) {
          let propName = key.substring(4);
          if (!this.filter[propName]) {
            this.filter[propName] = new Range();
          }
          this.filter[propName].from = moment(decodeURI(value));
        } else if (key.startsWith('frt_')) {
          let propName = key.substring(4);
          if (!this.filter[propName]) {
            this.filter[propName] = new Range();
          }
          this.filter[propName].to = moment(decodeURI(value));
        }
      }, this);
    },
    // method to be overridden
    defaultFilter () {},
    setupFilter () {
      if (_.isEmpty(this.$route.query)) {
        this.defaultFilter();
        this.updateQuery();
      } else {
        this.restoreFilter();
      }
    }
  },
  watch: {
    filter: {
      handler () {
        this.updateQuery();
      },
      deep: true
    },
    sortData: {
      handler () {
        this.updateQuery();
      },
      deep: true
    }
  },
  created () {
    this.setupFilter();
  }
};
