import Vue from 'vue';
import { API_ROOT } from 'config';

const contextPath = 'money-movement-category';

const customActions = {
  deactivate: { method: 'PUT', url: API_ROOT + contextPath + '{/id}/deactivate' },
  activate: { method: 'PUT', url: API_ROOT + contextPath + '{/id}/activate' },
  updatePosition: { method: 'PUT', url: API_ROOT + contextPath + '/{id}/position/{position}' }
};

export default Vue.resource(API_ROOT + contextPath + '{/id}', {}, customActions);
