export default [
  { value: 0, label: 'Január' },
  { value: 1, label: 'Február' },
  { value: 2, label: 'Marec' },
  { value: 3, label: 'Apríl' },
  { value: 4, label: 'Máj' },
  { value: 5, label: 'Jún' },
  { value: 6, label: 'Júl' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'Október' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' }
];
