<template>
  <div>
    <info-box title="STAV CASHFLOW" icon-class="fa fa-line-chart" :value-class="(cashFlow >= 0 ? 'text-success' : 'text-danger')" :value="cashFlow | currency(currency)">
    </info-box>
  </div>

</template>
<script>
  import infoBox from 'components/infoBox';
  import {mapGetters} from 'vuex';

  export default {
    components: {infoBox},
    props: {
      currency: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters({
        moneyBoxes: 'moneyBox/active',
      }),
      cashFlow() {
        return _(this.moneyBoxes).filter({currency: this.currency}).map('amount').sum();
      }
    }
  };
</script>
