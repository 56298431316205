import tmOrderResource from './tmOrderResource';
import _ from 'lodash';
import TmOrder from '../domain/tmOrder';
import { DOWNLOAD_BLOB } from '../../../utils';

export default {
  newTmOrder (data) {
    return new TmOrder(data);
  },
  async findAll () {
    const response = await tmOrderResource.query();
    if (response.ok) {
      return response.data.map((tmOrderData) => new TmOrder(tmOrderData));
    } else {
      return null;
    }
  },
  async create (tmOrder) {
    const response = await tmOrderResource.save({}, tmOrder);
    if (response.ok) {
      return new TmOrder(response.data);
    } else {
      return null;
    }
  },
  async update (tmOrder) {
    const response = await tmOrderResource.update({}, tmOrder);
    if (response.ok) {
      return new TmOrder(response.data);
    } else {
      return null;
    }
  },
  async updateDocuments (tmOrder) {
    //need to use omitBy instead pickBy because of possible zero in vatRate property
    const response = await tmOrderResource.updateDocuments({}, _.omitBy(tmOrder, _.isNil));
    if (response.ok) {
      return new TmOrder(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return tmOrderResource.delete({ id: id });
  },
  async generateOrderDocument (id, fileName) {
    await tmOrderResource.generateOrderDocument({ id })
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  },
  async generateOrderPdfDocument (id, fileName) {
    await tmOrderResource.generateOrderPdfDocument({ id })
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  }
};
