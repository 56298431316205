import actions from './userActions';
import mutations from './userMutations';
import getters from './userGetters';

const state = {
  items: [],
  info: null
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
