'use strict';
import _ from 'lodash';

export default {
  active: (state) => {
    return _.filter(state.items || [], 'active');
  },
  activeByCurrency: (state, getters) => (currency) => {
   return _.filter(getters.active || [], {currency});
  }
};
