<template>
  <div v-if="tmProject">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia kontraktu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Názov"
                        placeholder="Zadaj meno"
                        v-model="tmProject.name"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
          <div class="col-md-12">
            <select-field
              :options="customers"
              v-model="tmProject.customer"
              field-id="customer"
              label="Zákazník"
              validation="required"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12">
            <text-area-field field-id="note"
                             v-model="tmProject.note"
                             label="Poznámka"
                             :maxlength="300"
                             validation="max:300"
                             css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import textAreaField from 'components/form/textAreaField';
  import selectField from 'components/form/selectField';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, textAreaField, selectField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      tmProject: null
    }),
    computed: {
      ...mapGetters({
        customers: 'company/customers'
      })
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.tmProject.id) {
          operation = 'update';
        } else {
          operation = 'create';
        }
        const tmProject = _.cloneDeep(this.tmProject);
        this.$store.dispatch('tmProject/' + operation, tmProject).then((result) => {
          if (result) {
            notificationService.success('tmProject.edit.success');
            this.$router.push({ name: 'tmProjectDetail', params: { id: result.id }});
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.tmProject = null;
      }
    },
    watch: {
      model: function (model) {
        this.tmProject = _.cloneDeep(model);
      }
    },
    created () {
      this.$store.dispatch('company/getAll');
    }
  };
</script>
