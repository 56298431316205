import documentTemplateService from '../../../services/documentTemplateService';
import * as types from '../../mutationTypes';

const actions = {
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'documentTemplates', { root: true });
      const templates = await documentTemplateService.findAll();
      await commit(types.DOCUMENT_TEMPLATE_LIST, {
        items: templates
      });
      dispatch('app/loadingDataDisable', 'documentTemplates', { root: true });
    }
  },
  async create ({ dispatch }, documentTemplate) {
    try {
      const newDocumentTemplate = await documentTemplateService.create(documentTemplate);
      if (newDocumentTemplate) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ commit, dispatch }, documentTemplate) {
    try {
      const updatedDocumentTemplate = await documentTemplateService.update(documentTemplate);
      if (updatedDocumentTemplate) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await documentTemplateService.delete(id);
    if (response.ok) {
      await dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
