import _ from 'lodash';
import { USER_LIST, USER_UPDATE, USER_ACTIVATE, USER_DEACTIVATE, USER_INFO_UPDATE } from './mutationTypes';
import { MERGE } from '../../../utils';

const mutations = {
  [USER_LIST] (state, action) {
    state.items = action.items;
  },
  [USER_UPDATE] (state, action) {
    const toBeUpdated = _.find(state.items, { id: action.item.id });
    // when array, replace it at all - vue problem
    _.mergeWith(toBeUpdated, action.item, MERGE);
    state.selected = action.item;
  },
  [USER_ACTIVATE] (state, id) {
    _.find(state.items, { id: id }).active = true;
  },
  [USER_DEACTIVATE] (state, id) {
    _.find(state.items, { id: id }).active = false;
  },
  [USER_INFO_UPDATE] (state, action) {
    state.info = action.info;
  }
};

export default mutations;
