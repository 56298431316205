import employeeDocumentsView from 'modules/employee/components/employeeDocuments';
import employeeAccountView from 'modules/employee/components/employeeAccount';
import employeeBankAccounts from 'modules/employee/components/employeeBankAccounts';
import employeeContactsView from 'modules/employee/components/employeeContacts';
import employeeRecordsView from 'modules/employee/components/employeeRecords';
import employeeActivitiesView from 'modules/employee/components/employeeActivities';
import employeesView from 'modules/employee/components/employees';
import employeeView from 'modules/employee/components/employee';

export default [
  {
    path: '/employees',
    component: employeesView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/employee/:id',
    name: 'employee',
    component: employeeView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    },
    redirect: { name: 'employeeRecords' },
    children: [
      {
        path: 'documents',
        name: 'employeeDocuments',
        component: employeeDocumentsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'account',
        name: 'employeeAccount',
        component: employeeAccountView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'contacts',
        name: 'employeeContacts',
        component: employeeContactsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'records',
        name: 'employeeRecords',
        component: employeeRecordsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'activities',
        name: 'employeeActivities',
        component: employeeActivitiesView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'bank-accounts',
        name: 'employeeBankAccounts',
        component: employeeBankAccounts,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      }
    ]
  }
];
