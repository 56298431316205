/* eslint-disable camelcase */
/**
 * Mixin enforces instant of validator per vue component. Automatic installation of $validator is disabled by
 * VeeValidation configuration in CLIENT_SOURCE/validation/index.js
 */
export default {
  methods: {
    async submit(form) {
      await this.protect();
      const isValid = await form.validate();
      if (isValid) {
        this.onSubmit();
      } else {
        await this.unprotect();
      }
    }
  }
};
