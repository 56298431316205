<template>
  <page title="Bankové transakcie">
    <div class="box">
      <div class="box-header">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <select-field field-id="technologies"
                          label="Bankový účet"
                          :codelist="true"
                            :options="bankAccounts"
                          v-model="filter.bankAccount"></select-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <select-field
              :options="periods"
              v-model="period"
              field-id="period"
              label="Obdobie">
            </select-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <date-field v-model="filter.dateFrom" field-id="dateFrom" label="Dátum od"></date-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <date-field v-model="filter.dateTo" field-id="dateTo" label="Dátum do"></date-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i class="fa fa-gear"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a role="button" @click.prevent="syncAll()"><i class="fa fa-download text-primary"></i> Synchronizovať všetky účty</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="box-body table-responsive">
        <table class="table table-hover no-padding" v-if="bankTransactions && bankTransactions.length">
          <tbody>
          <tr>
            <th>Datum</th>
            <th>Čiastka</th>
            <th>VS</th>
            <th>KS</th>
            <th>SS</th>
            <th>Protiúčet</th>
            <th>Správa pre príjemcu</th>
            <th>Identifikácia</th>
          </tr>
          <template v-for="bankTransaction in bankTransactions">
            <tr :class="{'bg-danger': bankTransaction.amount < 0, 'bg-success': bankTransaction.amount > 0}">
              <td>{{ bankTransaction.date | date }}</td>
              <td class="text-right text-nowrap">{{ bankTransaction.amount | currency(bankTransaction.currency) }}</td>
              <td class="text-right">{{ bankTransaction.variableSymbol }}</td>
              <td class="text-right">{{ bankTransaction.constantSymbol }}</td>
              <td class="text-right">{{ bankTransaction.specificSymbol }}</td>
              <td class="text-right">{{ bankTransaction.formatCorrespondingAccount() }}</td>
              <td>{{ bankTransaction.message }}</td>
              <td>{{ bankTransaction.userIdentification }}</td>
            </tr>
          </template>
          </tbody>
        </table>
        <loading id="bankTransactions"></loading>
        <no-records :data="bankTransactions" loading-id="bankTransactions"></no-records>
      </div>
    </div>
  </page>

</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import moment from 'moment';
  import _ from 'lodash';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import currencies from 'codelist/currencies';
  import dateField from 'components/form/dateField';
  import periods from 'codelist/periods';
  import selectField from 'components/form/selectField';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import notificationService from 'services/notificationService';
  import page from 'components/page';
  import bookmarkableFilter from 'components/bookmarkableFilter';

  export default {
    components: { loading, noRecords, selectField, dateField, page },
    mixins: [bookmarkableFilter],
    data: () => ({
      periods,
      period: null,
      showCreateModal: false,
      filter: {
        bankAccount: null,
        dateFrom: null,
        dateTo: null
      }
    }),
    computed: {
      ...mapState({
        bankTransactions: (state) => state.bankTransaction.items
      }),
      ...mapGetters({
        bankAccounts: 'bankAccount/activeWithToken'
      })
    },
    methods: {
      currency (currency) {
        return currency ? _.find(currencies, { value: currency }).description : null;
      },
      fetchTransactions () {
        if (this.filter.bankAccount) {
          this.$store.dispatch('bankTransaction/findByFilter', _.pickBy({
            dateFrom: FORMAT_SYSTEM_DATE(this.filter.dateFrom),
            dateTo: FORMAT_SYSTEM_DATE(this.filter.dateTo),
            bankAccountId: this.filter.bankAccount
          }, _.identity));
        } else {
          this.$store.dispatch('bankTransaction/clear');
        }
      },
      async syncAll () {
        const syncResult = await this.$store.dispatch('bankTransaction/syncAll');
        if (syncResult) {
          notificationService.success('bankTransaction.syncAll.success');
        } else {
          notificationService.error('error.bankTransaction.syncAll');
        }
      },
      defaultFilter () {
        this.period = this.periods[0];
      }
    },
    watch: {
      period () {
        if (this.period) {
          this.filter.dateFrom = moment().subtract(this.period.value, this.period.momentDuration);
          this.filter.dateTo = null;
        } else {
          this.filter.dateFrom = null;
          this.filter.dateTo = null;
        }
      },
      filter: {
        handler () {
          this.fetchTransactions();
        },
        deep: true
      }
    },
    created () {
      this.$store.dispatch('bankAccount/findOwnershipped');
    }
  };
</script>
