import Lead from '../domain/lead';
import leadResource from './leadResource';
import Company from 'modules/company/domain/company';
import _ from 'lodash';

export default {
  newLead (company, oldLead) {
    if (company) {
      if (oldLead) {
        const newLead = _.cloneDeep(oldLead);
        newLead.address = company.address;
        newLead.companyId = company.companyId;
        newLead.name = company.name;
        return newLead;
      } else {
        return new Lead(company);
      }
    } else {
      return new Lead({ address: {}});
    }
  },
  async convert (lead) {
    const response = await leadResource.convert({}, _.pickBy(lead));
    if (response.ok) {
      return new Company(response.data);
    } else {
      return null;
    }
  },
  async find (id) {
    const response = await leadResource.get({ id });
    if (response.ok) {
      return new Lead(response.data);
    } else {
      return null;
    }
  },
  async findAll () {
    const response = await leadResource.query();
    if (response.ok) {
      return response.data.map((leadData) => new Lead(leadData));
    } else {
      return null;
    }
  },
  async create (lead) {
    const response = await leadResource.save({}, _.pickBy(lead));
    if (response.ok) {
      return new Lead(response.data);
    } else {
      return null;
    }
  },
  async update (lead) {
    const response = await leadResource.update({}, _.pickBy(lead));
    if (response.ok) {
      return new Lead(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return leadResource.delete({ id: id });
  }
};
