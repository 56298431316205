<template>
  <div v-if="item">
    <modalWindow v-model="showModel" @ok="submit()" @cancel="cancel()" :backdrop="false"
                 title="Editácia položky" @closed="onClose()" :cancel-text="'Zrušiť'" :ok-text="'Uložiť'">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="label"
                        label="Názov"
                        placeholder="Zadaj názov"
                        v-model="item.label"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="shortDescription"
                        label="Popis"
                        placeholder="Zadaj popis"
                        v-model="item.shortDescription"
                        :maxlength="100"
                        validation="min:3|max:200"></text-field>
          </div>
        </div>
        <div class="row" v-if="isEdit()">
          <div class="col-md-12">
            <checkbox-field
              field-id="valid"
              label="Vyradené"
              v-model="item.valid"
              :reverse="true">
            </checkbox-field>
          </div>
        </div>
      </v-form>
    </modalWindow>
  </div>
</template>

<script>
  import codeListCreateModalMixin from './codeListCreateModalMixin';
  import textField from 'components/form/textField';
  import checkboxField from 'components/form/checkboxField';
  import modalMixin from './modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { textField, checkboxField, vForm },
    mixins: [codeListCreateModalMixin, modalMixin],
    methods: {
      isEdit () {
        return this.item && this.item .id;
      }
    }
  };
</script>
