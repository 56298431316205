'use strict';
import _ from 'lodash';

export default {
  customers: (state, a, b, getters) => {
    return _.filter(getters['subject/withoutLeads'] || [], (subject) => subject.active && !_.includes(subject.roles, 'SMARTBRAINS'));
  },
  ownershipped: (state, a, b, getters) => {
    return _.filter(getters['subject/withoutLeads'] || [], (subject) => subject.active && _.includes(subject.roles, 'SMARTBRAINS'));
  },
  withoutLeads: (state) => {
    return _.filter(state.items, (subject) => subject.constructor.route !== 'lead');
  },
  active: (state) => {
    return _.filter(state.items || [], 'active');
  }
};
