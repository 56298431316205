<template>
  <div v-if="contact">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia kontaktu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Názov"
                        placeholder="Zadaj názov kontaktu"
                        v-model="contact.name"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="email"
                        label="E-mail"
                        placeholder="Zadaj e-mail"
                        v-model="contact.email"
                        :maxlength="50"
                        validation="min:6|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="phoneNumber"
                        label="Telefónne číslo"
                        placeholder="Zadaj telefónne číslo"
                        v-model="contact.phoneNumber"
                        :maxlength="30"
                        validation="min:9|max:30"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="position"
                        label="Pozícia"
                        placeholder="Zadaj pozíciu"
                        v-model="contact.position"
                        :maxlength="50"
                        validation="min:2|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <checkbox-field
              field-id="valid"
              label="Fakturácia"
              :middle="true"
              v-model="contact.forInvoicing">
            </checkbox-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field v-model="contact.note" label="Poznámka" field-id="note" :maxlength="300" validation="max:300" css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import Contact from 'modules/contact/domain/contact';
  import checkboxField from 'components/form/checkboxField';
  import modal from 'vue-strap/src/Modal';
  import modalMixin from 'components/modalMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import textAreaField from 'components/form/textAreaField';
  import validationMixin from 'components/validationMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { checkboxField, modal, vForm, textField, textAreaField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      contact: null
    }),
    methods: {
      onSubmit () {
        let operation;
        if (this.contact.id) {
          operation = 'contact/update';
        } else {
          operation = 'contact/create';
        }
        const contact = _.cloneDeep(this.contact);
        this.$store.dispatch(operation, contact).then((result) => {
          if (result) {
            notificationService.success('contact.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        }).catch(this.unprotect);
      },
      clearData () {
        this.contact = null;
      }
    },
    watch: {
      model: function (model) {
        this.contact = new Contact(model);
      }
    }
  };
</script>
