import Vue from 'vue';
import { API_ROOT } from '../../../config';

const customActions = {
  me: { method: 'GET', url: API_ROOT + 'user/me' },
  info: { method: 'GET', url: API_ROOT + 'user/info' },
  updateMe: { method: 'PUT', url: API_ROOT + 'user/me' },
  myPasswordChange: { method: 'PUT', url: API_ROOT + 'user/me/password' },
  passwordChange: { method: 'PUT', url: API_ROOT + 'user/password' },
  activate: { method: 'PUT', url: API_ROOT + 'user{/id}/activate' },
  deactivate: { method: 'PUT', url: API_ROOT + 'user{/id}/deactivate' }
};

export default Vue.resource(API_ROOT + 'user{/id}', {}, customActions);
