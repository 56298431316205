<template>
  <a role="button" @click.stop="projectDetail()">{{ project ? project.name : null }}</a>
</template>

<script>
  import Entity from 'domain/entity';

  export default {
    props: {
      project: {
        type: Entity,
        required: true
      },
      to: {
        type: String,
        default () {
          return 'projectRecords';
        }
      }
    },
    methods: {
      projectDetail () {
        this.$router.push({ name: this.to, params: { id: this.project.id }});
      }
    }
  };
</script>

<style scoped>

</style>
