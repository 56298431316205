<template>
  <div v-if="reward">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia dodávky" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <date-field v-model="reward.date" field-id="date" label="Dátum" validation="required"></date-field>
          </div>
          <div class="col-md-6">
            <static-field label="Projekt">
              {{ reward.project.name }}
            </static-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="subjects"
              v-model="reward.recipient"
              field-id="recipient"
              label="Príjemca"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <number-field
              v-model="reward.amount"
              label="Suma"
              placeholder="Zadaj sumu"
              field-id="amount"
              :min="1"
              :step="100"
              validation="required|numeric|min_value:1"
              :suffix="reward.currency | codeListValue(currencies)"></number-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field v-model="reward.note" label="Poznámka" field-id="note" :maxlength="300" validation="max:300" css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapGetters } from 'vuex';
  import { currencies } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import notificationService from 'services/notificationService';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import numberField from 'components/form/numberField';
  import dateField from 'components/form/dateField';
  import staticField from 'components/form/staticField';
  import validationMixin from 'components/validationMixin';
  import TmReward from '../domain/tmReward';
  import modalMixin from './modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, textAreaField, numberField, staticField, dateField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      reward: null,
      currencies: currencies
    }),
    computed: mapGetters({
      subjects: 'subject/withoutLeads'
    }),
    methods: {
      onSubmit () {
        let operation;
        if (this.reward.id) {
          operation = 'tmReward/update';
        } else {
          operation = 'tmReward/create';
        }
        const reward = _.cloneDeep(this.reward);
        this.$store.dispatch(operation, reward).then((result) => {
          if (result) {
            notificationService.success('tmReward.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.reward = null;
      }
    },
    watch: {
      model: function (model) {
        this.reward = new TmReward(model);
      }
    }
  };
</script>
