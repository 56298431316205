import actions from './accountActions';
import mutations from './accountMutations';

const state = {
  selected: null
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
