import Entity from '../../../domain/entity';
import subjectService from 'modules/subject/service/subjectService';

class Contact extends Entity {
  constructor (data) {
    super(data);

    this.name = data.name || null;
    this.email = data.email || null;
    this.phoneNumber = data.phoneNumber || null;
    this.note = data.note || null;
    this.subject = data.subject ? subjectService.getSubject(data.subject) : null;
    this.position = data.position || null;
    this.forInvoicing = data.forInvoicing || null;

    this.value = this.id;
    this.label = this.name;
  }
}

export default Contact;
