import currencyRatesView from 'modules/currencyRate/components/currencyRates';

export default [{
  path: '/currency-rates',
  component: currencyRatesView,
  meta: {
    requiresLoggedIn: true,
    requiresRole: 'ADMIN'
  }
}];
