import actions from './documentTemplateActions';
import mutations from './documentTemplateMutations';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
