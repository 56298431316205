<template>
  <div v-if="loggedIn" id="app" class="wrapper">

    <navbar />

    <div class="sidebar-wrapper">
      <aside class="main-sidebar">
        <!-- sidebar: style can be found in sidebar.less -->
        <section class="sidebar">
          <!-- Sidebar Menu -->
          <ul class="sidebar-menu tree" data-widget="tree" data-accordion="false">

            <!--active class ensure collapsed tree-view menu-->
            <li v-if="hasRole('DEALER')" class="treeview active">
              <a role="button"><i class="fa fa-briefcase"></i> <span>Projekty</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <router-link tag="li" to="/tm-projects" active-class="active"><a><i class="fa fa-file-o"></i><span>Kontrakty</span></a></router-link>
                <router-link tag="li" to="/tm-rewards" active-class="active"><a><i class="fa fa-dollar"></i><span>Provízie</span></a></router-link>
                <router-link tag="li" to="/tm-deliveries" active-class="active"><a><i class="fa fa-truck "></i><span>Dodávky</span></a></router-link>
                <router-link tag="li" to="/stats/czk" active-class="active"><a><i class="fa fa-pie-chart"></i><span>Štatistika (Kč)</span></a></router-link>
                <router-link tag="li" to="/stats/eur" active-class="active"><a><i class="fa fa-pie-chart"></i><span>Štatistika (€)</span></a></router-link>
              </ul>
            </li>

            <li v-if="hasRole('DEALER')" class="treeview active">
              <a role="button"><i class="fa fa-clock-o"></i> <span>Aktivity</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">

                <router-link tag="li" to="/activities" active-class="active">
                  <a>
                    <i class="fa fa-calendar-times-o"></i>
                    <span v-if="countOfActivities > 0" class="pull-right-container">
                      <span class="label label-primary pull-right">{{ countOfActivities }}</span>
                    </span>
                    <span>Plán aktivít</span>
                  </a>
                </router-link>
                <router-link tag="li" to="/records" active-class="active"><a><i class="fa fa-inbox"></i>Prehľad záznamov</a></router-link>
              </ul>
            </li>

            <li v-if="hasRole('ADMIN')" class="treeview active">
              <a role="button"><i class="fa fa-book"></i> <span>Kontakty</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <router-link tag="li" to="/subjects" active-class="active"><a><i class="fa fa-table"></i>Všetky subjekty</a></router-link>
                <router-link tag="li" to="/companies" active-class="active"><a><i class="fa fa-address-book"></i>Firmy</a></router-link>
                <router-link tag="li" to="/employees" active-class="active"><a><i class="fa fa-child"></i>Zamestnanci</a></router-link>
                <router-link tag="li" to="/leads" active-class="active"><a><i class="fa fa-user-secret"></i>Leads</a></router-link>
              </ul>
            </li>

            <li v-if="hasRole('ADMIN')" class="treeview active">
              <a role="button"><i class="fa fa-money"></i> <span>Účtovníctvo</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <router-link tag="li" to="/finance/overview/CZK" active-class="active"><a><i class="fa fa-line-chart"></i>Prehľad financií (Kč)</a></router-link>
                <router-link tag="li" to="/finance/overview/EUR" active-class="active"><a><i class="fa fa-line-chart"></i>Prehľad financií (€)</a></router-link>
                <router-link tag="li" to="/invoices/OUTCOME" active-class="active"><a><i class="fa fa-file-text-o"></i>Faktúry vystavené</a></router-link>
                <router-link tag="li" to="/invoices/INCOME" active-class="active"><a><i class="fa fa-file-text"></i>Faktúry prijaté</a></router-link>
                <router-link tag="li" to="/money-box" active-class="active"><a><i class="fa fa-dollar"></i>Pokladne a účty</a></router-link>
                <router-link tag="li" to="/bank-transactions" active-class="active"><a><i class="fa fa-exchange"></i>Bankové pohyby</a></router-link>
                <router-link tag="li" to="/currency-rates" active-class="active"><a><i class="fa fa-eur"></i>Menové kurzy</a></router-link>
              </ul>
            </li>

            <li v-if="hasRole('ADMIN')" class="treeview active">
              <a role="button"><i class="fa fa-cog"></i> <span>Nastavenia</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu">
                <router-link tag="li" to="/document-templates" active-class="active"><a><i class="fa fa-file-text-o"></i>Šablóny dokumentov</a></router-link>
                <router-link tag="li" to="/tm-reward-templates" active-class="active"><a><i class="fa fa-file-text-o"></i>Šablóny odmien</a></router-link>
                <router-link tag="li" to="/users" active-class="active"><a><i class="fa fa-user"></i>Užívatelia</a></router-link>
                <router-link tag="li" to="/technologies" active-class="active"><a><i class="fa fa-cogs"></i>Technológie</a></router-link>
                <router-link tag="li" to="/locations" active-class="active"><a><i class="fa fa-map-marker"></i>Lokality</a></router-link>
                <router-link tag="li" to="/finance/settings" active-class="active"><a><i class="fa fa-dollar"></i>Financie</a></router-link>
                <router-link tag="li" to="/invoice-series" active-class="active"><a><i class="fa fa-sort-numeric-asc"></i>Číselné rady faktúr</a></router-link>
                <router-link tag="li" to="/bank-accounts" active-class="active"><a><i class="fa fa-credit-card"></i>Bankové účty</a></router-link>
              </ul>
            </li>
          </ul>
          <!-- /.sidebar-menu -->
        </section>
        <!-- /.sidebar -->
      </aside>
    </div>

    <div class="content-wrapper">
      <router-view></router-view>
    </div>
    <footer class="main-footer hidden-print">
      <confirmation-modal :show-model="showConfirmationModal" :text="confirmationText" @cancel="confirmationCancel()" @confirm="confirmationDone()"></confirmation-modal>
      <span class="text-muted pull-right">Copyright &copy; 2017 <a href="http://www.smartbrains.cz" target="_blank">Smartbrains solutions s.r.o.</a> </span>
      <div class="clearfix"></div>
    </footer>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
  import confirmationModal from 'components/confirmationModal';
  import { DEFAULT_APP_COLOR } from 'config';
  import eventBus from 'services/eventBus';
  import i18n from 'i18n/';
  import { mapGetters, mapState } from 'vuex';
  import navbar from './navbar';

  export default {
    components: { confirmationModal, navbar },
    data: () => ({
      showConfirmationModal: false,
      confirmationText: null,
      placement: 'top',
      type: 'default'
    }),
    computed: {
      ...mapState({
        userInfo: (state) => state.user.info
      }),
      ...mapGetters({
        loggedIn: 'auth/loggedIn',
        hasRole: 'auth/hasRole'
      }),
      countOfActivities () {
        return this.userInfo ? this.userInfo.activitiesAllUnresolved + this.userInfo.activitiesUserUnresolved : 0;
      }
    },
    methods: {
      confirmationCancel () {
        eventBus.$emit('cancelled');
        this.showConfirmationModal = false;
      },
      confirmationDone () {
        eventBus.$emit('confirmed');
        this.showConfirmationModal = false;
      }
    },
    async mounted () {
      if (this.loggedIn) {
        await this.$store.dispatch('user/fetchInfo');
      }
      const configuration = await this.$store.dispatch('app/loadConfiguration');

      let color = `${configuration.application ? (configuration.application.color || DEFAULT_APP_COLOR) : DEFAULT_APP_COLOR}`;
      $('body').addClass(`skin-${color}`);
      $('head').append(`<link rel="icon" type="image/png" sizes="32x32" href="/favicon-${color}.png">`);

      eventBus.$on('confirmAction', (confirmationTextKey, args) => {
        this.showConfirmationModal = true;
        this.confirmationText = i18n.message(confirmationTextKey, args);
      });
    }
  };
</script>
