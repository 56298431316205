import Vue from 'vue';
import moment from 'moment';
import { OAUTH_CLIENT_ID, OAUTH_SECRET } from '../config';

const LOGIN_URL = '/oauth/token';
const LOGOUT_URL = '/api/logout';
const REDIRECT_TO_PARAM = 'redirectTo';

export default {
  async login (credentials) {
    const params = {
      'grant_type': 'password',
      'scope': 'read write',
      'username': credentials.username,
      'password': credentials.password,
      'client_id': OAUTH_CLIENT_ID,
      'client_secret': OAUTH_SECRET
    };

    const AUTH_BASIC_HEADERS = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + window.btoa(OAUTH_CLIENT_ID + ':' + OAUTH_SECRET)
      },
      emulateJSON: true
    };

    const response = await Vue.http.post(LOGIN_URL, params, AUTH_BASIC_HEADERS);

    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      validTo: moment().add(response.data.expires_in, 's')
    };
  },
  async logOut () {
    return await Vue.http.get(LOGOUT_URL);
  },
  loginPath (route) {
    let path = '/login';
    if (route.query[REDIRECT_TO_PARAM]) {
      path += `?${REDIRECT_TO_PARAM}=${route.query[REDIRECT_TO_PARAM]}`;
    } else if (route.name !== 'login') {
      path += `?${REDIRECT_TO_PARAM}=${encodeURIComponent(route.fullPath)}`;
    }
    return path;
  },
  mainPath (currentRoute) {
    return currentRoute.query[REDIRECT_TO_PARAM] ? decodeURIComponent(currentRoute.query[REDIRECT_TO_PARAM]) : '/';
  }
};
