import Entity from '../../../domain/entity';
import Document from 'modules/document/domain/document';

class Order extends Entity {
  constructor (data) {
    super(data);
    this.number = this.number || null;
    this.documents = data.documents ? data.documents.map(document => new Document(document)) : [];
  }

}

export default Order;
