import leadService from '../service/leadService';
import * as types from './mutationTypes';
import _ from 'lodash';

const actions = {
  async select ({ dispatch, commit }, id) {
    await commit(types.LEAD_SELECT, {
      item: null
    });
    dispatch('app/loadingDataEnable', 'lead', { root: true });
    const lead = await leadService.find(id);
    await commit(types.LEAD_SELECT, {
      item: lead
    });
    dispatch('app/loadingDataDisable', 'lead', { root: true });
  },
  async clear({ commit }) {
    await commit(types.LEAD_CLEAR);
  },
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'leads', { root: true });
      const leads = await leadService.findAll();
      await commit(types.LEAD_LIST, {
        items: leads
      });
      dispatch('app/loadingDataDisable', 'leads', { root: true });
    }
  },
  async create ({ dispatch }, lead) {
    try {
      const newLead = await leadService.create(lead);
      if (newLead) {
        await dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        return newLead;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch, commit }, lead) {
    try {
      const updatedLead = await leadService.update(lead);
      if (updatedLead) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        await commit(types.LEAD_UPDATE, {
          item: lead
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ dispatch }, id) {
    const response = await leadService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      dispatch('subject/getAll', true, { root: true });
      return true;
    } else {
      return false;
    }
  },
  async convert ({ dispatch, commit }, lead) {
    try {
      const convertedCompany = await leadService.convert(lead);
      if (convertedCompany) {
        dispatch('subject/getAll', true, { root: true });
        await dispatch('getAll', true);
        return convertedCompany;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  }
};

export default actions;
