<template>
  <page v-if="lead" :title="lead.name">
    <lead-modal :show-model="showCreateModal" :model="selectedLead" @close="hideModals()"></lead-modal>
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i class="fa fa-gear"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a role="button" @click.prevent="editLead(lead)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                  <li><a role="button" @click.prevent="deleteLead(lead)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>
                  <li>
                    <a role="button" @click.prevent="toggleLeadActive(lead)">
                      <i :class="{ 'fa-toggle-on': lead.active, 'fa-toggle-off text-success': !lead.active }" class="fa"></i> {{ lead.active ? 'Deaktivovať' : 'Aktivovať' }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="summary">
              <div class="pull-left labels">
                <p>IČ</p>
                <p>DIČ</p>
                <p>Adresa</p>
                <p v-if="hasRoles(lead)">Roly</p>
                <p>Aktivní</p>
                <p v-if="lead.web">Web</p>
                <p v-if="hasLocation(lead)">Lokality</p>
                <p v-if="hasTechnology(lead)">Technológie</p>
                <p v-if="lead.note">Poznámka</p>
              </div>
              <div class="pull-left values">
                <p>{{ lead.companyId }}</p>
                <p>{{ lead.vatId || '---' }}</p>
                <p>{{ lead && lead.address ? lead.address.format() : null }}</p>
                <p v-if="hasRoles(lead)">
                  <span class="" v-for="(role, index) in lead.roles" :title="role">{{ role | codeListValue(subjectRoles) }}<span v-if="lead.roles.length -1 !== index">, </span></span>
                </p>
                <p><i class="fa" :class="{'fa-check text-success': lead.active, 'fa-times text-danger': !lead.active}"></i>
                </p>
                <p v-if="lead.web"><a target="_blank" :href="lead.web">{{ lead.web }}</a></p>
                <p v-if="hasLocation(lead)">
                  <span class="role-item badge" v-for="location in lead.locations">{{ location.label }}</span>
                </p>
                <p v-if="hasTechnology(lead)">
                  <span class="icon-item badge" v-for="technology in lead.technologies">{{ technology.label }}</span>
                </p>
                <p v-if="lead.note">{{ lead.note }}</p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">

            <ul id="tabs" class="nav nav-tabs" data-tabs="tabs">
              <router-link tag="li" :to="{ name: 'leadRecords', params: { id: lead.id }}" active-class="active"><a>Záznamy</a></router-link>
              <router-link tag="li" :to="{ name: 'leadContacts', params: { id: lead.id }}" active-class="active"><a>Kontakty</a></router-link>
              <router-link tag="li" :to="{ name: 'leadBankAccounts', params: { id: lead.id }}" active-class="active"><a>Bankové účty</a></router-link>
              <router-link tag="li" :to="{ name: 'leadDocuments', params: { id: lead.id }}" active-class="active"><a>Dokumenty</a></router-link>
              <router-link tag="li" :to="{ name: 'leadActivities', params: { id: lead.id }}" active-class="active"><a>Aktivity</a></router-link>
            </ul>

            <div id="my-tab-content" class="tab-content">
              <div class="tab-pane active">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </template>
    </box>
    <loading class="content-header" id="lead"></loading>
  </page>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import leadModal from './leadCreateModal';
  import notificationService from 'services/notificationService';
  import loading from 'components/loading';
  import leadService from '../service/leadService';
  import subjectRoles from 'codelist/subjectRoles';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { leadModal, loading, page, box },
    data: () => ({
      showCreateModal: false,
      selectedLead: null,
      subjectRoles: subjectRoles
    }),
    computed: mapState({
      lead: (state) => state.lead.selected
    }),
    methods: {
      hasLocation (subject) {
        return subject.locations && subject.locations.length;
      },
      hasTechnology (subject) {
        return subject.technologies && subject.technologies.length;
      },
      hasRoles (subject) {
        return subject.roles && subject.roles.length;
      },
      createShow () {
        this.selectedLead = leadService.newLead();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      deleteLead (lead) {
        this.confirm('lead.delete.confirmation', [lead.name]).then(() => {
          this.$store.dispatch('lead/delete', lead.id).then((result) => {
            if (result) {
              notificationService.success('lead.delete.success');
              this.$router.push('/leads');
            }
          }).catch(this.unprotect);
        });
      },
      toggleLeadActive (lead) {
        const action = lead.active ? 'deactivate' : 'activate';
        const leadCopy = _.cloneDeep(lead);
        leadCopy.active = !lead.active;
        this.$store.dispatch('lead/update', leadCopy).then((result) => {
          if (result) {
            notificationService.success('lead.' + action + '.success');
          } else {
            notificationService.error('error.lead.' + action);
          }
        });
      },
      editLead (lead) {
        this.selectedLead = _.cloneDeep(lead);
        this.showCreateModal = true;
      }
    },
    created () {
      this.$store.dispatch('lead/select', this.$store.state.route.params.id);
    },
    async destroyed() {
      await this.$store.dispatch('lead/clear');
    }
  };
</script>
