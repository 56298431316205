<template>
  <div>
    <box>
      <template #header>
        <h3 class="box-title">Cashflow</h3>
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-12">
            <form class="form-inline">
              <radio-field
                field-id="groupType"
                class="type-radio"
                v-model="filter.groupType"
                :options="groupTypes"
                validation="required">
              </radio-field>
              <date-range-field v-model="filter.range" :max-date="maxDate"></date-range-field>
              <button class="btn btn-success" type="button" @click="fetch()" :disabled="!filter.range">Načítať</button>
            </form>
          </div>
        </div>

        <div class="row" v-if="sumItems">
          <div class="col-md-12">
            <hr>
            <bar-chart :chart-data="chartData" :label-callback="chartLabelCallback"
                       :before-label-callback="chartBeforeLabelCallback"
                       :after-label-callback="chartAfterLabelCallback"
                       tooltip-mode="index"></bar-chart>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr>
            <div class="table-responsive" v-if="sumItems" >
              <table class="table no-padding table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th v-for="date in dates" class="text-right text-nowrap">{{ date }}</th>
                    <th class="text-right text-nowrap">Celkom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Príjmy</td>
                    <td v-for="date in dates" class="text-right text-nowrap">
                      {{ itemByDate(date).totalAmountIncome | currency(currency) }}
                    </td>
                    <td class="text-right text-nowrap">{{ sumByProperty('totalAmountIncome') | currency(currency) }}</td>
                  </tr>
                  <tr>
                    <td>Výdaje</td>
                    <td v-for="date in dates" class="text-right text-nowrap">
                      {{ itemByDate(date).totalAmountOutcome | currency(currency) }}
                    </td>
                    <td class="text-right text-nowrap">{{ sumByProperty('totalAmountOutcome') | currency(currency) }}</td>
                  </tr>
                  <tr>
                    <td><strong>Celkom</strong></td>
                    <td v-for="date in dates" class="text-right text-nowrap">
                      <strong :class="sumClassObj(itemByDate(date).totalAmount)">{{ itemByDate(date).totalAmount | currency(currency) }}</strong>
                    </td>
                    <td class="text-right text-nowrap"><strong :class="sumClassObj(sumByProperty('totalAmount'))">{{ sumByProperty('totalAmount') | currency(currency) }}</strong></td>
                  </tr>
                  <tr>
                    <td><strong>CashFlow</strong></td>
                    <td v-for="date in dates" class="text-right text-nowrap">
                      <strong>{{ itemByDate(date).cashFlow | currency(currency) }}</strong>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <loading id="moneyMovements"></loading>
            <no-records :data="movements" loading-id="moneyMovements"></no-records>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <h3 class="box-title">Detailný prehľad dát</h3>
      </template>
      <template #body>
            <div class="row">
              <div class="col-md-2">
                <select-field field-id="dataSource"
                              label="Zdroj dát"
                              :codelist="true"
                              :options="dataSources"
                              v-model="filter.dataSource"></select-field>
              </div>
              <div class="col-md-10">
                <button class="btn btn-success btn-input-line" type="button" @click="recalculateDetailData()" :disabled="!filter.dataSource">Načítať</button>
              </div>
          </div>

        <div class="row">
          <div class="col-md-12">
            <hr>
            <div class="table-responsive" v-if="sumDetailItems">
              <table class="table no-padding table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th v-for="date in detailDates" class="text-right text-nowrap">{{ date }}</th>
                  <th class="text-right text-nowrap">Celkom</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="dataSourceValue in dataSourceValues">
                  <td class="text-nowrap">{{ dataSourceValue.label }}</td>
                  <td v-for="date in detailDates" class="text-right text-nowrap">
                    <i class="fa" :class="detailItemChangeClassObj(date, dataSourceValue.id)"></i>
                    <strong>{{ detailItemByDate(date, dataSourceValue.id) | currency(currency) }}</strong>
                  </td>
                  <td class="text-right text-nowrap">
                    <strong>{{ detailItemSum(dataSourceValue.id) | currency(currency) }}</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <no-records :data="dataSourceValues"></no-records>
      </template>
    </box>
  </div>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import {mapState} from 'vuex';
  import Range from 'components/range';
  import box from 'components/box';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import barChart from 'components/chart/barChart';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import currencyFilter from 'filters/currencyFilter';
  import i18n from 'i18n/';
  import radioField from 'components/form/radioField';
  import dateRangeField from 'components/form/dateRangeField';
  import selectField from 'components/form/selectField';
  import {FORMAT_SYSTEM_DATE, FORMAT_DATE, FORMAT_DATE_PERIOD_SLASH, FORMAT_DATE_WEEK_OF_YEAR, localeCompare} from 'utils';
  import {CHART_COLORS} from 'config';

  export default {
    components: {barChart, box, noRecords, loading, radioField, dateRangeField, selectField},
    mixins: [bookmarkableFilter],
    data: () => ({
      filter: {
        groupType: null,
        range: null,
        dataSource: null
      },
      groupTypes: [
        {value: 'month', label: 'Mesiace', dateFormat: FORMAT_DATE_PERIOD_SLASH},
        {value: 'week', label: 'Týždne', dateFormat: FORMAT_DATE_WEEK_OF_YEAR},
        {value: 'day', label: 'Dni', dateFormat: FORMAT_DATE}
      ],
      dataSources: [
        { value: 'CATEGORY', label: 'Kategórie', groupProperty: 'category', objectProperty: 'category' },
        { value: 'SUBJECT', label: 'Partneri', groupProperty: 'subject', objectProperty: 'subject' },
        { value: 'MONEY_BOX', label: 'Pokladne', groupProperty: 'moneyBox', objectProperty: 'moneyBox' }
      ],
      notDefined: {
        id: -1,
        label: i18n.message('label.notDefined')
      },
      maxDate: moment(),
      sumItems: null,
      cashFlowSum: null,
      sumDetailItems: null,
      dates: [],
      detailDates: [],
      dataSourceValues: [],
      sumItemTemplate: {
        totalAmountIncome: 0,
        totalAmountOutcome: 0,
        totalAmount: 0,
        cashFlow: 0
      }
    }),
    computed: {
      movements() {
        return this.$store.getters['moneyMovement/byFilter'](this.filter, this.sortData);
      },
      ...mapState({
        balances: (state) => state.moneyBox.balances
      }),
      chartData (state) {
        return {
          labels: this.dates,
          datasets: [
            {
              backgroundColor: CHART_COLORS.green,
              borderColor: CHART_COLORS.green,
              data: _.map(this.dates, label => this.itemByDate(label).cashFlow),
              label: i18n.message('label.cashFlow'),
              fill: false,
              type: 'line'
            },
            {
              backgroundColor: CHART_COLORS.red,
              borderColor: CHART_COLORS.red,
              data: _.map(this.dates, label => this.itemByDate(label).totalAmount),
              label: i18n.message('label.totalAmount'),
              fill: false,
              type: 'line'
            },
            {
              backgroundColor: CHART_COLORS.turquoise,
              data: _.map(this.dates, label => this.itemByDate(label).totalAmountIncome),
              label: i18n.message('label.totalAmountIncome')
            },
            {
              backgroundColor: CHART_COLORS.blue,
              data: _.map(this.dates, label => Math.abs(this.itemByDate(label).totalAmountOutcome)),
              label: i18n.message('label.totalAmountOutcome')
            }
          ]
        };
      },
      chartLabelCallback () {
        return (tooltipItem, data) => currencyFilter(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], this.currency) || '';
      },
      chartBeforeLabelCallback () {
        return (tooltipItem, data) => data.datasets[tooltipItem.datasetIndex].label || '';
      },
      chartAfterLabelCallback () {
        return (tooltipItem, data) => (tooltipItem.datasetIndex + 1) < data.datasets.length ? ' ' : null;
      }
    },
    props: {
      currency: {
        type: String,
        required: true
      }
    },
    methods: {
      defaultFilter () {
        this.filter.groupType = 'month';
        this.filter.range = new Range(moment().subtract(5, 'month'), moment());
        this.filter.dataSource = 'CATEGORY';
      },
      itemByDate(date) {
        return this.sumItems[date];
      },
      sumByProperty(property) {
        return _(this.sumItems).values().map(property).sum() | 0;
      },
      async fetch() {
        this.clear();
        const filter = this.createFilter();
        await this.$store.dispatch('moneyMovement/findByFilter', filter);
        await this.$store.dispatch('moneyBox/balances',  {dateTo: FORMAT_SYSTEM_DATE(this.filter.range.from.subtract(1, 'd')), currency: filter.currency});
        this.recalculateData();
      },
      createFilter() {
        const dateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const dateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        return _.pickBy({
          dateFrom,
          dateTo,
          active: true,
          currency: this.currency
        }, _.identity);
      },
      recalculateData() {
        // calculate headers
        const range = moment.range(this.filter.range.from, this.filter.range.to);
        const groupType = _.find(this.groupTypes, {value: this.filter.groupType});
        const rangeDates = Array.from(range.by(this.filter.groupType));
        this.dates = _.map(rangeDates, date => groupType.dateFormat(date));

        // sum total CashFlow to dateFrom
        this.cashFlowSum = _(this.balances).map('totalAmount').sum();
        let cashFlowSum = this.cashFlowSum;
        // calculate data
        this.sumItems = _(this.movements)
          .groupBy(movement => _.find(this.groupTypes, {value: this.filter.groupType}).dateFormat(movement.date)) //group by date format of selected group type
          .map((movementsPerDate, dateLabel) => {
            const result = {
              dateLabel,
              totalAmountIncome: _(movementsPerDate).filter({type: 'INCOME'}).map('totalAmount').sum() || 0,
              totalAmountOutcome: _(movementsPerDate).filter({type: 'OUTCOME'}).map('totalAmount').sum() || 0,
              totalAmount: _(movementsPerDate).map('totalAmount').sum() || 0,
              cashFlow: cashFlowSum + (_(movementsPerDate).map('totalAmount').sum() || 0)
            };
            cashFlowSum = result.cashFlow;
            return result;
          })
          .keyBy('dateLabel')
          .value();

        // fill all missing dates because of cashFlow attribute
        cashFlowSum = this.cashFlowSum;
        this.dates.forEach(date => {
          if (!this.sumItems[date]) {
            this.sumItems[date] = _.cloneDeep(this.sumItemTemplate);
            this.sumItems[date].cashFlow = cashFlowSum;
          } else {
            cashFlowSum = cashFlowSum + this.sumItems[date].totalAmount;
          }
        });
      },
      sumClassObj: amount => ({
        'text-danger': amount < 0,
        'text-success': amount >= 0
      }),
      recalculateDetailData() {
        this.detailDates = [...this.dates];

        const groupProperty = _.find(this.dataSources, {value: this.filter.dataSource}).groupProperty;

        // extract uniq dataSource values and order by label
        this.dataSourceValues = _(this.movements)
          .map(movement => movement[groupProperty] ? movement[groupProperty] : this.notDefined)
          .uniqBy('id')
          .sort(localeCompare('label'))
          .value();

        // group data for detail table. Response data object will have dates as keys and object with id of grouped
        // property as keys a value as real value
        this.sumDetailItems = _(this.movements)
          .groupBy(movement => _.find(this.groupTypes, {value: this.filter.groupType}).dateFormat(movement.date)) //group by date format of selected group type
          .map((movementsPerDate, dateLabel) => {

            //group by dataSource.id, result will be
            // [{
            //    id: 4,
            //    value : XX
            //  },
            //  ...]
            //
            const itemForDate = _(movementsPerDate)
              .groupBy(movement => movement[groupProperty] ? movement[groupProperty].id : this.notDefined.id)
              .map((movementsPerGroupProp, propId) => ({
                id: propId,
                value: _(movementsPerGroupProp).map('totalAmount').sum() || 0
              }))
              .keyBy('id')
              .value();
            return {
              dateLabel,
              values: itemForDate
            };
          })
          .keyBy('dateLabel')
          .value();
      },
      detailItemByDate(date, dataSourceId) {
        return _.isNil(this.sumDetailItems[date]) || _.isNil(this.sumDetailItems[date].values[dataSourceId]) ?
          0 : this.sumDetailItems[date].values[dataSourceId].value;
      },
      // generate class object for icon showing value change direction
      detailItemChangeClassObj(date, dataSourceId) {
        // skip first column
        if (!this.detailDates.indexOf(date)) {
          return null;
        } else {
          const prevValue = this.detailItemByDate(this.detailDates[this.detailDates.indexOf(date) - 1], dataSourceId);
          const currentValue = this.detailItemByDate(date, dataSourceId);
          return {
            'fa-caret-up text-success': currentValue > prevValue,
            'fa-caret-down text-danger': currentValue < prevValue
          };
        }
      },
      // sum of values per specific dataSource
      detailItemSum(dataSourceId) {
        return _(this.sumDetailItems)
          .values()
          .map('values')
          .filter(item => !_.isNil(item[dataSourceId]))
          .map(dataSourceId)
          .map('value')
          .sum();
      },
      clear() {
        this.sumItems = null;
        this.cashFlowSum = null;
        this.sumDetailItems = null;
        this.dates = [];
        this.detailDates = [];
        this.dataSourceValues = [];
      }
    },
    watch: {
      //need to clear cashed data based on currency change, because different currency
      // pages are same route only with different value of currency parameter and destroy method
      // is not called
      async currency() {
        this.clear();
      }
    }
  };
</script>
