'use strict';

import authService from 'services/authService';

export default (store, router) => {
  return () => {
    // do logout action if 401 is returned
    return function (response) {
      if (response.status === 401) {
        store.dispatch('auth/logOut');
        router.push(authService.loginPath(router.currentRoute));
      }
    };
  };
};
