import cashMoneyMovementResource from './cashMoneyMovementResource';
import _ from 'lodash';
import CashMoneyMovement from "../domain/cashMoneyMovement";

export default {
  newCashMoneyMovement() {
    return new CashMoneyMovement({
      type: 'CASH'
    });
  },
  async create(moneyMovement) {
    const response = await cashMoneyMovementResource.save({}, _.pickBy(moneyMovement));
    if (response.ok) {
      return new CashMoneyMovement(response.data);
    } else {
      return null;
    }
  },
  async update(moneyMovement) {
    const response = await cashMoneyMovementResource.update({}, _.pickBy(moneyMovement));
    if (response.ok) {
      return new CashMoneyMovement(response.data);
    } else {
      return null;
    }
  },
  async delete(id) {
    return cashMoneyMovementResource.delete({id: id});
  }
};
