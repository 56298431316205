import Vue from 'vue';
import { API_ROOT } from '../config';

const contextPath = 'tm-delivery';

const customActions = {
  updateDocuments: { method: 'PUT', url: API_ROOT + contextPath + '/update-documents' },
  pay: { method: 'PUT', url: API_ROOT + contextPath + '/pay' },
  generateAgreementDocument: { method: 'GET', url: API_ROOT + contextPath + '{/id}/generate-agreement', responseType: 'blob' }
};

export default Vue.resource(API_ROOT + contextPath + '{/id}', {}, customActions);
