<template>
  <div>
    <bank-movement-create-modal :show-model="showBankMovementCreateModal" :model="selectedBankMoneyMovement" @close="hideModals()"></bank-movement-create-modal>
    <bank-movement-split-modal :show-model="showBankMovementSplitModal" :model="selectedBankMoneyMovement" @close="hideModals()"></bank-movement-split-modal>
    <cash-movement-create-modal :show-model="showCashMovementCreateModal" :model="selectedCashMoneyMovement" @close="hideModals()"></cash-movement-create-modal>
    <table class="table table-responsive no-padding table-hover dataTable" v-if="movements && movements.length">
      <thead>
      <tr class="bg-gray">
        <the label="Dátum" property="date" :sort-data="sortData" @sort="sort($event)"></the>
        <th colspan="2">Pohyb</th>
        <th></th>
        <the label="Celkom" property="totalAmount" :sort-data="sortData" @sort="sort($event)" class="text-right text-nowrap"></the>
        <th class="text-right text-nowrap">Z toho DPH</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="moneyMovement in movements" :class="{ inactive : !moneyMovement.active }">
        <td>{{ moneyMovement.date | date }}</td>
        <td><i
          :class="{'fa-arrow-down text-danger': moneyMovement.type === 'OUTCOME', 'fa-arrow-up text-success': moneyMovement.type === 'INCOME'}"
          class="fa"></i></td>
        <td>
          <movement-description :movement="moneyMovement" :show-icon="false"></movement-description>
        </td>
        <td class="text-middle">
          <i v-if="moneyMovement.invoice" class="fa fa-paperclip" :title="moneyMovement.invoice.number"></i>
        </td>
        <td class="text-right text-nowrap" :class="{'text-danger' : moneyMovement.type === 'OUTCOME', 'text-success' : moneyMovement.type === 'INCOME'}">
          {{ moneyMovement.totalAmount | currency(moneyMovement.moneyBox.currency) }}
        </td>
        <td class="text-right text-nowrap" v-if="moneyMovement.fiscal">
          <span>{{ moneyMovement.totalVat * -1 | currency(moneyMovement.moneyBox.currency) }}</span>
          <div class="text-description" v-if="moneyMovement.taxableDate">DUZP: {{ moneyMovement.taxableDate | date }}</div>
        </td>
        <td class="text-center text-nowrap" v-else>
          -
        </td>
        <td class="text-right">
          <button class="btn btn-link" v-if="isSplittable(moneyMovement)" @click.stop="splitBankMoneyMovement(moneyMovement)"><i class="fa fa-cut  text-muted"></i></button>
          <button class="btn btn-link" v-if="moneyMovement.moneyBox.type === 'CASH'" @click.stop="editCashMoneyMovement(moneyMovement)"><i class="fa fa-pencil text-muted"></i></button>
          <button class="btn btn-link" v-else @click.stop="editBankMoneyMovement(moneyMovement)"><i class="fa fa-pencil text-muted"></i></button>
        </td>
      </tr>
      </tbody>
    </table>

    <loading id="moneyMovements"></loading>
    <no-records :data="movements" loading-id="moneyMovements"></no-records>
  </div>
</template>
<script>
  import the from 'components/the';
  import bankMovementCreateModal from './bankMovementCreateModal';
  import bankMovementSplitModal from './bankMovementSplitModal';
  import cashMovementCreateModal from './cashMovementCreateModal';
  import movementDescription from './moneyMovementDescrtiption';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import {mapState} from 'vuex';

  export default {
    components: {the, bankMovementCreateModal, cashMovementCreateModal, bankMovementSplitModal, movementDescription, loading, noRecords},
    data: () => ({
      showBankMovementCreateModal: false,
      showBankMovementSplitModal: false,
      showCashMovementCreateModal: false,
      selectedBankMoneyMovement: null,
      selectedCashMoneyMovement: null
    }),
    computed: {
      ...mapState({
        moneyMovementCategories: (state) => state.moneyMovementCategory.items
      })
    },
    props: {
      movements: {
        type: Array,
        required: true
      },
      sortData: {
        type: Object
      }
    },
    methods: {
      sort(sortData) {
        this.sortData.by = sortData.by;
        this.sortData.asc = sortData.asc;
      },
      editBankMoneyMovement(moneyMovement) {
        this.selectedBankMoneyMovement = _.cloneDeep(moneyMovement);
        this.showBankMovementCreateModal = true;
      },
      splitBankMoneyMovement(moneyMovement) {
        this.selectedBankMoneyMovement = _.cloneDeep(moneyMovement);
        this.showBankMovementSplitModal = true;
      },
      editCashMoneyMovement(moneyMovement) {
        this.selectedCashMoneyMovement = _.cloneDeep(moneyMovement);
        this.showCashMovementCreateModal = true;
      },
      hideModals() {
        this.showBankMovementCreateModal = false;
        this.showBankMovementSplitModal = false;
        this.showCashMovementCreateModal = false;
      },
      isSplittable(moneyMovement) {
        return moneyMovement.moneyBox.type === 'BANK'
          && moneyMovement.type === 'INCOME'
          && moneyMovement.active
          && !moneyMovement.invoice;
      }
    }
  };
</script>
