import * as types from './mutationTypes';
import invoiceService from '../service/invoiceService';

const actions = {
  async select ({ dispatch, commit }, id) {
    await commit(types.INVOICE_SELECT, {
      item: null
    });
    dispatch('app/loadingDataEnable', 'invoice', { root: true });
    const invoice = await invoiceService.find(id);
    await commit(types.INVOICE_SELECT, {
      item: invoice
    });
    dispatch('app/loadingDataDisable', 'invoice', { root: true });
    return invoice;
  },
  async findByFilter ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'invoices', { root: true });
    await commit(types.INVOICE_LIST_FILTERED, {
      filter: null,
      items: []
    });
    const invoices = await invoiceService.findByFilter(filter);
    await commit(types.INVOICE_LIST_FILTERED, {
      filter: filter,
      items: invoices
    });
    dispatch('app/loadingDataDisable', 'invoices', { root: true });
  },
  async refresh ({ dispatch, state }) {
    dispatch('findByFilter', state.filter);
  },
  async clear ({ commit}) {
    await commit(types.INVOICE_LIST_CLEAR);
  },
  async create ({ dispatch, state }, invoice) {
    const newInvoice = await invoiceService.create(invoice);
    return newInvoice || false;
  },
  async update ({ dispatch, state, commit }, invoice) {
    const updatedInvoice = await invoiceService.update(invoice);
    return updatedInvoice || false;
  },
  async delete ({ dispatch }, id) {
    return await invoiceService.delete(id);
  }
};

export default actions;
