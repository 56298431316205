import { SUBJECT_LIST } from './mutationTypes';
import subjectService from 'modules/subject/service/subjectService';

const actions = {
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'subjects', { root: true });
      const subjects = await subjectService.findAll();
      await commit(SUBJECT_LIST, {
        items: subjects
      });
      dispatch('app/loadingDataDisable', 'subjects', { root: true });
    }
  }
};

export default actions;
