import invoiceSeriesResource from './invoiceSeriesResource';
import _ from 'lodash';
import InvoiceSeries from '../domain/invoiceSeries';

export default {
  newInvoiceSeries () {
    return new InvoiceSeries({});
  },
  async findAll () {
    const response = await invoiceSeriesResource.query();
    if (response.ok) {
      return response.data.map((invoiceSeriesData) => new InvoiceSeries(invoiceSeriesData));
    } else {
      return null;
    }
  },
  async create (invoiceSeries) {
    const response = await invoiceSeriesResource.save({}, _.pickBy(invoiceSeries));
    if (response.ok) {
      return new InvoiceSeries(response.data);
    } else {
      return null;
    }
  },
  async update (invoiceSeries) {
    const response = await invoiceSeriesResource.update({}, _.pickBy(invoiceSeries));
    if (response.ok) {
      return new InvoiceSeries(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return invoiceSeriesResource.delete({ id: id });
  }
};
