export default {
  methods: {
    recordIcon (recordType) {
      switch (recordType) {
        case 'EMAIL_COMMUNICATION':
          return 'fa fa-envelope bg-blue';
        case 'PHONE_COMMUNICATION':
          return 'fa fa-phone bg-green';
        case 'PERSONAL_MEETING':
          return 'fa fa-users bg-purple';
        case 'COMMENT':
          return 'fa fa-info-circle bg-yellow';
      }
    }
  }
};
