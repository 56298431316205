import actions from './invoiceSeriesActions';
import getters from './invoiceSeriesGetters';
import mutations from './invoiceSeriesMutations';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
