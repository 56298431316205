import eventBus from '../services/eventBus';

export default () => {
  function keyDownHandler (event) {
    if (event.key === 'Escape') {
      eventBus.$emit('close');
    } else if (event.ctrlKey && event.key === 'Enter') {
      eventBus.$emit('submit');
    }
  }
  window.addEventListener('keydown', keyDownHandler);
};
