<template>
  <div v-if="split.originalMovement">
    <modal :backdrop="false" v-model="show" @ok="submit()" @cancel="cancel($event)" @closed="onClose()"
           title="Rozdeliť pohyb" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">

      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <static-field label="Dátum">
              {{ split.originalMovement.date | dateTime }}
            </static-field>
          </div>
          <div class="col-md-6" v-if="split.originalMovement.subject">
            <static-field label="Subjekt">
              {{ split.originalMovement.subject.format() }}
            </static-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <static-field label="Celkom ">
              <strong class="text-success">{{ split.originalMovement.totalAmount |
                currency(split.originalMovement.moneyBox.currency) }}</strong>
            </static-field>
          </div>
          <div class="col-md-6">
            <static-field label="Zostáva rozdeliť"
                          :class="{'text-success': remainingAmount === 0, 'text-danger': remainingAmount < 0}">
              {{ remainingAmount | currency(split.originalMovement.moneyBox.currency) }}
            </static-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1"><p>#</p></div>
          <div class="col-md-6"><p>Doklad</p></div>
          <div class="col-md-4"><p>Čiastka</p></div>
          <div class="col-md-1"></div>
        </div>
        <div class="row" v-for="(movement, index) in split.newMovements">
          <div class="col-md-1">
            <strong>{{ index + 1 }}</strong>
          </div>
          <div class="col-md-6">
            <select-field
              :options="possibleInvoices(movement)"
              :field-id="'invoice-' + index"
              v-model="movement.invoice">
            </select-field>
          </div>
          <div class="col-md-4">
            <number-field v-model="movement.totalAmount"
                          placeholder="Zadaj sumu"
                          field-id="totalAmount"
                          :field-id="'totalAmount-' + index"
                          :step="100"
                          :suffix="split.originalMovement.moneyBox.currency | codeListValue(currencies)"
                          :validation-obj="{required: true, decimal: 2, min_value:0.01, max_value: movement.invoice ? movement.invoice.unpaidAmount : 99999999.99}">

            </number-field>
          </div>
          <div class="col-md-1">
            <a role="button" v-show="split.newMovements.length > 2" @click.prevent="deleteMovement(movement)"><i
              class="fa fa-2x fa-times text-danger"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-success" type="button" @click.prevent="addMovement()"><i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <text-field v-model="split.note" label="Poznámka" field-id="note" :maxlength="300"
                        validation="max:300"></text-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>
<script>
  import bankMoneyMovementService from "../service/bankMoneyMovementService";
  import {currencies} from 'codelist/';
  import {mapState} from 'vuex';
  import modal from 'vue-strap/src/Modal';
  import modalMixin from 'components/modalMixin';
  import notificationService from 'services/notificationService';
  import numberField from 'components/form/numberField';
  import { RELATIONS } from 'domain/entity';
  import staticField from 'components/form/staticField';
  import selectField from 'components/form/selectField';
  import textField from 'components/form/textField';
  import validationMixin from 'components/validationMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: {modal, vForm, textField, selectField, staticField, numberField},
    data: () => ({
      split: {
        originalMovement: null,
        newMovements: [],
        note: null,
        [RELATIONS]: ['newMovements', 'originalMovement']
      },
      currencies,
    }),
    mixins: [validationMixin, modalMixin],
    computed: {
      ...mapState({
        invoices (state) {
          let currency = this.split.originalMovement.moneyBox.currency;
          return _.filter(state.invoice.items, invoice => {
            const currencyMatch = invoice.currency === currency;
            const subjectMatch = !this.split.originalMovement.subject || this.split.originalMovement.subject.id === invoice.subscriber.id;
            const minTotalAmount = invoice.unpaidAmount > 0;
            return currencyMatch && subjectMatch && minTotalAmount;
          });
        }
      }),
      remainingAmount () {
        return this.split.originalMovement.totalAmount - _(this.split.newMovements).map('totalAmount').compact().map(parseFloat).sum() || 0;
      }
    },
    methods: {
      onSubmit () {
        if (this.remainingAmount !== 0) {
          notificationService.error('error.bankMoneyMovement.remainingAmount.notZero');
          return this.unprotect();
        }

        this.$store.dispatch('bankMoneyMovement/split', this.split).then((result) => {
          if (result) {
            notificationService.success('moneyMovement.split.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      //don't offer invoices with lover unpaid amount than invoice amount or invoice twice
      possibleInvoices (movement) {
        return _(this.invoices).filter(invoice => invoice.unpaidAmount >= movement.totalAmount)
          .without(...(_.map(this.split.newMovements, 'invoice')))
          .value();
      },
      addMovement () {
        this.split.newMovements.push(bankMoneyMovementService.newMovement(this.split.originalMovement));
      },
      deleteMovement (movement) {
        this.split.newMovements = _.without(this.split.newMovements, movement);
      },
      clearData () {
        this.split.originalMovement = null;
        this.split.newMovements = [];
        this.split.note = null;
      }
    },
    watch: {
      model: function (model) {
        this.split.originalMovement = _.cloneDeep(model);
        this.split.newMovements = [
          bankMoneyMovementService.newMovement(this.split.originalMovement),
          bankMoneyMovementService.newMovement(this.split.originalMovement)
        ];
      },
      'split.newMovements': {
        handler: (newMovements, oldNewMovements) => {
          if (newMovements.length === oldNewMovements.length) {
            newMovements.forEach((newMovement, index) => {
              if (newMovement.invoice && parseFloat(newMovement.totalAmount) <= 0) {
                newMovement.totalAmount = newMovement.invoice.unpaidAmount;
              }
            });
          }
        },
        deep: true
      }
    }
  };
</script>
