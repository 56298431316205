import tmDeliveryResource from '../resources/tmDeliveryResource';
import _ from 'lodash';
import TmDelivery from '../domain/tmDelivery';
import {DOWNLOAD_BLOB} from '../utils';

export default {
  newTmDelivery (data) {
    const tmDelivery = new TmDelivery(data);
    tmDelivery.status = 'NEW';
    return tmDelivery;
  },
  // delivery items with 0 itemNumber are not persistent. So every time during creation or update, generation
  // of delivery item list need to be performed
  generateItems (delivery, order) {
    if (!order) {
      delivery.optionalItems.length = 0;
      return;
    }

    // remove all invalid items
    _.remove(delivery.optionalItems, deliveryItem => !_.some(order.items, { id: deliveryItem.orderItem.id }));
    // add all new missing items
    order.items.forEach(orderItem => {
      if (_.every(delivery.optionalItems, deliveryItem => deliveryItem.orderItem.id !== orderItem.id)) {
        delivery.optionalItems.push(
          {
            orderItem,
            itemNumber: 0
          }
        );
      }
    });
    // sort items
    delivery.optionalItems = _.sortBy(delivery.optionalItems, 'orderItem.name');
  },
  async findAll (filter) {
    const response = await tmDeliveryResource.query(filter);
    if (response.ok) {
      return response.data.map((tmDeliveryData) => new TmDelivery(tmDeliveryData));
    } else {
      return null;
    }
  },
  async find (id) {
    const response = await tmDeliveryResource.get({ id: id });
    if (response.ok) {
      return new TmDelivery(response.data);
    } else {
      return null;
    }
  },
  async create (tmDelivery) {
    const response = await tmDeliveryResource.save({}, _.pickBy(tmDelivery));
    if (response.ok) {
      return new TmDelivery(response.data);
    } else {
      return null;
    }
  },
  async update (tmDelivery) {
    const response = await tmDeliveryResource.update({}, _.pickBy(tmDelivery));
    if (response.ok) {
      return new TmDelivery(response.data);
    } else {
      return null;
    }
  },
  async updateDocuments (tmDelivery) {
    const response = await tmDeliveryResource.updateDocuments({}, _.pickBy(tmDelivery));
    if (response.ok) {
      return new TmDelivery(response.data);
    } else {
      return null;
    }
  },
  async pay (tmRewardIds) {
    const response = await tmDeliveryResource.pay({}, tmRewardIds);
    return !!response.ok;
  },
  async delete (id) {
    return tmDeliveryResource.delete({ id: id });
  },
  async generateAgreementDocument (id, amount, fileName) {
    await tmDeliveryResource.generateAgreementDocument({ id, amount })
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  }
};
