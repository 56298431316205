import * as types from './mutationTypes';
import currencyRateService from '../service/currencyRateService';

const actions = {
  async findByFilter ({ dispatch, commit, state }, filter) {
    await commit(types.CURRENCY_RATE_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'currencyRates', { root: true });
    const currencyRates = await currencyRateService.findByFilter(filter);
    await commit(types.CURRENCY_RATE_LIST, {
      filter: filter,
      items: currencyRates
    });
    dispatch('app/loadingDataDisable', 'currencyRates', { root: true });
  },
  async download ({ dispatch, state }, filter) {
    const result = await currencyRateService.download(filter);
    if (result) {
      await dispatch('findByFilter', state.filter);
    }
    return result;
  }
};

export default actions;
