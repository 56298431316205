<template>
  <div v-if="transaction">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia pohybu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <date-field v-model="transaction.date" field-id="date" label="Dátum" validation="required"></date-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <number-field v-model="transaction.amount" label="Suma" placeholder="Zadaj sumu" field-id="amount" :step="100" validation="required|decimal:2" ></number-field>
          </div>
          <div class="col-md-6">
            <select-field
              :options="currencies"
              v-model="transaction.currency"
              :codelist="true"
              field-id="currency"
              label="Mena"
              required
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field v-model="transaction.note" label="Poznámka" field-id="note" :maxlength="300" validation="max:300" css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import { currencies } from 'codelist/';
  import notificationService from 'services/notificationService';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import dateField from 'components/form/dateField';
  import textAreaField from 'components/form/textAreaField';
  import validationMixin from 'components/validationMixin';
  import Transaction from '../domain/transaction';
  import modalMixin from '../../../components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, numberField, textAreaField, dateField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      currencies,
      transaction: null
    }),
    methods: {
      onSubmit () {
        if (this.transaction.amount === '0') {
          return notificationService.error('error.transaction.amount.zero');
        }

        let operation;
        if (this.transaction.id) {
          operation = 'transaction/update';
        } else {
          operation = 'transaction/create';
        }
        const transaction = _.cloneDeep(this.transaction);
        transaction.type = transaction.amount > 0 ? 'INCOMING' : 'OUTGOING';
        this.$store.dispatch(operation, transaction).then((result) => {
          if (result) {
            notificationService.success('transaction.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.transaction = null;
      }
    },
    watch: {
      model: function (model) {
        this.transaction = new Transaction(model);
      }
    }
  };
</script>
