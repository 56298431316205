'use strict';

import numeral from 'numeral';
import _ from 'lodash';

export default (value, precision) => {
  if (_.isUndefined(value) || value === null) {
    return null;
  } else {
    return numeral(value).format('0,0[.][' + _.repeat('0', precision || 2) + ']');
  }
};
