import transactionResource from './transactionResource';
import _ from 'lodash';
import moment from 'moment/moment';
import Transaction from '../domain/transaction';
import { DOWNLOAD_BLOB_TEXT_FILE } from '../../../utils';

export default {
  newTransaction (account) {
    return new Transaction({ account: account, date: moment() });
  },
  async findByFilter (filter) {
    const response = await transactionResource.query(filter);
    if (response.ok) {
      return response.data.map((transactionData) => new Transaction(transactionData));
    } else {
      return null;
    }
  },
  async create (transaction) {
    const response = await transactionResource.save({}, _.pickBy(transaction));
    if (response.ok) {
      return new Transaction(response.data);
    } else {
      return null;
    }
  },
  async update (transaction) {
    const response = await transactionResource.update({}, _.pickBy(transaction));
    if (response.ok) {
      return new Transaction(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return transactionResource.delete({ id: id });
  },
  async exportTransactions (filter, fileName) {
    await transactionResource.exportTransactions(filter)
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB_TEXT_FILE(data, fileName));
  }
};
