'use strict';

import notificationService from 'services/notificationService';
import authService from 'services/authService';

export default (store) => {
  return (to, from, next) => {
    if (to.meta.requiresLoggedIn && !store.getters['auth/loggedIn']) {
      next(authService.loginPath(to));
    } else if (to.meta.requiresRole && !store.getters['auth/hasRole'](to.meta.requiresRole)) {
      notificationService.error('error.auth.invalid');
    } else {
      next();
    }
  };
};
