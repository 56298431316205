import Entity from 'domain/entity';

class User extends Entity {
  constructor (data) {
    super(data);

    this.username = this.username || null;
    this.password = this.password || null;
    this.firstname = this.firstname || null;
    this.surname = this.surname || null;
    this.email = this.email || null;
    this.phoneNumber = this.phoneNumber || null;
    this.roles = this.roles || [];

    this.value = this.id;
    this.label = this.fullName;
  }

  get fullName () {
    return this.firstname + ' ' + this.surname;
  }
}

export default User;
