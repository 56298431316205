'use strict';

import miniToastr from 'mini-toastr';
import i18n from 'i18n/';

export default {
  init () {
    // If using mini-toastr, provide additional configuration
    const toastTypes = {
      success: 'success',
      error: 'error',
      info: 'info',
      warn: 'warn'
    };

    miniToastr.init({ types: toastTypes, timeout: 5000});
  },

  info (key, defaultMessage) {
    miniToastr.info(i18n.message(key, defaultMessage));
  },
  success (key, defaultMessage) {
    miniToastr.success(i18n.message(key, defaultMessage));
  },
  error (key, args, defaultMessage) {
    miniToastr.error(i18n.message(key, args, defaultMessage));
  }
};
