import Entity from '../../../domain/entity';

class TmOrderItem extends Entity {
  constructor (data) {
    super(data);
    this.name = this.name || null;
    this.price = this.price || null;
  }
}

export default TmOrderItem;
