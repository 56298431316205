<template>
  <activities :entity="lead" :entity-type="'subject'"></activities>
</template>

<script>
  import activities from 'modules/activity/components/activities';
  import { mapState } from 'vuex';

  export default {
    components: { activities },
    computed: mapState({
      lead: (state) => state.lead.selected
    })
  };
</script>
