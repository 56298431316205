import * as types from './mutationTypes';
import financeSummaryService from '../service/financeSummaryService';

const actions = {
  async getVatSummaries ({ state, dispatch, commit }, currency) {
    dispatch('app/loadingDataEnable', 'financeVatSummaries', { root: true });
    const vatSummaries = await financeSummaryService.getVatSummaries(currency);
    await commit(types.FINANCE_SUMMARY_VAT_LIST, {
      items: vatSummaries
    });
    dispatch('app/loadingDataDisable', 'financeVatSummaries', { root: true });
  },
  async getTaxBaseSummaries ({ state, dispatch, commit }, currency) {
    dispatch('app/loadingDataEnable', 'financeTaxBaseSummaries', { root: true });
    const vatSummaries = await financeSummaryService.getTaxBaseSummaries(currency);
    await commit(types.FINANCE_SUMMARY_TAX_BASE_LIST, {
      items: vatSummaries
    });
    dispatch('app/loadingDataDisable', 'financeTaxBaseSummaries', { root: true });
  }
};

export default actions;
