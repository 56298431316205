import { INVOICE_SERIES_ACTIVATE, INVOICE_SERIES_DEACTIVATE, INVOICE_SERIES_LIST, INVOICE_SERIES_UPDATE } from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [INVOICE_SERIES_LIST] (state, action) {
    state.items = action.items;
  },
  [INVOICE_SERIES_UPDATE] (state, action) {
    // let toBeUpdated = _.find(state.items, {id: action.item.id});
    // when array, replace it at all - vue problem
    // _.mergeWith(toBeUpdated, action.item, MERGE);
    // state.selected = toBeUpdated;
    state.selected = _.find(state.items, { id: action.item.id });
  },
  [INVOICE_SERIES_ACTIVATE] (state, id) {
    const item = _.find(state.items, { id: id });
    if (item) {
      item.active = true;
    }
  },
  [INVOICE_SERIES_DEACTIVATE] (state, id) {
    const item = _.find(state.items, { id: id });
    if (item) {
      item.active = false;
    }
  }
};

export default mutations;
