<template>
  <page v-if="employee" :title="employee.name">
    <employee-modal :show-model="showCreateModal" :model="selectedEmployee" @close="hideModals()"></employee-modal>
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i class="fa fa-gear"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a role="button" @click="editEmployee(employee)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                  <li><a role="button" @click="deleteEmployee(employee)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>
                  <li><a role="button" @click.stop="toggleEmployeeActive(employee)"><i :class="{'fa-toggle-on': employee.active, 'fa-toggle-off text-success': !employee.active}" class="fa"></i> {{ employee.active ? 'Deaktivovať' : 'Aktivovať' }}</a></li>
                </ul>
              </div>
            </div>

            <div class="summary">
              <div class="pull-left labels">
                <p>Meno</p>
                <p>Priezvisko</p>
                <p>Rodné číslo</p>
                <p>Adresa</p>
                <p>Aktivní</p>
                <p v-if="hasLocation(employee)">Lokality</p>
                <p v-if="hasTechnology(employee)">Technológie</p>
                <p v-if="employee.note">Poznámka</p>
              </div>
              <div class="pull-left values">
                <p>{{ employee.firstName }}</p>
                <p>{{ employee.surname }}</p>
                <p>{{ employee.personalId }}</p>
                <p>{{ employee.address.format() }}</p>
                <p><i class="fa" :class="{'fa-check text-success': employee.active, 'fa-times text-danger': !employee.active}"></i></p>
                <p v-if="hasLocation(employee)">
                  <span class="role-item badge" v-for="location in employee.locations">{{ location.label }}</span>
                </p>
                <p v-if="hasTechnology(employee)">
                  <span class="icon-item badge" v-for="technology in employee.technologies">{{ technology.label }}</span>
                </p>
                <p v-if="employee.note">{{ employee.note }}</p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">

            <ul id="tabs" class="nav nav-tabs" data-tabs="tabs">
              <router-link tag="li" :to="{ name: 'employeeRecords', params: { id: employee.id }}" active-class="active"><a>Záznamy</a></router-link>
              <router-link tag="li" :to="{ name: 'employeeContacts', params: { id: employee.id }}" active-class="active"><a>Kontakty</a></router-link>
              <router-link tag="li" :to="{ name: 'employeeAccount', params: { id: employee.id }}" active-class="active"><a>Účet</a></router-link>
              <router-link tag="li" :to="{ name: 'employeeBankAccounts', params: { id: employee.id }}" active-class="active"><a>Bankové účty</a></router-link>
              <router-link tag="li" :to="{ name: 'employeeDocuments', params: { id: employee.id }}" active-class="active"><a>Dokumenty</a></router-link>
              <router-link tag="li" :to="{ name: 'employeeActivities', params: { id: employee.id }}" active-class="active"><a>Aktivity</a></router-link>
            </ul>

            <div id="my-tab-content" class="tab-content">
              <div class="tab-pane active" >
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </template>
    </box>
    <loading class="content-header" id="employee"></loading>
  </page>
</template>
<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import loading from 'components/loading';
  import employeeModal from './employeeCreateModal';
  import notificationService from 'services/notificationService';
  import employeeService from '../service/employeeService';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { employeeModal, loading, page, box },
    data: () => ({
      showCreateModal: false,
      selectedEmployee: null
    }),
    computed: mapState({
      loading: (state) => state.app.loadingData,
      employee: (state) => state.employee.selected
    }),
    methods: {
      hasLocation (subject) {
        return subject.locations && subject.locations.length;
      },
      hasTechnology (subject) {
        return subject.technologies && subject.technologies.length;
      },
      createShow () {
        this.selectedEmployee = employeeService.newEmployee();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      deleteEmployee (employee) {
        this.confirm('employee.delete.confirmation', [employee.name]).then(() => {
          this.$store.dispatch('employee/delete', employee.id).then((result) => {
            if (result) {
              notificationService.success('employee.delete.confirmation');
            }
          }).catch(this.unprotect);
        });
      },
      toggleEmployeeActive (employee) {
        const action = employee.active ? 'deactivate' : 'activate';
        this.$store.dispatch('employee/' + action, employee.id).then((result) => {
          if (result) {
            notificationService.success('employee.' + action + '.success');
          } else {
            notificationService.error('error.employee.' + action);
          }
        });
      },
      editEmployee (employee) {
        this.selectedEmployee = _.cloneDeep(employee);
        this.showCreateModal = true;
      }
    },
    created () {
      this.$store.dispatch('employee/select', this.$store.state.route.params.id);
    },
    async destroyed() {
      await this.$store.dispatch('employee/clear');
    }
  };
</script>
