<template>
  <box :collapsible="collapsible" :collapsed="collapsed">
    <template #header>
      <h4>{{ title }}</h4>
    </template>
    <template #body>
      <h4>{{ company.format() }}</h4>
      <div class="row">
        <div class="col-md-12">
          <dl>
            <dt>Sídlo</dt>
            <dd>{{ company.address.format() }}</dd>
          </dl>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <dl>
            <dt>IČ</dt>
            <dd>{{ company.companyId }}</dd>
          </dl>
        </div>
        <div class="col-md-6">
          <dl>
            <dt>DIČ</dt>
            <dd>{{ company.vatId }}</dd>
          </dl>
        </div>
      </div>
    </template>
  </box>
</template>
<script>
  import Company from '../domain/company';
  import box from 'components/box';

  export default {
    components: { box },
    props: {
      title: {
        type: String,
        required: true
      },
      company: {
        type: Company,
        required: true
      },
      collapsible: {
        type: Boolean,
        default: false
      },
      collapsed: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
