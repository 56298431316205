import contactResource from './contactResource';
import _ from 'lodash';
import Contact from '../domain/contact';

export default {
  newContact (subject) {
    return new Contact({ subject });
  },
  async findBySubject (subjectId) {
    const response = await contactResource.query({ subjectId });
    if (response.ok) {
      return response.data.map((contactData) => new Contact(contactData));
    } else {
      return null;
    }
  },
  async findByProject (projectId) {
    const response = await contactResource.query({ projectId });
    if (response.ok) {
      return response.data.map((contactData) => new Contact(contactData));
    } else {
      return null;
    }
  },
  async create (contact) {
    const response = await contactResource.save({}, _.pickBy(contact));
    if (response.ok) {
      return new Contact(response.data);
    } else {
      return null;
    }
  },
  async update (contact) {
    const response = await contactResource.update({}, _.pickBy(contact));
    if (response.ok) {
      return new Contact(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return contactResource.delete({ id: id });
  }
};
