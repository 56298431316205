import recordsOverviewView from 'modules/record/components/recordsOverview';

export default [
  {
    path: '/records',
    component: recordsOverviewView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    }
  }
];
