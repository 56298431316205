'use strict';

export default (store) => {
  return (request) => {
    const auth = store.state.auth;
    const hasAuthHeader = request.headers.has('Authorization');

    if (!hasAuthHeader && auth.accessToken && auth.validTo.isAfter()) {
      request.headers.set('Authorization', 'Bearer ' + auth.accessToken);
    }
  };
};
