import Vue from 'vue';
import { DOWNLOAD_BLOB } from 'utils';

export default {
  download (url, fileName) {
    Vue.http.get(url, { responseType: 'blob' })
    // resolve to Blob
      .then((response) => response.blob())
      .then((data) => DOWNLOAD_BLOB(data, fileName));
  }
};
