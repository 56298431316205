<template>
  <activities :entity="employee" :entity-type="'subject'" />
</template>

<script>
  import activities from 'modules/activity/components/activities';
  import { mapState } from 'vuex';

  export default {
    components: { activities },
    computed: mapState({
      employee: (state) => state.employee.selected
    })
  };
</script>
