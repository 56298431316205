import { BANK_ACCOUNT_ACTIVATE, BANK_ACCOUNT_DEACTIVATE, BANK_ACCOUNT_LIST, BANK_ACCOUNT_UPDATE } from './mutationTypes';
import _ from 'lodash';

const mutations = {
  [BANK_ACCOUNT_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [BANK_ACCOUNT_UPDATE] (state, action) {
    // let toBeUpdated = _.find(state.items, {id: action.item.id});
    // when array, replace it at all - vue problem
    // _.mergeWith(toBeUpdated, action.item, MERGE);
    // state.selected = toBeUpdated;
    state.selected = _.find(state.items, { id: action.item.id });
  },
  [BANK_ACCOUNT_ACTIVATE] (state, id) {
    const company = _.find(state.items, { id: id });
    if (company) {
      company.active = true;
    }
  },
  [BANK_ACCOUNT_DEACTIVATE] (state, id) {
    const company = _.find(state.items, { id: id });
    if (company) {
      company.active = false;
    }
  }
};

export default mutations;
