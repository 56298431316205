<template>
  <validation-observer ref="form" tag="form">
    <slot></slot>
  </validation-observer>
</template>
<script>
  import { ValidationObserver } from 'vee-validate';

  export default {
    components: { ValidationObserver },
    props: {
      refName: {
        type: String,
        default: 'form'
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate();
      }
    }
  };
</script>
