import Vue from 'vue';
import { API_ROOT } from '../../../config';

const contextPath = 'company';

const customActions = {
  generateAgreement: { method: 'GET', url: API_ROOT + contextPath + '{/id}/generate-agreement{/role}', responseType: 'blob' }
};

export default Vue.resource(API_ROOT + `${contextPath}{/id}`, {}, customActions);
