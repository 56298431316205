<template>
  <div>
    <tm-order-modal :show-model="showCreateTmOrderModal" :model="selectedOrder" @close="hideModals()"></tm-order-modal>
    <tm-delivery-modal :show-model="showCreateTmDeliveryModal" :model="selectedDelivery" @close="hideModals()"></tm-delivery-modal>
    <div class="row">
      <div class="col-md-12">
        <p>
          <button v-show="project.editable" class="btn btn-success" type="button" @click="createDeliveryOrderShow()"><i class="fa fa-plus" aria-hidden="true"></i> Pridať objednávku</button>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <accordion :one-at-atime="true" type="info">
          <panel v-for="order in project.deliveryOrders" :key="order.id">
            <strong slot="header">
              <u>Objednávka č. {{ order.number }}, obdobie: {{ order.validFrom | date }} - {{ order.validTo | date }}</u>
              <div class="clearfix"></div>
            </strong>
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <i class="fa fa-gear"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a role="button" @click.prevent="createDeliveryShow(order)"><i class="fa fa-plus-circle text-primary"></i> Pridať dodávku</a></li>
                      <li><a role="button" @click.prevent="editOrder(order)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                      <li><a role="button" @click.prevent="generateDocument(order)"><i class="fa fa-file-word-o text-info"></i> Generovať dokument DOCX</a></li>
                      <li><a role="button" @click.prevent="generatePdfDocument(order)"><i class="fa fa-file-pdf-o text-danger"></i> Generovať dokument PDF</a></li>
                      <li><a role="button" @click.prevent="prepareEmail(order)"><i class="fa fa-envelope text-primary"></i> Odoslať emailom</a></li>
                      <li><a role="button" @click.prevent="deleteOrder(order)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <dl>
                  <dt>Dodávateľ</dt>
                  <dd><subject-link v-if="order.supplier" :subject="order.supplier"></subject-link></dd>
                </dl>
                <dl>
                  <dt>Číslo</dt>
                  <dd>{{ order.number }}</dd>
                </dl>
                <dl>
                  <dt>Suma</dt>
                  <dd>{{ order.sum | currency(order.currency) }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Oberateľ</dt>
                  <dd><subject-link v-if="order.subscriber" :subject="order.subscriber"></subject-link></dd>
                </dl>
                <dl>
                  <dt>Prijatá objednávka</dt>
                  <dd>{{ order.mainOrder.number }}</dd>
                </dl>
                <dl>
                  <dt>Suma dodávok</dt>
                  <dd>{{ order.deliveriesSum | currency(order.currency) }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Platná od</dt>
                  <dd>{{ order.validFrom | date }}</dd>
                </dl>
                <dl>
                  <dt>Počet MD</dt>
                  <dd>{{ order.mdNumber }}</dd>
                </dl>
                <dl>
                  <dt>Zisk</dt>
                  <dd>{{ order.profit | currency(order.currency) }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Platná do</dt>
                  <dd>{{ order.validTo | date }}</dd>
                </dl>
                <dl>
                  <dt>Cena za MD</dt>
                  <dd>{{ order.mdRate | currency(order.currency) }}</dd>
                </dl>
                <dl>
                  <dt>DPH</dt>
                  <dd>{{ order.vatRate }} % </dd>
                </dl>
              </div>
            </div>
            <hr>

            <order-items v-model="order.items" :order-id="order.id" :currency="order.currency"></order-items>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <h4>Dokumenty</h4>
              </div>
              <div class="col-md-12">
                <documents :editable="project.editable" :documents="order.documents" @deleteDocument="deleteDocumentOrder($event, order)"></documents>
                <document-upload v-show="project.editable" @documentUploaded="documentUploadedOrder($event, order)"></document-upload>
              </div>
            </div>
          </panel>
        </accordion>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import { GENERATED_DOCUMENT_PDF_SUFFIX, GENERATED_DOCUMENT_SUFFIX, ORDER_GENEATED_DOCUMENT_NAME } from 'config';
  import { currencies, projectStatuses } from 'codelist/';
  import { accordion, panel } from 'vue-strap';
  import notificationService from 'services/notificationService';
  import emailService from 'services/emailService';
  import tmOrderService from 'modules/order/service/tmOrderService';
  import tmDeliveryService from 'services/tmDeliveryService';
  import { FORMAT_DATE, normalizeFilename } from 'utils';
  import tmOrderModal from 'components/tmOrderCreateModal';
  import tmDeliveryModal from 'components/tmDeliveryCreateModal';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';
  import moment from 'moment';
  import subjectLink from 'components/subjectLink';
  import orderItems from 'modules/order/components/tmOrderItems';

  export default {
    components: { accordion, panel, documents, documentUpload, tmOrderModal, tmDeliveryModal, subjectLink, orderItems },
    data: () => ({
      projectStatuses: projectStatuses,
      selectedOrder: null,
      selectedDelivery: null,
      showCreateTmOrderModal: false,
      showCreateTmDeliveryModal: false
    }),
    computed: mapState({
      project: (state) => state.tmProject.selected
    }),
    methods: {
      prepareEmail (order) {
        const now = moment();
        const contractDate = order.validFrom.isAfter(now) ? FORMAT_DATE(now) : FORMAT_DATE(order.validFrom);
        const currency = _.find(currencies, { value: order.currency });
        emailService.prepareEmailForSubject(
          order.supplier, // recipient
          'email.order.subject', // subject template key
          'email.order.body', // body template key
          [this.project.customer.name], // subject template parameters
          [order.mdNumber, order.mdRate, currency.label, contractDate]); // body template parameters
      },
      createDeliveryOrderShow () {
        this.selectedOrder = tmOrderService.newTmOrder({
          type: 'DELIVERY',
          project: this.project,
          currency: this.project.currency
        });
        this.showCreateTmOrderModal = true;
      },
      editOrder (order) {
        this.selectedOrder = _.cloneDeep(order);
        this.showCreateTmOrderModal = true;
      },
      deleteOrder (order) {
        this.confirm('order.delete.confirmation', [order.number]).then(() => {
          this.$store.dispatch('tmOrder/delete', order.id).then((result) => {
            if (result) {
              notificationService.success('order.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      orderFileName: function (order) {
        const supplierName = order.supplier.name;
        const customerName = _.find(this.project.incomingOrders, { id: order.mainOrder.id }).subscriber.label;
        const orderFrom = FORMAT_DATE(order.validFrom);
        const orderTo = FORMAT_DATE(order.validTo);
        return normalizeFilename(`${order.number}-${ORDER_GENEATED_DOCUMENT_NAME}-${supplierName}-${customerName}-${orderFrom}-${orderTo}`);
      },
      generateDocument (order) {
        const fileName = this.orderFileName(order);
        tmOrderService.generateOrderDocument(order.id, `${fileName}${GENERATED_DOCUMENT_SUFFIX}`);
      },
      generatePdfDocument (order) {
        const fileName = this.orderFileName(order);
        tmOrderService.generateOrderPdfDocument(order.id, `${fileName}${GENERATED_DOCUMENT_PDF_SUFFIX}`);
      },
      documentUploadedOrder (document, order) {
        const orderCopy = _.cloneDeep(order);
        orderCopy.documents.push(document);
        this.$store.dispatch('tmOrder/updateDocuments', orderCopy).then((result) => {
          if (result) {
            notificationService.success('order.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocumentOrder (document, order) {
        const orderCopy = _.cloneDeep(order);
        _.remove(orderCopy.documents, { id: document.id });
        this.$store.dispatch('tmOrder/updateDocuments', orderCopy).then((result) => {
          if (result) {
            notificationService.success('order.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      createDeliveryShow (order) {
        this.selectedDelivery = tmDeliveryService.newTmDelivery({
          order: order
        });
        this.showCreateTmDeliveryModal = true;
      },
      editDelivery (delivery) {
        this.selectedDelivery = _.cloneDeep(delivery);
        this.showCreateTmDeliveryModal = true;
      },
      deleteDelivery (delivery) {
        this.confirm('delivery.delete.confirmation').then(() => {
          this.$store.dispatch('tmDelivery/delete', delivery.id).then((result) => {
            if (result) {
              notificationService.success('delivery.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      hideModals () {
        this.showCreateTmOrderModal = false;
        this.showCreateTmDeliveryModal = false;
      }
    }
  };
</script>
