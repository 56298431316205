export const MONEY_BOX_LIST = 'MONEY_BOX_LIST';
export const MONEY_BOX_LIST_CLEAR = 'MONEY_BOX_LIST_CLEAR';
export const MONEY_BOX_ACTIVATE = 'MONEY_BOX_ACTIVATE';
export const MONEY_BOX_DEACTIVATE = 'MONEY_BOX_DEACTIVATE';
export const MONEY_BOX_BALANCE_LIST = 'MONEY_BOX_BALANCE_LIST';
export const MONEY_BOX_BALANCE_LIST_CLEAR = 'MONEY_BOX_BALANCE_LIST_CLEAR';

export const MONEY_MOVEMENT_LIST = 'MONEY_MOVEMENT_LIST';
export const MONEY_MOVEMENT_LIST_CLEAR = 'MONEY_MOVEMENT_LIST_CLEAR';
export const MONEY_MOVEMENT_LATEST_LIST = 'MONEY_MOVEMENT_LATEST_LIST';
export const MONEY_MOVEMENT_LATEST_LIST_CLEAR = 'MONEY_MOVEMENT_LATEST_LIST_CLEAR';

export const MONEY_MOVEMENT_CATEGORY_LIST = 'MONEY_MOVEMENT_CATEGORY_LIST';
export const MONEY_MOVEMENT_CATEGORY_ACTIVATE = 'MONEY_MOVEMENT_CATEGORY_ACTIVATE';
export const MONEY_MOVEMENT_CATEGORY_DEACTIVATE = 'MONEY_MOVEMENT_CATEGORY_ACTIVATE';

export const FINANCE_SUMMARY_VAT_LIST = "FINANCE_SUMMARY_VAT_LIST";
export const FINANCE_SUMMARY_TAX_BASE_LIST = "FINANCE_SUMMARY_TAX_BASE_LIST";
