import { AUTH_LOG_IN, AUTH_USER_UPDATE, AUTH_LOG_OUT } from '../../mutationTypes';

const mutations = {
  async [AUTH_LOG_IN] (state, action) {
    state.validTo = action.validTo;
    state.accessToken = action.accessToken;
    state.refreshToken = action.refreshToken;
  },
  async [AUTH_USER_UPDATE] (state, action) {
    state.user = action.user;
  },
  async [AUTH_LOG_OUT] (state) {
    state.user = null;
    state.validTo = null;
    state.accessToken = null;
    state.refreshToken = null;
  }
};

export default mutations;
