'use strict';

export default () => {
  return (to, from, next) => {
    if (from.name === 'login') {
      window.dispatchEvent(new Event('resize')); // fix expand of body after login
      window.dispatchEvent(new Event('load')); // fix expand sidebar menu - collapse/expand of menu items doesn't work after login.
    }
    next();
  };
};
