import bankMoneyMovementResource from './bankMoneyMovementResource';
import _ from 'lodash';
import BankMoneyMovement from "../domain/bankMoneyMovement";

export default {
  newMovement (originalMovement) {
    return new BankMoneyMovement({
      type: originalMovement.type,
      moneyBox: originalMovement.moneyBox,
      date: originalMovement.date,
      totalAmount: 0,
      vatAmount: 0,
      '@class': originalMovement['@class']
    });
  },
  async update(moneyMovement) {
    const response = await bankMoneyMovementResource.update({}, _.pickBy(moneyMovement));
    if (response.ok) {
      return new BankMoneyMovement(response.data);
    } else {
      return null;
    }
  },
  async split(moneyMovement) {
    const response = await bankMoneyMovementResource.split({}, _.pickBy(moneyMovement));
    if (response.ok) {
      return new BankMoneyMovement(response.data);
    } else {
      return null;
    }
  }
};
