import tmProjectResource from './tmProjectResource';
import _ from 'lodash';
import TmProject from 'modules/project/domain/tmProject';

export default {
  newTmProject () {
    return new TmProject({});
  },
  async find (id) {
    const response = await tmProjectResource.get({ id: id });
    if (response.ok) {
      return new TmProject(response.data);
    } else {
      return null;
    }
  },
  async findAll (filter) {
    const response = await tmProjectResource.query(filter);
    if (response.ok) {
      return response.data.map((tmProjectData) => new TmProject(tmProjectData));
    } else {
      return null;
    }
  },
  async create (tmProject) {
    const response = await tmProjectResource.save({}, _.pickBy(tmProject));
    if (response.ok) {
      return new TmProject(response.data);
    } else {
      return null;
    }
  },
  async update (tmProject) {
    const response = await tmProjectResource.update({}, _.pickBy(tmProject));
    if (response.ok) {
      return new TmProject(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return tmProjectResource.delete({ id: id });
  }
};
