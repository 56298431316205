import Entity from 'domain/entity';

class CurrencyRate extends Entity {
  constructor (data) {
    super(data);
    this.currency = data.currency || null;
    this.date = this.convertDate(data.date);
    this.value = data.value || null;
  }
}

export default CurrencyRate;
