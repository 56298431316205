import _ from 'lodash';
import { normalize } from 'utils';
import { alert } from 'vue-strap';
import { mapState } from 'vuex';
import notificationService from 'services/notificationService';
import codeListModal from 'components/codeListCreateModalGeneric';

export default {
  components: { alert, codeListModal },
  props: {
    title: {
      type: String,
      required: false
    },
    moduleName: {
      type: String,
      required: true
    },
    loadingId: {
      type: String,
      required: true
    },
    showQueryFilter: {
      type: Boolean,
      required: false,
      default: true
    },
    orderable: {
      type: Boolean,
      required: false,
      default: false
    },
    filterFnc: {
      type: Function,
      required: false,
      default: (item) => item
    },
    newItemTemplate: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    showCreateModal: false,
    selectedItem: null,
    query: null,
    showInactive: false
  }),
  computed: mapState({
    items (state) {
      let items;
      if (!this.showInactive) {
        items = this.$store.getters[this.moduleName + '/validItems'];
      } else {
        items = state[this.moduleName].items;
      }
      if (this.query) {
        const regexp = new RegExp(normalize(this.query), 'g');
        return items.filter(item => !!normalize(item.label).match(regexp)).filter(this.filterFnc);
      } else {
        return items.filter(this.filterFnc);
      }
    }
  }),
  methods: {
    createShow () {
      this.selectedItem = _.merge({ label: null, shortDescription: null }, this.newItemTemplate);
      this.showCreateModal = true;
    },
    createHide () {
      this.showCreateModal = false;
    },
    deleteItem (item) {
      this.confirm('codeList.delete.confirmation', [item.label]).then(() => {
        this.$store.dispatch(this.moduleName + '/delete', item.id).then((result) => {
          if (result) {
            notificationService.success('codeList.delete.success');
          }
          this.unprotect();
        }).catch(this.unprotect);
      });
    },
    editItem (item) {
      this.selectedItem = _.cloneDeep(item);
      this.showCreateModal = true;
    },
    deactivateItem (item) {
      this.$store.dispatch(this.moduleName + '/deactivate', item.id).then((result) => {
        if (result) {
          notificationService.success('codeList.update.deactivated');
        }
      });
    },
    activateItem (item) {
      this.$store.dispatch(this.moduleName + '/activate', item.id).then((result) => {
        if (result) {
          notificationService.success('codeList.update.activated');
        }
      });
    },
    positionDown(item) {
      //because of filters we need to set position of next item in order
      const indexOfNextItem = this.items.indexOf(item) + 1;
      //last index check
      if (this.items.length <= indexOfNextItem) {
        return;
      }
      const position = this.items[indexOfNextItem].order;

      this.updatePosition(item, position);
    },
    positionUp(item) {
      //because of filters we need to set position of next item in order
      const indexOfPrevItem = this.items.indexOf(item) - 1;
      //first index check
      if (indexOfPrevItem < 0) {
        return;
      }
      const position = this.items[indexOfPrevItem].order;

      this.updatePosition(item, position);
    },
    updatePosition (item, position) {
      this.$store.dispatch(this.moduleName + '/updatePosition', { id: item.id, position }).then((result) => {
        if (result) {
          notificationService.success('codeList.edit.success');
        } else {
          notificationService.error('codeList.edit.error');
        }
      });
    },
  },
  created () {
    this.$store.dispatch(this.moduleName + '/getAll');
  }
};
