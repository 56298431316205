<template>
  <page title="Kontrakty">
    <box class="compact">
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa názvu</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-2">
            <select-field
              :options="projectStatuses"
              v-model="filter.status"
              :codelist="true"
              field-id="status"
              label="Stav">
            </select-field>
          </div>
          <div class="col-md-2">
            <select-field
              :options="years"
              v-model="filter.year"
              :codelist="true"
              field-id="year"
              label="Rok">
            </select-field>
          </div>
          <div class="col-md-2">
            <button class="btn btn-success btn-input-line" @click.prevent="defaultFilter()">Zmazať filter</button>
          </div>
        </div>
      </template>
    </box>

    <box class="compact">
      <template #header>
        <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
      </template>
      <template #body>
        <tm-project-modal :show-model="showCreateModal" :model="selectedTmProject" @close="hideModals()"></tm-project-modal>
        <div class="table-responsive">
          <table class="table table-hover dataTable" v-if="projects && projects.length">
            <thead>
            <tr>
              <the label="Názov" property="name" :sort-data="sortData" @sort="sort($event)"></the>
              <th>Zákazník</th>
              <the label="Objednavka od" property="orderFrom" :sort-data="sortData" @sort="sort($event)"></the>
              <the label="Objednavka do" property="orderTo" :sort-data="sortData" @sort="sort($event)"></the>
              <th>Posledná dodávka</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="tmProject in projects">
              <tr @click.prevent="detail(tmProject)" role="button" :class="{'inactive': !tmProject.editable, 'bg-danger': dateBefore(tmProject, 0), 'bg-warning': dateBefore(tmProject, 30)}">
                <td>{{ tmProject.name }}</td>
                <td>
                  <subject-link v-if="tmProject.customer" :subject="tmProject.customer"></subject-link>
                </td>
                <td>{{ tmProject.orderFrom | date }}</td>
                <td>{{ tmProject.orderTo | date }}</td>
                <td>{{ tmProject.lastDelivery | dateMonth }}
                  <span v-if="tmProject.missingDelivery" title="Za minulý mesiac nie je vyplnená dodávka.">
                  <i class="fa fa-exclamation text-danger tooltip-box" aria-hidden="true"></i>
                </span>
                </td>
                <td class="text-right">
                  <button class="btn btn-xs btn-link" @click.stop="detail(tmProject)"><i class="fa fa-2x fa-search" title="Detail"></i></button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <loading id="tmProjects"></loading>
            <no-records :data="projects" loading-id="tmProjects"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { YEARS_MIN } from 'config';
  import { projectStatuses } from 'codelist/';
  import selectField from 'components/form/selectField';
  import loading from 'components/loading';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import noRecords from 'components/noRecords';
  import tmProjectModal from 'modules/project/components/tmProjectCreateModal';
  import the from 'components/the';
  import tmProjectService from 'modules/project/service/tmProjectService';
  import subjectLink from 'components/subjectLink';
  import page from 'components/page';
  import box from 'components/box';

  const now = moment();

  export default {
    components: { tmProjectModal, loading, noRecords, the, selectField, subjectLink, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      years: _.range(YEARS_MIN, now.year() + 1).reverse().map(year => ({ value: year, label: year + '' })),
      projectStatuses: projectStatuses,
      showCreateModal: false,
      selectedTmProject: null,
      filter: { query: null, status: null, year: null }
    }),
    computed: mapState({
      projects (state) {
        let allTmProjects = state.tmProject.itemsByFilter;
        if (this.filter.query) {
          const regexp = new RegExp(normalize(this.filter.query), 'g');
          allTmProjects = allTmProjects.filter(tmProject => !!normalize(tmProject.name).match(regexp));
        }

        const sorted = _.sortBy(allTmProjects, this.sortData.by);
        return this.sortData.asc ? sorted : sorted.reverse();
      }
    }),
    methods: {
      createFilter () {
        const filter = this.filter;
        return _.pickBy({
          year: filter.year ? filter.year : null,
          status: filter.status ? filter.status : null
        }, _.identity);
      },
      createShow () {
        this.selectedTmProject = tmProjectService.newTmProject();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      detail (tmProject) {
        this.$router.push({ name: 'tmProjectDetail', params: { id: tmProject.id }});
      },
      sort (sortData) {
        this.sortData.by = sortData.by;
        this.sortData.asc = sortData.asc;
      },
      dateBefore (tmProject, daysOffset) {
        return tmProject.editable && tmProject.orderTo && tmProject.orderTo.isBefore(moment().add(daysOffset, 'd'));
      },
      defaultFilter () {
        this.sortData.by = 'orderTo';
        this.sortData.asc = true;
        this.filter.year = null;
        this.filter.status = 'OPEN';
        this.filter.query = null;
      }
    },
    watch: {
      'filter.year': {
        handler () {
          this.selected = [];
          this.$store.dispatch('tmProject/getAllByFilter', this.createFilter());
        }
      },
      'filter.status': {
        handler () {
          this.selected = [];
          this.$store.dispatch('tmProject/getAllByFilter', this.createFilter());
        }
      }
    }
  };
</script>
