import { CONTACT_LIST } from 'modules/contact/store/contactMutationTypes';

const mutations = {
  [CONTACT_LIST] (state, action) {
    state.filter = action.filter ? action.filter : null;
    state.items = action.items ? action.items : [];
  }
};

export default mutations;
