import Technology from 'modules/technology/domain/technology';
import technologyResource from './technologyResource';

export default {
  async findAll () {
    const response = await technologyResource.query();
    if (response.ok) {
      return response.data.map((technologyData) => new Technology(technologyData));
    } else {
      return null;
    }
  },
  async create (technology) {
    const response = await technologyResource.save({}, technology);
    if (response.ok) {
      return new Technology(response.data);
    } else {
      return null;
    }
  },
  async update (technology) {
    const response = await technologyResource.update({}, technology);
    if (response.ok) {
      return new Technology(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return technologyResource.delete({ id: id });
  },
  async deactivate (id) {
    return technologyResource.deactivate({ id: id }, {});
  },
  async activate (id) {
    return technologyResource.activate({ id: id }, {});
  }
};
