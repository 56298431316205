import actions from './bankAccountActions';
import getters from './bankAccountGetters';
import mutations from './bankAccountMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
