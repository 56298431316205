import Location from '../domain/location';
import locationResource from '../service/locationResource';

export default {
  async findAll () {
    const response = await locationResource.query();
    if (response.ok) {
      return response.data.map((locationData) => new Location(locationData));
    } else {
      return null;
    }
  },
  async create (location) {
    const response = await locationResource.save({}, location);
    if (response.ok) {
      return new Location(response.data);
    } else {
      return null;
    }
  },
  async update (location) {
    const response = await locationResource.update({}, location);
    if (response.ok) {
      return new Location(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return locationResource.delete({ id: id });
  },
  async deactivate (id) {
    return locationResource.deactivate({ id: id }, {});
  },
  async activate (id) {
    return locationResource.activate({ id: id }, {});
  }
};
