import Entity from '../../../domain/entity';

class Account extends Entity {
  constructor (data) {
    super(data);

    this.balances = this.balances || [];
  }
}

export default Account;
