import * as types from './mutationTypes';
import bankAccountService from '../service/bankAccountService';
import _ from 'lodash';

const actions = {
  async findOwnershipped ({ dispatch }) {
    dispatch('findByFilter', {ownershipped: true});
  },
  async findByFilter ({ dispatch, commit }, filter) {
    await commit(types.BANK_ACCOUNT_LIST, {
      filter: null,
      items: []
    });
    dispatch('app/loadingDataEnable', 'bankAccounts', { root: true });
    const bankAccounts = await bankAccountService.findByFilter(filter);
    await commit(types.BANK_ACCOUNT_LIST, {
      filter: filter,
      items: bankAccounts
    });
    dispatch('app/loadingDataDisable', 'bankAccounts', { root: true });
  },
  async create ({ state, dispatch }, bankAccount) {
    try {
      const newBankAccount = await bankAccountService.create(bankAccount);
      if (newBankAccount) {
        await dispatch('findByFilter', state.filter);
        return newBankAccount;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ state, dispatch, commit }, bankAccount) {
    try {
      const updatedBankAccount = await bankAccountService.update(bankAccount);
      if (updatedBankAccount) {
        await dispatch('findByFilter', state.filter);
        await commit(types.BANK_ACCOUNT_UPDATE, {
          item: bankAccount
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.BANK_ACCOUNT_ACTIVATE, id);
      const bankAccount = _.find(state.items, { id: id });
      const updatedBankAccount = await bankAccountService.update(bankAccount);
      if (updatedBankAccount) {
        await dispatch('findByFilter', state.filter);
        await commit(types.BANK_ACCOUNT_UPDATE, {
          item: bankAccount
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.BANK_ACCOUNT_DEACTIVATE, id);
      const bankAccount = _.find(state.items, { id: id });
      const updatedBankAccount = await bankAccountService.update(bankAccount);
      if (updatedBankAccount) {
        await dispatch('findByFilter', state.filter);
        await commit(types.BANK_ACCOUNT_UPDATE, {
          item: bankAccount
        });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async delete ({ state, dispatch }, id) {
    const response = await bankAccountService.delete(id);
    if (response.ok) {
      await dispatch('findByFilter', state.filter);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
