import actions from './moneyMovementActions';
import getters from './moneyMovementGetters';
import mutations from './moneyMovementMutations';

const state = {
  items: [],
  filter: null,
  latestItems: []
};

export default {
  namespaced: true,
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
