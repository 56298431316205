import MoneyMovement from "./moneyMovement";

class CashMoneyMovement extends MoneyMovement {
  constructor (data) {
    super(data);

    if (!this['@class']) {
      this['@class'] = '.CashMoneyMovementDto';
    }
  }
}

export default CashMoneyMovement;
