import bankMoneyMovementService from '../service/bankMoneyMovementService';

const actions = {
  async update({state, dispatch, commit}, moneyMovement) {
    try {
      const updatedMovement = await bankMoneyMovementService.update(moneyMovement);
      if (updatedMovement) {
        await dispatch('moneyMovement/refresh', null, {root: true});
        dispatch('invoice/refresh', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async split({state, dispatch, commit}, moneyMovementSplit) {
    try {
      const updatedMovement = await bankMoneyMovementService.split(moneyMovementSplit);
      if (updatedMovement) {
        await dispatch('moneyMovement/refresh', null, {root: true});
        dispatch('invoice/refresh', null, { root: true });
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  }
};

export default actions;
