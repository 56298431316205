import * as types from './mutationTypes';
import invoiceSeriesService from '../service/invoiceSeriesService';
import _ from 'lodash';

const actions = {
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'invoiceSeries', { root: true });
      const invoiceSeries = await invoiceSeriesService.findAll();
      await commit(types.INVOICE_SERIES_LIST, {
        items: invoiceSeries
      });
      dispatch('app/loadingDataDisable', 'invoiceSeries', { root: true });
    }
  },
  async create ({ dispatch }, invoiceSeries) {
    const newInvoiceSeries = await invoiceSeriesService.create(invoiceSeries);
    if (newInvoiceSeries) {
      await dispatch('getAll', true);
      return newInvoiceSeries;
    } else {
      return false;
    }
  },
  async update ({ dispatch, commit }, invoiceSeries) {
    const updatedInvoiceSeries = await invoiceSeriesService.update(invoiceSeries);
    if (updatedInvoiceSeries) {
      await dispatch('getAll', true);
      await commit(types.INVOICE_SERIES_UPDATE, {
        item: invoiceSeries
      });
      return true;
    } else {
      return false;
    }
  },
  async activate ({ dispatch, state, commit }, id) {
    await commit(types.INVOICE_SERIES_ACTIVATE, id);
    const invoiceSeries = _.find(state.items, { id: id });
    const updatedInvoiceSeries = await invoiceSeriesService.update(invoiceSeries);
    if (updatedInvoiceSeries) {
      await dispatch('getAll', true);
      await commit(types.INVOICE_SERIES_UPDATE, {
        item: invoiceSeries
      });
      return true;
    } else {
      return false;
    }
  },
  async deactivate ({ dispatch, state, commit }, id) {
    await commit(types.INVOICE_SERIES_DEACTIVATE, id);
    const invoiceSeries = _.find(state.items, { id: id });
    const updatedInvoiceSeries = await invoiceSeriesService.update(invoiceSeries);
    if (updatedInvoiceSeries) {
      await dispatch('getAll', true);
      await commit(types.INVOICE_SERIES_UPDATE, {
        item: invoiceSeries
      });
      return true;
    } else {
      return false;
    }
  },
  async delete ({ dispatch }, id) {
    const response = await invoiceSeriesService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
