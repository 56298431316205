import actions from './technologyActions';
import mutations from './technologyMutations';
import getters from './technologyGetters';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  getters: getters,
  actions: actions
};
