import * as types from './mutationTypes';
import accountService from '../service/accountService';

const actions = {
  async select ({ dispatch, commit }, id) {
    dispatch('app/loadingDataEnable', 'account', { root: true });
    const account = await accountService.find(id);
    await commit(types.ACCOUNT_SELECT, {
      item: account
    });
    dispatch('app/loadingDataDisable', 'account', { root: true });
  },
  async refreshSelected ({ state, dispatch, commit }) {
    dispatch('app/loadingDataEnable', 'account', { root: true });
    const account = await accountService.find(state.selected.id);
    await commit(types.ACCOUNT_SELECT, {
      item: account
    });
    dispatch('app/loadingDataDisable', 'account', { root: true });
  }
};

export default actions;
