<template>
  <div>
    <contact-modal :show-model="showCreateModal" :model="selectedContact" @close="hideModals()"></contact-modal>
    <div class="row">
      <div class="col-md-12">
        <p>
          <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať kontakt</button>
        </p>
      </div>
    </div>
    <div v-if="contacts.length">
      <table class="table table-responsive no-padding table-hover" v-if="contacts">
        <thead>
        <tr>
          <th>Názov</th>
          <th>E-mail</th>
          <th>Telefónne číslo</th>
          <th>Pozícia</th>
          <th class="text-center">Fakturácia</th>
          <th>Poznámka</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="contact in contacts">
          <tr>
            <td>{{ contact.name }}</td>
            <td>{{ contact.email }}</td>
            <td>{{ contact.phoneNumber }}</td>
            <td>{{ contact.position }}</td>
            <td class="text-center"><boolean-icon v-model="contact.forInvoicing"/></td>
            <td>{{ contact.note }}</td>
            <td class="text-right">
              <button class="btn btn-xs btn-link" @click.stop="editContact(contact)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
              <button class="btn btn-xs btn-link" @click.stop="deleteContact(contact)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <loading class="content-header" id="contacts"></loading>
    <no-records :data="contacts" loading-id="contacts"></no-records>
  </div>
</template>
<script>
  import _ from 'lodash';
  import booleanIcon from 'components/booleanIcon';
  import { mapState } from 'vuex';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import contactModal from './contactCreateModal';
  import contactService from 'modules/contact/service/contactService';
  import notificationService from 'services/notificationService';

  export default {
    components: { booleanIcon, loading, noRecords, contactModal },
    data: () => ({
      selectedContact: null,
      showCreateModal: false
    }),
    computed: mapState({
      loading: (state) => state.app.loadingData,
      contacts: (state) => state.contact.items
    }),
    props: {
      subject: {
        type: Object,
        required: true
      }
    },
    methods: {
      createShow () {
        this.selectedContact = contactService.newContact(this.subject);
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      editContact (contact) {
        this.selectedContact = _.cloneDeep(contact);
        this.showCreateModal = true;
      },
      deleteContact (contact) {
        this.confirm('contact.delete.confirmation', [contact.name]).then(() => {
          this.$store.dispatch('contact/delete', contact.id).then((result) => {
            if (result) {
              notificationService.success('contact.delete.success');
            }
          }).catch(this.unprotect);
        });
      }
    },
    mounted () {
      this.$store.dispatch('contact/findBySubject', this.subject.id);
    }
  };
</script>
