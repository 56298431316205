'use strict';
import _ from 'lodash';
import { normalize } from 'utils';

export default {
  active: (state) => {
    return _.filter(state.items || [], 'active');
  },
  byFilter: state => (filter, sortData) => {
    let allMoneyMovement = state.items;
    if (filter && filter.query) {
      const regexp = new RegExp(normalize(filter.query), 'g');
      allMoneyMovement = allMoneyMovement.filter(moneyMovement => {
        const searchString = moneyMovement.note + ' ' + (moneyMovement.category ? moneyMovement.category.label : '');
        return !!normalize(searchString).match(regexp);
      });
    }
    //for date ordering consider transactionId as second order parameter
    let sortBy = sortData.by === 'date' ? [sortData.by, 'bankTransaction.transactionId'] : sortData.by;
    const sorted = _.sortBy(allMoneyMovement, sortBy);
    return sortData.asc ? sorted : sorted.reverse();
  }
};
