<template>
  <div>
    <validation-provider :rules="validation" v-slot="{ errors }">
      <div class="form-group radio-field" :class="{ 'has-error': errors.length }">
        <label v-if="label">{{ label }}</label>
        <div :class="{'no-label': !label}">
          <label :class="{'radio-inline': inline}" v-for="option in options">
            <input type="radio"
                   :class="cssClass"
                   :name="fieldId"
                   :value="option.value"
                   v-model="innerValue">
            {{ option.label }}
          </label>
          <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
        </div>
      </div>
    </validation-provider>
  </div>
</template>
<script>

  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {ValidationProvider},
    data: () => ({
      innerValue: null
    }),
    props: {
      value: {
        type: String,
        default: null
      },
      label: {
        type: String
      },
      cssClass: {
        type: String,
        required: false
      },
      fieldId: {
        type: String,
        required: true
      },
      validation: {
        type: String
      },
      validationObj: {
        type: Object,
        default () {
          return {};
        }
      },
      inline: {
        type: Boolean,
        default: true
      },
      options: {
        type: Array,
        required: true,
        default () {
          return [];
        }
      }
    },
    watch: {
      innerValue (value) {
        this.$emit('input', value);
      },
      value: {
        handler(value) {
          this.innerValue = value;
        }
      }
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    }
  };
</script>
}
