import subjectResource from './subjectResource';
import Company from '../../company/domain/company';
import Employee from '../../employee/domain/employee';
import Lead from '../../lead/domain/lead';

export default {
  getSubject (data) {
    switch (data['@class']) {
      case '.CompanyDto': return new Company(data);
      case '.EmployeeDto': return new Employee(data);
      case '.LeadDto': return new Lead(data);
      default: {
        throw new Error('Unresolved subject type.');
      }
    }
  },
  async findAll () {
    const response = await subjectResource.query();
    if (response.ok) {
      return response.data.map(this.getSubject);
    } else {
      return null;
    }
  }
};
