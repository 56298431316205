<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="content-header">
          <h1 v-text="title"></h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    }
  };
</script>
