<template>
  <div v-if="account">
    <transaction-modal :show-model="showCreateModal" :model="selectedTransaction" @close="hideModals()"></transaction-modal>

    <dl v-if="account.balances.length">
      <template v-for="balance in account.balances">
        <dt>Stav konta:</dt>
        <dd :class="{'text-success' : balance.amount > 0, 'text-danger' : balance.amount < 0}">{{ balance.amount | currency(balance.currency) }}</dd>
      </template>

      <hr>

      <div class="row">
        <div class="col-sm-12, col-md-6 col-lg-4">
          <date-range-field v-model="filter.range"></date-range-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p>
            <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať transakciu</button>
            <button :disabled="!transactions.length" class="btn btn-default" @click="exportTransactions"><i class="fa fa-download"></i> Exportovať</button>
          </p>
        </div>
      </div>
      <div v-if="transactions.length">
        <table class="table table-responsive no-padding table-hover" v-if="transactions">
          <thead>
          <tr>
            <th>Suma</th>
            <th>Typ</th>
            <th>Dátum</th>
            <th>Poznámka</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="transaction in transactions">
            <tr>
              <td class="text-right text-nowrap">{{ transaction.amount | currency(transaction.currency) }}</td>
              <td>{{ transaction.type | codeListValue(transactionTypes) }}</td>
              <td>{{ transaction.date | date }}</td>
              <td><truncate :content="transaction.note"></truncate></td>
              <td class="text-right text-nowrap">
                <button class="btn btn-xs btn-link" :disabled="transaction.generated" @click.stop="repeatTransaction(transaction)"><i class="fa fa-2x fa-repeat text-success" title="Opakovať"></i></button>
                <button class="btn btn-xs btn-link" :disabled="transaction.generated" @click.stop="editTransaction(transaction)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                <button class="btn btn-xs btn-link" :disabled="transaction.generated" @click.stop="deleteTransaction(transaction)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <loading id="transactions"></loading>
    </dl>
    <div class="row" v-else>
      <div class="col-md-12">
        <p>
          <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať transakciu</button>
        </p>
      </div>
      <div class="col-md-12">
        <p class="alert alert-warning text-center">
          Žiadny záznam
        </p>
      </div>
    </div>
    <loading class="content-header" id="account"></loading>
  </div>
</template>
<script>
  import moment from 'moment';
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import transactionTypes from 'codelist/transactionTypes';
  import periods from 'codelist/periods';
  import loading from 'components/loading';
  import truncate from 'components/truncate';
  import Range from 'components/range';
  import dateRangeField from 'components/form/dateRangeField';
  import bookmarkableFilter from "components/bookmarkableFilter";
  import transactionModal from './transactionCreateModal';
  import transactionService from '../service/transactionService';
  import notificationService from 'services/notificationService';
  import { FORMAT_SYSTEM_DATE, normalizeFilename } from 'utils';
  import { TRANSACTIONS_DOCUMENT_NAME, GENERATED_DOCUMENT_CSV_SUFFIX } from 'config';

  export default {
    components: { loading, transactionModal, truncate, dateRangeField },
    mixins: [bookmarkableFilter],
    data: () => ({
      transactionTypes,
      periods,
      period: null,
      selectedTransaction: null,
      showCreateModal: false,
      filter: {
        range: null
      }
    }),
    computed: mapState({
      account: (state) => state.account.selected,
      loading: (state) => state.app.loadingData,
      transactions: (state) => state.transaction.items
    }),
    props: {
      accountId: {
        type: Number,
        required: true
      },
      subjectName: {
        type: String,
        required: true
      }
    },
    methods: {
      createShow () {
        this.selectedTransaction = transactionService.newTransaction(this.account);
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      repeatTransaction (transaction) {
        this.selectedTransaction = _.cloneDeep(transaction);
        this.selectedTransaction.date = moment();
        this.selectedTransaction.id = null;
        this.showCreateModal = true;
      },
      editTransaction (transaction) {
        this.selectedTransaction = _.cloneDeep(transaction);
        this.showCreateModal = true;
      },
      deleteTransaction (transaction) {
        this.confirm('transaction.delete.confirmation').then(() => {
          this.$store.dispatch('transaction/delete', transaction.id).then((result) => {
            if (result) {
              notificationService.success('transaction.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      fetchTransactions () {
        this.$store.dispatch('transaction/findByFilter', this.createFilter());
      },
      exportTransactions () {
        const dateFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const dateTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        const dateFromFormatted = _.isNil(dateFrom) ? '' : `_${dateFrom}`;
        const dateToFormatted = _.isNil(dateTo) ? '' : `_${dateTo}`;
        const normalizedSubjectName = normalizeFilename(this.subjectName || '');
        const fileName = `${normalizedSubjectName}${dateFromFormatted}${dateToFormatted}_${TRANSACTIONS_DOCUMENT_NAME}${GENERATED_DOCUMENT_CSV_SUFFIX}`;
        transactionService.exportTransactions(this.createFilter(), fileName);
      },
      createFilter () {
        const dateFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const dateTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        return _.pickBy({
          dateFrom,
          dateTo,
          accountId: this.accountId
        }, _.identity);
      },
      defaultFilter () {
        this.filter.range = new Range(moment().subtract(1, 'month'));
      }
    },
    watch: {
      'filter.range' () {
        this.fetchTransactions();
      }
    },
    mounted () {
      this.period = this.periods[0];
      this.$store.dispatch('account/select', this.accountId);
    }
  };
</script>
