<template>
  <span>
    <template v-if="!content"></template>
    <template v-else-if="show || content.length <= minLength" :class="{ 'formatted-text': show}">
      {{ content }}
    </template>
    <template v-else>
      {{ content | truncate(minLength) }} <a @click.prevent.stop="show = true" role="button">Zobraziť všetko</a>
    </template>
  </span>
</template>

<script>
  export default {
    data: () => ({
      show: false
    }),
    props: {
      content: {
        type: String,
        required: false
      },
      minLength: {
        type: Number,
        required: false,
        default: 100
      }
    }
  };
</script>
