import notificationService from 'services/notificationService';
import documentService from 'modules/document/service/documentService';

export default {
  props: {
    deleteConfirm: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    fileIcon (fileName) {
      const ext = fileName.split('.').pop().toLowerCase();
      switch (ext) {
        case 'pdf': return 'fa-file-pdf-o';
        case 'xls':
        case 'xlsx':
        case 'csv':
          return 'fa-file-excel-o';
        case 'doc':
        case 'docx':
          return 'fa-file-word-o';
        case 'jpeg':
        case 'jpg':
        case 'png':
          return 'fa-file-image-o';
        default: return 'fa-file-o';
      }
    },
    download (document) {
      documentService.download('api/document/' + document.id, document.fileName);
    },
    deleteDocument (document) {
      if (this.deleteConfirm) {
        this.confirm('document.delete.confirmation', [document.fileName]).then(() => {
          if (this.doDelete(document)) {
            notificationService.success('document.delete.success');
          }
        });
      } else {
        this.doDelete(document);
      }
    },
    doDelete (document) {
      this.$emit('deleteDocument', document);
    }
  }
};
