import { TM_DELIVERY_LIST, TM_DELIVERY_LIST_CLEAR } from '../../../store/mutationTypes';

const mutations = {
  [TM_DELIVERY_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [TM_DELIVERY_LIST_CLEAR] (state) {
    state.items = [];
  }
};

export default mutations;
