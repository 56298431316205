'use strict';
import i18n from 'i18n/';

import _ from 'lodash';

export default (value, args) => {
  if (_.isUndefined(value) || value === null) {
    return null;
  } else {
    return i18n.message(value, args);
  }
};
