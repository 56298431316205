import CodeList from 'domain/codeList';

class VatRate extends CodeList {

  constructor (data) {
    super(data);

    this.rate = data.rate;

    this.label = data.rate + '%';
    this.value = data.rate;
  }
}

export default VatRate;
