<template>
  <page title="Plán aktivít">
    <activity-modal :show-model="showCreateModal" :model="selectedActivity" @close="hideModals()"></activity-modal>

    <!--filter box-->
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <date-field
              v-model="filter.periodFrom"
              field-id="date"
              label="Dátum od"
              validation="required">
            </date-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <date-field
              v-model="filter.periodTo"
              field-id="date"
              label="Dátum do"
              validation="required">
            </date-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <select-field
              :options="activityStatuses"
              v-model="filter.status"
              :codelist="true"
              field-id="status"
              label="Stav">
            </select-field>
          </div>
          <div class="col-sm-6 col-md-3">
            <select-field
              :options="users"
              v-model="filter.assignee"
              field-id="assignee"
              label="Riešiteľ"
              :codelist="true">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <button class="btn btn-success" @click.prevent="createActivity()"><i class="fa fa-plus"></i> Pridať</button>
          </div>
        </div>
      </template>
    </box>
    <!--end of filter box-->

    <!--assigned activities data table-->
    <div class="row">
      <div class="col-md-12">
        <activities-table
          :activities="assignedActivities"
          :title="'Priradené aktivity'"
          :display-assignee="!filter.assignee">
        </activities-table>
      </div>
    </div>
    <!--end of assigned activities data table-->

    <!--un-assigned activities data table-->
    <div class="row">
      <div class="col-md-12">
        <activities-table
          :activities="unassignedActivities"
          :title="'Nepriradené aktivity'">
        </activities-table>
      </div>
    </div>
    <!--end of assigned activities data table-->
  </page>
</template>

<script>
  import selectField from '../../../components/form/selectField';
  import dateField from '../../../components/form/dateField';
  import activityStatuses from '../../../codelist/activityStatuses';
  import { mapGetters, mapState } from 'vuex';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import _ from 'lodash';
  import activityModal from 'modules/activity/components/activityCreateModal';
  import activityService from 'modules/activity/service/activityService';
  import activitiesTable from 'modules/activity/components/activitiesTable';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { dateField, selectField, activityModal, activitiesTable, page, box },
    mixins: [bookmarkableFilter],
    data: () => {
      return {
        filter: {
          periodFrom: null,
          periodTo: null,
          status: null,
          assignee: null
        },
        activityStatuses: activityStatuses,
        showCreateModal: false,
        selectedActivity: null
      };
    },
    computed: {
      ...mapGetters({
        users: 'user/active'
      }),
      ...mapState({
        assignedActivities (state) {
          if (this.filter.assignee) {
            return state.activity.items
              .filter(item =>
                !!item.assignee &&
                !!this.filter.assignee &&
                item.assignee.id === this.filter.assignee.id);
          } else {
            return state.activity.items.filter(item => !!item.assignee);
          }
        },
        unassignedActivities: (state) => state.activity.items.filter(item => !item.assignee),
        currentUser: (state) => state.auth.user,
        activities: (state) => state.activity.items
      })
    },
    methods: {
      createFilter () {
        return _.pickBy({
          periodFrom: FORMAT_SYSTEM_DATE(this.filter.periodFrom),
          periodTo: FORMAT_SYSTEM_DATE(this.filter.periodTo),
          status: this.filter.status || null
        }, _.identity);
      },
      createActivity () {
        this.selectedActivity = activityService.newActivity();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      defaultFilter () {
        this.filter.periodFrom = null;
        this.filter.periodTo = null;
        this.filter.status = 'PLANNED';
        this.filter.assignee = this.$store.state.auth.user.id;
      }
    },
    watch: {
      filter: {
        handler () {
          this.$store.dispatch('activity/getAllByFilter', this.createFilter());
        },
        deep: true
      }
    },
    mounted () {
      this.$store.dispatch('user/getAll', true);
      this.$store.dispatch('user/fetchInfo');
    }
  };
</script>
