  <template>
  <div v-if="order">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia objednávky" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6" v-if="order.type === 'INCOMING' || !!order.id">
            <text-field field-id="number"
                        label="Číslo"
                        placeholder="Zadaj číslo"
                        v-model="order.number"
                        :maxlength="100"
                        validation="required|min:1|max:100"></text-field>
        </div>
  </div>
    <div class="row" v-if="order.type === 'DELIVERY'">
          <div class="col-md-6">
            <select-field
              :options="mainOrderOptions"
              v-model="order.mainOrder"
              field-id="mainOrder"
              label="Prijatá objednávka"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="supplierOptions"
              v-model="order.supplier"
              field-id="supplier"
              label="Dodávateľ"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <select-field
              :options="subscriberOptions"
              v-model="order.subscriber"
              field-id="subscriber"
              label="Odberateľ"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <date-field v-model="order.validFrom" field-id="validFrom" label="Platná od" validation="required"></date-field>
          </div>
          <div class="col-md-6">
            <date-field v-model="order.validTo" field-id="validTo" label="Platná do" validation="required"></date-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <number-field
              v-model="order.mdNumber"
              label="Počet MD" placeholder="Zadaj počet MD"
              field-id="mdNumber"
              :min="1"
              :step="1"
              validation="required|numeric|max_value:99999|min_value:1">
            </number-field>
          </div>
          <div :class="{'col-md-3': !project.currency, 'col-md-6': project.currency}">
            <number-field
              v-model="order.mdRate"
              label="Cena za MD"
              placeholder="Zadaj cenu"
              :readonly="order.hasDelivery"
              field-id="mdRate"
              :min="1"
              :step="100"
              :suffix="project.currency | codeListValue(currencies)"
              validation="required|numeric|max_value:9999999|min_value:1">
            </number-field>
          </div>
          <div class="col-md-3" v-if="!project.currency">
            <select-field
              :options="currencies"
              v-model="order.currency"
              :codelist="true"
              field-id="currency"
              label="Mena"
              :readonly="!!project.currency"
              required
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="vatRates"
              v-model="order.vatRate"
              :codelist="true"
              field-id="vatRate"
              label="Sadzba DPH"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <number-field
              v-if="order.type === 'INCOMING'"
              v-model="order.invoiceDueDateOffset"
              label="Splatnosť faktúry v dňoch" placeholder="Zadaj počet dní"
              field-id="invoiceDueDateOffset"
              :min="1"
              :step="1"
              validation="required|numeric|max_value:99999|min_value:1">
            </number-field>
          </div>
        </div>
        <div class="row" v-if="order.type === 'INCOMING'">
          <div class="col-md-12">
            <text-area-field field-id="note"
                             v-model="order.invoiceItemText"
                             :maxlength="300"
                             :readonly='!invoiceItemText'
                             validation="max:300"
                             css-class="invoiceItemText">
              <template #label>
                <input type="checkbox" v-model="invoiceItemText"/> Text na faktúru
              </template>
            </text-area-field>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Suma objednávky</label>
              <span>{{ order.sum | currency(order.currency) }}</span>
            </div>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import { currencies } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import textField from 'components/form/textField';
  import dateField from 'components/form/dateField';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import modalMixin from './modalMixin';
  import textAreaField from 'components/form/textAreaField';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, textAreaField, textField, dateField, numberField, selectField, vForm },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      vatPayer: false,
      order: null,
      currencies: currencies,
      invoiceItemText: null
    }),
    computed: {
      ...mapState({
        companies: (state) => state.company.items,
        project: (state) => state.tmProject.selected,
        mainOrderOptions: (state) => state.tmProject.selected.incomingOrders
      }),
      vatRates () {
        return _.unionBy(this.$store.getters['vatRate/validItems'](this.order.validFrom), 'rate');
      },
      subscriberOptions () {
        return this.order.type === 'INCOMING'
        ? (this.$store.getters['subject/customers'])
          : (this.$store.getters['subject/ownershipped']);
      },
      supplierOptions () {
        return this.order.type === 'INCOMING'
        ? (this.$store.getters['subject/ownershipped'])
          : (this.$store.getters['subject/customers']);
      }
    },
    methods: {
      onSubmit () {
        if (this.order.validFrom.isAfter(this.order.validTo)) {
          this.unprotect();
          return notificationService.error('error.tmOrder.invalidDates');
        }
        let operation;
        if (this.order.id) {
          operation = 'tmOrder/update';
        } else {
          operation = 'tmOrder/create';
        }
        const order = _.cloneDeep(this.order);
        if (!this.invoiceItemText) {
          order.invoiceItemText = null;
        }
        this.$store.dispatch(operation, order).then((result) => {
          if (result) {
            notificationService.success('order.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.order = null;
      }
    },
    watch: {
      model: function (model) {
        this.order = _.cloneDeep(model);
        this.invoiceItemText = !!this.order.invoiceItemText;
        const lastOrder = this.order.type === 'INCOMING' ? this.project.lastIncomingOrder : this.project.lastDeliveryOrder;
        if (this.order && !this.order.id && lastOrder) {
          this.order.mdRate = lastOrder.mdRate;
          this.order.supplier = lastOrder.supplier;
          this.order.vatRate = lastOrder.vatRate;
          if (lastOrder.invoiceItemText) {
            this.invoiceItemText = true;
            this.order.invoiceItemText = lastOrder.invoiceItemText;
          }
          this.order.invoiceDueDateOffset = lastOrder.invoiceDueDateOffset || this.order.invoiceDueDateOffset;
        }
      },
      'order.mainOrder': function (mainOrder) {
        if (mainOrder && !this.order.id) {
          this.order.subscriber = mainOrder.supplier ? _.find(this.subscriberOptions, { value: mainOrder.supplier.id }) : null;
          this.order.validFrom = mainOrder.validFrom;
          this.order.validTo = mainOrder.validTo;
          this.order.mdNumber = mainOrder.mdNumber;
          this.order.vatRate = mainOrder.vatRate;
        }
      },
      'invoiceItemText': function (newInvoiceItemText, oldInvoiceItemText) {
        if (!newInvoiceItemText && oldInvoiceItemText) {
          this.order.invoiceItemText = null;
        }
      }
    },
    async created () {
      await this.$store.dispatch('vatRate/getAll');
      await this.$store.dispatch('subject/getAll');
    }
  };
</script>
