<template>
  <page title="Provízie">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-12 col-md-3 col-lg-3">
            <date-range-field v-model="filter.range" :no-label="true" label="Obdobie"></date-range-field>
          </div>
          <div class="col-md-2">
            <select-field
              :options="tmRewardStatuses"
              v-model="filter.status"
              :codelist="true"
              field-id="status"
              label="Stav">
            </select-field>
          </div>
          <div class="col-md-2">
            <select-field
              :options="subjects"
              v-model="filter.recipient"
              field-id="recipient"
              label="Príjemca"
              :codelist="true">
            </select-field>
          </div>
          <div class="col-md-2">
            <select-field
              :options="subjects"
              v-model="filter.sourceSubject"
              field-id="sourceSubject"
              label="Globálna odmena z"
              :codelist="true">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button :disabled="!selected.length" class="btn btn-success" @click="pay"><i class="fa fa-usd"></i> Zaplatiť</button>
            <button :disabled="!selected.length" class="btn btn-default" @click="exportRewards"><i class="fa fa-download"></i> Označené exportovať</button>
            <button :disabled="!selected.length" class="btn btn-default" @click="send"><i class="fa fa-envelope"></i> Odoslať emailom</button>
          </div>
        </div>
        <div class="row box-data-info">
          <div class="col-md-12">
            <loading id="tmRewards"></loading>
            <no-records :data="rewards" loading-id="tmRewards"></no-records>
          </div>
        </div>
      </template>
    </box>

    <template v-if="rewardGroups && rewardGroups.length" >
      <box v-for="(rewardGroup, index) in rewardGroups" :key="rewardGroup[0].recipient.id">
        <template #header>
          <h4>{{ rewardGroup[0].recipient.format() }}</h4>
        </template>
        <template #body>
          <div class="table-responsive">
            <table class="table no-padding table-hover no-padding">
              <tbody>
              <tr>
                <th><input type="checkbox" :value="index" v-model="selectedGroup" @change="updateSelected(rewardGroup, $event)"></th>
                <th>Projekt</th>
                <th>Dátum</th>
                <th>Suma</th>
                <th>Stav</th>
                <th>Šablóna</th>
                <th>Poznámka</th>
              </tr>
              <template v-for="reward in rewardGroup">
                <tr :class="{'text-muted': reward.status === 'PAID'}">
                  <td><input type="checkbox" :id="reward.id" :value="reward" v-model="selected"></td>
                  <project-link v-if="reward.project" :project="reward.project"></project-link>
                  <td>{{ reward.date | date }}</td>
                  <td>{{ reward.amount | currency(reward.currency) }}</td>
                  <td>{{ reward.status | codeListValue(tmRewardStatuses) }}</td>
                  <td><span
                    v-if="reward.template">{{ reward.template.type | codeListValue(tmRewardTemplateTypes) }}</span></td>
                  <td>{{ reward.note }}</td>
                </tr>
              </template>
              </tbody>
              <tfoot>
              <tr>
                <th>Celkom</th>
                <th>
                  <span v-if="sumAmount(rewardGroup, 'CZK') > 0">{{ sumAmount(rewardGroup, 'CZK') | currency('CZK') }}</span>
                  <span v-if="sumAmount(rewardGroup, 'CZK') > 0 && sumAmount(rewardGroup, 'EUR') > 0">,&nbsp;&nbsp;</span>
                  <span v-if="sumAmount(rewardGroup, 'EUR') > 0">{{ sumAmount(rewardGroup, 'EUR') | currency('EUR') }}</span>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>
        </template>
      </box>
    </template>
  </page>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { TM_REWARDS_DOCUMENT_NAME, TM_REWARDS_DUE_DATE_DAY_OFFSET, GENERATED_DOCUMENT_CSV_SUFFIX } from 'config';
  import { FORMAT_DATE_PERIOD, normalizeFilename } from 'utils';
  import { tmRewardStatuses, tmRewardTemplateTypes } from 'codelist/';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import notificationService from 'services/notificationService';
  import emailService from '../services/emailService';
  import { FORMAT_DATE_PERIOD_SLASH, FORMAT_SYSTEM_DATE } from 'utils';
  import tmRewardService from '../services/tmRewardService';
  import currencyFilter from 'filters/currencyFilter';
  import projectLink from 'modules/project/components/projectLink';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';
  import Range from 'components/range';
  import dateRangeField from 'components/form/dateRangeField';

  const now = moment();

  export default {
    components: { loading, noRecords, selectField, projectLink, page, box, dateRangeField },
    mixins: [bookmarkableFilter],
    data: () => ({
      tmRewardStatuses: tmRewardStatuses,
      tmRewardTemplateTypes: tmRewardTemplateTypes,
      filter: {
        range: null,
        sourceSubject: null,
        status: null,
        recipient: null
      },
      fetching: false,
      selected: [],
      selectedGroup: [] // required only for unselect group checkboxes
    }),
    computed: {
      ...mapState({
        rewards: (state) => state.tmReward.items,
        rewardGroups: (state) => _(state.tmReward.items)
          .groupBy(reward => reward.recipient.id)
          .values()
          .value(),
        subjects: (state) => state.subject.items
      }),
    },
    methods: {
      unselectAll () {
        this.selected = [];
        this.selectedGroup = [];
      },
      updateSelected (rewards, event) {
        const checked = event.target.checked;

        if (checked) { // rewards should be removed
          this.selected = _.unionBy(this.selected, rewards, 'id');
        } else { // rewards should be added
          this.selected = _.differenceBy(this.selected, rewards, 'id');
        }
      },
      createFilter () {
        const periodFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const periodTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        return _.pickBy({
          periodFrom,
          periodTo,
          status: this.filter.status ? this.filter.status : null,
          recipient: this.filter.recipient ? this.filter.recipient : null,
          sourceSubject: this.filter.sourceSubject ? this.filter.sourceSubject : null
        }, _.identity);
      },
      sumAmount (rewards, currency) {
        return _(rewards).filter({ 'currency': currency }).map('amount').sum();
      },
      pay () {
        if (_.some(this.selected, { status: 'PAID' })) {
          return notificationService.error('error.tmRewards.pay.alreadyPaid');
        }
        this.confirm('tmRewards.pay.confirmation').then(() => {
          this.$store.dispatch('tmReward/pay', _.map(this.selected, 'id')).then((result) => {
            if (result) {
              notificationService.success('tmRewards.pay.success');
            }
            this.unselectAll();
          }).catch(this.unprotect);
        });
      },
      exportRewards () {
        const baseName = TM_REWARDS_DOCUMENT_NAME;
        const fileSuffix = GENERATED_DOCUMENT_CSV_SUFFIX;
        const dateFrom = FORMAT_DATE_PERIOD(_.minBy(this.selected, 'date').date.format());
        const dateTo = FORMAT_DATE_PERIOD(_.maxBy(this.selected, 'date').date.format());
        const recipients = _(this.selected).flatMap('recipient').uniqBy('id').map(recipient => recipient.format()).value().join('-');
        const tmpName = normalizeFilename(`${recipients}-${dateFrom}-${dateTo}`);
        const result = `${baseName}-${tmpName}${fileSuffix}`;
        tmRewardService.exportRewards(_.map(this.selected, 'id'), result);
      },
      send () {
        if (_(this.selected).flatMap('recipient').uniqBy('id').value().length > 1) {
          return notificationService.error('error.tmRewards.email.multipleRecipients');
        }
        const recipient = _(this.selected).flatMap('recipient').first();
        const dateFrom = FORMAT_DATE_PERIOD_SLASH(_.minBy(this.selected, 'date').date.format());
        const dateTo = FORMAT_DATE_PERIOD_SLASH(_.maxBy(this.selected, 'date').date.format());
        const totalAmounts = [];
        const amountCZK = _(this.selected).filter({ 'currency': 'CZK' }).flatMap('amount').sum();
        const amountEUR = _(this.selected).filter({ 'currency': 'EUR' }).flatMap('amount').sum();
        if (amountCZK) {
          totalAmounts.push(`${currencyFilter(amountCZK, 'CZK')}`);
        }
        if (amountEUR) {
          totalAmounts.push(`${currencyFilter(amountEUR, 'EUR')}`);
        }
        emailService.prepareEmailForSubject(
          recipient,
          'email.tmRewards.subject', // subject template key
          'email.tmRewards.body', // body template key
          [dateFrom, dateTo], // subject template parameters
          [dateFrom, dateTo, totalAmounts.join(', '), TM_REWARDS_DUE_DATE_DAY_OFFSET]); // body template parameters
      },
      defaultFilter () {
        this.filter.range = new Range(moment().subtract(1, 'month'), moment());
        this.filter.status = 'NEW';
      }
    },
    watch: {
      filter: {
        handler () {
          this.$store.dispatch('tmReward/getAll', this.createFilter());
        },
        deep: true
      }
    },
    created () {
      this.$store.dispatch('tmReward/getAll', this.createFilter());
      this.$store.dispatch('subject/getAll');
    }
  };
</script>
