import Vue from 'vue';

export default (store) => {
  Vue.mixin({
    methods: {
      protected: () => {
        return store.state.app.submitProtection;
      },
      isProtected: () => { // same as "protected" - fix of parsing problem in vue files and "protected" reserved word
        return store.state.app.submitProtection;
      },
      async protect () {
        await store.dispatch('app/protect');
      },
      async unprotect () {
        await store.dispatch('app/unprotect');
      }
    }
  });
};
