<template>
  <div class="login-page">
    <div class="login-box" v-if="!loggedIn">
      <div class="login-logo">
        <a><b>BrainIs</b></a>
      </div>
      <div class="login-box-body">
        <v-form ref="form">
          <text-field field-id="username"
                      label="Prihlasovacie meno"
                      placeholder="Zadaj svoje prihlasovacie meno"
                      :maxlength="100"
                      v-model="credentials.username"
                      validation="required|min:5|max:100"
                      :focus="true"></text-field>
          <text-field field-id="password"
                      label="Heslo"
                      placeholder="Zadaj svoje heslo"
                      v-model="credentials.password"
                      :maxlength="20"
                      type="password"
                      validation="required|min:5|max:20"></text-field>
          <div class="row">
            <div class="col-xs-12">
              <div class="pull-right">
                <button class="btn btn-primary btn-block btn-flat" type="submit" @click.prevent="submit($refs.form)">Prihlásiť</button>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>

  import authService from 'services/authService';
  import validationMixin from 'components/validationMixin';
  import textField from 'components/form/textField';
  import vForm from 'components/form/vForm';
  import { mapGetters } from 'vuex';

  export default {
    components: { textField, vForm },
    mixins: [validationMixin],
    data: () => ({
      credentials: { username: '', password: '' }
    }),
    computed: {
      ...mapGetters({
        loggedIn: 'auth/loggedIn'
      })
    },
    methods: {
      onSubmit () {
        this.$store.dispatch('auth/logIn', this.credentials).then((loggedIn) => {
          if (loggedIn) {
            this.$router.push(authService.mainPath(this.$router.currentRoute));
          }
        }).finally(this.unprotect);;
      }
    }
  };

</script>
