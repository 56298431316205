import companiesView from 'modules/company/components/companies';
import companyView from 'modules/company/components/company';
import companyDocumentsView from 'modules/company/components/companyDocuments';
import companyAccountView from 'modules/company/components/companyAccount';
import companyBankAccounts from 'modules/company/components/companyBankAccounts';
import companyContactsView from 'modules/company/components/companyContacts';
import companyRecordsView from 'modules/company/components/companyRecords';
import companyActivitiesView from 'modules/company/components/companyActivities';

export default [
  {
    path: '/companies',
    component: companiesView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/company/:id',
    name: 'company',
    component: companyView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    },
    redirect: { name: 'companyRecords' },
    children: [
      {
        path: 'documents',
        name: 'companyDocuments',
        component: companyDocumentsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'account',
        name: 'companyAccount',
        component: companyAccountView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'bank-accounts',
        name: 'companyBankAccounts',
        component: companyBankAccounts,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'contacts',
        name: 'companyContacts',
        component: companyContactsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'records',
        name: 'companyRecords',
        component: companyRecordsView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      },
      {
        path: 'activities',
        name: 'companyActivities',
        component: companyActivitiesView,
        meta: {
          requiresLoggedIn: true,
          requiresRole: 'ADMIN'
        }
      }
    ]
  }
];
