import { LIST } from 'store/mutationTypes';

const mutations = {
  [LIST] (state, action) {
    state.items = action.items;
    state.entity = action.entity;
    state.entityId = action.entityId;
  }
};

export default mutations;
