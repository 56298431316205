import documentTemplateResource from '../resources/documentTemplateResource';
import _ from 'lodash';
import DocumentTemplate from 'modules/document/domain/documentTemplate';

export default {
  newDocumentTemplate () {
    return new DocumentTemplate({});
  },
  async findAll () {
    const response = await documentTemplateResource.query();
    if (response.ok) {
      return response.data.map((documentTemplateData) => new DocumentTemplate(documentTemplateData));
    } else {
      return null;
    }
  },
  async create (documentTemplate) {
    const response = await documentTemplateResource.save({}, _.pickBy(documentTemplate));
    if (response.ok) {
      return new DocumentTemplate(response.data);
    } else {
      return null;
    }
  },
  async update (documentTemplate) {
    const response = await documentTemplateResource.update({}, _.pickBy(documentTemplate));
    if (response.ok) {
      return new DocumentTemplate(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return documentTemplateResource.delete({ id: id });
  }
};
