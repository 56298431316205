import actions from './contactActions';
import mutations from './contactMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
