'use strict';

export default {
  loggedIn: state => {
    return !!state.accessToken && state.validTo.isAfter();
  },
  hasRole: (state, getters) => (role) => {
    return getters.loggedIn && state.user && state.user.roles.includes(role);
  }
};
