'use strict';
import $ from 'jquery';

export default () => {
  return (to, from, next) => {
    if (from.meta.bodyClass) {
      $('body').removeClass(from.meta.bodyClass);
    }

    if (to.meta.bodyClass) {
      $('body').addClass(to.meta.bodyClass);
    }
    next();
  };
};
