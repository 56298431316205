<template>
  <div>
    <template v-if="movement">
      <i v-if="showIcon" :class="{'fa-arrow-down text-danger': movement.type === 'OUTCOME', 'fa-arrow-up text-success': movement.type === 'INCOME'}"
        class="fa"></i>
      <strong v-if="movement.subject">
        {{ movement.subject.format() }}
      </strong>
      <span v-else> --- </span>
      <div v-if="movement.category || movement.note">
        <span v-if="movement.category" class="label label-default">{{ movement.category.label }}</span>
        <span v-if="movement.note" class="text-description">Poznámka: <truncate :content="movement.note" :min-length="50"></truncate></span>
      </div>
    </template>
  </div>
</template>
<script>
  import MoneyMovement from "../domain/moneyMovement";
  import truncate from 'components/truncate';

  export default {
    components: {truncate},
    props: {
      movement: {
        type: MoneyMovement,
        required: false
      },
      showIcon: {
        type: Boolean,
        default: true,
        required: false
      }
    }
  };
</script>
