<template>
  <div v-if="invoiceSeries">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia číselnej rady faktúry" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="companies"
              v-model="invoiceSeries.company"
              field-id="company"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="name"
                        label="Prefix číselnej rady"
                        placeholder="Zadaj prefix"
                        v-model="invoiceSeries.prefix"
                        :maxlength="5"
                        validation="required|max:5"></text-field>
          </div>
          <div class="col-md-6">
            <checkbox-field
              field-id="defaultFlag"
              label="Predvolená č. rada"
              v-model="invoiceSeries.defaultFlag"
              :middle="true">
            </checkbox-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="years"
              v-model="invoiceSeries.year"
              :codelist="true"
              field-id="year"
              label="Rok"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <number-field
              v-model="invoiceSeries.index"
              label="Aktuálny index"
              placeholder="Index"
              field-id="index"
              :min="1"
              :max="9999999"
              :step="1"
              validation="required|numeric|min_value:1|max_value:9999999">
            </number-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapGetters } from 'vuex';
  import years from 'codelist/years';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import checkboxField from 'components/form/checkboxField';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, selectField, numberField, checkboxField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      invoiceSeries: null,
      years
    }),
    computed: {
      ...mapGetters({
        companies: 'company/ownershipped'
      }),
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.invoiceSeries.id) {
          operation = 'invoiceSeries/update';
        } else {
          operation = 'invoiceSeries/create';
        }
        const invoiceSeries = _.cloneDeep(this.invoiceSeries);
        this.$store.dispatch(operation, invoiceSeries).then((result) => {
          if (result) {
            notificationService.success('invoiceSeries.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.invoiceSeries = null;
      }
    },
    watch: {
      model: function (model) {
        this.invoiceSeries = _.cloneDeep(model);
      }
    },
    created () {
      this.$store.dispatch('company/getAll');
    }
  };
</script>
