import Entity from './entity';
import moment from 'moment';

class CodeList extends Entity {
  constructor (data) {
    super(data);

    this.label = this.label || null;
    this.shortDescription = this.shortDescription || null;
    this.validFrom = this.validFrom ? this.convertDate(this.validFrom).startOf('d') : null;
    this.validTo = this.validTo ? this.convertDate(this.validTo).endOf('d') : null;

    this.value = this.id;
  }

  validInDate (date) {
    return !this.validTo || this.validTo.isSameOrAfter(date || moment());
  }

  get valid () {
    return !this.validTo || this.validTo.isSameOrAfter(moment());
  }

  set valid (val) {
    if (val) {
      this.validTo = null;
    } else {
      this.validTo = moment().subtract(1, 'day');
    }
  }
}

export default CodeList;
