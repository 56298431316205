import actions from './tmDeliveryActions';
import mutations from './tmDeliveryMutations';

const state = {
  filter: null,
  items: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
