<template>
  <div v-if="moneyBox">
    <modal :backdrop="false" v-model="show" @ok="submit()" @cancel="cancel($event)" @closed="onClose()" :title="(isEdit() ? 'Upraviť' : 'Pridať') + ' pokladňu alebo účet'" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
        <div class="row">
          <div class="col-md-12">
            <radio-field
              field-id="type"
              label="Typ"
              v-model="moneyBox.type"
              :options="moneyBoxTypes"
              validation="required">
            </radio-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Názov"
                        placeholder="Zadaj názov účtu"
                        v-model="moneyBox.name"
                        :maxlength="200"
                        validation="required|min:3|max:200"></text-field>
          </div>
        </div>
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <select-field
              label="Firma"
              :options="companies"
              field-id="company"
              v-model="moneyBox.company"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6" v-if="moneyBox.type === 'CASH'">
            <select-field
              label="Mena"
              :options="currencies"
              field-id="currency"
              v-model="moneyBox.currency"
              :codelist="true"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6" v-if="moneyBox.type === 'BANK'">
            <select-field
              label="Prepojený účet"
              :options="bankAccounts"
              field-id="bankAccount"
              v-model="moneyBox.bankAccount"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row" v-if="isEdit()">
          <div class="col-md-12">
            <checkbox-field
              field-id="active"
              label="Vyradené"
              v-model="moneyBox.active"
              :middle="false"
              :reverse="true">
            </checkbox-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { currencies } from 'codelist/';
  import moneyBoxTypes from '../domain/moneyBoxTypes';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import checkboxField from 'components/form/checkboxField';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import selectField from 'components/form/selectField';
  import radioField from 'components/form/radioField';
  import { mapGetters } from 'vuex';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, selectField, radioField, checkboxField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      moneyBox: null,
      currencies,
      moneyBoxTypes
    }),
    computed: {
      ...mapGetters({
        companies: 'company/ownershipped'
      }),
      bankAccounts () {
        if (!this.moneyBox || !this.moneyBox.company) {
          return [];
        } else {
          return _(this.$store.getters['bankAccount/active'])
            .filter(bankAccount => bankAccount.subject.id === this.moneyBox.company.id)
            .value();
        }
      },
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.moneyBox.id) {
          operation = 'moneyBox/update';
        } else {
          operation = 'moneyBox/create';
        }
        const moneyBox = _.cloneDeep(this.moneyBox);

        if (moneyBox.type === 'BANK') {
          //currency will be set from bank account settings
          moneyBox.currency = null;
        } else {
          //clear bank account if is not BANK type
          moneyBox.bankAccount = null;
        }

        this.$store.dispatch(operation, moneyBox).then((result) => {
          if (result) {
            notificationService.success('moneyBox.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.moneyBox = null;
      }
    },
    watch: {
      model: function (model) {
        this.moneyBox = _.cloneDeep(model);
      },
      'moneyBox.company' () {
        if (!this.moneyBox) {
          return;
        }
        // if company has been changed, clear bank account if selected
        if (!this.moneyBox.company || (this.moneyBox.bankAccount
          && this.moneyBox.company
          && this.moneyBox.bankAccount.subject.id !== this.moneyBox.company.id)) {
          this.moneyBox.bankAccount = null;
        }
        // if only one bank account is available, select it
        if (this.moneyBox.company && !this.moneyBox.bankAccount && this.bankAccounts.length === 1) {
          this.moneyBox.bankAccount = this.bankAccounts[0];
        }
      }
    },
    mounted () {
      this.$store.dispatch('bankAccount/findOwnershipped');
      this.$store.dispatch('company/getAll');
    }
  };
</script>
