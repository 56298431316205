import bankAccountsView from 'modules/bank/components/adminBankAccounts';

export default [
  {
    path: '/bank-accounts',
    component: bankAccountsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  }
];
