import Entity from './entity';
import subjectService from 'modules/subject/service/subjectService';
import TmProject from 'modules/project/domain/tmProject';

class TmRewardTemplate extends Entity {
  constructor (data) {
    super(data);
    this.type = data.type || null;
    this.rewardShare = data.rewardShare || null;
    this.fixedRate = data.fixedRate || null;
    this.recipient = data.recipient ? subjectService.getSubject(data.recipient) : null;
    this.sourceSubject = data.sourceSubject ? subjectService.getSubject(data.sourceSubject) : null;
    this.sourceProject = data.sourceProject ? new TmProject(data.sourceProject) : null;
  }
}

export default TmRewardTemplate;
