<template>
  <page v-if="project" :title="project.name">
    <box class="compact">
      <template #body>
        <tm-project-modal :show-model="showCreateModal" :model="selectedProject" @close="hideModals()"></tm-project-modal>

        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i class="fa fa-gear"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" :disabled="!project.editable">
                  <li><a v-show="project.editable" role="button" @click.prevent="editProject(project)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                  <li><a v-show="project.editable" role="button" @click.prevent="closeProject(project)"><i class="fa fa-toggle-on text-success"></i> Uzavrieť</a></li>
                  <li><a v-show="!project.editable" role="button" @click.prevent="openProject(project)"><i class="fa fa-toggle-off text-success"></i> Znovu otvoriť</a></li>
                  <!--<li><a role="button" @click.prevent="deleteCompany(company)"><i class="fa fa-times text-danger"></i> Zmazať</a></li>-->
                  <!--<li><a role="button" @click.prevent="toggleCompanyActive(company)"><i :class="{'fa-toggle-on': company.active, 'fa-toggle-off text-success': !company.active}" class="fa"></i> {{ company.active ? 'Deaktivovať' : 'Aktivovať' }}</a></li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <dl>
              <dt>Zákazník</dt>
              <dd><subject-link v-if="project.customer" :subject="project.customer"></subject-link></dd>
            </dl>
            <dl>
              <dt>Stav</dt>
              <dd>{{ project.status | codeListValue(projectStatuses) }}</dd>
            </dl>
          </div>
          <div class="col-md-3">
            <dl>
              <dt>Suma dodávok</dt>
              <dd>{{ project.deliveriesSum | currency(project.currency) }}</dd>
            </dl>
            <dl>
              <dt>Suma provízií</dt>
              <dd>{{ project.rewardsSum | currency(project.currency) }}</dd>
            </dl>
          </div>
          <div class="col-md-3">
            <dl>
              <dt>Suma prijatých objednávok</dt>
              <dd>{{ project.incomingOrdersSum | currency(project.currency) }}</dd>
            </dl>
            <dl>
              <dt>Suma dodávkových objednávok</dt>
              <dd>{{ project.deliveryOrdersSum | currency(project.currency) }}</dd>

            </dl>
          </div>
          <div class="col-md-3">
            <dl>
              <dt>Zisk</dt>
              <dd>{{ project.profit | currency(project.currency) }}</dd>
            </dl>
            <dl>
              <dt>Zisk bez provizií</dt>
              <dd>{{ project.profitClear | currency(project.currency) }}</dd>
            </dl>
          </div>
        </div>
        <div class="row" v-if="project.note">
          <div class="col-md-12">
            <dl>
              <dt>Poznámka</dt>
              <dd>{{ project.note }}</dd>
            </dl>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <ul id="tabs" class="nav nav-tabs" data-tabs="tabs">
              <router-link tag="li" :to="{ name: 'projectIncomingOrders', params: { id: project.id }}" active-class="active"><a>Prijaté objednávky</a></router-link>
              <router-link tag="li" :to="{ name: 'projectDeliveryOrders', params: { id: project.id }}" active-class="active"><a>Dodávkové objednávky</a></router-link>
              <router-link tag="li" :to="{ name: 'projectDeliveries', params: { id: project.id }}" active-class="active"><a>Dodávky</a></router-link>
              <router-link tag="li" :to="{ name: 'projectRewards', params: { id: project.id }}" active-class="active"><a>Provízie</a></router-link>
              <router-link tag="li" :to="{ name: 'projectRecords', params: { id: project.id }}" active-class="active"><a>Záznamy</a></router-link>
              <router-link tag="li" :to="{ name: 'projectActivities', params: { id: project.id }}" active-class="active"><a>Aktivity</a></router-link>
            </ul>
            <div id="my-tab-content" class="tab-content">
              <div class="tab-pane active" >
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </template>
    </box>
    <loading class="content-header" id="tmProject"></loading>
  </page>
</template>

<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import { projectStatuses } from 'codelist/';
  import loading from 'components/loading';
  import tmProjectModal from 'modules/project/components/tmProjectCreateModal';
  import notificationService from 'services/notificationService';
  import subjectLink from 'components/subjectLink';
  import page from 'components/page';
  import box from 'components/box';

  export default {

    components: { loading, tmProjectModal, subjectLink, page, box },
    data: () => ({
      projectStatuses: projectStatuses,
      selectedProject: null,
      showCreateModal: false
    }),
    computed: mapState({
      project: (state) => state.tmProject.selected
    }),
    methods: {
      hideModals () {
        this.showCreateModal = false;
      },
      editProject (project) {
        this.selectedProject = _.cloneDeep(project);
        this.showCreateModal = true;
      },
      closeProject (project) {
        this.confirm('tmProject.close.confirmation', [project.name]).then(() => {
          this.$store.dispatch('tmProject/close').then((result) => {
            if (result) {
              notificationService.success('tmProject.close.success');
            }
          }).catch(this.unprotect);
        });
      },
      openProject (project) {
        this.confirm('tmProject.open.confirmation', [project.name]).then(() => {
          this.$store.dispatch('tmProject/open').then((result) => {
            if (result) {
              notificationService.success('tmProject.open.success');
            }
          }).catch(this.unprotect);
        });
      }
    },
    created () {
      this.$store.dispatch('tmProject/select', this.$store.state.route.params.id);
    }
  };
</script>
