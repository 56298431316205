<template>
  <div>
    <div>
      <button v-show="project.editable" class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať províziu</button>
    </div>
    <reward-modal :show-model="showCreateModal" :model="selectedTmReward" @close="hideModals()"></reward-modal>
    <div>
      <div>
        <table class="table table-responsive no-padding table-hover" v-if="project && project.rewards.length">
          <thead>
            <tr>
              <th>Dátum</th>
              <th>Príjemca</th>
              <th>Status</th>
              <th>Dodávka</th>
              <th>Suma</th>
              <th>Šablóna</th>
              <th v-show="project.editable"></th>
            </tr>
          </thead>
          <tbody>

          <template v-for="reward in project.rewards">
            <tr :class="{inactive : !reward.status === 'PAID'}">
              <td>{{ reward.date | date }}</td>
              <td>
                <subject-link v-if="reward.recipient" :subject="reward.recipient"></subject-link>
              </td>
              <td>{{ reward.status | codeListValue(tmRewardStatuses) }}</td>
              <td><span v-if="reward.delivery">{{ reward.delivery.period | dateMonth }}</span></td>
              <td>{{ reward.amount | currency(reward.currency) }}</td>
              <td><span v-if="reward.template">{{ reward.template.type | codeListValue(tmRewardTemplateTypes) }}</span></td>
              <td class="text-right" v-show="project.editable">
                <button class="btn btn-xs btn-link" :disabled="!reward.editable" @click.stop="editTmReward(reward)"><i class="fa fa-2x fa-pencil-square-o text-primary" title="Editovať"></i></button>
                <button class="btn btn-xs btn-link" :disabled="!reward.editable" @click.stop="deleteTmReward(reward)"><i class="fa fa-2x fa-times text-danger" title="Zmazať"></i></button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import rewardModal from 'components/tmRewardCreateModal';
  import tmRewardTemplateTypes from 'codelist/tmRewardTemplateTypes';
  import tmRewardStatuses from 'codelist/tmRewardStatuses';
  import tmRewardService from 'services/tmRewardService';
  import notificationService from 'services/notificationService';
  import subjectLink from 'components/subjectLink';

  export default {
    components: { rewardModal, subjectLink },
    data: () => ({
      tmRewardTemplateTypes: tmRewardTemplateTypes,
      tmRewardStatuses: tmRewardStatuses,
      selectedTmReward: null,
      showCreateModal: false
    }),
    computed: mapState({
      project: (state) => state.tmProject.selected
    }),
    methods: {
      createShow () {
        if (!this.project.currency) {
          return notificationService.error('error.tmReward.create.emptyProject');
        }
        this.selectedTmReward = tmRewardService.newTmReward(this.project);
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      deleteTmReward (reward) {
        this.confirm('tmReward.delete.confirmation', [reward.name]).then(() => {
          this.$store.dispatch('tmReward/delete', reward.id).then((result) => {
            if (result) {
              notificationService.success('tmReward.delete.success');
            }
          }).catch(this.unprotect);
        });
      },
      editTmReward (reward) {
        this.selectedTmReward = _.cloneDeep(reward);
        this.showCreateModal = true;
      }
    },
    created () {
      this.$store.dispatch('subject/getAll');
    }
  };
</script>
