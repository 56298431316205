<template>
  <v-form ref="form">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <box class="compact">
          <template #header>
            <h4>Dodávateľ</h4>
          </template>
          <template #body>
            <select-field
              :options="supplierOptions"
              v-model="invoice.supplier"
              :clearable="false"
              field-id="supplier"
              validation="required">
            </select-field>
          </template>
        </box>
      </div>
      <div class="col-md-4 col-lg-4">
        <box>
          <template #header>
            <h4>Odberateľ</h4>
          </template>
          <template #body>
            <select-field
              :options="subscriberOptions"
              v-model="invoice.subscriber"
              :clearable="false"
              field-id="subscriber"
              validation="required">
            </select-field>
          </template>
        </box>
      </div>
      <div class="col-md-4 col-lg-4">
        <box>
          <template #header>
            <h4 v-if="outcome && !isEdit()">Číselá rada faktúry</h4>
            <h4 v-else>Číslo faktúry</h4>
          </template>
          <template #body>
            <select-field
              v-if="outcome && !isEdit()"
              :options="invoiceSeries"
              :clearable="false"
              v-model="invoice.invoiceSeries"
              field-id="invoiceSeries"
              validation="required">
            </select-field>
            <text-field v-else
                        field-id="number"
                        v-model="invoice.number"
                        :maxlength="20"
                        validation="required|max:20"></text-field>
          </template>
        </box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <box>
          <template #header>
            <h4>Platobné nastavenie</h4>
          </template>
          <template #body>
            <div class="row">
              <div class="col-md-3">
                <date-field v-model="invoice.dateOfIssue" field-id="dateOfIssue" label="Dátum vystavenia" validation="required" :show-arrows="false"></date-field>
              </div>
              <div class="col-md-3">
                <date-field v-model="invoice.taxableDate" field-id="dateOfIssue" label="Dátum zdan. plnenia" validation="required" :show-arrows="false"></date-field>
              </div>
              <div class="col-md-3">
                <select-field
                  :options="paymentMethods"
                  v-model="invoice.paymentMethod"
                  :codelist="true"
                  :clearable="false"
                  field-id="paymentMethod"
                  label="Spôsob platby"
                  validation="required">
                </select-field>
              </div>
              <div class="col-md-3">
                <date-field v-model="invoice.dueDate" field-id="dueDate" label="Splatnosť" validation="required" :show-arrows="false" :rtl="true"></date-field>
              </div>
            </div>
          </template>
        </box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <box>
          <template #header>
            <h4>Ďalšie nastavenie</h4>
          </template>
          <template #body>
            <div class="row">
              <div class="col-md-3 col-lg-2">
                <select-field
                  :options="currencies"
                  v-model="invoice.currency"
                  :codelist="true"
                  :clearable="false"
                  field-id="currency"
                  label="Mena"
                  validation="required">
                </select-field>
              </div>
              <div class="col-md-6 col-lg-4">
                <select-field
                  field-id="bankAccounts"
                  label="Bankový účet"
                  :clearable="false"
                  :options="bankAccounts"
                  v-model="invoice.bankAccount"
                  label-prop="formatId"
                  validation="required">
                </select-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-6">
                <text-field field-id="reference"
                            label="Referencia (napr. objednávka)"
                            v-model="invoice.reference"
                            :maxlength="200"
                            validation="max:200"></text-field>
              </div>
              <div class="col-md-3 col-lg-2">
                <text-field field-id="variableSymbol"
                            label="Variabilný symbol"
                            v-model="invoice.variableSymbol"
                            :maxlength="10"
                            :readonly="!isEdit() && outcome"
                            :validation-obj="{max: 10, regex: /^[0-9]{0,10}$/}"></text-field>
              </div>
              <div class="col-md-3 col-lg-2">
                <text-field field-id="constantSymbol"
                            label="Konštantný symbol"
                            v-model="invoice.constantSymbol"
                            :maxlength="4"
                            :validation-obj="{max: 4, regex: /^[0-9]{0,4}$/}"></text-field>
              </div>
              <div class="col-md-3 col-lg-2">
                <text-field field-id="specificSymbol"
                            label="Špecifický symbol"
                            v-model="invoice.specificSymbol"
                            :maxlength="10"
                            :validation-obj="{max: 10, regex: /^[0-9]{0,10}$/}"></text-field>
              </div>
            </div>
          </template>
        </box>
      </div>
    </div>
    <box v-if="outcome">
      <template #header>
        <h4>Položky faktúry</h4>
      </template>
      <template #body>
        <div class="row d-flex" v-for="(item, index) in invoice.items">
          <div class="col-md-6 pr-0">
            <text-area-field :field-id="'item-name-' + index"
                        label="Položka"
                        v-model="item.name"
                        :maxlength="300"
                        validation="required|max:300"></text-area-field>
          </div>
          <div class="col-md-2 pr-0">
            <number-field
              v-model="item.number"
              label="Množstvo"
              :field-id="'item-number-' + index"
              :min="0"
              :step="1"
              validation="required|decimal:5|max_value:99999999.99999|min_value:0">
            </number-field>
          </div>
          <div class="col-auto pr-0">
            <select-field
              :options="invoiceItemUnits"
              v-model="item.unit"
              :codelist="true"
              :clearable="false"
              :field-id="'item-unit-' + index"
              label="MJ"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-2 pr-0">
            <number-field
              v-model="item.price"
              label="Cena"
              :field-id="'item-price-' + index"
              :step="1"
              validation="required|decimal:2|max_value:99999999.99|min_value:-99999999.99">
            </number-field>
          </div>
          <div class="col-auto pr-0">
            <select-field
              :options="vatRates"
              v-model="item.vatRate"
              :codelist="true"
              :clearable="false"
              :field-id="'item-vat-rate-' + index"
              label="Sadzba DPH"
              validation="required">
            </select-field>
          </div>
          <div class="col-auto buttons">
            <a role="button" class="link-input-line" v-show="invoice.items.length > 1" @click.prevent="deleteItem(item)"><i class="fa fa-2x fa-times text-danger"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-success" type="button" @click.prevent="addItem()"><i class="fa fa-plus"></i></button>
          </div>
        </div>
      </template>
    </box>
    <box v-if="income">
      <template #header>
        <h4>Celkom</h4>
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-3 pull-right">
            <number-field v-model="invoice.totalVat"
                          label="DPH"
                          placeholder="Zadaj DPH"
                          field-id="totalVat"
                          :step="100"
                          :suffix="invoice.currency | codeListValue(currencies)"
                          validation="required|decimal:2|max_value:99999999.99|min_value:0" ></number-field>
          </div>
          <div class="col-md-3 pull-right">
            <number-field v-model="invoice.totalAmount"
                          label="Celkom"
                          placeholder="Zadaj sumu"
                          field-id="totalAmount"
                          :step="100"
                          :suffix="invoice.currency | codeListValue(currencies)"
                          validation="required|decimal:2|max_value:99999999.99|min_value:0.01" ></number-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr>
            <router-link tag="button" type="button" class="btn btn-default pull-left" :to="'/invoices/' + invoice.type">Zrušiť</router-link>
            <button class="btn btn-success pull-right" :disabled="isProtected()" @click.prevent="submit($refs.form)">{{ isEdit() ? 'Uložiť' : 'Pridať faktúru' }}</button>
          </div>
        </div>
      </template>
    </box>
    <div class="row" v-if="outcome">
      <div class="col-md-12">
        <invoice-summary-box :invoice="invoice">
          <div class="row">
            <div class="col-md-12">
              <hr>
              <router-link tag="button" type="button" class="btn btn-default pull-left" :to="'/invoices/' + invoice.type">Zrušiť</router-link>
              <button class="btn btn-success pull-right" :disabled="isProtected()" @click.prevent="submit($refs.form)">{{ isEdit() ? 'Uložiť' : 'Pridať faktúru' }}</button>
            </div>
          </div>
        </invoice-summary-box>
      </div>
    </div>
  </v-form>
</template>
<script>
  import _ from 'lodash';
  import BankAccount from "../../bank/domain/bankAccount";
  import box from 'components/box';
  import { currencies, paymentMethods, invoiceItemUnits } from 'codelist/';
  import dateField from 'components/form/dateField';
  import Invoice from "../domain/invoice";
  import invoiceService from '../service/invoiceService';
  import invoiceSummaryBox from './invoiceSummaryBox';
  import { mapState } from 'vuex';
  import moment from 'moment';
  import notificationService from 'services/notificationService';
  import numberField from 'components/form/numberField';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import textField from 'components/form/textField';
  import vForm from 'components/form/vForm';
  import validationMixin from 'components/validationMixin';

  export default {
    data: () => ({
      currencies,
      paymentMethods,
      invoiceItemUnits,
      dueDateOffset: null
    }),
    props: {
      ...mapState({
        configuration: (state) => state.app.configuration
      }),
      invoice: {
        type: Invoice,
        required: true
      },
      supplierOptions:  {
        type: Array,
        default () {
          return [];
        }
      },
      subscriberOptions:  {
        type: Array,
        default () {
          return [];
        }
      }
    },
    components: {vForm, box, selectField, dateField, numberField, textAreaField, textField, invoiceSummaryBox },
    mixins: [validationMixin],
    computed: {
      bankAccounts () {
        if (!this.invoice.supplier || !this.invoice.currency) {
          return [];
        } else {
          return _.filter(this.invoice.supplier.bankAccounts, {currency: this.invoice.currency})
            .map((bankAccount) => new BankAccount(bankAccount));
        }
      },
      invoiceSeries () {
        if (!this.invoice || !this.invoice.supplier) {
          return [];
        } else {
          return _(this.$store.getters['invoiceSeries/active'])
            .filter(invoiceSeries => invoiceSeries.company && (invoiceSeries.company.id === this.invoice.supplier.id))
            .value();
        }
      },
      vatRates () {
        const list = this.invoice.supplier
          ? this.$store.getters['vatRate/validItems'](this.invoice.dateOfIssue, this.invoice.supplier.address.country)
          : this.$store.getters['vatRate/validItems'](this.invoice.dateOfIssue);
        return _.unionBy(list, 'rate');
      },
      outcome () {
        return this.invoice.type === 'OUTCOME';
      },
      income () {
        return this.invoice.type === 'INCOME';
      }
    },
    methods: {
      addItem() {
        this.invoice.items.push(invoiceService.newInvoiceItem());
      },
      deleteItem(item) {
        this.invoice.items = _.without(this.invoice.items, item);
      },
      onSubmit () {
        let operation = this.invoice.id ? 'update' : 'create';
        const invoice = _.cloneDeep(this.invoice);
        this.$store.dispatch('invoice/' + operation, invoice).then((result) => {
          if (result) {
            notificationService.success('invoice.edit.success');
            this.$router.push({ name: 'invoiceDetail', params: { id: result.id }});
          }
        }).finally(this.unprotect);
      },
      isEdit () {
        return !!(this.invoice && this.invoice.id);
      }
    },
    watch: {
      'invoice.supplier' () {
        // if supplier has been changed, clear bank account if selected
        if (!this.invoice.supplier ||
          (this.invoice.bankAccount && this.invoice.supplier)) {
          this.invoice.bankAccount = null;
        }
        // if only one bank account is available, select it
        if (this.invoice.supplier && !this.invoice.bankAccount && this.bankAccounts.length === 1) {
          this.invoice.bankAccount = this.bankAccounts[0];
        }
        //clear selected invoiceSeries if not match for new supplier
        if (!this.invoice.supplier ||
          (this.invoice.supplier
            && this.invoice.invoiceSeries
            && (this.invoice.supplier.id !== this.invoice.invoiceSeries.company.id))) {
          this.invoice.invoiceSeries = null;
        }
        //select default invoiceSeries
        if (this.invoice.supplier && !this.invoice.invoiceSeries && this.invoiceSeries) {
          this.invoice.invoiceSeries = _.find(this.invoiceSeries, 'defaultFlag');
        }
      },
      'invoice.currency' () {
        if (!this.invoice.currency ||
          (this.invoice.bankAccount
            && this.invoice.currency
            && this.invoice.bankAccount.currency !== this.invoice.currency)) {
          this.invoice.bankAccount = null;
        }
        // if only one bank account is available, select it
        if (this.invoice.supplier && !this.invoice.bankAccount && this.bankAccounts.length === 1) {
          this.invoice.bankAccount = this.bankAccounts[0];
        }
      },
      'invoice.invoiceSeries' (series) {
        if (this.invoice && !this.isEdit()) {
          this.invoice.variableSymbol = series ? series.nextVariableSymbol() : null;
        }
      },
      'invoice.dateOfIssue' (newValue, oldValue) {
        //check old value - for first appear of edit page, old value is undefined
        if (newValue && (oldValue || !this.isEdit())) {
          this.invoice.taxableDate = moment(newValue);
          this.invoice.dueDate = moment(newValue).add(this.dueDateOffset, 'day');
        }
      }
    },
    async created () {
      await this.$store.dispatch('invoiceSeries/getAll', true);
      await this.$store.dispatch('bankAccount/findOwnershipped');
      await this.$store.dispatch('vatRate/getAll');

      //set dueDateOffset based on passed invoice
      if (this.invoice && this.invoice.dueDate && this.invoice.dateOfIssue) {
        this.dueDateOffset = this.invoice.dueDate.diff(this.invoice.dateOfIssue, 'days');
      } else {
        this.dueDateOffset = this.configuration.invoice.defaultDueDateOffset;
      }
    }
  };
</script>
