<template>
  <div v-if="bankAccount">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia bankového účtu" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Názov bankového účtu"
                        placeholder="Zadaj názov účtu"
                        v-model="bankAccount.name"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row" v-if="subjectEditable">
          <div class="col-md-12">
              <select-field
                :options="subjects"
                v-model="bankAccount.subject"
                field-id="subject"
                label="Firma"
                validation="required"></select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <text-field field-id="accountId"
                        label="Číslo účtu"
                        placeholder="Zadaj číslo účtu"
                        v-model="bankAccount.accountId"
                        :maxlength="17"
                        :validation-obj="{required: true, min: 1, max: 17, regex: /^[0-9-]{1,17}$/}"></text-field>
          </div>
          <div class="col-md-3">
            <text-field field-id="bankId"
                        label="Kód banky"
                        v-model="bankAccount.bankId"
                        :maxlength="4"
                        :validation-obj="{required: true, length: 4, regex: /^[0-9]{4}$/}"></text-field>
          </div>
          <div class="col-md-4">
            <select-field
              :options="currencies"
              v-model="bankAccount.currency"
              :codelist="true"
              :clearable="true"
              field-id="currency"
              label="Mena"
              required
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div class="col-md-5">
            <text-field field-id="iban"
                        label="IBAN"
                        placeholder="Zadaj IBAN"
                        v-model="bankAccount.iban"
                        :maxlength="34"
                        :validation-obj="{min: 4, max: 34, regex: /^[A-Z,0-9]{3,34}$/}"
                        @paste="onIbanPaste"></text-field>
          </div>
          <div class="col-md-3">
            <text-field field-id="bic"
                        label="BIC"
                        placeholder="Zadaj BIC"
                        v-model="bankAccount.bic"
                        :maxlength="11"
                        :validation-obj="{min: 1, max: 11, regex: /^[A-Z,0-9]{1,11}$/}"></text-field>
          </div>
        </div>
        <div class="row" v-if="apiTokenEditable">
          <div class="col-md-12">
            <text-field field-id="apiToken"
                        label="API token"
                        placeholder="Zadaj token"
                        v-model="bankAccount.apiToken"
                        :maxlength="100"
                        validation="min:3|max:100"></text-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { currencies } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import selectField from 'components/form/selectField';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, selectField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      bankAccount: null,
      currencies: currencies
    }),
    props: {
      subjects: {
        required: true,
        type: Array
      },
      apiTokenEditable: {
        required: false,
        type: Boolean,
        default: true
      },
      subjectEditable: {
        required: false,
        type: Boolean,
        default: true
      }
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.bankAccount.id) {
          operation = 'bankAccount/update';
        } else {
          operation = 'bankAccount/create';
        }
        const bankAccount = _.cloneDeep(this.bankAccount);
        this.$store.dispatch(operation, bankAccount).then((result) => {
          if (result) {
            notificationService.success('bankAccount.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      onIbanPaste(event) {
        this.bankAccount.iban = (event.clipboardData.getData('text') || '').replace(/\s/g, '');
        event.preventDefault();
      },
      clearData () {
        this.bankAccount = null;
      }
    },
    watch: {
      model: function (model) {
        this.bankAccount = _.cloneDeep(model);
        //for new bank account, set subject if only one is in list of subjects
        if (this.bankAccount && !this.bankAccount.subject && this.subjects && this.subjects.length === 1 ) {
          this.bankAccount.subject= this.subjects[0];
        }
      }
    }
  };
</script>
