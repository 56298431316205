<template>
  <div v-if="tmRewardTemplate">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia šablóny odmeny" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="types"
              v-model="tmRewardTemplate.type"
              field-id="type"
              label="Typ"
              :codelist="true"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6" v-if="tmRewardTemplate.type === 'PROJECT'">
            <select-field
              :options="projects"
              v-model="tmRewardTemplate.sourceProject"
              field-id="sourceProject"
              label="Projekt"
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="subjects"
              v-model="tmRewardTemplate.sourceSubject"
              field-id="sourceSubject"
              label="Odmena z"
              required
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <select-field
              :options="subjects"
              v-model="tmRewardTemplate.recipient"
              field-id="recipient"
              label="Príjemca"
              required
              validation="required">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="tmRewardTypes"
              v-model="rewardTypeSelected"
              field-id="rewardType"
              label="Typ odmeny"
              :codelist="true"
              required
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6" v-if="rewardTypeSelected != null">
            <number-field
              v-if="rewardTypeSelected === 'SHARED'"
              v-model="tmRewardTemplate.rewardShare"
              label="Odmena" placeholder="Zadaj odmenu v %"
              field-id="rewardValue"
              :min="1"
              :max="100"
              :step="1"
              validation="required|decimal:2|min_value:1|max_value:100"
              suffix="%">
            </number-field>
            <number-field
              v-if="rewardTypeSelected === 'FIXED' && tmRewardTemplate.sourceProject"
              v-model="tmRewardTemplate.fixedRate"
              label="Odmena" placeholder="Zadaj odmenu v mene"
              field-id="rewardValue"
              :min="1"
              :max="10000"
              :step="100"
              validation="required|numeric|min_value:1|max_value:10000"
              :suffix="projectCurrency | codeListValue(currencies)">
            </number-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import tmRewardTemplateTypes from 'codelist/tmRewardTemplateTypes';
  import tmRewardTypes from 'codelist/tmRewardTypes';
  import notificationService from 'services/notificationService';
  import selectField from 'components/form/selectField';
  import numberField from 'components/form/numberField';
  import validationMixin from 'components/validationMixin';
  import TmRewardTemplate from '../domain/tmRewardTemplate';
  import currencies from 'codelist/currencies';
  import modalMixin from './modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, numberField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      tmRewardTemplate: null,
      types: tmRewardTemplateTypes,
      rewardTypeSelected: null,
      currencies: currencies
    }),
    computed: {
      ...mapGetters({
        projects: 'tmProject/openWithCurrency',
        subjects: 'subject/withoutLeads'
      }),
      tmRewardTypes: function () {
        let options;
        if (!this.tmRewardTemplate || !this.tmRewardTemplate.type) {
          options = [];
        } else if (this.tmRewardTemplate.type === 'GLOBAL') {
          options = _.filter(tmRewardTypes, { 'value': 'SHARED' });
        } else if (this.tmRewardTemplate.type === 'PROJECT') {
          options = tmRewardTypes;
        }

        return options;
      },
      // we need find out currency from state's projects because reward templates are fetched without project's orders
      projectCurrency: function () {
        const projectId = this.tmRewardTemplate.sourceProject.id;
        const tmProject = _.find(this.projects, { id: projectId });
        return !!tmProject && !!tmProject.currency ? tmProject.currency : null;
      }
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.tmRewardTemplate.id) {
          operation = 'tmRewardTemplate/update';
        } else {
          operation = 'tmRewardTemplate/create';
        }

        if (this.rewardTypeSelected === 'SHARED') {
          this.tmRewardTemplate.fixedRate = null;
        } else {
          this.tmRewardTemplate.rewardShare = null;
        }

        const tmRewardTemplate = _.cloneDeep(this.tmRewardTemplate);
        this.$store.dispatch(operation, tmRewardTemplate).then((result) => {
          if (result) {
            notificationService.success('tmRewardTemplate.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.tmRewardTemplate = null;
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.tmRewardTemplate = new TmRewardTemplate(model);
          if (model.id) {
            this.rewardTypeSelected = model.fixedRate ? 'FIXED' : 'SHARED';
          }
        }
      },
      tmRewardTypes (val) {
        // if rewardTypeSelected is not in filtered options, clear it
        if (!!val && !_.some(val, { value: this.rewardTypeSelected })) {
          this.rewardTypeSelected = null;
        }
      }
    },
    created () {
      this.$store.dispatch('subject/getAll');
      this.$store.dispatch('tmProject/getAll');
    }
  };
</script>
