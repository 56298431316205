import { CURRENCY_RATE_LIST } from './mutationTypes';

const mutations = {
  [CURRENCY_RATE_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  }
};

export default mutations;
