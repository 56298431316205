<template>
  <div class="form-group">
    <label v-show="label" class="col-form-label" :class="{ required: requiredVal }">{{ label }}</label>
    <validation-provider :name="fieldId" :rules="validation || validationObj" v-slot="{ errors }" slim mode="lazy">
      <date-field :locale="locale"
                  :id="fieldId"
                  :class="{ 'is-invalid': errors.length, ...cssClass }"
                  :input-size="inputSize"
                  :no-label="true"
                  :max-date="maxDateData"
                  :color="color"
                  :formatted="formatted()"
                  :format="format()"
                  v-model="innerValue"
                  :only-date="!showTime"
                  :only-time="!showDate"
                  :label="placeholder"
                  :disabled="disabled"
                  no-button-now></date-field>
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>

<script>
import dateField from 'vue-ctk-date-time-picker';
import {COLOR, LOCALE} from 'config';
import moment from 'moment';
import {ValidationProvider} from 'vee-validate';

export default {
  components: { dateField, ValidationProvider },
  data: () => ({
    locale: LOCALE,
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.sss',
    timeFormat: 'HH:mm:ss.sss',
    innerValue: null,
    maxDateData: null,
    color: COLOR
  }),
  computed: {
    requiredVal () {
      return ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required;
    },
    onlyDate () {
      return this.showDate && !this.showTime;
    },
    onlyTime () {
      return !this.showDate && this.showTime;
    }
  },
  props: {
    cssClass: {
      type: String,
      required: false
    },
    fieldId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    inputSize: {
      type: String,
      required: false,
      default: 'sm'
    },
    maxDate: {
      type: Object,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    validation: {
      type: String,
      required: false,
      default: ''
    },
    validationObj: {
      type: Object,
      default () {
        return {};
      }
    },
    value: {
      type: Object
    },
    showTime: {
      type: Boolean,
      required: false,
      default: true
    },
    showDate: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatted () {
      if (this.showDate && this.showTime) {
        return 'lll';
      } else if (this.onlyDate) {
        return 'L';
      } else {
        return 'HH:mm';
      }
    },

    format () {
      if (this.showDate && this.showTime) {
        return this.dateTimeFormat;
      } else if (this.onlyDate) {
        return this.dateFormat;
      } else {
        return this.timeFormat;
      }
    },

    seInnerValue (value) {
      if (value) {
        if (this.onlyTime) {
          this.innerValue = value.format(this.timeFormat);
        } else if (this.onlyDate) {
          this.innerValue = value.format(this.dateFormat);
        } else {
          this.innerValue = value.format(this.dateTimeFormat);
        }
      } else {
        this.innerValue = null;
      }
    }
  },
  watch: {
    innerValue (value) {
      let valueToEmit = null;

      if (value) {
        valueToEmit = this.onlyTime ? moment(value, this.timeFormat) : moment(value);
      }

      this.$emit('input', valueToEmit);
    },
    value (value) {
      this.seInnerValue(value);
    },
    maxDate (maxDate) {
      this.maxDateData = maxDate ? maxDate.format(this.showTime ? this.dateTimeFormat : this.dateFormat) : null;
    }
  },
  created () {
    this.seInnerValue(this.value);
  }
};
</script>
