<template>
  <div v-if="employee">
    <documents :documents="employee.documents" @deleteDocument="deleteDocument($event, employee)"></documents>
    <document-upload @documentUploaded="documentUploaded($event, employee)"></document-upload>
  </div>
</template>
<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import notificationService from 'services/notificationService';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';

  export default {
    components: { documents, documentUpload },
    computed: mapState({
      employee: (state) => state.employee.selected
    }),
    methods: {
      documentUploaded (document, employee) {
        const employeeCopy = _.cloneDeep(employee);
        employeeCopy.documents.push(document);
        this.$store.dispatch('employee/update', employeeCopy).then((result) => {
          if (result) {
            notificationService.success('employee.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocument (document, employee) {
        const employeeCopy = _.cloneDeep(employee);
        _.remove(employeeCopy.documents, { id: document.id });
        this.$store.dispatch('employee/update', employeeCopy).then((result) => {
          if (result) {
            notificationService.success('employee.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      }
    }
  };
</script>
