<template>
  <page title="Bankové účty">
    <box>
      <template #body>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>
    <box>
      <template #body>
        <bank-accounts :filter="filter" :subjects="subjects"></bank-accounts>
      </template>
    </box>

  </page>
</template>
<script>
  import page from 'components/page';
  import box from 'components/box';
  import bankAccounts from './bankAccounts';
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      filter: {
        query: null,
        showInactive: false,
        ownershipped: true
      }
    }),
    components: {page, box, bankAccounts},
    computed: {
      ...mapGetters({
        subjects: 'subject/ownershipped'
      })
    },
    created () {
      this.$store.dispatch('subject/getAll', true);
    }
  };
</script>
