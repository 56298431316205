'use strict';
import _ from 'lodash';

export default {
  open: state => {
    return _.filter(state.items || [], { status: 'OPEN' });
  },
  openWithCurrency: state => {
    return _.filter(state.items || [], function (project) {
      return project.status === 'OPEN' && !!project.currency;
    });
  }
};
