import Entity from './entity';
import TmOrderItem from '../modules/order/domain/tmOrderItem';

class TmDeliveryItem extends Entity {
  constructor (data) {
    super(data);
    this.itemNumber = this.itemNumber || null;
    this.orderItem = new TmOrderItem(data.orderItem);
  }
}

export default TmDeliveryItem;
