import actions from './subjectActions';
import mutations from './subjectMutations';
import getters from './subjectGetters';

const state = {
  items: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations: mutations,
  actions: actions
};
