<template>
  <header class="main-header">

    <router-link v-if="loggedIn" tag="span" to="/"><a class="logo" href="#"><span class="logo-mini">B</span><span class="logo-lg"><img src="/logo.svg" alt="logo"></span></a></router-link>
    <router-link v-else tag="span" to="/login"><a class="navbar-brand" href="#">BrainIS</a></router-link>

    <nav class="navbar navbar-static-top">
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button" ref="pushmenu">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" v-if="user"> {{ user.fullName }}
              <span class="caret"></span></a>
            <ul class="dropdown-menu">
              <router-link tag="li" to="/profile" active-class="active"><a>Môj profil</a></router-link>
              <router-link tag="li" to="/password-change" active-class="active"><a>Zmena hesla</a></router-link>
              <li role="separator" class="divider"></li>
              <li><a href="" @click.prevent="logout()">Odhlásit</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import authService from 'services/authService';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
    ...mapGetters({
      loggedIn: 'auth/loggedIn'
    }),
  },
  methods: {
    logout () {
      authService.logOut();
      this.$store.dispatch('auth/logOut');
      this.$router.push('/login');
    }
  }
};
</script>
