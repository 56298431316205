<template>
  <page title="Faktúry">
    <div class="row">
      <div class="col-md-4" style="flex: 1;">
        <box class="filter-block">
          <template #header>
            <h4>Prehľad</h4>
          </template>
          <template #body>
            <div class="row" v-for="unpaidByCurrency in unpaid">
              <div class="col-md-12">
                <span class="label label-default pull-left label-inline">{{ unpaidByCurrency.count }}</span>
                <span class="pull-left">Neuhradené</span>
                <span class="pull-right">{{ unpaidByCurrency.amount | currency(unpaidByCurrency.currency) }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-for="unpaidByCurrency in overdue">
                <span class="label label-danger pull-left label-inline">{{ unpaidByCurrency.count }}</span>
                <span>Po splatnosti</span> <span class="pull-right text-danger">{{ unpaidByCurrency.amount | currency(unpaidByCurrency.currency) }}</span>
              </div>
            </div>
          </template>
        </box>

      </div>
      <div class="col-md-4">
        <box class="filter-block">
          <template #header>
            <h4>Obdobie</h4>
          </template>
          <template #body>
            <date-range-field v-model="filter.range"></date-range-field>
          </template>
        </box>
      </div>
      <div class="col-md-4">
        <box class="filter-block">
          <template #header>
            <h4>Stav</h4>
          </template>
          <template #body>
            <select-field
              :options="statuses"
              field-id="status"
              :codelist="true"
              v-model="filter.status">
            </select-field>
            <div class="pull-right">
              <button class="btn btn-success" type="button" @click="fetch()">Načítať</button>
            </div>
          </template>
        </box>
      </div>
    </div>

    <box>
      <template #header>
        <router-link tag="button" class="btn btn-success" :to="'/invoice-new/' + type" replace><i class="fa fa-plus"></i></router-link>
        <button :disabled="!selected.length" class="btn btn-default" @click="exportTransactions"><i class="fa fa-download"></i></button>
      </template>

      <template #body>

        <div class="row">
          <div class="col-md-12 table-responsive">
            <table class="table no-padding table-hover dataTable" v-if="invoices && invoices.length > 0">
              <thead>
              <tr>
                <th v-if="type === 'INCOME'"><input type="checkbox" v-model="selectedAll" @change="updateSelected()"></th>
                <th>Dátum vystavenia</th>
                <th>Číslo</th>
                <th>Faktura</th>
                <th class="text-right">Celkom</th>
                <th class="text-right">Neuhradené</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="invoice in invoices" @click.prevent="detail(invoice)" role="button">
                <td @click.stop v-if="type === 'INCOME'"><input type="checkbox" :id="invoice.id" :value="invoice" v-model="selected"></td>
                <td>{{ invoice.dateOfIssue | date }}</td>
                <td>
                  <span>{{ invoice.number }}</span>
                  <div class="text-description">Splatnosť: {{ invoice.dueDate | date }}</div>
                </td>
                <td class="text-middle">{{ invoice.type === 'OUTCOME' ? invoice.subscriber.format() : invoice.supplier.format() }}</td>
                <td class="text-right">{{ invoice.totalAmount | currency(invoice.currency) }}</td>
                <td class="text-right" :class="{'text-danger': invoice.overdue, 'text-success': invoice.paid}">
                  <span>{{ invoice.unpaidAmount | currency(invoice.currency) }}</span>
                  <div class="text-description" v-if="invoice.overdue">Po splatnosti : {{ invoice.overdueDays }} dní</div>
                </td>
              </tr>
              </tbody>
            </table>
            <loading id="invoices"></loading>
            <no-records :data="invoices" loading-id="invoices"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import {mapState} from 'vuex';
  import page from 'components/page';
  import box from 'components/box';
  import Range from 'components/range';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import dateRangeField from 'components/form/dateRangeField';
  import selectField from 'components/form/selectField';
  import {FORMAT_SYSTEM_DATE} from 'utils';
  import { GENERATED_DOCUMENT_XML_SUFFIX } from 'config';
  import i18n from 'i18n/';


  import statuses from 'modules/invoice/domain/invoiceStatuses';
  import invoiceService from '../service/invoiceService';

  export default {
    components: {box, loading, page, noRecords, dateRangeField, selectField},
    mixins: [bookmarkableFilter],
    data: () => ({
      statuses,
      filter: {
        range: null,
        status: null
      },
      selected: [],
      selectedAll: false
    }),
    props: {
      type: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState({
        invoices: (state) => state.invoice.items
      }),
      unpaid() {
        return _(this.invoices)
          .groupBy('currency')
          .values()
          .map(invoices => ({
            currency: invoices[0].currency,
            count: _.filter(invoices, invoice => invoice.unpaidAmount > 0).length,
            amount: _.sumBy(invoices, 'unpaidAmount')
          }))
          .value();
      },
      overdue() {
        return _(this.invoices)
          .groupBy('currency')
          .values()
          .map(invoices => ({
            currency: invoices[0].currency,
            count: _.filter(invoices, invoice => invoice.unpaidAmount > 0 && invoice.dueDate.isBefore())
              .length,
            amount: _(invoices)
              .filter(invoice => invoice.unpaidAmount > 0 && invoice.dueDate.isBefore())
              .sumBy('unpaidAmount')
          }))
          .value();
      }
    },
    watch: {
      // need to clear invoices based on type change, because different
      // pages are same route only with different value of type parameter and destroy method
      // is not called
      type: {
        handler (newType, oldType) {
          if (newType !== oldType) {
            this.defaultFilter();
            this.fetch();
          }
        }
      }
    },
    methods: {
      defaultFilter() {
        this.filter.range = new Range(moment().subtract(1, 'month'), null);
      },
      detail(invoice) {
        this.$router.push({name: 'invoiceDetail', params: {id: invoice.id}});
      },
      updateSelected () {
        this.selected = this.selectedAll ? this.invoices : [];
      },
      createFilter () {
        const filter = this.filter;
        const dateOfIssueFrom = this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const dateOfIssueTo = this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;

        return _.pickBy({
          dateOfIssueFrom,
          dateOfIssueTo,
          status: filter.status,
          type: this.type,
        }, _.identity);
      },
      async fetch() {
        this.selected = [];
        this.selectedAll = false;
        await this.$store.dispatch('invoice/findByFilter', this.createFilter());
      },
      exportTransactions() {
        const baseName = i18n.message('invoice.export.transaction.file.name');
        const result = `${baseName}${GENERATED_DOCUMENT_XML_SUFFIX}`;
        invoiceService.exportTransactions(_.map(this.selected, 'id'), result);
      }
    },
    mounted () {
      this.fetch();
    },
    destroyed () {
      this.$store.dispatch('invoice/clear');
    }
  };
</script>
