import tmDeliveriesView from 'modules/delivery/components/tmDeliveries';


export default [
  {
    name: 'tmDeliveries',
    path: '/tm-deliveries',
    component: tmDeliveriesView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'DEALER'
    }
  }
];
