<template>
  <div v-if="!loading[loadingId] && (!data || data.length === 0)" class="text-center">
    <alert type="warning">{{ text | translate }}</alert>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { alert } from 'vue-strap';

  export default {
    components: { alert },
    computed: mapState({
      loading: (state) => state.app.loadingData
    }),
    props: {
      data: {
        type: Array
      },
      loadingId: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        required: false,
        default () {
          return 'common.data.notRecords';
        }
      }
    }
  };

</script>
