const getters = {
  validItems: state => {
    return state.items.filter(item => item.valid);
  },
  validItemsByType: state => (type) => {
    return state.items.filter(item => item.valid && (!type || item.type === type));
  }
};

export default getters;
