<template>
  <div ref="box" class="box" :class="{'collapsed-box' : collapsed}" data-collapse-icon="fa-chevron-down"
       data-expand-icon="fa-chevron-right">
    <div class="box-header with-border" :class="{ 'collapsible': collapsible }" v-show="hasheader()"
         :data-widget="collapsible ? 'collapse' : ''">
      <slot name="header"></slot>

      <div v-show="collapsible" class="box-tools pull-right">
        <button type="button" class="btn btn-box-tool">
          <i class="fa" :class="collapseIcon"></i>
        </button>
      </div>
    </div>
    <div class="box-body" :style="style">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      show: true,
      style: null,
      collapseIcon: 'fa-chevron-down'
    }),
    props: {
      collapsible: {
        type: Boolean,
        default: false
      },
      collapsed: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      hasheader() {
        return !!this.$slots.header;
      }
    },
    mounted() {
      if (this.collapsed) {
        this.style = 'display: none;';
        this.collapseIcon = 'fa-chevron-right';
      }
      $(this.$refs.box).boxWidget();
    }
  };
</script>
