import _ from 'lodash';
import moment from 'moment';
import {normalize} from '../utils';

class Entity {
  constructor (data, idProp, labelProp) {
    _.merge(this, data);
    if (data && data.created) {
      this.created = this.convertDate(data.created);
    }

    if (!_.isNil(idProp)) {
      this.id = this[idProp];
    }
    if (!_.isNil(labelProp)) {
      this.label = this[labelProp];
    }

    this[RELATIONS] = [];
    this._searchString = '';

  }

  convertDate (date) {
    return date ? moment(date) : null;
  }

  // set relations -> entities, which are transferred for create/update
  relations () {
    this[RELATIONS].length = 0;
    this[RELATIONS].push(...arguments);
  }

  matches(query) {
    const regexp = new RegExp(normalize(query), 'g');
    return this._searchString.match(regexp);
  }
}

export default Entity;

export const RELATIONS = '_updateRelations';
