import {
  TM_REWARD_TEMPLATE_ACTIVATE, TM_REWARD_TEMPLATE_DEACTIVATE, TM_REWARD_TEMPLATE_LIST,
  TM_REWARD_TEMPLATE_LIST_FILTERED
} from '../../mutationTypes';
import _ from 'lodash';

const mutations = {
  [TM_REWARD_TEMPLATE_LIST] (state, action) {
    state.items = action.items;
  },
  [TM_REWARD_TEMPLATE_LIST_FILTERED] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [TM_REWARD_TEMPLATE_ACTIVATE] (state, id) {
    _.find(state.items, { id: id }).active = true;
  },
  [TM_REWARD_TEMPLATE_DEACTIVATE] (state, id) {
    _.find(state.items, { id: id }).active = false;
  }
};

export default mutations;
