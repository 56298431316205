import MoneyMovementCategory from '../domain/moneyMovementCategory';
import moneyMovementCategoryResource from './momentMovementCategoryResource';

export default {
  newMoneyMovementCategory(obj) {
    return new MoneyMovementCategory(obj);
  },
  async findAll () {
    const response = await moneyMovementCategoryResource.query();
    if (response.ok) {
      return response.data.map((mmCategoryData) => new MoneyMovementCategory(mmCategoryData));
    } else {
      return null;
    }
  },
  async create (mmCategory) {
    const response = await moneyMovementCategoryResource.save({}, mmCategory);
    if (response.ok) {
      return new MoneyMovementCategory(response.data);
    } else {
      return null;
    }
  },
  async update (mmCategory) {
    const response = await moneyMovementCategoryResource.update({}, mmCategory);
    if (response.ok) {
      return new MoneyMovementCategory(response.data);
    } else {
      return null;
    }
  },
  async delete (id) {
    return moneyMovementCategoryResource.delete({ id });
  },
  async deactivate (id) {
    return moneyMovementCategoryResource.deactivate({ id }, {});
  },
  async activate (id) {
    return moneyMovementCategoryResource.activate({ id }, {});
  },
  async updatePosition(id, position) {
    const response = await moneyMovementCategoryResource.updatePosition({ id, position }, {});
    return response.ok;
  }
};
