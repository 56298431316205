<template>
  <page :title="invoice && invoice.id ? 'Úprava faktúry' : 'Nová faktúra'">
    <invoice-create-form :invoice="invoice" v-if="invoice" :supplier-options="supplierOptions" :subscriber-options="subscriberOptions"></invoice-create-form>
  </page>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
  import page from 'components/page';
  import invoiceCreateForm from './invoiceCreateForm';
  import invoiceService from '../service/invoiceService';

  export default {
    components: { page, invoiceCreateForm },
    data: () => ({
      invoice: null
    }),
    props: {
      id: {
        type: String
      },
      deliveryIds: {
        type: String
      },
      invoiceId: {
        type: String
      }
    },
    computed: {
      ...mapState({
        configuration: (state) => state.app.configuration
      }),
      ...mapGetters({
        supplierOptions: 'company/suppliers',
        subscriberOptions: 'company/ownershipped'
      })
    },
    async mounted () {
      await this.$store.dispatch('company/getAll', true);

      if (this.id) {
        this.invoice = _.cloneDeep(await this.$store.dispatch('invoice/select', this.id));
      } else if (this.deliveryIds) {
        this.invoice = await invoiceService.incomeForDelivery(this.deliveryIds.split('-'), this.configuration);
      } else if (this.invoiceId) {
        this.invoice = await invoiceService.incomeCopy(this.invoiceId, this.configuration);
      } else {
        this.invoice = invoiceService.newIncomeInvoice(this.configuration);
      }
    }
  };
</script>
