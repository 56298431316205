<template>
  <div>
    <account :account-id="company.account.id" :subject-name="company.format()"></account>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import account from 'modules/account/components/account';

  export default {
    components: { account },
    computed: mapState({
      company: (state) => state.company.selected
    })
  };
</script>
