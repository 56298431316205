import _ from 'lodash';
import TmDelivery from '../../../domain/tmDelivery';
import subjectService from 'modules/subject/service/subjectService';
import { FORMAT_DATE } from 'utils';
import TmProject from 'modules/project/domain/tmProject';
import Order from './order';
import TmOrderItem from './tmOrderItem';

class TmOrder extends Order {
  constructor (data) {
    super(data);
    this.supplier = this.supplier ? subjectService.getSubject(this.supplier) : null;
    this.subscriber = this.subscriber ? subjectService.getSubject(this.subscriber) : null;
    this.deliveries = data.deliveries ? data.deliveries.map(delivery => new TmDelivery(delivery)) : [];
    this.subOrders = data.subOrders ? data.subOrders.map(suborder => new TmOrder(suborder)) : [];
    this.validFrom = this.convertDate(data.validFrom);
    this.validTo = this.convertDate(data.validTo);
    this.type = this.type || null;
    this.currency = this.currency || null;
    this.vatRate = this.vatRate === 'undefined' ? null : this.vatRate;
    this.mdNumber = this.mdNumber || null;
    this.mdRate = this.mdRate || null;
    this.mainOrder = this.mainOrder ? new TmOrder(this.mainOrder) : null;
    this.project = this.project ? new TmProject(this.project) : null;
    this.items = data.items ? data.items.map(orderItem => new TmOrderItem(orderItem)) : [];
    this.invoiceDueDateOffset = this.invoiceDueDateOffset || null;
    this.invoiceItemText = this.invoiceItemText || null;

    this.value = this.id;
    this.label = this.number + ' (' + FORMAT_DATE(this.validFrom) + ' - ' + FORMAT_DATE(this.validTo) + ')';
  }

  get sum () {
    return (this.mdNumber || 0) * (this.mdRate || 0);
  }

  get deliveriesSum () {
    return _(this.deliveries)
      .map(delivery => delivery.price)
      .sum();
  }

  get hasDelivery () {
    return this.deliveries.length > 0 || _.some(this.subOrders, suborder => suborder.deliveries && suborder.deliveries.length);
  }

  get profit () {
    return _(this.deliveries)
      .map(delivery => delivery.profit)
      .sum();
  }

}

export default TmOrder;
