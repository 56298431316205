import _ from 'lodash';
import * as types from './mutationTypes';
import tmProjectService from '../service/tmProjectService';

const actions = {
  async select ({ dispatch, commit }, id) {
    await commit(types.TM_PROJECT_SELECT, {
      item: null
    });
    dispatch('app/loadingDataEnable', 'tmProject', { root: true });
    const project = await tmProjectService.find(id);
    await commit(types.TM_PROJECT_SELECT, {
      item: project
    });
    dispatch('app/loadingDataDisable', 'tmProject', { root: true });
  },
  async refreshSelected ({ state, dispatch, commit }) {
    dispatch('app/loadingDataEnable', 'tmProject', { root: true });
    dispatch('getAll', true);
    if (state.selected) {
      const project = await tmProjectService.find(state.selected.id);
      await commit(types.TM_PROJECT_SELECT, {
        item: project
      });
    }
    dispatch('app/loadingDataDisable', 'tmProject', { root: true });
  },
  async refreshAll ({ state, dispatch }) {
    if (state.filter) {
      await dispatch('getAllByFilter', state.filter);
    }
    await dispatch('getAll', true);
  },
  async getAllByFilter ({ state, dispatch, commit }, filter) {
    dispatch('app/loadingDataEnable', 'tmProjects', { root: true });
    await commit(types.TM_PROJECT_LIST_FILTERED, {
      filter: null,
      itemsByFilter: []
    });
    const tmProjects = await tmProjectService.findAll(filter);
    await commit(types.TM_PROJECT_LIST_FILTERED, {
      filter: filter,
      itemsByFilter: tmProjects
    });
    dispatch('app/loadingDataDisable', 'tmProjects', { root: true });
  },
  async getAll ({ state, dispatch, commit }) {
    dispatch('app/loadingDataEnable', 'tmProjects', { root: true });
    const tmProjects = await tmProjectService.findAll();
    await commit(types.TM_PROJECT_LIST, {
      items: tmProjects
    });
    dispatch('app/loadingDataDisable', 'tmProjects', { root: true });
  },
  async create ({ dispatch, commit }, tmProject) {
    try {
      const newTmProject = await tmProjectService.create(tmProject);
      if (newTmProject) {
        await dispatch('refreshAll');
        return newTmProject;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch }, tmProject) {
    try {
      const updatedProject = await tmProjectService.update(tmProject);
      if (updatedProject) {
        await dispatch('refreshSelected');
        return updatedProject;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async open ({ state, dispatch }) {
    const project = _.cloneDeep(state.selected);
    project.status = 'OPEN';
    await dispatch('update', project);
    await dispatch('refreshSelected', project);
  },
  async close ({ state, dispatch }) {
    const project = _.cloneDeep(state.selected);
    project.status = 'CLOSED';
    await dispatch('update', project);
    await dispatch('refreshSelected', project);
  },
  async delete ({ dispatch }, id) {
    const response = await tmProjectService.delete(id);
    if (response.ok) {
      dispatch('refreshAll');
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
