import Entity from './entity';
import subjectService from 'modules/subject/service/subjectService';
import TmProject from 'modules/project/domain/tmProject';
import TmDelivery from './tmDelivery';
import TmRewardTemplate from './tmRewardTemplate';

class TmReward extends Entity {
  constructor (data) {
    super(data);
    this.recipient = data.recipient ? subjectService.getSubject(data.recipient) : null;
    this.project = data.project ? new TmProject(data.project) : null;
    this.delivery = data.delivery ? new TmDelivery(data.delivery) : null;
    this.template = data.template ? new TmRewardTemplate(data.template) : null;
    this.date = this.convertDate(data.date);
  }

  get editable () {
    return !this.template && this.status === 'NEW';
  }
}

export default TmReward;
