import Entity from '../../../domain/entity';
import subjectService from 'modules/subject/service/subjectService';

class BankAccount extends Entity {
  constructor (data) {
    super(data);
    this.subject = this.subject ? subjectService.getSubject(this.subject) : null;
    this.name = data.name || null;
    this.accountId = data.accountId || null;
    this.bankId = data.bankId || null;
    this.currency = data.currency || null;
    this.iban = data.iban || null;
    this.bic = data.bic || null;
    this.apiToken = data.apiToken || null;
    this.active = data.active;

    this.formatId = `${this.accountId} / ${this.bankId}`;
    this.value = this.id;
    this.label = this.name;
    this.labelDescription = `${this.formatId}`;
  }
}

export default BankAccount;
