<template>
  <page title="Pokladne a účty">
    <box>
      <template #body>
        <cash-movement-create-modal :show-model="showCashMovementCreateModal" :model="selectedMoneyMovement"
                                    @close="hideModals()"></cash-movement-create-modal>
        <div class="row">
          <div class="col-md-3">
            <select-field
              :options="moneyBoxes"
              v-model="filter.moneyBoxId"
              field-id="moneyBox"
              :codelist="true"
              label="Pokladňa"></select-field>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Vyhľadať {{ filter.query }}</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-md-3">
            <date-range-field v-model="filter.range" :no-label="true" label="Obdobie"></date-range-field>
          </div>
        </div>
      </template>
    </box>

    <div class="row">
      <div class="col-md-6" v-if="selectedMoneyBox">
        <box>
          <template #body>
            <money-box-balance :money-box="selectedMoneyBox"></money-box-balance>
          </template>
        </box>
      </div>
      <div class="col-md-6" v-if="selectedMoneyBox && movements">
        <box>
          <template #body>
            <money-box-delta :movements="movements" :currency="selectedMoneyBox.currency"></money-box-delta>
          </template>
        </box>
      </div>
    </div>

    <box>
      <template #header>
        <button class="btn btn-success" title="Pridať pohyb"
                :disabled="!filter.moneyBoxId || isBankMoneybox(filter.moneyBoxId)"
                @click.prevent="createCashMoneyMovement()"><i class="fa fa-plus"></i></button>
      </template>
      <template #body>
        <money-movements v-if="movements" :movements="movements" :sort-data="sortData"></money-movements>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import {mapGetters} from 'vuex';
  import box from 'components/box';
  import page from 'components/page';
  import Range from 'components/range';
  import moneyBoxBalance from './moneyBoxBalance';
  import moneyBoxDelta from './moneyBoxDelta';
  import selectField from 'components/form/selectField';
  import dateRangeField from 'components/form/dateRangeField';
  import moneyMovements from './moneyMovements';
  import bookmarkableFilter from "components/bookmarkableFilter";
  import cashMovementCreateModal from "./cashMovementCreateModal";
  import moneyMovementService from "../service/moneyMovementService";
  import {FORMAT_SYSTEM_DATE} from 'utils';

  export default {
    components: {
      moneyBoxDelta, moneyBoxBalance, moneyMovements, page, box, selectField, dateRangeField, cashMovementCreateModal},
    mixins: [bookmarkableFilter],
    data: () => ({
      filter: {
        range: null,
        moneyBoxId: null,
        query: null
      },
      showCashMovementCreateModal: false,
      selectedMoneyMovement: null,
    }),
    computed: {
      selectedMoneyBox() {
        return this.moneyBoxes && this.filter.moneyBoxId ? _.find(this.moneyBoxes, {id: this.filter.moneyBoxId}) : null;
      },
      ...mapGetters({
        moneyBoxes: 'moneyBox/active',
      }),
      movements() {
        return this.$store.getters['moneyMovement/byFilter'](this.filter, this.sortData);
      }
    },
    methods: {
      defaultFilter() {
        this.sortData.by = 'date';
        this.sortData.asc = false;
        this.filter.query = null;
        this.filter.range = new Range(moment().subtract(1, 'month'), moment());
      },
      isBankMoneybox(moneyboxId) {
        return moneyboxId && _.some(this.moneyBoxes, {id: moneyboxId, type: 'BANK'});
      },
      createCashMoneyMovement() {
        const moneyBox = _.find(this.moneyBoxes, {id: this.filter.moneyBoxId});
        this.selectedMoneyMovement = moneyMovementService.newCashMoneyMovement({moneyBox: moneyBox, fiscal: false});
        this.showCashMovementCreateModal = true;
      },
      hideModals() {
        this.showCashMovementCreateModal = false;
      },
      fetchMoneyMovements() {
        this.$store.dispatch('moneyMovement/findByFilter', this.createFilter());
      },
      createFilter() {
        const dateFrom = this.filter && this.filter.range && this.filter.range.from && this.filter.range.from.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.from) : null;
        const dateTo = this.filter && this.filter.range && this.filter.range.to && this.filter.range.to.isValid() ?
          FORMAT_SYSTEM_DATE(this.filter.range.to) : null;
        return _.pickBy({
          dateFrom,
          dateTo,
          moneyBoxId: this.filter && this.filter.moneyBoxId ? this.filter.moneyBoxId : null
        }, _.identity);
      },
    },
    watch: {
      filter: {
        handler () {
          this.fetchMoneyMovements();
        },
        deep: true
      }
    },
    async created() {
      await this.$store.dispatch('moneyBox/getAll', true);
      await this.$store.dispatch('subject/getAll', true);
      await this.$store.dispatch('moneyMovementCategory/getAll', true);
      await this.$store.dispatch('vatRate/getAll');
      await this.$store.dispatch('invoice/findByFilter', { status: 'OUTSTANDING' });
    },
    destroyed() {
      this.$store.dispatch('moneyMovement/clear');
    }
  };
</script>
