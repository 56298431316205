<template>
  <div v-if="activity">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  title="Editácia aktivity" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-12">
            <text-field
              field-id="name"
              label="Názov"
              placeholder="Zadaj názov aktivity"
              v-model="activity.name"
              :maxlength="100"
              validation="required|min:3|max:100">
            </text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-if="entityType === 'project' || !entityType">
            <select-field
              :options="subjectsFiltered"
              field-id="subjectId"
              label="Subjekt"
              v-model="activity.subject">
            </select-field>
          </div>
          <div class="col-md-6" v-if="entityType === 'subject' || !entityType">
            <select-field
              :options="projectsFiltered"
              field-id="projectId"
              label="Projekt"
              v-model="activity.project">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="contacts"
              field-id="contactId"
              label="Kontakt"
              v-model="activity.contact">
            </select-field>
          </div>
          <div class="col-md-6">
            <select-field
              :options="users"
              field-id="userId"
              label="Riešiteľ"
              v-model="activity.assignee">
            </select-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="activityTypes"
              field-id="typ"
              label="Typ"
              :codelist="true"
              v-model="activity.type"
              validation="required">
            </select-field>
          </div>
          <div class="col-md-6">
            <date-field v-model="activity.date" field-id="date" label="Dátum" validation="required"></date-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field
              field-id="note"
              v-model="activity.note"
              label="Poznámka"
              :maxlength="300"
              validation="max:300"
              css-class="note">
            </text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import _ from 'lodash';
  import modal from 'vue-strap/src/Modal';
  import activityTypes from 'codelist/activityTypes';
  import textField from 'components/form/textField';
  import textAreaField from 'components/form/textAreaField';
  import selectField from 'components/form/selectField';
  import dateField from 'components/form/dateField';
  import validationMixin from 'components/validationMixin';
  import modalMixin from '../../../components/modalMixin';
  import notificationService from 'services/notificationService';
  import Entity from '../../../domain/entity';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, selectField, textField, textAreaField, dateField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      activity: null,
      activityTypes: activityTypes
    }),
    computed: {
      ...mapState({
        contacts: (state) => state.contact.items,
        subjects: (state) => state.subject.items
      }),
      ...mapGetters({
        projects: 'tmProject/open',
        users: 'user/active'
      }),
      projectsFiltered: function () {
        if (this.activity.subject) {
          return _(this.projects).filter(p => {
            const subjectIds = this.getSubjectIdsForProject(p);
            return subjectIds.indexOf(this.activity.subject.id) !== -1;
          }).value();
        } else {
          return this.projects;
        }
      },
      subjectsFiltered: function () {
        if (this.entityType === 'project' && this.entity) {
          const subjectsIds = this.getSubjectIdsForProject(this.entity);
          return _(this.subjects).filter(s => subjectsIds.indexOf(s.id) !== -1).value();
        } else {
          return this.subjects;
        }
      }
    },
    props: {
      entity: {
        type: Entity
      },
      entityType: {
        type: String
      }
    },
    methods: {
      getSubjectIdsForProject (project) {
        return _(project.orders)
          .map(o => [o.subscriber.id, o.supplier.id])
          .flatten()
          .value();
      },
      onSubmit () {
        let operation;
        if (this.activity.id) {
          operation = 'activity/update';
        } else {
          operation = 'activity/create';
        }
        const activity = _.cloneDeep(this.activity);
        this.$store.dispatch(operation, activity).then((result) => {
          if (result) {
            notificationService.success('activity.edit.success');
            this.$emit('close');
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.activity = null;
        this.$store.dispatch('contact/clearState');
      }
    },
    watch: {
      model: function (model) {
        if (model) {
          this.activity = _.cloneDeep(model);
          if (this.entity && this.entityType) {
            this.activity[this.entityType] = this.entity;
          }
        }
      },
      'activity.subject': function (newValue, oldValue) {
        // do not clear project and contact for first load in edit mode
        if (this.activity && !_.isUndefined(oldValue)) {
          this.activity.contact = null;
          if (this.entityType !== 'project') {
            this.activity.project = null;
          }
        }
        if (this.activity && this.activity.subject) {
          this.$store.dispatch('contact/findBySubject', this.activity.subject.id);
        } else {
          // clear fetched contacts when activity is created
          this.$store.dispatch('contact/clearState');
        }
      }
    },
    created () {
      this.$store.dispatch('subject/getAll');
      this.$store.dispatch('tmProject/getAll');
      this.$store.dispatch('user/getAll');
    }
  };
</script>
