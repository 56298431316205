import Vue from 'vue';
import Router from 'vue-router';
import loginView from 'views/login';

import tmRewardTemplatesView from 'views/tmRewardTemplates';
import tmRewardsView from 'views/tmRewards';
import statsView from 'views/stats';
import documentTemplatesView from 'views/documentTemplates';
import profileView from 'views/profile';
import profileEditView from 'views/profileEdit';
import passwordChangeView from 'views/passwordChange';

import activityRoutes from 'modules/activity/router/activityRoutes';
import bankAccountRoutes from 'modules/bank/router/bankAccountRoutes';
import bankTransactionRoutes from 'modules/bank/router/bankTransactionRoutes';
import companyRoutes from 'modules/company/router/companyRoutes';
import currencyRateRoutes from 'modules/currencyRate/router/currencyRateRoutes';
import employeeRoutes from 'modules/employee/router/employeeRoutes';
import leadRoutes from 'modules/lead/router/leadRoutes';
import locationRoutes from 'modules/location/router/locationRoutes';
import invoiceRoutes from 'modules/invoice/router/invoiceRoutes';
import invoiceSeriesRoutes from 'modules/invoice/router/invoiceSeriesRoutes';
import recordRoutes from 'modules/record/router/recordRoutes';
import subjectRoutes from 'modules/subject/router/subjectRoutes';
import technologyRoutes from 'modules/technology/router/technologyRoutes';
import tmProjectRoutes from 'modules/project/router/tmProjectRoutes';
import tmDeliveryRoutes from 'modules/delivery/router/tmDeliveryRoutes';
import userRoutes from 'modules/user/router/userRoutes';
import financeRoutes from 'modules/finance/router/financeRoutes';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [
    ...activityRoutes,
    ...bankAccountRoutes,
    ...bankTransactionRoutes,
    ...companyRoutes,
    ...currencyRateRoutes,
    ...employeeRoutes,
    ...financeRoutes,
    ...leadRoutes,
    ...locationRoutes,
    ...invoiceRoutes,
    ...invoiceSeriesRoutes,
    ...recordRoutes,
    ...subjectRoutes,
    ...technologyRoutes,
    ...tmProjectRoutes,
    ...userRoutes,
    ...tmDeliveryRoutes,
    {
      path: '/',
      redirect: '/tm-projects'
    },
    {
      name: 'login',
      path: '/login',
      component: loginView,
      meta: {
        bodyClass: 'login-page'
      }
    },
    {
      path: '/profile',
      component: profileView,
      meta: {
        requiresLoggedIn: true
      }
    },
    {
      path: '/profile-edit',
      component: profileEditView,
      meta: {
        requiresLoggedIn: true
      }
    },
    {
      path: '/password-change',
      component: passwordChangeView,
      meta: {
        requiresLoggedIn: true
      }
    },
    {
      path: '/tm-reward-templates',
      name: 'tmRewardTemplates',
      component: tmRewardTemplatesView,
      meta: {
        requiresLoggedIn: true,
        requiresRole: 'ADMIN'
      }
    },
    {
      path: '/document-templates',
      name: 'documentTemplates',
      component: documentTemplatesView,
      meta: {
        requiresLoggedIn: true,
        requiresRole: 'ADMIN'
      }
    },
    {
      name: 'tmRewards',
      path: '/tm-rewards',
      component: tmRewardsView,
      meta: {
        requiresLoggedIn: true,
        requiresRole: 'DEALER'
      }
    },
    {
      path: '/stats/czk',
      name: 'statsCzk',
      component: statsView,
      meta: {
        requiresLoggedIn: true,
        requiresRole: 'DEALER'
      },
      props: { currencyCode: 'CZK' }
    },
    {
      path: '/stats/eur',
      name: 'statsEur',
      component: statsView,
      meta: {
        requiresLoggedIn: true,
        requiresRole: 'DEALER'
      },
      props: { currencyCode: 'EUR' }
    },
    {
      path: '/*',
      redirect: '/'
    }
  ]
});

export default router;

