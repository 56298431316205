export const TM_DELIVERY_LIST = 'TM_DELIVERY_LIST';
export const TM_DELIVERY_LIST_CLEAR = 'TM_DELIVERY_LIST_CLEAR';

export const TM_REWARD_LIST = 'TM_REWARD_LIST';

export const TM_REWARD_TEMPLATE_LIST = 'TM_REWARD_TEMPLATE_LIST';
export const TM_REWARD_TEMPLATE_LIST_FILTERED = 'TM_REWARD_TEMPLATE_LIST_FILTERED';
export const TM_REWARD_TEMPLATE_ACTIVATE = 'TM_REWARD_TEMPLATE_ACTIVATE';
export const TM_REWARD_TEMPLATE_DEACTIVATE = 'TM_REWARD_TEMPLATE_DEACTIVATE';

export const DOCUMENT_TEMPLATE_LIST = 'DOCUMENT_TEMPLATE_LIST';

export const LIST = 'LIST';

export const AUTH_USER_UPDATE = 'AUTH_USER_UPDATE';
export const AUTH_LOG_IN = 'AUTH_LOG_IN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export const APP_SUBMIT_PROTECTION_ENABLE = 'APP_SUBMIT_PROTECTION_ENABLE';
export const APP_SUBMIT_PROTECTION_DISABLE = 'APP_SUBMIT_PROTECTION_DISABLE';
export const APP_CONFIGURATION_LOAD = 'APP_CONFIGURATION_LOAD';
export const APP_LOADING_DATA_ENABLE = 'APP_LOADING_DATA_ENABLE';
export const APP_LOADING_DATA_DISABLE = 'APP_LOADING_DATA_DISABLE';
