import eventBus from 'services/eventBus';

export default {
  data: () => ({
    show: false
  }),
  props: {
    showModel: {
      type: Boolean,
      default () {
        return false;
      }
    },
    model: {
      type: Object
    }
  },
  methods: {
    async submit () {
      this.protect();
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.onSubmit();
      } else {
        this.unprotect();
      }
    },
    cancel () {
      this.$emit('close');
    },
    onClose () {
      this.unprotect();
      this.clear();
    },
    clear () {
      if (this.clearData) {
        this.clearData();
      }
    },
    isEdit () {
      return this.model && this.model.id;
    }
  },
  watch: {
    showModel (showModel) {
      this.show = showModel;
    }
  },
  created () {
    eventBus.$on('submit', () => {
      if (this.show) {
        this.submit();
      }
    });
    eventBus.$on('close', () => {
      if (this.show) {
        this.cancel();
      }
    });
    // force close for direct change of route without closing window
    eventBus.$on('close-force', () => {
      if (this.show) {
        this.onClose();
      }
    });
  }
};
