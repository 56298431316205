<template>
  <validation-provider :name="fieldId" :rules="validation || validationObj" v-slot="{ errors }">
    <div class="form-group" :class="{ 'has-error': errors.length }">
      <label v-show="label">{{ label }}</label>
      <input :type="type"
             v-model="innerValue"
             :placeholder="placeholder"
             :maxlength="maxlength"
             class="form-control"
             :class="cssClass"
             v-focus="focus"
             @paste="paste"
             :readonly="readonly"/>
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
     </div>
  </validation-provider>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {ValidationProvider},
    data: () => ({
      innerValue: null
    }),
    props: {
      value: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      label: {
        type: String
      },
      placeholder: {
        type: String,
        default: ''
      },
      cssClass: {
        type: String,
        required: false
      },
      fieldId: {
        type: String,
        required: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      maxlength: {
        type: Number,
        required: false,
        default () {
          return 100000;
        }
      },
      validation: {
        type: String
      },
      validationObj: {
        type: Object,
        default () {
          return {};
        }
      },
      required: {
        type: Boolean,
        default: false
      },
      focus: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      paste(e) {
        this.$emit('paste', e);
      }
    },
    watch: {
      innerValue (value) {
        this.$emit('input', value);
      },
      value: {
        handler(value) {
          this.innerValue = value;
        }
      }
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    }
  };
</script>
