<template>
  <div v-if="employee">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  :title="isEdit() ? 'Upraviť zamestnanca' : 'Pridať zamestnanca'" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="firstName"
                        label="Meno"
                        placeholder="Zadaj meno"
                        v-model="employee.firstName"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="surname"
                        label="Priezvisko"
                        placeholder="Zadaj priezvisko"
                        v-model="employee.surname"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="personalId"
                        label="Rodné číslo"
                        placeholder="Zadaj rodné číslo"
                        v-model="employee.personalId"
                        :maxlength="11"
                        validation="required|min:10|max:11"></text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <select-field field-id="locations"
                          label="Lokality"
                          :multiple="true"
                          :options="locations"
                          v-model="employee.locations"></select-field>
          </div>
          <div class="col-md-6">
            <select-field field-id="technologies"
                          label="Technológie"
                          :multiple="true"
                          :options="technologies"
                          v-model="employee.technologies"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-6">
            <text-field field-id="streetName"
                        label="Ulica"
                        placeholder="Zadaj ulicu"
                        v-model="employee.address.streetName"
                        :maxlength="50"
                        validation="required|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="houseNumber"
                        label="Číslo domu"
                        placeholder="Zadaj číslo domu"
                        v-model="employee.address.houseNumber"
                        :maxlength="10"
                        validation="required|max:10"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="city"
                        label="Mesto"
                        placeholder="Zadaj mesto"
                        v-model="employee.address.city"
                        :maxlength="50"
                        validation="required|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="zipCode"
                        label="PSČ"
                        placeholder="Zadaj smerovacie číslo"
                        v-model="employee.address.zipCode"
                        :maxlength="10"
                        validation="required|max:10"></text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="countries"
              v-model="employee.address.country"
              :codelist="true"
              field-id="country"
              label="Štát"
              validation="required"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12">
            <text-area-field field-id="note"
                             v-model="employee.note"
                             label="Poznámka"
                             :maxlength="300"
                             validation="max:300"
                             css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { countries } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import textAreaField from 'components/form/textAreaField';
  import selectField from 'components/form/selectField';
  import { mapGetters } from 'vuex';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';

  export default {
    components: { modal, vForm, textField, selectField, textAreaField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      vatPayer: false,
      employee: null,
      countries: countries
    }),
    computed: {
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      })
    },
    methods: {
      onSubmit () {
        let operation;
        if (this.employee.id) {
          operation = 'employee/update';
        } else {
          operation = 'employee/create';
        }
        const employee = _.cloneDeep(this.employee);
        this.$store.dispatch(operation, employee).then((result) => {
          if (result) {
            notificationService.success('employee.edit.success');
            this.$emit('close');
            if (operation === 'employee/create') {
              this.$router.push({ name: 'employee', params: { id: result.id }});
            }
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.vatPayer = false;
        this.employee = null;
      }
    },
    watch: {
      model: function (model) {
        this.employee = _.cloneDeep(model);
        if (model) {
          this.vatPayer = this.employee && !!this.employee.vatId;
        }
      }
    },
    mounted () {
      this.$store.dispatch('technology/getAll', true);
      this.$store.dispatch('location/getAll', true);
    }
  };
</script>
