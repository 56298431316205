import _ from 'lodash';
import {currencies} from 'codelist/';
import validationMixin from 'components/validationMixin';
import notificationService from 'services/notificationService';
import {mapGetters, mapState } from 'vuex';
import modalMixin from 'components/modalMixin';

export default {
  mixins: [validationMixin, modalMixin],
  data: () => ({
    currencies,
    editableSubject: true,
    moneyMovement: null,
    selectedAmountType: null,
    movementAmountTypes: [
      {value: 'FISCAL', label: 'Z toho DPH:'},
      {value: 'NON_FISCAL', label: 'Nefiškálny'}
    ],
    modeInitialized: false
  }),
  computed: {
    ...mapState({
      invoices(state) {
        let currency = this.moneyMovement.moneyBox.currency;
        return _.filter(state.invoice.items, invoice => {
          const invoiceType = invoice.type !==  this.moneyMovement.type; //OUTCOME invoice type for INCOME movement and vice versa
          const currencyMatch = invoice.currency === currency;
          const subjectMatch = !this.moneyMovement.subject || (this.moneyMovement.subject.id === (this.moneyMovement.type === 'INCOME' ? invoice.subscriber.id : invoice.supplier.id));
          const minTotalAmount = invoice.unpaidAmount >= this.moneyMovement.totalAmount;
          return invoiceType && currencyMatch && subjectMatch && minTotalAmount;
        });
      },
      categories () {
        return this.$store.getters['moneyMovementCategory/validItemsByType'](this.moneyMovement.type);
      },
      vatRates () {
        return this.moneyMovement.date ? _.unionBy(this.$store.getters['vatRate/validItems'](this.moneyMovement.date), 'rate') : [];
      }
    }),
    ...mapGetters({
      subjects: 'subject/active'
    })
  },
  methods: {
    onSubmit () {
      let operation;
      operation = this.moneyMovement.moneyBox.type.toLowerCase() + 'MoneyMovement' + (this.moneyMovement.id ? '/update' : '/create');

      const moneyMovement = _.cloneDeep(this.moneyMovement);

      //need to map enum to boolean
      if (this.selectedAmountType === 'FISCAL') {
        moneyMovement.fiscal = true;
      } else {
        moneyMovement.fiscal = false;
        moneyMovement.totalVat = null;
      }

      this.$store.dispatch(operation, moneyMovement).then((result) => {
        if (result) {
          notificationService.success('moneyMovement.edit.success');
          this.$emit('close');
        }
      }).finally(this.unprotect);
    },
    vatDisabled() {
      return this.selectedAmountType !== 'FISCAL';
    },
    clearData () {
      this.moneyMovement = null;
    }
  },
  watch: {
    model: function (model) {
      this.modelInitialized = false;
      this.moneyMovement = _.cloneDeep(model);
      //ensure positive values also for OUTCOME type
      if (this.moneyMovement.type === 'OUTCOME') {
        this.moneyMovement.totalAmount = Math.abs(this.moneyMovement.totalAmount);
        this.moneyMovement.totalWithoutAmount = Math.abs(this.moneyMovement.totalAmount);
        this.moneyMovement.totalVat = _.isNil(this.moneyMovement.totalVat) ? null : Math.abs(this.moneyMovement.totalVat);
      }
      this.selectedAmountType = this.moneyMovement.fiscal ? 'FISCAL' : 'NON_FISCAL';

      //mark that model is initialized
      this.$nextTick(() => {
        this.modelInitialized = true;
      });
    }
  }
};
