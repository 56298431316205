import Vue from 'vue';

export default () => {

  Vue.directive('focus', {
    inserted: function (el, binding) {
      // condition pass when value is set to true or undefined
      if (!binding.hasOwnProperty('value') || binding.value) {
        el.focus();
      }
    }
  });
};
