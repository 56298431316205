<template>
    <div class="row documents">
      <div class="col-md-12">
        <p :class="{compact}" v-for="document in documents">
          <a role="button" @click.prevent="download(document)" download @click.stop>
            <i :class="[fileIcon(document.fileName)]" class="fa" :title="document.fileName"></i> <span v-if="!compact">{{ document.fileName }}</span>
          </a>
          <a role="button" v-show="editable" @click.prevent="deleteDocument(document)"> &nbsp;&nbsp;<i class="fa fa-times text-danger"></i></a>
        </p>
        <alert v-show="!compact && (!documents || documents.length === 0) && showAlert" type="warning">{{ 'common.data.notRecords' | translate }}</alert>
      </div>
    </div>
</template>
<script>

  import { alert } from 'vue-strap';
  import documentMixin from './documentMixin';

  export default {
    components: { alert },
    props: {
      documents: {
        type: Array,
        required: true
      },
      editable: {
        type: Boolean,
        default: true
      },
      compact: {
        type: Boolean,
        default: false
      },
      showAlert: {
        type: Boolean,
        default: true
      }
    },
    mixins: [documentMixin]
  };
</script>
