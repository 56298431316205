import {
  MONEY_MOVEMENT_LIST,
  MONEY_MOVEMENT_LATEST_LIST,
  MONEY_MOVEMENT_LATEST_LIST_CLEAR,
  MONEY_MOVEMENT_LIST_CLEAR
} from './mutationTypes';

const mutations = {
  [MONEY_MOVEMENT_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  },
  [MONEY_MOVEMENT_LIST_CLEAR] (state) {
    state.items = [];
  },
  [MONEY_MOVEMENT_LATEST_LIST] (state, action) {
    state.latestItems = action.items;
  },
  [MONEY_MOVEMENT_LATEST_LIST_CLEAR] (state) {
    state.latestItems = [];
  }
};

export default mutations;
