<template>
  <box class="box-success">
    <template #header>
      <h4>Celkom</h4>
    </template>
    <template #body>
      <div class="row" v-if="outcome">
        <div class="col-md-6">
          <h5>Vyčíslenie DPH</h5>
        </div>
      </div >
      <div class="row flex-container" v-if="outcome">
        <div class="col-sm-12 col-md-6 table-responsive">
          <table class="table no-padding table-hover dataTable">
            <thead>
            <th>Sadzba</th>
            <th>Základ</th>
            <th>DPH</th>
            </thead>
            <tbody>
            <tr v-for="summary in invoice.summaries">
              <td>{{ summary.vatRate }} %</td>
              <td>{{ summary.totalPrice | currency(invoice.currency) }}</td>
              <td>{{ summary.totalVat | currency(invoice.currency) }}</td>
            </tr>
            </tbody>
            <tfoot v-if="invoice.summaries.length > 0">
            <tr>
              <th>Celkom</th>
              <th>{{ invoice.summary.totalPrice | currency(invoice.currency) }}</th>
              <th>{{ invoice.summary.totalVat | currency(invoice.currency) }}</th>
            </tr>
            </tfoot>
          </table>
        </div>
        <div class="col-sm-12 col-md-6 flex-item-bottom">
          <h4 class="text-right">CELKOM:&nbsp;&nbsp;&nbsp; {{ invoice.summary.totalPriceWithVat | currency(invoice.currency) }}</h4>
        </div>
      </div>
      <div class="row" v-if="income">
        <div class="col-md-6"></div>
        <div class="col-md-3">
          <h4 class="text-right">CELKOM:</h4>
          <div class="text-right">Celkom bez DPH:</div>
        </div>
        <div class="col-md-3">
          <h4 class="text-right">{{ invoice.totalAmount | currency(invoice.currency) }}</h4>
          <div class="text-right">{{ invoice.totalWithoutVat | currency(invoice.currency) }}</div>
        </div>
      </div>
      <div class="row" v-if="income">
        <div class="col-md-6"></div>
        <div class="col-md-3">
          <p class="text-right">Z toho DPH:</p>
        </div>
        <div class="col-md-3">
          <div class="text-right">{{ invoice.totalVat | currency(invoice.currency) }}</div>
        </div>
      </div>
      <slot></slot>
    </template>
  </box>
</template>
<script>
  import Invoice from '../domain/invoice';
  import box from 'components/box';

  export default {
    components: { box },
    props: {
      invoice: {
        type: Invoice,
        required: true
      }
    },
    computed: {
      outcome () {
        return this.invoice.type === 'OUTCOME';
      },
      income () {
        return this.invoice.type === 'INCOME';
      }
    }
  };
</script>
