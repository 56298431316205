<template>
  <div v-if="company">
    <documents :documents="company.documents" @deleteDocument="deleteDocument($event, company)"></documents>
    <document-upload @documentUploaded="documentUploaded($event, company)"></document-upload>
  </div>
</template>
<script>
  import _ from 'lodash';
  import { mapState } from 'vuex';
  import notificationService from 'services/notificationService';
  import documents from 'modules/document/components/documents';
  import documentUpload from 'modules/document/components/documentUpload';

  export default {
    components: { documents, documentUpload },
    computed: mapState({
      company: (state) => state.company.selected
    }),
    methods: {
      documentUploaded (document, company) {
        const companyCopy = _.cloneDeep(company);
        companyCopy.documents.push(document);
        this.$store.dispatch('company/update', companyCopy).then((result) => {
          if (result) {
            notificationService.success('company.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      },
      deleteDocument (document, company) {
        const companyCopy = _.cloneDeep(company);
        _.remove(companyCopy.documents, { id: document.id });
        this.$store.dispatch('company/update', companyCopy).then((result) => {
          if (result) {
            notificationService.success('company.edit.success');
            this.$emit('close');
          } else {
            this.unprotect();
          }
        });
      }
    }
  };
</script>
