<template>
  <page title="Leads">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label>Vyhľadaj podľa mena</label>
              <input v-focus type="text" class="form-control" v-model="filter.query">
            </div>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Technológie"
                          :codelist="true"
                          :multiple="true"
                          :options="technologies"
                          v-model="filter.technologies"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="technologies"
                          label="Lokality"
                          :codelist="true"
                          :multiple="true"
                          :options="locations"
                          v-model="filter.locations"></select-field>
          </div>
          <div class="col-sm-2">
            <select-field field-id="roles"
                          label="Roly"
                          :multiple="true"
                          :codelist="true"
                          :options="subjectRoles"
                          v-model="filter.roles"></select-field>
          </div>
          <div class="col-sm-3">
            <div class="form-group centered-checkbox">
              <label>
                <input class="centered" type="checkbox" id="inactive" v-model="filter.showInactive"> Zobraziť neaktívne
              </label>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #header>
        <div class="row">
          <div class="col-sm-2">
            <button class="btn btn-success" @click.prevent="createShow()"><i class="fa fa-plus"></i> Pridať</button>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <lead-modal :show-model="showCreateModal" :model="selectedLead" @close="hideModals()"></lead-modal>
              <table class="table table-hover no-padding" v-if="leads && leads.length">
                <tbody>
                <tr>
                  <th>Názov</th>
                  <th>IČ</th>
                  <th>DIČ</th>
                  <th>Lokality</th>
                  <th>Roly</th>
                  <th>Technológie</th>
                  <th></th>
                </tr>
                <template v-for="lead in leads">
                  <tr :class="{ inactive : !lead.active }" @click.prevent="detail(lead)" role="button">
                    <td>{{ lead.name }}</td>
                    <td>{{ lead.companyId }}</td>
                    <td>{{ lead.vatId }}</td>
                    <td>
                      <span class="icon-item badge" v-for="location in lead.locations">{{ location.label }}</span>
                    </td>
                    <td>
                      <span class="role-item" v-for="role in lead.roles" :title="role | codeListValue(subjectRoles)">
                        <i class="fa fa-user" :class="role | codeListProp(subjectRoles, 'iconColor')" aria-hidden="true"></i>
                      </span>
                    </td>
                    <td>
                      <span class="icon-item badge" v-for="technology in lead.technologies">{{ technology.label }}</span>
                    </td>
                    <td class="text-right">
                      <router-link tag="button" class="btn btn-xs btn-link" :to="{ name: 'lead', params: { id: lead.id } }"><i class="fa fa-2x fa-search" title="Detail"></i></router-link>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="leads"></loading>
            <no-records :data="leads" loading-id="leads"></no-records>
          </div>
        </div>
      </template>
    </box>

  </page>

</template>

<script>
  import _ from 'lodash';
  import { normalize } from 'utils';
  import { mapState, mapGetters } from 'vuex';
  import leadModal from './leadCreateModal';
  import notificationService from 'services/notificationService';
  import leadService from '../service/leadService';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import selectField from 'components/form/selectField';
  import subjectRoles from 'codelist/subjectRoles';
  import bookmarkableFilter from 'components/bookmarkableFilter';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { leadModal, loading, noRecords, selectField, page, box },
    mixins: [bookmarkableFilter],
    data: () => ({
      showCreateModal: false,
      selectedLead: null,
      subjectRoles: subjectRoles,
      filter: {
        query: null,
        showInactive: false,
        technologies: [],
        locations: [],
        roles: []
      }
    }),
    computed: {
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      }),
      ...mapState({
        leads (state) {
          let leads;
          if (!this.filter.showInactive) {
            leads = this.$store.getters['lead/active'];
          } else {
            leads = state.lead.items;
          }
          if (_.some(this.filter.technologies)) {
            leads = _.filter(leads, lead => {
              return !_(this.filter.technologies).difference(_.map(lead.technologies, 'id')).some();
            });
          }
          if (_.some(this.filter.locations)) {
            leads = _.filter(leads, lead => {
              return !_(this.filter.locations).difference(_.map(lead.locations, 'id')).some();
            });
          }
          if (_.some(this.filter.roles)) {
            leads = _.filter(leads, lead => {
              return !_(this.filter.roles).difference(lead.roles).some();
            });
          }
          if (this.filter.query) {
            const regexp = new RegExp(normalize(this.filter.query), 'g');
            return leads.filter(lead => {
              return !!normalize(lead.name).match(regexp);
            });
          } else {
            return leads;
          }
        }
      })
    },
    methods: {
      createShow () {
        this.selectedLead = leadService.newLead();
        this.showCreateModal = true;
      },
      hideModals () {
        this.showCreateModal = false;
      },
      detail (lead) {
        this.$router.push({ name: 'lead', params: { id: lead.id }});
      }
    },
    created () {
      this.$store.dispatch('lead/getAll');
    }
  };
</script>
