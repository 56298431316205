import financeSettingsView from 'modules/finance/components/financeSettings';
import financeOverview from 'modules/finance/components/financeOverview';
import moneyBox from 'modules/finance/components/moneyBox';

export default [
  {
    path: '/finance/overview/:currency',
    component: financeOverview,
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    path: '/finance/settings',
    component: financeSettingsView,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  },
  {
    name: 'moneyBox',
    path: '/money-box',
    component: moneyBox,
    meta: {
      requiresLoggedIn: true,
      requiresRole: 'ADMIN'
    }
  }];
