import { TM_REWARD_LIST } from '../../mutationTypes';

const mutations = {
  [TM_REWARD_LIST] (state, action) {
    state.items = action.items;
    state.filter = action.filter;
  }
};

export default mutations;
