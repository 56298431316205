<template>
  <i class="fa" :class="{'fa-check text-success': value, 'fa-times text-danger': !value}"></i>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
