<template>
  <a role="button" v-if="subject" @click.stop="projectDetail()">{{ subject ? subject.format() : null }}</a>
</template>

<script>
  import Entity from '../domain/entity';

  export default {
    props: {
      subject: {
        type: Entity,
        required: true
      },
      to: {
        type: String,
        default () {
          return `${this.subject.constructor.route}Records`;
        }
      }
    },
    methods: {
      projectDetail () {
        this.$router.push({ name: this.to, params: { id: this.subject.id }});
      }
    }
  };
</script>

<style scoped>

</style>
