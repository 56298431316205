import Vue from 'vue';
import { API_ROOT } from '../../../config';

const context = 'tm-order';
const customActions = {
  updateDocuments: { method: 'PUT', url: API_ROOT + context + '/update-documents' },
  generateOrderDocument: { method: 'GET', url: API_ROOT + context + '/generate-document{/id}', responseType: 'blob' },
  generateOrderPdfDocument: { method: 'GET', url: API_ROOT + context + '/generate-document{/id}/pdf', responseType: 'blob' }
};

export default Vue.resource(API_ROOT + context + '/{id}', {}, customActions);
