import _ from 'lodash';
import countries from 'codelist/countries';

class Address {
  constructor (data) {
    _.merge(this, data);

    this.streetName = data.streetName || null;
    this.houseNumber = data.houseNumber || null;
    this.city = data.city || null;
    this.zipCode = data.zipCode || null;
    this.country = data.country || null;
  }

  format () {
    const result = [];
    if (this.streetName) {
      result.push(this.streetName);
    }
    if (this.streetName && this.houseNumber) {
      result.push(this.houseNumber);
    }
    if (this.zipCode) {
      result.push(this.zipCode);
    }
    if (this.city) {
      result.push(this.city);
    }
    if (this.country) {
      result.push(_.find(countries, { value: this.country }).label);
    }
    return result.join(', ');
  }
}

export default Address;
