<template>
  <page :title="'Faktúra č. ' + invoice.number" v-if="invoice">

    <div class="row">
      <div class="col-md-4">

        <div class="row">
          <div class="col-md-12">
            <company-box title="Odberateľ" :company="invoice.subscriber"></company-box>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <company-box title="Dodávateľ" :company="invoice.supplier" :collapsible="true"
                         :collapsed="outcome"></company-box>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12">
            <box>
              <template #header>
                <h4>Detail faktúry
                  <span class="pull-right" v-if="invoice.partiallyPaid && !invoice.overdue"><i class="text-orange fa fa-info-circle"></i> Čiastočne uhradená</span>
                  <span class="pull-right" v-if="invoice.paid"><i class="text-green fa fa-check"></i> Uhradená</span>
                  <span class="pull-right" v-if="invoice.overdue"><i class="text-red fa fa-exclamation-triangle"></i> Po splatnosti</span>
                </h4>
              </template>
              <template #body>
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group block">
                      <button type="button" class="btn btn-default" @click.stop="edit(invoice)" :disabled="invoice.paid || invoice.partiallyPaid"><i class="fa fa-pencil text-primary" title="Editovať"></i></button>
                      <button type="button" class="btn btn-default" @click.stop="copy(invoice)"><i class="fa fa-clone" title="Kopírovať"></i></button>
                      <button type="button" class="btn btn-default" @click.stop="deleteInvoice(invoice)" :disabled="invoice.paid || invoice.partiallyPaid" v-if="income"><i class="fa fa-times text-danger" title="Zmazať"></i></button>
                      <button type="button" class="btn btn-success" v-if="outcome"><i class="fa fa-download"></i></button>
                      <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown" v-if="outcome">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu" role="menu" v-if="outcome">
                        <li><a role="button" @click="generateDocument(template)" v-for="template in documentTemplates"><i class="fa fa-file-word-o text-info"></i >{{ template.name }}</a></li>
                        <li><a role="button" @click="generatePdfDocument(template)" v-for="template in documentTemplates"><i class="fa fa-file-pdf-o text-danger"></i >{{ template.name }}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <dl>
                      <dt>Dátum vystavenia</dt>
                      <dd>{{ invoice.dateOfIssue | date }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Splatnosť</dt>
                      <dd>{{ invoice.dueDate | date }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Dátum zdan. plnenia</dt>
                      <dd>{{ invoice.taxableDate | date }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Spôsob platby</dt>
                      <dd>{{ invoice.paymentMethod | codeListValue(paymentMethods) }}</dd>
                    </dl>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">

                  </div>
                </div>
              </template>
            </box>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <box :collapsible="true" :collapsed="outcome">
              <template #header>
                <h4>Ďalšie nastavenia</h4>
              </template>
              <template #body>
                <div class="row">
                  <div class="col-md-2">
                    <dl>
                      <dt>Mena</dt>
                      <dd>{{ invoice.currency | codeListValue(currencies) }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-10" v-show="invoice.bankAccount">
                    <dl>
                      <dt>Účet</dt>
                      <dd>{{ invoice.bankAccount.name }}</dd>
                    </dl>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3">
                    <dl>
                      <dt>Referencia</dt>
                      <dd>{{ invoice.reference }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Variabilny s.</dt>
                      <dd>{{ invoice.variableSymbol }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Konštantný s.</dt>
                      <dd>{{ invoice.constantSymbol }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Špecifický s.</dt>
                      <dd>{{ invoice.specificSymbol }}</dd>
                    </dl>
                  </div>
                </div>
              </template>
            </box>
          </div>
        </div>
        <div class="row" v-if="outcome">
          <div class="col-md-12">
            <box>
              <template #header>
                <h4>Položky faktúry</h4>
              </template>
              <template #body>
                <div class="table-responsive">
                  <table class="table table-hover dataTable">
                    <thead>
                    <tr>
                      <th>Položka</th>
                      <th>Množstvo</th>
                      <th class="text-nowrap">Cena za MJ</th>
                      <th class="text-nowrap">Sadzba DPH</th>
                      <th>Základ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in invoice.items">
                      <td>{{ item.name }}</td>
                      <td class="text-right">{{ item.number }} {{ item.unit | codeListValue(invoiceItemUnits) }}</td>
                      <td class="text-right text-nowrap">{{ item.price | currency(invoice.currency) }}</td>
                      <td class="text-right text-nowrap">{{ item.vatRate }} %</td>
                      <td class="text-right text-nowrap">{{ item.totalPrice | currency(invoice.currency) }}</td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </box>
          </div>
        </div>
        <invoice-summary-box :invoice="invoice"/>
      </div>
    </div>
  </page>
</template>
<script>
  import _ from 'lodash';
  import box from 'components/box';
  import companyBox from 'modules/company/components/companyBox';
  import { currencies, invoiceItemUnits, paymentMethods } from 'codelist/';
  import { GENERATED_DOCUMENT_PDF_SUFFIX, GENERATED_DOCUMENT_SUFFIX, INVOICE_GENERATED_DOCUMENT_NAME } from 'config';
  import invoiceService from '../service/invoiceService';
  import invoiceSummaryBox from './invoiceSummaryBox';
  import {mapState} from 'vuex';
  import notificationService from 'services/notificationService';
  import page from 'components/page';

  export default {
    components: {page, box, companyBox, invoiceSummaryBox},
    data: () => ({
      paymentMethods,
      currencies,
      invoiceItemUnits,
      show: true
    }),
    computed: {
      ...mapState({
        invoice: (state) => state.invoice.selected,
        documentTemplates: (state) => _.filter(state.documentTemplate.items, {type: 'INVOICE'})
      }),
      outcome () {
        return this.invoice.type === 'OUTCOME';
      },
      income () {
        return this.invoice.type === 'INCOME';
      }
    },
    methods: {
      generateDocument (template) {
        const fileName = `${INVOICE_GENERATED_DOCUMENT_NAME}-${this.invoice.number}${GENERATED_DOCUMENT_SUFFIX}`;
        invoiceService.generateDocument(this.invoice.id, template.id, fileName);
      },
      generatePdfDocument (template) {
        const fileName = `${INVOICE_GENERATED_DOCUMENT_NAME}-${this.invoice.number}${GENERATED_DOCUMENT_PDF_SUFFIX}`;
        invoiceService.generatePdfDocument(this.invoice.id, template.id, fileName);
      },
      edit(invoice) {
        this.$router.push({name: this.invoice.type === 'OUTCOME' ? 'invoiceOutcomeEdit' : 'invoiceIncomeEdit', params: { id: invoice.id + '' }});
      },
      copy(invoice) {
        this.$router.push({name: this.invoice.type === 'OUTCOME' ? 'invoiceOutcomeCopy' : 'invoiceIncomeCopy', params: {invoiceId: invoice.id}});
      },
      deleteInvoice (invoice) {
        this.confirm('invoice.delete.confirmation', [invoice.number]).then(() => {
          this.$store.dispatch('invoice/delete', invoice.id).then((result) => {
            if (result) {
              notificationService.success('invoice.delete.success');
              this.$router.push(`/invoices/${invoice.type}`);
            }
          }).catch(this.unprotect);
        });
      },
    },
    created() {
      this.$store.dispatch('invoice/select', this.$store.state.route.params.id);
      this.$store.dispatch('documentTemplate/getAll');
    }
  };
</script>
