<template>
  <page title="Nastavenie financií">
    <div class="row">
      <div class="col-md-12">
        <money-box-modal :show-model="showMoneyBoxCreateModal" :model="selectedMoneyBox" @close="hideModals()"></money-box-modal>
        <box>
          <template #header>
            <h3 class="box-title">Pokladne</h3>
          </template>
          <template #body>
            <div class="row">
              <div class="col-md-12">
                <p class="pull-left">
                  <button class="btn btn-success" @click.prevent="createMoneyBoxShow()"><i class="fa fa-plus"></i></button>
                </p>
                <label class="pull-right">
                  <input class="centered" type="checkbox" id="inactive" v-model="filter.moneyBoxShowInactive"> Zobraziť vyradené

                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-responsive no-padding table-hover">
                  <thead>
                    <tr class="bg-gray">
                      <th>#</th>
                      <th>Typ</th>
                      <th>Názov</th>
                      <th class="text-right">Aktuálny stav</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(moneyBox, index) in moneyBoxes" :class="{ inactive : !moneyBox.active }">
                      <td>{{ index + 1 }}</td>
                      <td>{{ moneyBox.type | codeListValue(moneyBoxTypes) }}</td>
                      <td>{{ moneyBox.name }}</td>
                      <td class="text-right">{{ moneyBox.amount | currency(moneyBox.currency) }}</td>
                      <td class="text-right">
                        <button class="btn btn-link" @click.stop="editMoneyBox(moneyBox)"><i class="fa fa-pencil text-muted"></i></button>
                        <button class="btn btn-link" @click.prevent="updatePosition(moneyBox, index - 1)" v-if="moneyBox.active"><i class="fa fa-long-arrow-up text-muted"></i></button>
                        <button class="btn btn-link" @click.prevent="updatePosition(moneyBox, index + 1)" v-if="moneyBox.active"><i class="fa fa-long-arrow-down text-muted"></i></button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="bg-gray">
                      <td colspan="5">Počet položiek: {{ moneyBoxes.length }}</td></tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </template>
        </box>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <code-list loading-id="money-movement-categories"
                   module-name="moneyMovementCategory"
                   title="Výdajové kategórie pohybov"
                   :orderable="true"
                   :show-query-filter="false"
                   :filter-fnc="(item) => 'OUTCOME' === item.type"
                   :new-item-template="{ type: 'OUTCOME' }">
        </code-list>

      </div>
      <div class="col-sm-6">
        <code-list loading-id="money-movement-categories"
                   module-name="moneyMovementCategory"
                   title="Príjmové kategórie pohybov"
                   :orderable="true"
                   :show-query-filter="false"
                   :filter-fnc="(item) => 'INCOME' === item.type"
                   :new-item-template="{ type: 'INCOME' }">
        </code-list>
      </div>
    </div>
  </page>
</template>

<script>
  import page from 'components/page';
  import box from 'components/box';
  import moneyBoxTypes from '../domain/moneyBoxTypes';
  import { mapState } from 'vuex';
  import notificationService from 'services/notificationService';
  import moneyBoxModal from './moneyBoxCreateModal';
  import moneyBoxService from "../service/moneyBoxService";
  import codeList from 'components/codeListGeneric';

  export default {
    components: { page, box, moneyBoxModal, codeList },
    data: () => ({
      moneyBoxTypes,
      showMoneyBoxCreateModal: false,
      selectedMoneyBox: null,
      filter: {
        moneyBoxShowInactive: false
      }
    }),
    computed: {
      ...mapState({
        moneyBoxes(state) {
          return this.filter.moneyBoxShowInactive ? state.moneyBox.items : this.$store.getters['moneyBox/active'];
        }
      })
    },
    methods: {
      defaultFilter () {
        this.filter.showInactive = false;
      },
      updatePosition (moneyBox, position) {
        if (position >= 0 && position < this.moneyBoxes.length) {
          this.$store.dispatch('moneyBox/updatePosition', { id: moneyBox.id, position}).then((result) => {
            if (result) {
              notificationService.success('moneyBox.edit.success');
            } else {
              notificationService.error('error.moneyBox.updatePosition');
            }
          });
        }
      },
      createMoneyBoxShow () {
        this.selectedMoneyBox = moneyBoxService.newMoneyBox();
        this.showMoneyBoxCreateModal = true;
      },
      editMoneyBox (moneyBox) {
        this.selectedMoneyBox = _.cloneDeep(moneyBox);
        this.showMoneyBoxCreateModal = true;
      },
      hideModals () {
        this.showMoneyBoxCreateModal = false;
      }
    },
    created() {
      this.$store.dispatch('moneyBox/getAll', true);
      this.$store.dispatch('moneyMovementCategory/getAll', true);
    }
  };
</script>
