import recordResource from '../resources/recordResource';
import _ from 'lodash';
import Record from 'modules/record/domain/record';
import moment from 'moment';

export default {
  newFromActivity (activity) {
    const newRecord = new Record({
      date: moment(),
      type: this.getRecordType(activity.type),
      contact: activity.contact,
      subject: activity.subject,
      project: activity.project,
      note: _.isString(activity.note) ? activity.note + '\n' : null
    });
    return newRecord;
  },
  getRecordType (activityType) {
    switch (activityType) {
      case 'PHONE_CALL':
        return 'PHONE_COMMUNICATION';
      case 'EMAIL':
        return 'EMAIL_COMMUNICATION';
      default:
        return null;
    }
  },
  async findAll (filter) {
    const response = await recordResource.query(filter);
    if (response.ok) {
      return response.data.map((recordData) => new Record(recordData));
    } else {
      return null;
    }
  },
  async findByEntity (queryParams) {
    const response = await recordResource.query({ id: queryParams.entityId, entity: queryParams.entity });
    if (response.ok) {
      return response.data.map((recordData) => new Record(recordData));
    } else {
      return null;
    }
  },
  async create (data) {
    const response = await recordResource.save({}, _.pickBy(data));
    if (response.ok) {
      return new Record(response.data);
    } else {
      return null;
    }
  },
  async update (data) {
    const response = await recordResource.update({}, _.pickBy(data));
    if (response.ok) {
      return new Record(response.data);
    } else {
      return null;
    }
  },
  async delete (data) {
    return recordResource.delete({ id: data.id });
  }
};
