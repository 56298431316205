/* eslint-disable no-unused-vars */
import Vue from 'vue';
import notificationService from 'services/notificationService';
import { sync } from 'vuex-router-sync';
import moment from 'moment';
import momentSk from 'moment/locale/sk';
import { extendMoment } from 'moment-range';
import numeral from 'numeral';
import numeralSk from 'numeral/locales/sk';
import adminLte from 'admin-lte/dist/js/adminlte'; // it must be here for loading adminLte
import vueStrapLang from 'vue-strap/dist/vue-strap-lang'; // it must be here because of lang loading
import 'bootstrap/dist/js/bootstrap';
import App from './components/app';
import './resources/';
import router from './router/';
import store from './store/';
import interceptors from './interceptors';
import hooks from './hooks';
import mixins from './mixins';
import validations from './validation/';
import filters from './filters/';
import directives from './directives';
import VueClipboard from 'vue-clipboard2';

// override moment default toJSON which use UTC time zone, becouse of using LocalDate in BE
moment.fn.toJSON = function () { return this.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); };

// set numeral locale to slovak
numeral.locale('sk');
// set moment locale to slovak
moment.locale('sk');
//extend moment with moment-range
extendMoment(moment);


sync(store, router);

// register http interceptor
interceptors(store, router);

// register hooks
hooks(store, router);

// register mixins
mixins(store);

validations();
filters();
directives();

Vue.use(VueClipboard);

const app = new Vue({
  router,
  store,
  ...App
});

notificationService.init();

export { app, router, store };
