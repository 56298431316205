<template>
  <div v-if="company">
    <modal v-model="show" @ok="submit()" @cancel="cancel()" @closed="onClose()" :backdrop="false"
                  :title="isEdit() ? 'Upraviť firmu' : 'Pridať firmu'" cancel-text="Zrušiť" ok-text="Uložiť" :medium="true">
      <v-form ref="form">
        <div class="row">
          <div v-if="!isEdit()" class="col-md-12">
            <select-field
              :options="companies"
              v-model="searchedCompany"
              field-id="searchedCompanyId"
              label="Vyhladaj v ARESe (aspoň 4 písmenká názvu, nebo IČO)"
              label-prop="labelDetail"
              :on-search="onSearch"></select-field>
          </div>
          <div class="col-md-12">
            <text-field field-id="name"
                        label="Název spoločnosti"
                        placeholder="Zadaj názov firmy"
                        v-model="company.name"
                        :maxlength="100"
                        validation="required|min:3|max:100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <text-field field-id="companyId"
                        label="IČO"
                        placeholder="Zadaj IČ firmy"
                        v-model="company.companyId"
                        :maxlength="20"
                        validation="required|max:20"></text-field>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>
                <input type="checkbox" v-model="vatPayer"/> Plátca DPH
              </label>
              <input class="form-control" id="vatId" placeholder="DIČ" v-model="company.vatId" name="vatId" maxlength="12" v-show="vatPayer">
            </div>
          </div>
          <div class="col-md-6">
            <select-field field-id="roles"
                          label="Roly"
                          :multiple="true"
                          :codelist="true"
                          :options="subjectRoles"
                          v-model="company.roles"></select-field>
          </div>
        </div>
          <div class="row">
            <div class="col-md-6">
              <select-field field-id="locations"
                            label="Lokality"
                            :multiple="true"
                            :options="locations"
                            v-model="company.locations"></select-field>
            </div>
            <div class="col-md-6">
              <select-field field-id="technologies"
                            label="Technológie"
                            :multiple="true"
                            :options="technologies"
                            v-model="company.technologies"></select-field>
            </div>
          </div>

        <hr>

        <div class="row">
          <div class="col-md-6">
            <text-field field-id="streetName"
                        label="Ulica"
                        placeholder="Zadaj ulicu"
                        v-model="company.address.streetName"
                        :maxlength="50"
                        validation="required|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="houseNumber"
                        label="Číslo domu"
                        placeholder="Zadaj číslo domu"
                        v-model="company.address.houseNumber"
                        :maxlength="10"
                        validation="required|max:10"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <text-field field-id="city"
                        label="Zadaj mesto"
                        placeholder="Zadaj obec"
                        v-model="company.address.city"
                        :maxlength="50"
                        validation="required|max:50"></text-field>
          </div>
          <div class="col-md-6">
            <text-field field-id="zipCode"
                        label="PSČ"
                        placeholder="Zadaj smerovacie číslo"
                        v-model="company.address.zipCode"
                        :maxlength="10"
                        validation="required|max:10"></text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <select-field
              :options="countries"
              v-model="company.address.country"
              :codelist="true"
              field-id="country"
              label="Štát"
              validation="required"></select-field>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-6">
            <text-field field-id="companyWeb"
                        label="Web"
                        placeholder="Zadaj web firmy"
                        v-model="company.web"
                        :maxlength="100"></text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <text-area-field field-id="note"
                             v-model="company.note"
                             label="Poznámka"
                             :maxlength="300"
                             validation="max:300"
                             css-class="note"></text-area-field>
          </div>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { countries, subjectRoles } from 'codelist/';
  import modal from 'vue-strap/src/Modal';
  import validationMixin from 'components/validationMixin';
  import notificationService from 'services/notificationService';
  import textField from 'components/form/textField';
  import selectField from 'components/form/selectField';
  import textAreaField from 'components/form/textAreaField';
  import { mapGetters, mapState } from 'vuex';
  import modalMixin from 'components/modalMixin';
  import vForm from 'components/form/vForm';
  import { ARES_SEARCH_MIN_LENGTH, ARES_SEARCH_DELAY } from '../../../config';

  export default {
    components: { modal, vForm, textField, selectField, textAreaField },
    mixins: [validationMixin, modalMixin],
    data: () => ({
      vatPayer: false,
      company: null,
      subjectRoles: subjectRoles,
      searchedCompany: null
    }),
    computed: {
      ...mapState({
        companies: (state) => state.company.aresItems
      }),
      ...mapGetters({
        technologies: 'technology/validItems',
        locations: 'location/validItems'
      }),
      countries () {
        return countries;
      }
    },
    methods: {
      onSearch (search, loading) {
        if (search && search.length >= ARES_SEARCH_MIN_LENGTH) {
          this.deferredFetch(search, loading);
        }
      },
      deferredFetch: _.debounce(function (name, loading) {
        loading(true);
        this.$store.dispatch('company/fetchFromAres', name).finally(() => loading(false));
      }, ARES_SEARCH_DELAY),
      onSubmit () {
        let operation;
        if (this.isEdit()) {
          operation = 'company/update';
        } else {
          operation = 'company/create';
        }
        const company = _.cloneDeep(this.company);
        this.$store.dispatch(operation, company).then((result) => {
          if (result) {
            notificationService.success('company.edit.success');
            this.$emit('close');
            if (!this.isEdit()) {
              this.$router.push({ name: 'company', params: { id: result.id }});
            }
          }
        }).finally(this.unprotect);
      },
      clearData () {
        this.vatPayer = false;
        this.company = null;
        this.searchedCompany = null;
      },
      isEdit () {
        return this.company && this.company.id;
      }
    },
    watch: {
      model: function (model) {
        this.company = _.cloneDeep(model);
        if (model) {
          this.vatPayer = this.company && !!this.company.vatId;
        }
      },
      searchedCompany (company) {
        this.company = _.cloneDeep(company);
        this.vatPayer = this.company && !!this.company.vatId;
      }
    },
    mounted () {
      this.$store.dispatch('technology/getAll', true);
      this.$store.dispatch('location/getAll', true);
    }
  };
</script>
