<template>
  <page title="Menové kurzy">
    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <date-field v-model="filter.date" field-id="date" label="Dátum"></date-field>
          </div>
          <div class="col-sm-6 col-md-8">
            <div class="pull-right">
              <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <i class="fa fa-gear"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a role="button" @click.prevent="download()"><i class="fa fa-download text-primary"></i> Stiahnuť pre vybraný dátum</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </box>

    <box>
      <template #body>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover no-padding" v-if="currencyRates && currencyRates.length">
                <tbody>
                <tr>
                  <th>Datum</th>
                  <th>Mena</th>
                  <th>Hodnota</th>
                </tr>
                <template v-for="currencyRate in currencyRates">
                  <tr>
                    <td>{{ currencyRate.date | date }}</td>
                    <td>{{ currencyRate.currency }}</td>
                    <td>{{ currencyRate.rate }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <loading id="currencyRates"></loading>
            <no-records :data="currencyRates" loading-id="currencyRates"></no-records>
          </div>
        </div>
      </template>
    </box>
  </page>
</template>
<script>
  import _ from 'lodash';
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import dateField from 'components/form/dateField';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import notificationService from 'services/notificationService';
  import page from 'components/page';
  import box from 'components/box';

  export default {
    components: { dateField, loading, noRecords, page, box },
    data () {
      return {
        filter: {
          date: moment()
        }
      };
    },
    computed: {
      ...mapState({
        currencyRates: (state) => state.currencyRate.items
      })
    },
    methods: {
      fetchRates () {
        this.$store.dispatch('currencyRate/findByFilter', _.pickBy({
          date: FORMAT_SYSTEM_DATE(this.filter.date)
        }, _.identity));
      },
      async download () {
        const syncResult = await this.$store.dispatch('currencyRate/download', {
          date: FORMAT_SYSTEM_DATE(this.filter.date)
        });
        if (syncResult) {
          notificationService.success('currencyRate.download.success');
        } else {
          notificationService.error('error.currencyRate.download');
        }
      }
    },
    watch: {
      filter: {
        handler () {
          this.fetchRates();
        },
        deep: true
      }
    },
    created () {
      this.fetchRates();
    }
  };
</script>
