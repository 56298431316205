import * as types from './mutationTypes';
import moneyBoxService from '../service/moneyBoxService';
import _ from 'lodash';

const actions = {
  async getAll ({ state, dispatch, commit }, force) {
    if (!state.items || state.items.length === 0 || force) {
      dispatch('app/loadingDataEnable', 'moneyBoxes', { root: true });
      const moneyBoxes = await moneyBoxService.findAll();
      await commit(types.MONEY_BOX_LIST, {
        items: moneyBoxes
      });
      dispatch('app/loadingDataDisable', 'moneyBoxes', { root: true });
    }
  },
  async balances ({ state, dispatch, commit }, filter) {
    const balances = await moneyBoxService.balances(filter);
    await commit(types.MONEY_BOX_BALANCE_LIST, {
      items: balances
    });
  },
  async create ({ dispatch }, moneyBox) {
    try {
      const newBankAccount = await moneyBoxService.create(moneyBox);
      if (newBankAccount) {
        await dispatch('getAll', true);
        return newBankAccount;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async update ({ dispatch, commit }, moneyBox) {
    try {
      const updatedBankAccount = await moneyBoxService.update(moneyBox);
      if (updatedBankAccount) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async updatePosition ({ dispatch, state, commit }, data) {
    try {
      const updated = await moneyBoxService.updatePosition(data.id, data.position );
      if (updated) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async activate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.MONEY_BOX_ACTIVATE, id);
      const moneyBox = _.find(state.items, { id: id });
      const updatedBankAccount = await moneyBoxService.update(moneyBox);
      if (updatedBankAccount) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async deactivate ({ dispatch, state, commit }, id) {
    try {
      await commit(types.MONEY_BOX_DEACTIVATE, id);
      const moneyBox = _.find(state.items, { id: id });
      const updatedBankAccount = await moneyBoxService.update(moneyBox);
      if (updatedBankAccount) {
        await dispatch('getAll', true);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  async refresh ({ dispatch}) {
    await dispatch('getAll', true);
  },
  async clear ({ commit}) {
    await commit(types.MONEY_BOX_LIST_CLEAR);
    await commit(types.MONEY_BOX_BALANCE_LIST_CLEAR);
  },
  async delete ({ dispatch }, id) {
    const response = await moneyBoxService.delete(id);
    if (response.ok) {
      dispatch('getAll', true);
      return true;
    } else {
      return false;
    }
  }
};

export default actions;
