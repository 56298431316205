import Address from '../../../domain/address';
import Document from 'modules/document/domain/document';
import Subject from '../../../domain/subject';
import Account from '../../account/domain/account';

class Company extends Subject {
  constructor (data) {
    super(data);
    this.name = data.name || null;
    this.companyId = data.companyId || null;
    this.vatId = data.vatId || null;
    this.account = data.account ? new Account(data.account) : null;
    this.web = data.web ? data.web : null;

    this.address = data.address ? new Address(data.address) : null;
    this.documents = data.documents ? data.documents.map(document => new Document(document)) : [];

    this.value = this.id;
    this.label = this.name;
    this.labelDetail = `${this.name} (IČ: ${this.companyId})`;

    if (!this['@class']) {
      this['@class'] = '.CompanyDto';
    }
  }

  static get route () {
    return 'company';
  }

  format () {
    return this.name;
  }

  supportsContractAgreement () {
    return false;
  }
}

export default Company;
