<template>
  <div>
    <activity-modal :show-model="showActivityModal" :model="selectedActivity" :entity="entity" :entity-type="entityType" @close="hideModals()"></activity-modal>
    <record-modal :show-model="showRecordModal" :model="newRecord" :entity="entity" @close="hideModals()" :record-entity="entityType"></record-modal>

    <div class="row">
      <div class="col-md-12">
        <p>
          <button class="btn btn-success" @click.prevent="createActivity()"><i class="fa fa-plus"></i> Pridať aktivitu</button>
        </p>
      </div>
    </div>
    <div v-if="activityGroups && activityGroups.length">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <ul class="timeline">
            <template v-for="activityGroup in activityGroups">
              <li class="time-label"><span class="bg-orange">{{ activityGroup.date | date }}</span></li>

              <!--activities of day-->
              <li v-for="activity in activityGroup.activities">
                <i :title="activity.type | codeListValue(activityTypes)" :class="typeIcon(activity.type)"></i>
                <div class="timeline-item">
                  <span class="time">{{ activity.createdBy.fullName }}</span>

                  <div class="timeline-header">
                    <span :title="activity.status | codeListValue(activityStatuses)">
                      <i :class="statusIcon(activity.status, true)"></i> {{ activity.name }} <span v-if="activity.assignee">( {{ activity.assignee.fullName }} )</span>
                    </span>

                    <span v-if="entityType === 'project' && activity.subject">
                      <span class="splitter">|</span>
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <router-link
                        tag="span"
                        :to="{ name: `${activity.subject.constructor.route}Activities`, params: { id: activity.subject.id }}"
                        active-class="active">
                        <a>{{ activity.subject.name }}</a>
                      </router-link>
                    </span>

                    <span v-if="entityType === 'subject' && activity.project">
                      <span class="splitter">|</span>
                      <i class="fa fa-briefcase" aria-hidden="true"></i>
                      <router-link
                        tag="span"
                        :to="{ name: 'projectActivities', params: { id: activity.project.id }}"
                        active-class="active">
                        <a>{{ activity.project.name }}</a>
                      </router-link>
                    </span>

                    <span v-if="activity.contact">
                      <span class="splitter">|</span>
                      <i class="fa fa-address-book-o" aria-hidden="true"></i> {{ activity.contact.label }}
                    </span>
                  </div>

                  <div class="timeline-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="formatted-text">{{ activity.note }}</div>
                        <div class="pull-right">
                          <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                              <i class="fa fa-gear"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li><a role="button" @click.prevent="editActivity(activity)"><i class="fa fa-pencil-square-o text-primary"></i> Editovať</a></li>
                              <li v-if="activity.isOpen()"><a role="button" @click.prevent="finishActivity(activity)"><i class="fa fa-check text-success"></i> Ukončiť</a></li>
                              <li v-if="activity.isOpen()"><a role="button" @click.prevent="cancelActivity(activity)"><i class="fa fa-times text-danger"></i> Zrušiť</a></li>
                              <li><a role="button" @click.prevent="sendActivity(activity)"><i class="fa fa-envelope-o text-primary"></i> Odoslať</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </li>
            </template>
            <li><i class="fa fa-clock-o bg-gray"></i></li>
          </ul>
        </div>
      </div>
    </div>
    <loading class="content-header" id="activities"></loading>
    <no-records :data="activityGroups" loading-id="activities"></no-records>
  </div>
</template>

<script>
  import _ from 'lodash';
  import Entity from '../../../domain/entity';
  import { mapState } from 'vuex';
  import { FORMAT_SYSTEM_DATE } from 'utils';
  import loading from 'components/loading';
  import noRecords from 'components/noRecords';
  import activityStatuses from 'codelist/activityStatuses';
  import activityTypes from 'codelist/activityTypes';
  import activityModal from 'modules/activity/components/activityCreateModal';
  import notificationService from 'services/notificationService';
  import activityService from '../service/activityService';
  import recordService from 'services/recordService';
  import recordModal from 'modules/record/components/recordCreateModal';
  import emailService from 'services/emailService';

  export default {
    components: { loading, noRecords, activityModal, recordModal },
    data: () => {
      return {
        activityStatuses: activityStatuses,
        activityTypes: activityTypes,
        showActivityModal: false,
        showRecordModal: false,
        selectedActivity: null,
        newRecord: null
      };
    },
    props: {
      entity: {
        type: Entity,
        required: true
      },
      entityType: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState({
        activities: (state) => state.activity.items,
        activityGroups: (state) => _(state.activity.items)
          .groupBy(activity => FORMAT_SYSTEM_DATE(activity.date))
          .map(activities => {
            return { date: activities[0].date, activities };
          }).orderBy('date', 'desc').value()
      })
    },
    methods: {
      createRecord (activity) {
        this.newRecord = recordService.newFromActivity(activity);
        this.showRecordModal = true;
      },
      createActivity () {
        this.selectedActivity = activityService.newActivity();
        this.showActivityModal = true;
      },
      editActivity (activity) {
        this.selectedActivity = _.cloneDeep(activity);
        this.showActivityModal = true;
      },
      finishActivity (activity) {
        this.$store.dispatch('activity/finish', activity.id).then((result) => {
          if (result) {
            notificationService.success('activity.finish.success');
            this.createRecord(activity);
          } else {
            notificationService.error('error.activity.finish');
          }
        });
      },
      cancelActivity (activity) {
        this.$store.dispatch('activity/cancel', activity.id).then((result) => {
          if (result) {
            notificationService.success('activity.close.success');
          } else {
            notificationService.error('error.activity.close');
          }
        });
      },
      sendActivity (activity) {
        const contacts = activity.contact ? [activity.contact] : null;
        emailService.prepareEmailForSubject({ contacts }, activity.name, activity.note);
      },
      hideModals () {
        this.showActivityModal = false;
        this.showRecordModal = false;
      },
      typeIcon (activityType) {
        switch (activityType) {
          case 'EMAIL':
            return 'fa fa-envelope bg-blue';
          case 'CALL':
            return 'fa fa-phone bg-green';
          case 'PERSONAL_MEETING':
            return 'fa fa-users bg-purple';
          case 'TO_DO':
            return 'fa fa-list-ol bg-yellow';
        }
      },
      statusIcon (status) {
        switch (status) {
          case 'PLANNED':
            return 'fa fa-clock-o text-warning';
          case 'DONE':
            return 'fa fa-check-circle-o text-success';
          case 'CANCELED':
            return 'fa fa-ban text-danger';
        }
      }
    },
    mounted () {
      this.$store.dispatch('activity/getAllByFilter', { [this.entityType + 'Id']: this.entity.id });
    }
  };
</script>
